import { useState, useMemo } from 'react';
import Styled from 'components/emotion/Modal/styled';
import { useTranslation } from 'react-i18next';

const MosaicEditInputs = ({ onEdit, data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Styled.MosaicLabel>{t('mosaic.modal.title')}</Styled.MosaicLabel>
      <Styled.MosaicInput
        placeholder={t('mosaic.modal.title_placeholder')}
        onInput={onEdit('title')}
        value={data.title || ''}
      />
      <Styled.MosaicLabel>{t('mosaic.modal.description')}</Styled.MosaicLabel>
      <Styled.MosaicInput
        placeholder={t('mosaic.modal.description_placeholder')}
        onInput={onEdit('description')}
        value={data.description || ''}
      />
    </>
  );
};

export const useModalContent = (content, confirmExecution) => {
  const [mosaicMetadata, setMosaicMetadata] = useState(
    content.mosaicEdit && {
      title: content.mosaicEdit.title,
      description: content.mosaicEdit.description
    }
  );
  const category = useMemo(() => content.category ?? 'description', []);

  const handleMosaicEdit = (label) => (e) => {
    if (!content.mosaicEdit) {
      return;
    }

    if (label === 'title') {
      const title = e.target.value;
      if (title.length > 18) {
        return;
      }
      setMosaicMetadata((prev) => ({
        ...prev,
        title
      }));
    } else if (label === 'description') {
      const description = e.target.value;
      if (description.replaceAll(' ', '').length > 44) {
        return;
      }
      setMosaicMetadata((prev) => ({
        ...prev,
        description
      }));
    }
  };

  const handleConfirmClick = async (setIsDelay, setIsShown) => {
    if (category === 'mosaicInput') {
      await confirmExecution.fn({ ...confirmExecution.parameters, metadata: mosaicMetadata });
    } else {
      await confirmExecution.fn(confirmExecution.parameters);
    }
    setIsDelay(false);
    setIsShown(false);
  };

  let inner;
  switch (category) {
    case 'description':
      inner = content.description
        .split('<line-break>')
        .map((line, index) => (
          <Styled.ModalDescription key={index}>{line}</Styled.ModalDescription>
        ));
      break;
    case 'mosaicInput':
      inner = <MosaicEditInputs onEdit={handleMosaicEdit} data={mosaicMetadata} />;
      break;
  }

  return { inner, handleConfirmClick };
};
