import { createContext, useState, useContext } from 'react';
import { useSnackActivation } from 'hooks';
import { createPortal } from 'react-dom';
import Snack from 'components/Snack';

export const GlobalSnackContext = createContext({});

export const GlobalSnackProvider = ({ children }) => {
  const { isShown, initializeRemainingTime, hideSnack } = useSnackActivation();

  const [message, setMessage] = useState(null);
  const [response, setResponse] = useState(null);
  const [refreshEnabled, setRefreshEnabled] = useState(false);
  const [goEnabled, setGoEnabled] = useState(false);
  const [goTo, setGoTo] = useState(null);

  function snack({
    response = null,
    errorMessage = null,
    refreshButtonEnabled = false,
    goButtonEnabled = false,
    goTo = {},
    toHideSnack = false
  }) {
    if (toHideSnack) {
      hideSnack();
      return;
    }
    initializeRemainingTime();

    setMessage(errorMessage);
    setResponse(response);
    setRefreshEnabled(refreshButtonEnabled);
    setGoEnabled(goButtonEnabled);
    setGoTo(goTo);
  }

  return (
    <GlobalSnackContext.Provider value={snack}>
      {children}
      {isShown &&
        createPortal(
          <Snack
            response={response}
            message={message}
            refreshEnabled={refreshEnabled}
            goEnabled={goEnabled}
            goTo={goTo}
          />,
          document.getElementById('toast')
        )}
    </GlobalSnackContext.Provider>
  );
};
export const useGlobalSnack = () => {
  return useContext(GlobalSnackContext);
};
