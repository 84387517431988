import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import MainHeader from 'components/MainHeader';
import SearchInput from 'components/inputs/SearchInput';
import PurchasedItemSearchInput from 'components/inputs/PurchasedItemSearchInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import CohortAreaChart from 'components/charts/CohortAreaChart';
import AdvancedButton from 'components/buttons/AdvancedButton';
import { TabButton } from 'components/buttons/TabButton';
import NoData from 'components/NoData';
import { INFO24PX, COHORT_TOOLTIP_LTV, COHORT_TOOLTIP_RETENTION, EXPORT } from 'constants/images';
import { useCustomerServiceMutation, usePageInit } from 'hooks';
import { mutationKeys, queryKeys } from 'constants/keys';
import { useUserInfoContext } from 'context';
// import { extractMonth } from 'utils';
import { useDashboardContext } from 'context';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { checkIsAdvanced } from 'hooks/service/useGetCustomerService/utils';
import { nameExportFile, numberWithCommas, extractMonth } from 'utils';
import './index.scss';
import { axiosPrivate, customerAPI } from 'api';
import useGetResult from 'hooks/service/useGetCustomerService/useGetResult';
import { getReportsParameters } from 'utils/userStorage';
import {
  ParametersSkeleton,
  ParameterSkeleton,
  TitleSkeleton,
  DynamicSkeleton
} from 'components/skeletons';

function adjustPeriodParameter(
  periodOption,
  selectedIntervalId,
  previousPeriodId,
  previousPeriodValue
) {
  let id;
  let value;
  if (selectedIntervalId === 'year') {
    id = previousPeriodId;
    value = previousPeriodValue;
  }
  if (selectedIntervalId === 'quarter') {
    if (
      previousPeriodId === 'week' ||
      previousPeriodId === 'month' ||
      previousPeriodId === 'quarter'
    ) {
      id = previousPeriodId;
      value = previousPeriodValue;
    } else if (previousPeriodId === 'year') {
      id = periodOption.filter((v) => v.id === 'quarter')[0].id;
      value = periodOption.filter((v) => v.id === 'quarter')[0].value;
    }
  }
  if (selectedIntervalId === 'month') {
    if (previousPeriodId === 'week' || previousPeriodId === 'month') {
      id = previousPeriodId;
      value = previousPeriodValue;
    } else if (previousPeriodId === 'quarter' || previousPeriodId === 'year') {
      id = periodOption.filter((v) => v.id === 'month')[0].id;
      value = periodOption.filter((v) => v.id === 'month')[0].value;
    }
  }
  return { id, value };
}

const transformOptions = (data) => {
  const toBeReturn = {
    interval: data.intervals.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    monthlyFrom: data.ranges.month.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    monthlyTo: data.ranges.month.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    quarterlyFrom: data.ranges.quarter.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    quarterlyTo: data.ranges.quarter.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    yearlyFrom: data.ranges.year.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    yearlyTo: data.ranges.year.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    retention: data.intervals.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    period: data.periods.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    segment: data.segments.map((v) => ({ id: +v.id, value: v.name ? v.name : '' })),
    channel: data.channels.map((v) => ({ id: +v.id, value: v.name })),
    trackFirstItemOnly: [
      {
        id: false,
        value: 'No'
      },
      {
        id: true,
        value: 'Yes'
      }
    ],
    anyMoment: [
      {
        id: false,
        value: 'No'
      },
      {
        id: true,
        value: 'Yes'
      }
    ],
    status: data.statuses.map((v) => ({ id: +v.id, value: v.name })),
    region: data.regions.map((v) => ({ id: +v.id, value: v.name }))
  };
  return toBeReturn;
};
const CohortAnalysis = () => {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();
  const { currency } = useDashboardContext();
  const [tabSelectIdx, setTabSelectIdx] = useState({
    ltv: 0
  });
  const [isCohortColorOn, setIsCohortColorOn] = useState({
    ltv: true,
    retention: true
  });
  const [selectedParameters, setSelectedParameters] = useState(null);
  const [advancedButton, setAdvancedButton] = useState('default'); // default, pressed
  const [isGetResult, setIsGetResult] = useState(false);
  const reportsParameters = getReportsParameters();
  // const { option, result, parameters, advancedButton, handlers } = useGetCustomerService({
  //   optionQueryKey: queryKeys.OPTION.COHORT,
  //   serviceQueryKey: queryKeys.SERVICE.COHORT,
  // });
  const { data: optionWithoutPurchasedItem } = useQuery(
    [queryKeys.OPTION.COHORT, 'options', userInfo?.company.shopId],
    async ({ queryKey }) => {
      const { data } = await axiosPrivate.post(customerAPI.OPTION, {
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        page: queryKey[0]
      });
      return data;
    },
    {
      select: (data) => transformOptions(data)
    }
  );
  const { data: result } = useGetResult(
    isGetResult && selectedParameters
      ? checkIsAdvanced[queryKeys.SERVICE.COHORT](advancedButton)
      : null,
    selectedParameters
  );
  const [logExport] = useCustomerServiceMutation({
    mutationKey: mutationKeys.CUSTOMER.LOG_EXPORT
  });

  const handleTabSelect = (key, idx) => {
    setTabSelectIdx((prev) => ({ ...prev, [key]: idx }));
  };

  const handleToggle = (category) =>
    setIsCohortColorOn((prev) => {
      return {
        ...prev,
        [category]: !prev[category]
      };
    });

  const handleResultGet = () => {
    setIsGetResult(true);
  };

  const handleAdvancedButton = (state) => {
    setAdvancedButton(state);
  };

  const handleOptionsFilter = ({ label }) => {
    if (label === 'from') {
      if (selectedParameters.interval.id === 'month') {
        return optionWithoutPurchasedItem.monthlyFrom.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.monthlyTo.id) < 0
        );
      } else if (selectedParameters.interval.id === 'quarter') {
        return optionWithoutPurchasedItem.quarterlyFrom.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.quarterlyTo.id) < 0
        );
      } else {
        return optionWithoutPurchasedItem.yearlyFrom.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.yearlyTo.id) < 0
        );
      }
    }
    if (label === 'to') {
      if (selectedParameters.interval.id === 'month') {
        return optionWithoutPurchasedItem.monthlyTo.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.monthlyFrom.id) > 0
        );
      } else if (selectedParameters.interval.id === 'quarter') {
        return optionWithoutPurchasedItem.quarterlyTo.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.quarterlyFrom.id) > 0
        );
      } else {
        return optionWithoutPurchasedItem.yearlyTo.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.yearlyFrom.id) > 0
        );
      }
    }
    if (label === 'retention') {
      if (selectedParameters.interval.id === 'month') {
        return optionWithoutPurchasedItem.retention;
      } else if (selectedParameters.interval.id === 'quarter') {
        return optionWithoutPurchasedItem.retention.filter(
          (v) => v.id === 'quarter' || v.id === 'year'
        );
      } else {
        return optionWithoutPurchasedItem.retention.filter((v) => v.id === 'year');
      }
    }
    if (label === 'period') {
      if (selectedParameters.interval.id === 'month') {
        return optionWithoutPurchasedItem.period.filter((v) => v.id === 'week' || v.id === 'month');
      } else if (selectedParameters.interval.id === 'quarter') {
        return optionWithoutPurchasedItem.period.filter(
          (v) => v.id === 'week' || v.id === 'month' || v.id === 'quarter'
        );
      } else {
        return optionWithoutPurchasedItem.period;
      }
    }
    return;
  };
  const handleParameterGet = ({ label, interval }) => {
    if (label === 'from') {
      switch (interval) {
        case 'month':
          return selectedParameters.monthlyFrom;
        case 'quarter':
          return selectedParameters.quarterlyFrom;
        default:
          return selectedParameters.yearlyFrom;
      }
    }

    if (label === 'to') {
      switch (interval) {
        case 'month':
          return selectedParameters.monthlyTo;
        case 'quarter':
          return selectedParameters.quarterlyTo;
        default:
          return selectedParameters.yearlyTo;
      }
    }

    if (label === 'purchasedItem') {
      return {
        category: selectedParameters.category,
        item: selectedParameters.item,
        anyMoment: selectedParameters.anyMoment
      };
    }

    return selectedParameters[label];
  };

  const handleParameterSet = (selectedOption, label) => {
    let key = label === 'additional' ? 'region' : label;
    if (key === 'from') {
      key =
        selectedParameters.interval.value === 'monthly'
          ? 'monthlyFrom'
          : selectedParameters.interval.value === 'quarterly'
          ? 'quarterlyFrom'
          : 'yearlyFrom';
    }
    if (key === 'to') {
      key =
        selectedParameters.interval.value === 'monthly'
          ? 'monthlyTo'
          : selectedParameters.interval.value === 'quarterly'
          ? 'quarterlyTo'
          : 'yearlyTo';
    }
    if (key === 'interval') {
      setSelectedParameters((prev) => ({
        ...prev,
        [key]: {
          id: selectedOption.id,
          value: selectedOption.value
        },
        period: adjustPeriodParameter(
          optionWithoutPurchasedItem.period,
          selectedOption.id,
          prev.period.id,
          prev.period.value
        )
      }));
    } else {
      setSelectedParameters((prev) => ({
        ...prev,
        [key]: {
          id: selectedOption.id,
          value: selectedOption.value
        }
      }));
    }
  };

  useEffect(() => {
    setIsGetResult(false);
  }, [result]);

  useEffect(() => {
    if (optionWithoutPurchasedItem) {
      if (reportsParameters) {
        setAdvancedButton('pressed');
        setSelectedParameters((prev) => ({
          ...prev,
          interval: {
            id: reportsParameters.interval,
            value: optionWithoutPurchasedItem.interval.filter(
              (v) => v.id === reportsParameters.interval
            )[0].value
          },
          monthlyFrom: {
            id: reportsParameters.monthlyFrom,
            value: reportsParameters.monthlyFrom
          },
          monthlyTo: {
            id: reportsParameters.monthlyTo,
            value: reportsParameters.monthlyTo
          },
          quarterlyFrom: {
            id: optionWithoutPurchasedItem.quarterlyFrom[0].id,
            value: optionWithoutPurchasedItem.quarterlyFrom[0].value
          },
          quarterlyTo: {
            id: optionWithoutPurchasedItem.quarterlyTo[
              optionWithoutPurchasedItem.quarterlyTo.length - 1
            ].id,
            value:
              optionWithoutPurchasedItem.quarterlyTo[
                optionWithoutPurchasedItem.quarterlyTo.length - 1
              ].value
          },
          yearlyFrom: {
            id: optionWithoutPurchasedItem.yearlyFrom[0].id,
            value: optionWithoutPurchasedItem.yearlyFrom[0].value
          },
          yearlyTo: {
            id: optionWithoutPurchasedItem.yearlyTo[optionWithoutPurchasedItem.yearlyTo.length - 1]
              .id,
            value:
              optionWithoutPurchasedItem.yearlyTo[optionWithoutPurchasedItem.yearlyTo.length - 1]
                .value
          },
          segment: {
            id: reportsParameters.segment,
            value: optionWithoutPurchasedItem.segment.filter(
              (v) => v.id === reportsParameters.segment
            )[0].value
          },
          period: {
            id: reportsParameters.period,
            value: optionWithoutPurchasedItem.period.filter(
              (v) => v.id === reportsParameters.period
            )[0].value
          },
          channel: {
            id: reportsParameters.channel,
            value: optionWithoutPurchasedItem.channel.filter(
              (v) => v.id === reportsParameters.channel
            )[0].value
          },
          trackFirstItemOnly: {
            id: reportsParameters.trackFirstItemOnly,
            value: optionWithoutPurchasedItem.trackFirstItemOnly.filter(
              (v) => v.id === reportsParameters.trackFirstItemOnly
            )[0].value
          },
          region: {
            id: reportsParameters.region,
            value: optionWithoutPurchasedItem.region.filter((v) => v.id === 0)[0].value
          },
          anyMoment: {
            id: reportsParameters.anyMoment,
            value: optionWithoutPurchasedItem.anyMoment.filter((v) => v.id === false)[0].value
          },
          item: {},
          category: {}
        }));
      } else {
        setSelectedParameters((prev) => ({
          ...prev,
          interval: {
            id: optionWithoutPurchasedItem.interval.filter((v) => v.id === 'month')[0].id,
            value: optionWithoutPurchasedItem.interval.filter((v) => v.id === 'month')[0].value
          },
          monthlyFrom: {
            id:
              optionWithoutPurchasedItem.monthlyFrom.length > 24
                ? optionWithoutPurchasedItem.monthlyFrom[
                    optionWithoutPurchasedItem.monthlyFrom.length - 24
                  ].id
                : optionWithoutPurchasedItem.monthlyFrom[0].id,
            value:
              optionWithoutPurchasedItem.monthlyFrom.length > 24
                ? optionWithoutPurchasedItem.monthlyFrom[
                    optionWithoutPurchasedItem.monthlyFrom.length - 24
                  ].value
                : optionWithoutPurchasedItem.monthlyFrom[0].value
          },
          monthlyTo: {
            id: optionWithoutPurchasedItem.monthlyTo[
              optionWithoutPurchasedItem.monthlyTo.length - 1
            ].id,
            value:
              optionWithoutPurchasedItem.monthlyTo[optionWithoutPurchasedItem.monthlyTo.length - 1]
                .value
          },
          quarterlyFrom: {
            id: optionWithoutPurchasedItem.quarterlyFrom[0].id,
            value: optionWithoutPurchasedItem.quarterlyFrom[0].value
          },
          quarterlyTo: {
            id: optionWithoutPurchasedItem.quarterlyTo[
              optionWithoutPurchasedItem.quarterlyTo.length - 1
            ].id,
            value:
              optionWithoutPurchasedItem.quarterlyTo[
                optionWithoutPurchasedItem.quarterlyTo.length - 1
              ].value
          },
          yearlyFrom: {
            id: optionWithoutPurchasedItem.yearlyFrom[0].id,
            value: optionWithoutPurchasedItem.yearlyFrom[0].value
          },
          yearlyTo: {
            id: optionWithoutPurchasedItem.yearlyTo[optionWithoutPurchasedItem.yearlyTo.length - 1]
              .id,
            value:
              optionWithoutPurchasedItem.yearlyTo[optionWithoutPurchasedItem.yearlyTo.length - 1]
                .value
          },
          segment: {
            id: optionWithoutPurchasedItem.segment.filter((v) => v.id === 0)[0].id,
            value: optionWithoutPurchasedItem.segment.filter((v) => v.id === 0)[0].value
          },
          period: {
            id: optionWithoutPurchasedItem.period.filter((v) => v.id === 'month')[0].id,
            value: optionWithoutPurchasedItem.period.filter((v) => v.id === 'month')[0].value
          },
          channel: {
            id: optionWithoutPurchasedItem.channel.filter((v) => v.id === 0)[0].id,
            value: optionWithoutPurchasedItem.channel.filter((v) => v.id === 0)[0].value
          },
          trackFirstItemOnly: {
            id: optionWithoutPurchasedItem.trackFirstItemOnly.filter((v) => v.id === false)[0].id,
            value: optionWithoutPurchasedItem.trackFirstItemOnly.filter((v) => v.id === false)[0]
              .value
          },
          region: {
            id: optionWithoutPurchasedItem.region.filter((v) => v.id === 0)[0].id,
            value: optionWithoutPurchasedItem.region.filter((v) => v.id === 0)[0].value
          },
          anyMoment: {
            id: optionWithoutPurchasedItem.anyMoment.filter((v) => v.id === false)[0].id,
            value: optionWithoutPurchasedItem.anyMoment.filter((v) => v.id === false)[0].value
          },
          item: {},
          category: {}
        }));
      }

      setIsGetResult(true);
    }
  }, [optionWithoutPurchasedItem]);

  const handleExport = () => {
    if (userInfo?.user.role === 'observer') return;
    if (result === 'no data') return;

    const firstWorksheetColumns = [
      { header: '', key: 'void' },
      { header: 'parameter', key: 'parameter' },
      { header: 'value', key: 'value' }
    ];
    const secondWorksheetColumns = result.cohortAnalysisTable.headers.map((v) => ({
      header: v,
      key: v.replace(' ', '').toLowerCase()
    }));
    const thirdWorksheetColumns = result.cohortAnalysisTable.headers.map((v) => ({
      header: v,
      key: v.replace(' ', '').toLowerCase()
    }));

    const firstWorksheetRows = [
      {
        void: 'Basic',
        parameter: 'Interval',
        value: selectedParameters.interval.value
      },
      {
        void: 'Basic',
        parameter: 'From',
        value:
          selectedParameters.interval.value === 'monthly'
            ? selectedParameters.monthlyFrom.value
            : selectedParameters.interval.value === 'quarterly'
            ? selectedParameters.quarterlyFrom.value
            : selectedParameters.yearlyFrom.value
      },
      {
        void: 'Basic',
        parameter: 'To',
        value:
          selectedParameters.interval.value === 'monthly'
            ? selectedParameters.monthlyTo.value
            : selectedParameters.interval.value === 'quarterly'
            ? selectedParameters.quarterlyTo.value
            : selectedParameters.yearlyTo.value
      },
      {
        void: 'Basic',
        parameter: 'Segment',
        value: selectedParameters.segment.value
      },
      {
        void: 'Advanced',
        parameter: 'Period',
        value: selectedParameters.period.value
      },
      {
        void: 'Advanced',
        parameter: 'Channel',
        value: selectedParameters.channel.value
      },
      {
        void: 'Advanced',
        parameter: 'Additional',
        value: selectedParameters.region.value
      },
      {
        void: 'Advanced',
        parameter: 'Category',
        value: selectedParameters.category.value
      },
      {
        void: 'Advanced',
        parameter: 'Item',
        value: selectedParameters.item.value
      },
      {
        void: 'Advanced',
        parameter: 'Any Moment',
        value: selectedParameters.anyMoment.value
      }
    ];
    const secondWorksheetRows = result.cohortAnalysisTable.rows
      .filter((v, i) => i % 2 === 0)
      .map((v) => {
        const row = {};
        v.forEach((w, j) => {
          if (j === 0) {
            row[secondWorksheetColumns[j].key] = w.value.slice(0, -3);
          } else {
            row[secondWorksheetColumns[j].key] = w.value;
          }
        });
        return row;
      });
    const thirdWorksheetRows = result.cohortAnalysisTable.rows
      .filter((v, i) => i % 2 === 1)
      .map((v, i) => {
        const row = {};
        v.forEach((w, j) => {
          if (j === 0) {
            row[thirdWorksheetColumns[j].key] = secondWorksheetRows[i].month;
          } else if (j == 1) {
            row[thirdWorksheetColumns[j].key] = secondWorksheetRows[i].nc;
          } else {
            row[thirdWorksheetColumns[j].key] = w.value / 100;
          }
        });
        return row;
      });

    const workbook = new Excel.Workbook();
    const firstWorksheetName = 'Parameters';
    const secondWorksheetName = 'LTV';
    const thirdWorksheetName = 'Retention';
    const workbookName = `${nameExportFile('cohort').slice(0, -4)}.xlsx`;
    const saveExcel = async () => {
      try {
        const firstWorksheet = workbook.addWorksheet(firstWorksheetName);
        const secondWorksheet = workbook.addWorksheet(secondWorksheetName);
        const thirdWorksheet = workbook.addWorksheet(thirdWorksheetName);

        /**
         * First worksheet : parameteers
         */
        firstWorksheet.columns = firstWorksheetColumns;
        firstWorksheet.getRow(1).font = { bold: true };
        firstWorksheet.columns.forEach((column, i) => {
          if (i === 0) {
            column.width = 13;
          } else {
            column.width = column.header.length + 5;
          }
          column.alignment = { horizontal: 'center' };
        });
        firstWorksheetRows.forEach((singleData) => {
          firstWorksheet.addRow(singleData);
        });
        firstWorksheet.eachRow({ includeEmpty: false }, (row) => {
          const currentCell = row._cells;
          currentCell.forEach((singleCell) => {
            const cellAddress = singleCell._address;
            firstWorksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        });

        /**
         * Second worksheet : ltv
         */
        const ltvOriginData = result.cohortAnalysisTable.rows.filter((v, i) => i % 2 === 0);
        const ltvColors = {
          'neon-blue-900': '060073',
          'neon-blue-800': '0b03a6',
          'neon-blue-700': '2118d9',
          'neon-blue-600': '362ef2',
          'neon-blue-500': '4b43ff',
          'neon-blue-400': '6a62fe',
          'neon-blue-300': '8681fc',
          'neon-blue-200': 'a8a3fb',
          'neon-blue-100': 'c1bef7',
          'neon-blue-50': 'e0dff8'
        };

        secondWorksheet.columns = secondWorksheetColumns;
        secondWorksheet.getRow(1).font = { bold: true };
        secondWorksheet.columns.forEach((column) => {
          column.width = column.header.length + 5;
          column.alignment = { horizontal: 'center' };
        });
        secondWorksheetRows.forEach((singleData) => {
          secondWorksheet.addRow(singleData);
        });
        secondWorksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          const currentCell = row._cells;

          currentCell.forEach((singleCell, i) => {
            const cellAddress = singleCell._address;
            secondWorksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
            if (cellAddress.replace(/\D/g, '') !== '1') {
              const className = ltvOriginData[rowNumber - 2][i].className;
              if (className && isCohortColorOn.ltv) {
                secondWorksheet.getCell(cellAddress).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: ltvColors[className] }
                };
                if (Number(className.replace(/\D/g, '')) >= 500) {
                  secondWorksheet.getCell(cellAddress).font = {
                    color: { argb: 'ffffff' }
                  };
                }
              }
            }
          });
        });

        /**
         * Third worksheet : retention
         */
        const retentionOriginData = result.cohortAnalysisTable.rows.filter((v, i) => i % 2 === 1);
        const retentionColors = {
          'red-900': 'ad0000',
          'red-800': 'ce1212',
          'red-700': 'da1b1b',
          'red-600': 'e93535',
          'red-500': 'f94c4c',
          'red-400': 'ff7070',
          'red-300': 'ff9999',
          'red-200': 'ffb3b3',
          'red-100': 'fad1d1',
          'red-50': 'fde7e7'
        };

        thirdWorksheet.columns = thirdWorksheetColumns;
        thirdWorksheet.getRow(1).font = { bold: true };
        thirdWorksheet.columns.forEach((column) => {
          column.width = column.header.length + 5;
          column.alignment = { horizontal: 'center' };
        });
        thirdWorksheetRows.forEach((singleData) => {
          thirdWorksheet.addRow(singleData);
        });
        thirdWorksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          const currentCell = row._cells;
          currentCell.forEach((singleCell, i) => {
            const cellAddress = singleCell._address;
            thirdWorksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
            if (cellAddress.replace(/\D/g, '') !== '1') {
              const className = retentionOriginData[rowNumber - 2][i].className;
              if (className && isCohortColorOn.retention) {
                thirdWorksheet.getCell(cellAddress).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: retentionColors[className] }
                };
                if (Number(className.replace(/\D/g, '')) >= 700) {
                  thirdWorksheet.getCell(cellAddress).font = {
                    color: { argb: 'ffffff' }
                  };
                }
              }
            }
          });
        });

        /**
         * Export it!
         */
        const buf = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buf]), workbookName);
        logExport({
          companyId: userInfo?.company.companyId,
          shopId: userInfo?.company.shopId,
          page: 'life_time_value',
          fileName: `${nameExportFile('cohort').slice(0, -4)}.xlsx`
        });
      } catch (error) {
        console.error(error);
      } finally {
        workbook.removeWorksheet(firstWorksheetName);
        workbook.removeWorksheet(secondWorksheetName);
        workbook.removeWorksheet(thirdWorksheetName);
      }
    };
    saveExcel();
  };

  useEffect(() => {
    if (selectedParameters) {
      setSelectedParameters(null);
    }
  }, [userInfo?.company.shopId]);

  useEffect(() => {
    usePageInit();
  }, [])
  

  return (
    <>
      <MainHeader
        main={t("cohort.cohort")}
        sub={t("cohort.see_how_much_value_your")}
      />
      {!selectedParameters || !optionWithoutPurchasedItem ? (
        <ParametersSkeleton>
          <ParameterSkeleton />
          <ParameterSkeleton />
          <ParameterSkeleton />
          <ParameterSkeleton />
        </ParametersSkeleton>
      ) : (
        <div className="box ltv-parameters">
          <div className="row first">
            <SearchInput
              label="interval"
              width="260"
              options={optionWithoutPurchasedItem.interval}
              parameter={handleParameterGet({ label: 'interval' })}
              setParameter={handleParameterSet}
            />
            <SearchInput
              label="from"
              width="260"
              options={handleOptionsFilter({ label: 'from' })}
              interval={selectedParameters.interval.id}
              parameter={handleParameterGet({
                label: 'from',
                interval: selectedParameters.interval.id
              })}
              setParameter={handleParameterSet}
            />
            <SearchInput
              label="to"
              width="260"
              options={handleOptionsFilter({ label: 'to' })}
              interval={selectedParameters.interval.id}
              parameter={handleParameterGet({
                label: 'to',
                interval: selectedParameters.interval.id
              })}
              setParameter={handleParameterSet}
            />
            <SearchInput
              label="segment"
              width="260"
              options={optionWithoutPurchasedItem.segment}
              parameter={handleParameterGet({ label: 'segment' })}
              setParameter={handleParameterSet}
            />
          </div>
          {advancedButton === 'pressed' && (
            <>
              <div className="advanced-division">
                <svg>
                  <line x1="0" y1="50%" x2="100%" y2="50%" stroke="#DDDDDD" strokeDasharray={3} />
                </svg>
                <span className="info">
                  {t("retention.advanced")}
                </span>
                <span className="tooltip-box">
                    <TooltipInfo belong="parameter" />
                  </span>
              </div>
              <div className="row second">
                <SearchInput
                  label="period"
                  width="260"
                  style={{ zIndex: 5 }}
                  options={handleOptionsFilter({ label: 'period' })}
                  interval={selectedParameters.interval.id}
                  parameter={handleParameterGet({ label: 'period' })}
                  setParameter={handleParameterSet}
                  labelTooltip={
                    <span className="period-tooltip-box">
                      <PeriodTooltipInfo />
                    </span>
                  }
                />
                <PurchasedItemSearchInput
                  label={t("options.purchased_item")}
                  width="260"
                  queryKey={queryKeys.OPTION.COHORT}
                  anyMomentOptions={optionWithoutPurchasedItem.anyMoment}
                  parameters={handleParameterGet({ label: 'purchasedItem' })}
                  setParameter={handleParameterSet}
                  setParametersState={setSelectedParameters} // temporarily
                  reportsParameters={reportsParameters} // temporarily
                />
                <SearchInput
                  style={{ zIndex: 4 }}
                  label="channel"
                  width="260"
                  options={optionWithoutPurchasedItem.channel}
                  parameter={handleParameterGet({ label: 'channel' })}
                  setParameter={handleParameterSet}
                />
                <SearchInput
                  style={{ zIndex: 4 }}
                  label="additional"
                  width="260"
                  options={optionWithoutPurchasedItem.region}
                  parameter={handleParameterGet({ label: 'region' })}
                  setParameter={handleParameterSet}
                />
              </div>
              <div className="row third"></div>
            </>
          )}
          <div className="row fourth">
            <PrimaryButton value={t("cohort.apply")} onClick={handleResultGet} style={{ width: '124px' }} />
            <AdvancedButton state={advancedButton} changeState={handleAdvancedButton} />
          </div>
        </div>
      )}
      <>
        <div className="box cohort-container">
          {!result ? (
            <div className="overview-skeleton-container">
              <DynamicSkeleton width={1100} height={800} />
            </div>
          ) : (
            <>
              <div className="cohort-toggles-container">
                <div className="cohort-toggles">
                  <div className="cohort-toggle">
                    <TooltipInfo
                      belong="cohort"
                      category="ltv"
                      text={t("cohort.the_larger_the_difference")}
                    />
                    {t("cohort.ltv_heatmap")}
                    <ToggleButton
                      handleToggle={handleToggle}
                      category="ltv"
                      isOn={isCohortColorOn.ltv}
                    />
                  </div>
                  <div className="cohort-toggle">
                    <TooltipInfo
                      belong="cohort"
                      category="retention"
                      text={t("cohort.the_greater_the_value")}
                    />
                    {t("cohort.retention_heatmap")}
                    <ToggleButton
                      handleToggle={handleToggle}
                      category="retention"
                      isOn={isCohortColorOn.retention}
                    />
                  </div>
                </div>
                <div className="cohort-export-button" onClick={handleExport}>
                  <img src={EXPORT} alt="export-button" />
                  {t("settings.export")}
                </div>
              </div>
              {result.cohortAnalysisTable === 'no data' ? (
                <NoData />
              ) : (
                <div className="cohort-table-container">
                  <table className="cohort-table">
                    <thead>
                      <tr>
                        {result.cohortAnalysisTable.headers.map((v, i) => (
                          <th key={`${v}-${i}`}>{v}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {result.cohortAnalysisTable.rows.map((v, i) => (
                        <tr key={`tr${i}`}>
                          {v.map((x, j) => {
                            // ltv 라인인 경우
                            if (i % 2 === 0) {
                              if (j === 0) {
                                const dateValue = x.value;

                                if (dateValue.includes('w')) {
                                  const year = x.value.slice(2, 4);
                                  const month = x.value.slice(5, 7);
                                  let yearAndMonth = `${year}.${month}`;

                                  return (
                                    <td key={`td${j}`}>
                                      {(i === 0 || x.value.slice(-2) === '1w') && (
                                        <span className="year">{yearAndMonth}</span>
                                      )}
                                      <span className="month">{x.value.slice(-2)}</span>
                                    </td>
                                  );
                                } else {
                                  const year = x.value.slice(0, 4);
                                  let date = x.value.slice(5, 7);
                                  if (date[0] === '0') date = date.slice(1);

                                  return (
                                    <td key={`td${j}`}>
                                      {(i === 0 || date === '1') && (
                                        <span className="year">{year}</span>
                                      )}
                                      <span className="month">
                                        {extractMonth(x.value)}
                                      </span>
                                    </td>
                                  );
                                }
                              }
                              if (j === 1) {
                                return <td key={`td${j}`}>{numberWithCommas(x.value)}</td>;
                              }
                              return (
                                <td
                                  className={isCohortColorOn.ltv ? `${x.className}` : ''}
                                  key={`td${j}`}
                                >
                                  {numberWithCommas(x.value)}
                                </td>
                              );
                            }

                            // retention 라인인 경우
                            if (i % 2 === 1) {
                              if (j < 2) return <td key={`td${j}`}></td>;
                              return (
                                <td
                                  className={isCohortColorOn.retention ? `${x.className}` : 'off'}
                                  key={`td${j}`}
                                >
                                  {x.value}%
                                </td>
                              );
                            }
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>

        <div className="box customer-value-progress-chart-container">
          {!result ? (
            <div className="overview-skeleton-container">
              <TitleSkeleton width={150} />
              <DynamicSkeleton width={1100} height={380} />
            </div>
          ) : result.customerValueProgressChart.customerValueProgress === 'no data' ? (
            <NoData />
          ) : (
            <CohortAreaChart
              title={t("cohort.customer_value_progress")}
              chartId="cvp"
              data={result.customerValueProgressChart.customerValueProgress}
              svgWidth={1072}
              svgHeight={404}
            />
          )}
        </div>
        <div className="box changes-over-time-chart-container">
          {!result ? (
            <div className="overview-skeleton-container">
              <TitleSkeleton width={150} />
              <DynamicSkeleton width={1100} height={380} />
            </div>
          ) : (tabSelectIdx.ltv === 0 && result.changesOverTimeChart.ltv6 === 'no data') ||
            (tabSelectIdx.ltv === 1 && result.changesOverTimeChart.ltv3 === 'no data') ? (
            <NoData />
          ) : (
            <CohortAreaChart
              title={<Trans i18nKey={"cohort.ltv_changes_over_time"} values={{number: tabSelectIdx.ltv === 0 ? '6' : '3'}}/>}
              chartId="cot"
              data={
                tabSelectIdx.ltv === 0
                  ? result.changesOverTimeChart.ltv6
                  : result.changesOverTimeChart.ltv3
              }
              svgWidth={1072}
              svgHeight={432}
            />
          )}
          <div className="toggle-button-container">
            {result && (
              <TabButton
                options={['LTV 6', 'LTV 3']}
                select={tabSelectIdx.ltv}
                selectKey={'ltv'}
                handleSelect={handleTabSelect}
              />
            )}
          </div>
        </div>
      </>
    </>
  );
};

function TooltipInfo({ belong, text, category }) {
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  return (
    <div className="tooltip-info-container">
      {isTooltipShown && (
        <div className={'tooltip' + (category === 'ltv' ? ' ltv' : ' retention')}>
          {belong === 'parameter' && (
            <>
              <Trans i18nKey={"cohort.if_you_adjust_advanced"} />
            </>
          )}
          {belong === 'cohort' && (
            <>
              {text}
              <br />
              <img src={category === 'ltv' ? COHORT_TOOLTIP_LTV : COHORT_TOOLTIP_RETENTION} />
            </>
          )}
        </div>
      )}

      <img
        src={INFO24PX}
        alt="info"
        onMouseEnter={() => {
          setIsTooltipShown(true);
        }}
        onMouseLeave={() => {
          setIsTooltipShown(false);
        }}
      />
    </div>
  );
}

const ToggleButton = ({ disabled, isOn, handleToggle, category }) => {
  return (
    <div
      className={`toggle ${disabled ? 'disabled' : ''} ${isOn ? 'on' : ''}`}
      onClick={() => !disabled && handleToggle(category)}
    >
      <div className={`toggle_btn ${disabled ? 'disabled' : ''} ${isOn ? 'on' : ''}`} />
    </div>
  );
};

function PeriodTooltipInfo() {
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  return (
    <div className="tooltip-info-container">
      {isTooltipShown && (
        <div className="tooltip">
          <Trans i18nKey={"cohort.this_affects_the_vertical"}/>
        </div>
      )}

      <img
        src={INFO24PX}
        alt="info"
        onMouseOver={() => {
          setIsTooltipShown(true);
        }}
        onMouseLeave={() => {
          setIsTooltipShown(false);
        }}
      />
    </div>
  );
}

export default CohortAnalysis;