import styled from '@emotion/styled';
import { theme } from 'styles@emotion/theme';

export const Container = styled.div`
  position: relative;
`;

export const Legend = {
  Container: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    column-gap: 12px;
    row-gap: 4px;
    margin-bottom: 26px;
  `,
  Item: styled.div`
    display: flex;
    column-gap: 5px;
    align-items: center;
    cursor: pointer;
  `,
  ItemName: styled.div`
    ${theme.font.chartLegendSemibold}
    color: ${({ isActive }) => (isActive ? theme.color.gray900 : theme.color.gray400)}
  `
};
export const Tooltip = {
  Container: styled.div`
    position: absolute;
    z-index: 2;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    width: ${({ width }) => (width ? `${width}px` : 'fit-content')};
    border-radius: 5px;
    background-color: ${theme.color.white};
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
    pointer-events: none;

    & > .tooltip-header {
      padding-bottom: 7px;
      border-bottom: 1px solid ${theme.color.gray300};
      & > .tooltip-header-element {
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 7px;
        & > .color {
          width: 9px;
          height: 9px;
          border-radius: 50%;
        }
        & > .title {
          ${theme.font.chartTooltipSmall}
          color: ${theme.color.gray900};
        }
      }
      & > .tooltip-header-additional {
        display: flex;
        align-items: center;
        column-gap: 5px;
        ${theme.font.chartTooltipSmall}
        color: ${theme.color.gray700};
        & .name {
          white-space: nowrap;
        }
        & .value {
        }
      }
    }

    & > .tooltip-body {
      padding-top: 10px;
      & > .tooltip-body-element {
        display: flex;
        align-items: center;
        column-gap: 5px;
        color: ${theme.color.gray900};

        &.bordered {
          margin-top: 7px;
          padding-top: 7px;
          border-top: 1px solid ${theme.color.gray300};
        }
        &.invisible {
          display: none;
        }

        & > .color {
          width: 9px;
          height: 9px;
          border-radius: 50%;
        }
        & > .bold {
          white-space: nowrap;
          ${theme.font.chartLegendSemibold}
        }
        & > .normal {
          ${theme.font.chartTooltipBody}
        }
        & > .light {
          ${theme.font.chartTooltipBody}
          color: ${theme.color.gray500};
        }
      }
    }
    ${({ arrowPosition }) => {
      switch (arrowPosition) {
        case 'bottom':
          return `
          & :after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -7px;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-top: 8px solid ${theme.color.white};
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
          }
          `;
        case 'topLeft':
          return `
          & :after {
            content: '';
            position: absolute;
            left: -5px;
            top: 10px;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-right-color: ${theme.color.white};
          }
          `;
        default:
          return;
      }
    }}
  `,
  Header: styled.div``,
  Body: styled.div``
};

export const Chart = styled.svg`
  & .y-axis {
    & > .tick {
      & > line {
        visibility: hidden;
      }
      & > text {
        ${theme.font.chartAxisLabel}
        color: ${theme.color.gray700};
      }
    }
  }
  & > .x-axis {
    & > path {
      visibility: hidden;
    }
    & > .tick {
      & > line {
        visibility: hidden;
      }
      & > text {
        ${theme.font.chartAxisLabel}
        color: ${theme.color.gray700};
      }
    }
  }
`;
