import styled from '@emotion/styled';
import { theme } from 'styles@emotion/theme';

export const PageTitle = {
  Container: styled.div`
    margin-bottom: 32px;
  `,
  Main: styled.div`
    ${theme.font.h6}
    color: ${theme.color.gray900};
  `,
  Sub: styled.div`
    margin: 0;
    ${theme.font.bodyRegular}
    color: ${theme.color.gray500};
  `,
};
