import styled from '@emotion/styled'
import { theme } from 'styles@emotion/theme'

const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.isVertical ? 'column' : 'row'};
  gap: 12px;
  align-self: end;
  align-items: end;
  margin-top: 25px;
`

const LabelContainer = styled.div`
  ${theme.font.chartLegend}

  &:hover {
    cursor: pointer;
  }
`

const Label = styled.span`
  vertical-align: middle;
  ${props => props.disabled ? 'opacity: 0.2' : ''};
  ${props => props.interactive ? 'font-weight: bold' : ''};
`

const FilledCircle = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border: 6px ${props => props.color} solid;
  margin-right: 5px;
  vertical-align: middle;
  ${props => props.disabled ? 'opacity: 0.2' : ''};
`

const LineCircle = styled(FilledCircle)`
  border-width: 3px;
  ${props => props.disabled ? 'opacity: 0.2' : ''};
`

export default {
  Container,
  LabelContainer,
  Label,
  LineCircle,
  FilledCircle
}