import styled from '@emotion/styled';
import { theme } from 'styles@emotion/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;
  gap: 30px;
  ${theme.font.bodyRegular}
`;
export const Buttons = styled.div`
  position: relative;
  display: flex;
  gap: 1.5px;
  width: fit-content;
`;
export const Button = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  left: -24px;
  padding: 15px 24px;
  background-color: ${({ selected }) => (selected ? theme.color.white : theme.color.gray50)};
  border-radius: 10px 10px 0px 0px;
  ${theme.font.buttonRegular}
  color: ${({ selected }) => (selected ? theme.color.gray900 : theme.color.gray500)};
  box-shadow: 2px -3px 10px rgba(0, 0, 0, 0.05);
  z-index: 1;
  & svg {
    margin-right: 10px;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 3px;
    background-color: ${({ selected }) => (selected ? theme.color.neonBlue500 : '')};
  }
`;
export const ButtonLine = styled.div`
  position: absolute;
  left: -29px;
  bottom: -20px;
  width: 1210px;
  height: 20px;
  background-color: transparent;
  box-shadow: -5px -14px 8px -8px rgba(0, 0, 0, 0.07);
  z-index: 2;
`;
