import { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'utils'
import { useClickOutside } from 'hooks/UI';
import { CHEVRON_DOWN, REMOVETEXT } from 'constants/images';
import 'components/inputs/OriginalSearchInput/index.scss';

export default function ForecastingSearchInput({
  label,
  width = 300,
  style = {},
  options = null,
  selectedValue,
  selectValue,
  disabled,
  helpTooltip,
  searchWithKeyword = null,
  fetchNextItemList = () => {}
}) {
  const { t } = useTranslation();
  const containerEl = useRef(null);
  const inputEl = useRef(null);
  const itemListRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFullOption, setIsFullOption] = useState(true); // if fullOption flag is checked, all options should be shown
  const [isClickedInside, setIsClickedInside] = useClickOutside(containerEl);
  const [inputValue, setInputValue] = useState(selectedValue);
  const [filterValue, setFilterValue] = useState('')

  const [ scrollTarget, setScrollTarget ] = useState(null)

  const [ visibleOptions, setVisibleOptions ] = useState([])

  const handleKeywordUpdate = useCallback(debounce(async (inputValue) => {
    const keyword = inputValue.toLowerCase()
    if (searchWithKeyword instanceof Function) {
      await searchWithKeyword(keyword)
      return
    }

    if (options === null || options === undefined)  return

    const filtered = options.filter((v) => v.value.toLowerCase().includes(keyword))
    setVisibleOptions(filtered)
  }), [options, searchWithKeyword])

  useEffect(() => {
    if (options !== null) {
      if (!selectedValue) {
        options.forEach((option) => {
          // setting initial param
          if (option.id === 0) {
            selectValue(option, label)
          }
        })
      }
      
      if (isFullOption) {
        setVisibleOptions(options)
        return
      }

      if (inputValue.length > 0) {
        // handleKeywordUpdate(inputValue)
        setVisibleOptions(options)
      }
      else if(inputValue.length === 0) {
        setVisibleOptions(options)
      }
    }
  }, [options])

    // visible options will be controlled by visibleOptions
  useEffect(() => {
    if (options !== null) {
      handleKeywordUpdate(filterValue)
    }
  }, [filterValue])
    

  useEffect(() => {
    if (!isClickedInside) {
      setIsFocused(false);
    }
  }, [isClickedInside]);

  // when focus out, if typed input is valid, set it to the parameter
  useEffect(() => {
    if (isFocused) {
      setIsFullOption(true);
      setInputValue('');
    }
    if (!isFocused) {
      if (options === null || options === undefined)  return
      
      const typedInput = options.filter((v) => v.value === inputValue);
      if (typedInput.length === 1) {
        selectValue(typedInput[0], label);
      } else {
        setInputValue(selectedValue)
        setFilterValue('')
      }
    }
  }, [isFocused, selectedValue]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && filterValue.length <= 0) fetchNextItemList();
      },
      {
        root: itemListRef.current,
      }
    );

    scrollTarget && intersectionObserver.observe(scrollTarget);

    return () => {
      intersectionObserver.disconnect();
    };
  }, [scrollTarget]);

  function optionToJSX(option, i) {
    return (
      <li
        onClick={() => {
          // setInputValue(option.value);
          setInputValue('')
          selectValue(option, label);
          setIsFocused(false);
        }}
        className={'option' + (selectedValue === option.value ? ' selected' : '')}
        key={`${option.value}${i}`}
        ref={
          i === visibleOptions.length - 50
          ? setScrollTarget
          : null
        }
      >
        {option.value}
      </li>
    )
  }

  return (
    <div
      ref={containerEl}
      className="search-input"
      style={{ ...style, width: `${width}px`, minWidth: `${width}px` }}
    >
      <label className="input-label" onClick={() => setIsClickedInside(false)}>
        {
          t(`options.${
            label === 'trackFirstItemOnly'
            ? 'track identical item only'
            : label === 'anyMoment'
            ? 'any moment'
            : label.includes('_')
            ? label.replace('_', ' ')
            : label
          }`)
        }
        {helpTooltip}
      </label>
      <div className={`input-wrapper ${label}`}>
        <input
          value={inputValue}
          onInput={(e) => {
            setInputValue(e.target.value);
            setFilterValue(e.target.value);
            if (isFullOption) setIsFullOption(false);
          }}
          ref={inputEl}
          className="input"
          placeholder={"Search"}
          disabled={disabled}
          onClick={() => {
            setIsFocused(true);
          }}
          // onKeyDown={(e) => {
          //   if (e.key === 'ArrowDown') {
          //   }
          //   if (e.key === 'ArrowUp') {
          //   }
          //   if (e.key === 'Enter') {
          //   }
          // }}
        />
        {isFocused && (
          <img
            src={REMOVETEXT}
            className="remove-text-icon"
            alt="remove-text"
            onClick={() => {
              setInputValue('');
            }}
          />
        )}
        <img
          onClick={() => {
            if (disabled) {
              return;
            }

            setIsFocused((prev) => !prev);
            if (!isFocused) inputEl.current.focus();
          }}
          src={CHEVRON_DOWN}
          className={`chevron-down${isFocused ? ' reverse' : ''} ${disabled ? 'disabled' : ''}`}
          alt="drop-down"
        />
      </div>
      {
        visibleOptions &&
          visibleOptions.length !== 0 &&
          isFocused && (
            <ul className="options-list" ref={itemListRef}>
              {visibleOptions
                .map(optionToJSX)}
            </ul>
          )
      }
    </div>
  );
}