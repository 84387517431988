import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { LEGEND_INDICATOR_FILLED_RECT, LEGEND_INDICATOR_DASHED_RECT } from 'constants/images';
import ChartSVG from 'components/charts/ChartSVG';
import { chartKeys } from 'constants/keys';

const initialLegend = {
  last: true,
  current: true,
  segments: []
};

export const NumberOfCategoriesAndCustomers = ({ data }) => {
  const { t } = useTranslation();
  const [isLegendSelect, setIsLegendSelect] = useState({
    ...initialLegend,
    last: data.isLastExist,
    segments: data.segments.map(() => true)
  });

  const handleLegendClick =
    (item, segmentIndex = null) =>
    (e) => {
      e.preventDefault();
      if (segmentIndex !== null) {
        const isOneTrue = isLegendSelect.segments.filter((v) => v).length < 2;
        if (isOneTrue && isLegendSelect.segments[segmentIndex]) return;

        setIsLegendSelect((prev) => ({
          ...prev,
          segments: prev.segments.map((v, i) =>
            i === segmentIndex ? !prev.segments[i] : prev.segments[i]
          )
        }));
      } else {
        if ((!isLegendSelect.last || !isLegendSelect.current) && isLegendSelect[item] === true)
          return;
        setIsLegendSelect((prev) => ({
          ...prev,
          [item]: !prev[item]
        }));
      }
    };
  return (
    <>
      <div className="segmentation-ncc_legend_dates">
        <div className={`segmentation-ncc_legend_dates_item disable`}>
          <img src={LEGEND_INDICATOR_FILLED_RECT} alt="legend-indicator-filled" />

          <div>
            <Trans
              i18nKey={'segmentation.data_updated_on'}
              values={{ date: data.updateDates[0] }}
            />
          </div>
        </div>
        {data.updateDates[1] && (
          <div
            className={`segmentation-ncc_legend_dates_item ${!isLegendSelect.last ? 'off' : ''}`}
            onClick={handleLegendClick('last')}
          >
            <img src={LEGEND_INDICATOR_DASHED_RECT} alt="legend-indicator-dashed" />
            <div>
              <Trans
                i18nKey={'segmentation.data_updated_on'}
                values={{ date: data.updateDates[1] }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="segmentation-ncc_legend_segments">
        {data.segments.map((v, i) => {
          return (
            <div
              key={`${v.segment_id}`}
              className={`segmentation-ncc_legend_segments_item ${
                !isLegendSelect.segments[i] ? 'off' : ''
              }`}
              onClick={handleLegendClick('segments', i)}
            >
              <div
                className="segmentation-ncc_legend_segments_color"
                style={{ backgroundColor: v.color }}
              />
              <div className="segmentation-ncc_legend_segments_name">{v.segment_name}</div>
            </div>
          );
        })}
      </div>
      <div className="segmentation-ncc_chart">
        <div className="segmentation-ncc_chart_purchase_once">
          <ChartSVG
            chartInfo={{
              data: data.purchaseOnce,
              chartId: chartKeys.SEGMENTATION.NUMBER_OF_CATEGORIES_AND_CUSTOMERS,
              options: {
                isLegendSelect,
                tooltipValueTitle: t('segmentation.of_customers_who_only') + ': '
              }
            }}
          />
          <div className="segmentation-ncc_chart_title">
            {t('segmentation.of_customers_who_only')}
          </div>
        </div>
        <div className="segmentation-ncc_chart_purchase_all_categories">
          <ChartSVG
            chartInfo={{
              data: data.purchaseAllCategories,
              chartId: chartKeys.SEGMENTATION.NUMBER_OF_CATEGORIES_AND_CUSTOMERS,
              options: {
                isLegendSelect,
                tooltipValueTitle: t('segmentation.of_customers_who_bought') + ': '
              }
            }}
          />
          <div className="segmentation-ncc_chart_title">
            {t('segmentation.of_customers_who_bought')}
          </div>
        </div>
        <div className="segmentation-ncc_chart_purchased_categories">
          <ChartSVG
            chartInfo={{
              data: data.purchasedCategories,
              chartId: chartKeys.SEGMENTATION.NUMBER_OF_CATEGORIES_AND_CUSTOMERS,
              options: {
                isLegendSelect,
                tooltipValueTitle: t('segmentation.of_categories_bought') + ': '
              }
            }}
          />
          <div className="segmentation-ncc_chart_title">
            {t('segmentation.of_categories_bought')}
          </div>
        </div>
      </div>
    </>
  );
};
