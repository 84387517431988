export const mutationKeys = {
  AUTH: {
    SIGNIN: 'signin',
    GOOGLE_SIGNIN: 'googleSignin',
    REFRESH: 'refresh',
    SIGNOUT: 'signout',
    EMAIL: 'postEmail',
    CONFIRM_CODE: 'confirmCode',
    RESPOND_CHALLENGE: 'respondChallenge',
  },
  REPORT: {
    EVALUATE: 'evaluateReport',
    OPEN: 'openReport',
  },
  ACCOUNT: {
    EDIT_PROFILE: 'editProfile',
    CHANGE_PASSWORD: 'changePassword',
    CREATE_USER: 'createUser',
    ASSIGN_EMPLOYEE: 'assignEmployee',
    DEACTIVATE_USER: 'deactivateUser',
    UPDATE_EMPLOYEE_ROLE: 'updateEmployeeRole',
    UPDATE_ORGANIZATION: 'updateOrganization',
  },
  CUSTOMER: {
    LOG_EXPORT: 'logExport',
    EDIT_SEGMENT_NAME: 'editSegmentName',
  },
};
