import { useTranslation, Trans } from 'react-i18next';
import { TitleSkeleton, DynamicSkeleton } from 'components/skeletons';
import Chart from 'components/emotion/Chart';
import NoData from 'components/emotion/NoData';
import { chartKeys } from 'constants/keys';
import { columnsForModel } from './utils';
import info from 'assets/icons/info.svg';
import TableUserBase from 'components/TableUserBase/TableUserBase';

export default function Summary({ result }) {
  const { t } = useTranslation();

  if (!result) {
    return (
      <div className="overview-skeleton-container">
        <TitleSkeleton width={250} />
        <DynamicSkeleton width={290} height={30} />
        <DynamicSkeleton width={290} height={30} />
        <DynamicSkeleton width={290} height={30} />
        <DynamicSkeleton width={290} height={30} />
        <DynamicSkeleton width={290} height={30} />
      </div>
    );
  }

  if (result?.modelScore.headers.length > 0) {
    return (
      <>
        <div
          className="UserBased_box_title"
          style={{
            display: 'flex',
            marginBottom: '20px',
            itemAlign: 'center',
            justifyContent: 'center',
            gap: '3px'
          }}
        >
          <div
            style={{
              display: 'flex'
            }}
          >
            {t('user_based.model_score')}
          </div>
          <div className="info_logo_user">
            <img src={info} key="[i]" />
          </div>
          <div className="info_box">
            <div className="triangle" />
            <Trans i18nKey={'user_based.each_score_is_independent'} />
          </div>
        </div>

        <TableUserBase
          data={result.modelScore}
          columns={columnsForModel}
          icons={[<img src={info} className="info_logo" key="[i]" />]}
          hasIconHeadrs={['Latest Transaction']}
          helpTexts={[t('feature_based.last_date_of_transaction')]}
        />
      </>
    );
  }

  if (result?.crmSimulation.data) {
    return (
      <>
        <div
          className="UserBased_box_title"
          style={{
            display: 'flex',
            marginBottom: '20px',
            itemAlign: 'center',
            justifyContent: 'center',
            gap: '3px'
          }}
        >
          <div
            style={{
              display: 'flex'
            }}
          >
            {t('user_based.crm_simulation')}
          </div>
          <div className="info_logo_user">
            <img src={info} key="[i]" />
          </div>
          <div className="info_box">
            <div className="triangle" />
            <Trans i18nKey={'user_based.estimated_customers_with_retentics'} />
          </div>
        </div>
        {result.crmSimulation?.data.total && result.crmSimulation?.data.values ? (
          <Chart
            chartInfo={{
              data: result.crmSimulation.data,
              legends: result.crmSimulation.legends,
              chartId: chartKeys.USER_BASED.CRM_SIMULATION
            }}
          />
        ) : (
          <NoData
            main={t('error.theres_no_data')}
            sub={t('error.please_select_another_parameter_to')}
          />
        )}
      </>
    );
  }

  return <></>;
}
