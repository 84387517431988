import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RETENTICS_LOGIN_LOGO, ERROR_FILLED, CHECK_FILLED, EYE_OFF, EYE, X_ICON } from 'constants/images';
import { Link } from 'react-router-dom';
import './index.scss';
import { clearNewMemberInfo, getNewMemberInfo } from 'utils/userStorage';
import { useAuthMutation } from 'hooks/auth';
import { mutationKeys } from 'constants/keys';
import { useNavigateRoute } from 'hooks';

export default function NewMember() {
  const { t } = useTranslation()
  const handleNavigate = useNavigateRoute();
  const [values, setValues] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [isNewPasswordShow, setIsNewPasswordShow] = useState(false);
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(false);
  const onKeyDown = (event) => {
    if (event.code === 'Space') event.preventDefault();
  };
  const [respondChallenge, error, status, respondChallengeAsync] = useAuthMutation({
    mutationKey: mutationKeys.AUTH.RESPOND_CHALLENGE
  });

  const onChange = useCallback((e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, []);
  const handleSave = async () => {
    // 비밀번호 전송
    try {
      await respondChallengeAsync({
        password: values.newPassword,
        email: getNewMemberInfo().email,
        username: getNewMemberInfo().username,
        session: getNewMemberInfo().session
      });
      handleNavigate('/login');

      clearNewMemberInfo();
    } catch (e) {
      console.log(e);
    }
  };

  //isPasswordFilled
  const isNewPasswordFill = values.newPassword.length > 0;
  //isRepasswordFilled
  const isConfirmpasswordFill = values.confirmPassword.length > 0;

  const minChar = values.newPassword.length > 0 && values.newPassword.match(/^.{8,}$/);
  const oneUp = values.newPassword.length > 0 && values.newPassword.match(/^(?=.*[A-Z]).{1,}$/);
  const oneLower = values.newPassword.length > 0 && values.newPassword.match(/^(?=.*?[a-z]).{1,}$/);
  const oneSpecial =
    values.newPassword.length > 0 &&
    values.newPassword.match(/^(?=.*?[`\\/~!@#$%^&*()_|+\-=?;:'",.<>}{\][]).{1,}$/);

  const isPasswordValid = minChar && oneUp && oneLower && oneSpecial;
  // isRepassFulled
  const isConfirmPasswordValid =
    values.confirmPassword === values.newPassword && values.confirmPassword.length > 0;
  const passwordValidStatus = isPasswordValid ? 'valid' : 'invalid';

  const isActive = values.newPassword.length > 0 && isConfirmPasswordValid && isPasswordValid;
  return (
    (<div className="NewMember">
      <div className="NewMember_box">
        <Link to="/login">
          <div className="NewMember_box_logo">
            <img src={RETENTICS_LOGIN_LOGO} className="NewMember_box_logo_img" />
          </div>
        </Link>

        <div className="NewMember_box_insidebox">
          <div className="contentsWrapper">
            <div className="contents">
              <div className="contents_header">{t("login.change_your_password_for_your")}</div>

              {/* password 입력 */}
              <div className="title">{t("settings.new_password")}</div>
              <div className={`inputbox ${isNewPasswordFill ? passwordValidStatus : ''}`}>
                <input
                  type={isNewPasswordShow ? 'text' : 'password'}
                  className="inputbox_text2 pass"
                  name="newPassword"
                  value={values.newPassword}
                  onKeyDown={onKeyDown}
                  onChange={onChange}
                  autoComplete="new-password"
                />

                {/* 비밀번호 보이게할땐 eye로 교체 */}
                <div className="eye" onClick={() => setIsNewPasswordShow((prev) => !prev)}>
                  <img src={isNewPasswordShow ? EYE : EYE_OFF} />
                </div>

                {isNewPasswordFill &&
                  (!minChar || !oneUp || !oneLower || !oneSpecial ? (
                    <img src={ERROR_FILLED} className="inputbox_error" />
                  ) : (
                    <img src={CHECK_FILLED} className="inputbox_error" />
                  ))}
              </div>
              {isNewPasswordFill && (!minChar || !oneUp || !oneLower || !oneSpecial) ? (
                <p className="error">{t("settings.your_password_doesnt_meet_the")}</p>
              ) : (
                ''
              )}

              {/* password 타이핑 시작시 보이고 체크되도록 */}
              {values.newPassword && (
                <ul className={`check_box `}>
                  <li className="check_list">
                    {!minChar ? (
                      <img src={X_ICON} className="pass_alert" />
                    ) : (
                      <img src={CHECK_FILLED} className="pass_alert" />
                    )}{t("settings.must_have_a_minimum_of")}</li>
                  <li className="check_list">
                    {!oneUp ? (
                      <img src={X_ICON} className="pass_alert" />
                    ) : (
                      <img src={CHECK_FILLED} className="pass_alert" />
                    )}{t("login.must_include_at_least_1_upper")}</li>
                  <li className="check_list">
                    {!oneLower ? (
                      <img src={X_ICON} className="pass_alert" />
                    ) : (
                      <img src={CHECK_FILLED} className="pass_alert" />
                    )}{t("login.must_include_at_least_1_lower")}</li>
                  <li className="check_list">
                    {!oneSpecial ? (
                      <img src={X_ICON} className="pass_alert" />
                    ) : (
                      <img src={CHECK_FILLED} className="pass_alert" />
                    )}{t("login.must_include_at_least_1_special")}</li>
                </ul>
              )}

              {/* password 재확인 */}
              <div className="title">{t("settings.confirm_password")}</div>
              <div
                className={`inputbox ${
                  isConfirmpasswordFill && !isConfirmPasswordValid ? 'invalid' : ''
                } ${isConfirmPasswordValid ? 'valid2' : ''}`}
              >
                <input
                  type={isConfirmPasswordShow ? 'text' : 'password'}
                  className="inputbox_text2 pass"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={onChange}
                  autoComplete="new-password"
                />

                {/* 비밀번호 보이게할땐 eye로 교체 */}
                <div className="eye" onClick={() => setIsConfirmPasswordShow((prev) => !prev)}>
                  <img src={isConfirmPasswordShow ? EYE : EYE_OFF} />
                </div>
                {isConfirmPasswordValid && <img src={CHECK_FILLED} className="inputbox_error" />}
                {isConfirmpasswordFill && !isConfirmPasswordValid && (
                  <img src={ERROR_FILLED} className="inputbox_error" />
                )}
              </div>
              {isConfirmpasswordFill && !isConfirmPasswordValid && (
                <p className="error">{t("settings.please_enter_the_same_password")}</p>
              )}

              {/* ////////////////////////////  */}

              {/* 버튼 부분 */}
              <div className="button_box">
                <button
                  className={`send_button ${!isActive ? ' deactive' : ' active'}`}
                  disabled={!isActive}
                  onClick={() => {
                    if (!isActive) return;
                    handleSave();
                  }}
                >{t("inquiries.save")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
  );
}
