import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

import { axiosPrivate, customerAPI } from 'api';
import models from './models';
import * as dataHelpers from './dataHelpers';
import { formatNewDate } from 'utils';

export const queryFns = {
  async overview(context, userInfo) {
    const { data } = await axiosPrivate.post(customerAPI.OVERVIEW, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
    });
    return data;
  },
  async segmentation(context, userInfo) {
    const { data } = await axiosPrivate.post(customerAPI.SEGMENTATION, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
    });
    return data;
  },
  async basicRetention(context, userInfo) {
    const [_, parameters] = context.queryKey;

    const { data } = await axiosPrivate.post(customerAPI.RETENTION, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      interval: parameters.interval.id,
      from_time:
        parameters.interval.id === 'month'
          ? parameters.monthlyFrom.id
          : parameters.interval.id === 'quarter'
          ? parameters.quarterlyFrom.id
          : parameters.yearlyFrom.id,
      to_time:
        parameters.interval.id === 'month'
          ? parameters.monthlyTo.id
          : parameters.interval.id === 'quarter'
          ? parameters.quarterlyTo.id
          : parameters.yearlyTo.id,
      segment_id: parameters.segment.id,
      // retention value is always same with interval value in basic
      retention: parameters.interval.id,
      channel_id: parameters.channel.id,
      product_id: parameters.item.id,
      region_id: parameters.region.id,
      category_id: parameters.category.id,
      any_moment: parameters.anyMoment.id,
    });

    return data;
  },
  async advancedRetention(context, userInfo) {
    const [_, parameters] = context.queryKey;
    const { data } = await axiosPrivate.post(customerAPI.RETENTION, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      interval: parameters.interval.id,
      from_time:
        parameters.interval.id === 'month'
          ? parameters.monthlyFrom.id
          : parameters.interval.id === 'quarter'
          ? parameters.quarterlyFrom.id
          : parameters.yearlyFrom.id,
      to_time:
        parameters.interval.id === 'month'
          ? parameters.monthlyTo.id
          : parameters.interval.id === 'quarter'
          ? parameters.quarterlyTo.id
          : parameters.yearlyTo.id,
      segment_id: parameters.segment.id,
      retention: parameters.retention.id,
      channel_id: parameters.channel.id,
      product_id: parameters.item.id,
      region_id: parameters.region.id,
      category_id: parameters.category.id,
      any_moment: parameters.anyMoment.id,
    });

    return data;
  },
  async basicLtv(context, userInfo) {
    const [_, parameters] = context.queryKey;
    const { data } = await axiosPrivate.post(customerAPI.LTV, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      interval: parameters.interval.id,
      segment_id: parameters.segment.id,
      // period value is always same with interval value in basic
      period: parameters.interval.id,
      channel_id: parameters.channel.id,
      product_id: parameters.item.id,
      same: parameters.trackFirstItemOnly.id,
      category_id: parameters.category.id,
      region_id: parameters.region.id,
      any_moment: parameters.anyMoment.id,
    });
    return data;
  },
  async advancedLtv(context, userInfo) {
    const [_, parameters] = context.queryKey;
    const { data } = await axiosPrivate.post(customerAPI.LTV, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      interval: parameters.interval.id,
      segment_id: parameters.segment.id,
      period: parameters.period.id,
      channel_id: parameters.channel.id,
      product_id: parameters.item.id,
      same: parameters.trackFirstItemOnly.id,
      category_id: parameters.category.id,
      region_id: parameters.region.id,
      any_moment: parameters.anyMoment.id,
    });
    return data;
  },
  async forecasting(context, userInfo) {
    const [_, parameters] = context.queryKey;
    const { data } = await axiosPrivate.post(customerAPI.FORECASTING, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      segment_id: parameters.segment.id,
      product_id: parameters.item.id,
    });
    return data;
  },
  async crossSelling(context, userInfo) {
    const [_, parameters] = context.queryKey;
    const { data } = await axiosPrivate.post(customerAPI.CROSS_SELLING, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      segment_id: parameters.segment.id,
    });
    return data;
  },
  async userBased(context, userInfo) {
    const [_, parameters] = context.queryKey;
    const { data } = await axiosPrivate.post(customerAPI.USER_BASED, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      segment_id: parameters.segment.id,
      status_id: parameters.status.id,
      retention: parameters.retention.id,
    });
    return data;
  },
  async featureBased(context, userInfo) {
    const [_, parameters] = context.queryKey;
    const { data } = await axiosPrivate.post(customerAPI.FEATURE_BASED, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      region_id: parameters.region.id,
      category_id: parameters.category.id,
    });
    return data;
  },
  // async report({ signal, queryKey }) {
  async report(context, userInfo) {
    const { data } = await axiosPrivate.post(customerAPI.REPORT, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
    });
    return data;
  },
  async getExport(context, userInfo) {
    const [_, parameters] = context.queryKey;
    const { data } = await axiosPrivate.post(customerAPI.GET_EXPORT, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
    });
    return data;
  },
};

export const transformData = {
  overview(data, currency) {
    const summary = dataHelpers.overview.generateSummary(data.cards);
    const sales = dataHelpers.overview.generateSales(data.charts.sales, currency);
    const newLeadToCustomer = dataHelpers.overview.generateNewLeadToCustomer(
      data.charts.lead_to_customer,
      currency
    );
    const monthlyCustomers = dataHelpers.overview.generateMonthlyCustomer(
      data.charts.monthly_customers,
      currency
    );
    const monthlyTransaction = dataHelpers.overview.generateMonthlyTransaction(
      data.charts.monthly_transactions,
      currency
    );
    const categoryRankings = dataHelpers.overview.generateCategoryRankings(
      data.charts.category_ranking
    );
    const locationRankings = dataHelpers.overview.generateLocationRankings(
      data.charts.location_ranking
    );
    const itemRankings = dataHelpers.overview.generateItemRankings(data.tables);
    const customerRankings = dataHelpers.overview.generateCustomerRankings(data.tables, currency);
    const thirdMonthLtv = dataHelpers.overview.generateThirdMonthLtv(
      data.charts.life_time_value,
      currency
    );
    const sixthMonthLtv = dataHelpers.overview.generateSixthMonthLtv(
      data.charts.life_time_value,
      currency
    );
    const abTesting = dataHelpers.overview.generateAbTesting(data.charts.abtest);

    const overview = {
      ...models.overview(),
    };
    // overview.dates = `${data.cards.from_time.slice(0, 10)} ~ ${data.cards.to_time.slice(0, 10)}`;
    overview.dates = `${formatNewDate(
      data.cards.from_time.slice(0, 10),
      currency
    )} ~ ${formatNewDate(data.cards.to_time.slice(0, 10), currency)}`;
    overview.currency = data.cards.currency;
    overview.summary.transaction = summary.transaction;
    overview.summary.lead = summary.lead;
    overview.summary.customer.value = summary.customer.value;
    overview.summary.customer.ltc = summary.customer.ltc;
    overview.summary.segments = summary.segments;
    overview.summary.retention = summary.retention;
    overview.summary.ltv6 = summary.ltv6;
    overview.salesChart.monthly = sales.monthly;
    overview.salesChart.yearly = sales.yearly;
    overview.newLeadToCustomerChart.newLead = newLeadToCustomer.newLead;
    overview.newLeadToCustomerChart.newCustomer = newLeadToCustomer.newCustomer;
    overview.newLeadToCustomerChart.ltc = newLeadToCustomer.ltc;
    overview.monthlyCustomersChart.monthlyCustomers = monthlyCustomers;
    overview.monthlyTransactionChart.transactionPerCustomer =
      monthlyTransaction.transactionPerCustomer;
    overview.monthlyTransactionChart.transactionWithoutSignIn =
      monthlyTransaction.transactionWithoutSignIn;
    overview.monthlyTransactionChart.transaction = monthlyTransaction.transaction;
    overview.categoryRankingsChart.categoryRankings = categoryRankings;
    overview.locationRankingsChart.locationRankings = locationRankings;
    overview.itemRankingsTable.export = itemRankings.export;
    overview.itemRankingsTable.itemRankings = itemRankings.table;
    overview.thirdMonthLtvChart.thirdMonthLtv = thirdMonthLtv;
    overview.sixthMonthLtvChart.sixthMonthLtv = sixthMonthLtv;
    overview.abTestingChart.withRetentics = abTesting.withRetentics;
    overview.abTestingChart.withoutRetentics = abTesting.withoutRetentics;
    overview.customersTable.export = customerRankings.export;
    overview.customersTable.customers = customerRankings.table;

    return overview;
  },
  segmentation(data, currency) {
    const segments = [...data.segmentation].sort((a, b) => a.segment_id - b.segment_id);
    const segmentsNames = segments.map((v) => v.segment_name);
    const segmentSize = dataHelpers.segmentation.generateSegmentSize(
      segments,
      data.charts.segmentation
    );
    const sales = dataHelpers.segmentation.generateSales(segments, data.charts.sales);
    const transactions = dataHelpers.segmentation.generateTransactions(
      segments,
      data.charts.transactions
    );
    const monthlyCustomers = dataHelpers.segmentation.generateMonthlyCustomers(
      segments,
      data.charts.monthly_customers,
      currency
    );
    const monthlySales = dataHelpers.segmentation.generateMonthlySales(
      segments,
      data.charts.monthly_sales,
      currency
    );
    const ltvSix = dataHelpers.segmentation.generateLtvSix(
      segments,
      data.charts.life_time_value,
      currency
    );
    const monthlyRetention = dataHelpers.segmentation.generateMonthlyRetention(
      segments,
      data.charts.retention,
      currency
    );
    const tables = dataHelpers.segmentation.generateTables(segments, data.tables, currency);

    const segmentation = {
      ...models.segmentation(),
    };
    segmentation.currency = data.dashboard.currency;
    segmentation.segmentsNames = segmentsNames;
    segmentation.segmentSizeChart.segmentSize = segmentSize;
    segmentation.salesPerChart.averageSalesPerCustomer = sales.averageSalesPerCustomer;
    segmentation.salesPerChart.totalSalesPerSegment = sales.totalSalesPerSegment;
    segmentation.transactionsPerChart.averageTransactionsPerCustomer =
      transactions.averageTransactionsPerCustomer;
    segmentation.transactionsPerChart.totalTransactionsPerSegment =
      transactions.totalTransactionsPerSegment;
    segmentation.monthlyCustomersChart.monthlyCustomers = monthlyCustomers;
    segmentation.monthlySalesChart.monthlySales = monthlySales;
    segmentation.ltvSixChart.ltvSix = ltvSix;
    segmentation.monthlyRetentionChart.monthlyRetention = monthlyRetention;
    segmentation.tables = tables;

    return segmentation;
  },
  basicRetention(data, currency) {
    if (data.result.length === 0) return 'no data';

    const retentionRate = dataHelpers.retention.generateRetentionRate(data.result, currency);
    const waterfall = dataHelpers.retention.generateWaterfall(data.result, currency);
    const exportData = data.result.map((v) => ({
      date: v.x.slice(0, 7),
      new: v.new,
      retentionRate: v.ratio,
      active: v.active,
      churned: v.churned,
      reactive: v.reactive,
      retained: v.retained,
    }));
    const retention = {
      ...models.retention(),
    };
    retention.retentionRateChart.retentionRate = retentionRate;
    retention.waterfallChart.waterfall = waterfall;
    retention.exportData = exportData;
    return retention;
  },
  advancedRetention(data, currency) {
    if (data.result.length === 0) return 'no data';

    const retentionRate = dataHelpers.retention.generateRetentionRate(data.result, currency);
    const waterfall = dataHelpers.retention.generateWaterfall(data.result, currency);
    const exportData = data.result.map((v) => ({
      date: v.x.slice(0, 7),
      new: v.new,
      retentionRate: v.ratio,
      active: v.active,
      churned: v.churned,
      reactive: v.reactive,
      retained: v.retained,
    }));
    const retention = {
      ...models.retention(),
    };
    retention.retentionRateChart.retentionRate = retentionRate;
    retention.waterfallChart.waterfall = waterfall;
    retention.exportData = exportData;
    return retention;
  },
  basicLtv(data, currency) {
    if (data.result.length === 0) return 'no data';

    const ltv3Chart = data.charts.life_time_value.filter((v) => v.name === 'ltv3')[0];
    const ltv6Chart = data.charts.life_time_value.filter((v) => v.name === 'ltv6')[0];
    const customerValueProgress = dataHelpers.ltv.generateCustomerValueProgress(
      data.charts.customer_value_progress
    );
    const ltv3 = ltv3Chart ? dataHelpers.ltv.generateLtv3(ltv3Chart, currency) : 'no data';
    const ltv6 = ltv6Chart ? dataHelpers.ltv.generateLtv6(ltv6Chart, currency) : 'no data';
    const cohortAnalysis = dataHelpers.ltv.generateCohortAnalysis(data.result, currency);

    const ltv = {
      ...models.ltv(),
    };
    ltv.customerValueProgressChart.customerValueProgress = customerValueProgress;
    ltv.changesOverTimeChart.ltv6 = ltv6;
    ltv.changesOverTimeChart.ltv3 = ltv3;
    ltv.cohortAnalysisTable.headers = cohortAnalysis.headers;
    ltv.cohortAnalysisTable.rows = cohortAnalysis.rows;
    return ltv;
  },
  advancedLtv(data, currency) {
    if (data.result.length === 0) return 'no data';

    const ltv3Chart = data.charts.life_time_value.filter((v) => v.name === 'ltv3')[0];
    const ltv6Chart = data.charts.life_time_value.filter((v) => v.name === 'ltv6')[0];
    const customerValueProgress = dataHelpers.ltv.generateCustomerValueProgress(
      data.charts.customer_value_progress
    );
    const ltv3 = ltv3Chart ? dataHelpers.ltv.generateLtv3(ltv3Chart, currency) : 'no data';
    const ltv6 = ltv6Chart ? dataHelpers.ltv.generateLtv6(ltv6Chart, currency) : 'no data';
    const cohortAnalysis = dataHelpers.ltv.generateCohortAnalysis(data.result, currency);

    const ltv = {
      ...models.ltv(),
    };
    ltv.customerValueProgressChart.customerValueProgress = customerValueProgress;
    ltv.changesOverTimeChart.ltv6 = ltv6;
    ltv.changesOverTimeChart.ltv3 = ltv3;
    ltv.cohortAnalysisTable.headers = cohortAnalysis.headers;
    ltv.cohortAnalysisTable.rows = cohortAnalysis.rows;
    return ltv;
  },
  forecasting(data, currency) {
    if (data.result.length === 0) return 'no data';

    const transactionForecasting = dataHelpers.forecasting.generateTransactionForecasting(
      data.result,
      currency
    );
    const forecasting = {
      ...models.forecasting(),
    };
    forecasting.transactionForecastingChart.transactionForecasting = transactionForecasting;
    return forecasting;
  },
  crossSelling(data) {
    const crossSelling = {
      ...models.crossSelling(),
    };
    crossSelling.export = data?.tables[0]?.options?.export;
    crossSelling.counts = data?.tables[0]?.options?.total_row;
    crossSelling.crossSellingTable.headers = data.tables[0].headers;
    crossSelling.crossSellingTable.cells = data.tables[0].cells;
    return crossSelling;
  },
  userBased(data, currency) {
    const userBased = {
      ...models.userBased(),
    };
    userBased.export = data?.options?.export;
    userBased.counts = data?.options?.total_row;
    userBased.userBasedTable.headers = [
      'Name',
      'Segment',
      'Status',
      'Transaction',
      'Latest Transaction',
      'Next Item',
      'Ground',
    ];
    userBased.userBasedTable.cells = data.result.map((v) => {
      const copied = { ...v };
      copied.latest_transaction = {
        at: formatNewDate(copied.latest_transaction.at, currency),
        since: copied.latest_transaction.since,
      };
      return copied;
    });
    return userBased;
  },
  featureBased(data, currency) {
    const featureBased = {
      ...models.featureBased(),
    };
    featureBased.export = data?.options?.export;
    featureBased.counts = data?.options?.total_row;
    featureBased.featureBasedTable.headers = [
      'Name',
      'Segment',
      'Status',
      'Transaction',
      'Latest Transaction',
      'Score',
    ];
    featureBased.featureBasedTable.cells = data.result.map((v) => {
      const copied = { ...v };
      copied.latest_transaction = {
        at: formatNewDate(copied.latest_transaction.at, currency),
        since: copied.latest_transaction.since,
      };
      return copied;
    });
    return featureBased;
  },
  report(data) {
    const retention = data.retention;
    const ltv = data.life_time_value;

    const good = [];
    const bad = [];

    retention.forEach((v, i) => {
      if (v.result.good) {
        good.push(v);
      } else {
        bad.push(v);
      }
    });
    ltv.forEach((v, i) => {
      if (v.result.good) {
        good.push(v);
      } else {
        bad.push(v);
      }
    });
    good.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    bad.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

    const report = {
      ...models.report(),
    };
    report.good = good;
    report.bad = bad;
    return report;
  },
  getExport(data) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const list = data.result.map((v, i) => {
      let date = new Date(v.created_at);
      date = zonedTimeToUtc(date, 'utc');
      date = utcToZonedTime(date, timeZone);

      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      const month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(date);
      const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
      const hour = new Intl.DateTimeFormat('en', { hour: 'numeric', hour12: false }).format(date);
      const minute = new Intl.DateTimeFormat('en', { minute: 'numeric' }).format(date);
      const second = new Intl.DateTimeFormat('en', { second: 'numeric' }).format(date);
      const createdAt = `${year.slice(2)}/${month}/${day} ${hour
        .slice(0, 2)
        .trim()
        .padStart(2, '0')}:${minute}:${second}`;

      return {
        no: i + 1,
        dataMenu: v.page,
        dataName: v.name,
        userName: v.username,
        date: createdAt,
        options: v.options,
        dashboardName: v.dashboard_id,
      };
    });
    const sortedList = list.sort((a, b) => b.no - a.no);
    const getExport = {
      ...models.getExport(),
    };
    getExport.list = sortedList;
    return getExport;
  },
};
