import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OriginalSearchInput from 'components/inputs/OriginalSearchInput';
import MainHeader from 'components/MainHeader';
import Export from 'assets/icons/export.svg';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { useCustomerServiceFetch, useCustomerServiceMutation, usePageInit } from 'hooks';
import { mutationKeys, queryKeys } from 'constants/keys';
import { initialParameters } from './utils';
import CrossSellingTable from './CrossSellingTable';
import './CrossSelling.scss';
import { exportXLSX, nameExportFile } from 'utils';
import { useUserInfoContext } from 'context';
import {
  ParametersSkeleton,
  ParameterSkeleton,
  TitleSkeleton,
  DynamicSkeleton
} from 'components/skeletons';

const CrossSelling = () => {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();
  const [parameters, setParameters] = useState(initialParameters);

  const { isOptionRender, isResultRender, option, result, fetchResult } = useCustomerServiceFetch({
    optionQueryKey: queryKeys.OPTION.CROSS_SELLING,
    serviceQueryKey: queryKeys.SERVICE.CROSS_SELLING,
    parameters
  });
  const [logExport] = useCustomerServiceMutation({
    mutationKey: mutationKeys.CUSTOMER.LOG_EXPORT
  });
  function handleLogExport(params) {
    logExport(params);
  }

  const selectParameter = (selectedOption, label) => {
    setParameters((prev) => ({
      ...prev,
      [label]: {
        id: selectedOption.id,
        value: selectedOption.value
      }
    }));
  };
  const handleExport = async (e, url) => {
    if (userInfo?.user.role === 'observer') return;
    e.preventDefault();
    await exportXLSX(url, 'Cross-selling')
      .then(() => {
        handleLogExport({
          companyId: userInfo?.company.companyId,
          shopId: userInfo?.company.shopId,
          page: 'cross_selling',
          fileName: `${nameExportFile('Cross-selling').slice(0, -4)}.xlsx`
        });
      })
      .catch((err) => {
        console.log('export failed');
        console.log(err);
      });
  };

  useEffect(() => {
    if (option) {
      setParameters((prev) => ({
        ...prev,
        segment: {
          id: option.segment.filter((v) => v.id === 0)[0].id,
          value: option.segment.filter((v) => v.id === 0)[0].value
        }
      }));
      fetchResult();
    }
  }, [option]);

  useEffect(() => {
    setParameters(initialParameters);
  }, [userInfo?.company.shopId]);

  useEffect(() => {
    usePageInit();
  }, []);

  return (
    <>
      <div className="CrossSelling">
        <MainHeader
          main={t('cross.crossselling')}
          sub={t('cross.find_the_sequential_purchase_patterns')}
        />

        {!isOptionRender ? (
          <ParametersSkeleton>
            <ParameterSkeleton />
          </ParametersSkeleton>
        ) : (
          <div className="CrossSelling_search_box">
            <OriginalSearchInput
              label="segment"
              width="269"
              options={option.segment}
              selectedValue={parameters.segment.value}
              selectValue={selectParameter}
            />

            <div className="button_layer">
              <PrimaryButton
                value={t('cross.apply')}
                onClick={fetchResult}
                style={{ width: '124px' }}
              />
            </div>
          </div>
        )}
        <div className="CrossSelling_table_box">
          {!isResultRender ? (
            <div className="overview-skeleton-container">
              <TitleSkeleton width={400} />
              <DynamicSkeleton width={1100} height={50} />
              <DynamicSkeleton width={1100} height={50} />
              <DynamicSkeleton width={1100} height={50} />
              <DynamicSkeleton width={1100} height={50} />
              <DynamicSkeleton width={1100} height={50} />
              <DynamicSkeleton width={1100} height={50} />
              <DynamicSkeleton width={1100} height={50} />
              <DynamicSkeleton width={1100} height={50} />
              <DynamicSkeleton width={1100} height={50} />
              <DynamicSkeleton width={1100} height={50} />
              <DynamicSkeleton width={1100} height={50} />
              <DynamicSkeleton width={1100} height={50} />
            </div>
          ) : (
            <>
              <p className="table_box_title">
                {result?.counts?.toLocaleString()}
                {t('cross.n_pairs_found')}
              </p>
              <a className="table_box_export" onClick={(e) => handleExport(e, result?.export)}>
                <img src={Export} className="export_img" />
                {t('settings.export')}
              </a>
              <CrossSellingTable selling={result.crossSellingTable} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CrossSelling;
