import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { setLogger } from 'react-query';
import ReactDOM from 'react-dom';
import './i18n';
import App from './App';
import './index.scss';
import {
  GlobalToastProvider,
  GlobalSnackProvider,
  GlobalModalProvider,
  UserInfoProvider,
  GoogleIdentityProvider
} from 'context';

ReactDOM.render(
  <Suspense fallback={null}>
    <GoogleIdentityProvider>
      <UserInfoProvider>
        <GlobalModalProvider>
          <Router>
            <GlobalSnackProvider>
              <GlobalToastProvider>
                <App />
              </GlobalToastProvider>
            </GlobalSnackProvider>
          </Router>
        </GlobalModalProvider>
      </UserInfoProvider>
    </GoogleIdentityProvider>
  </Suspense>,
  document.getElementById('root')
);