import { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { axiosBase, axiosPrivate, customerAPI } from 'api';
import { useGlobalSnack, useUserInfoContext } from 'context';
import { getItemJourneyHandler } from './requests';
import {
  initializeClientParameters,
  transformOptionData,
  updateParameterItems,
  checkIfHeadParameter,
  transformBestJourneyData,
  transformExploreJourneyData,
  replaceOrdinalToNumber,
  replaceNumberToOrdinal,
  fillNullToFollowingHeadParameters
} from './utils';
import { ITEM_JOURNEY } from 'constants';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { nameExportFile } from 'utils';
import { useCustomerServiceMutation } from '../useCustomerServiceMutation';
import { mutationKeys } from 'constants/keys';
import { useTranslation } from 'react-i18next';

export const useItemJourney = () => {
  const { userInfo } = useUserInfoContext();
  const [logExport] = useCustomerServiceMutation({
    mutationKey: mutationKeys.CUSTOMER.LOG_EXPORT
  });

  const [parameterItems, setParameterItems] = useState({});

  const [clientParameters, setClientParameters] = useState({});
  const [bestServerParameters, setBestServerParameters] = useState({});
  const [exploreServerParameters, setExploreServerParameters] = useState({});

  const [exploreRequestStep, setExploreRequestStep] = useState(0);
  const [requestExplore, setRequestExplore] = useState(false);

  // data-fetching hooks
  const dataFromOptionAPI = useOption();
  const bestJourney = useBestJourney(bestServerParameters);
  const exploreJourney = useExploreJourney(exploreServerParameters, exploreRequestStep);

  const selectParameter = (item, property) => {
    // explore Journey
    if (checkIfHeadParameter(property)) {
      setClientParameters((prev) => ({
        ...prev,
        ...fillNullToFollowingHeadParameters(prev, property, item)
      }));

      setExploreRequestStep(replaceOrdinalToNumber(property));
      setRequestExplore(true);

      return;
    }
    setClientParameters((prev) => ({
      ...prev,
      [property]: item
    }));
  };

  const exportFile = async () => {
    if (!bestJourney.data || !exploreJourney.data) return;
    if (userInfo?.user.role === 'observer') return;

    const urls = bestJourney.data.exports;
    const zip = new JSZip();

    await Promise.all([
      ...urls.map((obj) =>
        axiosBase.get(obj.url, {
          responseType: 'blob'
        })
      )
    ])
      .then((res) => {
        res.forEach((v, i) => {
          const reqUrl = v.config.url;
          const segmentId = urls.find((obj) => obj.url === reqUrl).seg_id;
          const segmentName = parameterItems.segment.find((seg) => seg.id === segmentId).value;

          zip.file(
            `${nameExportFile('itemjourney').slice(0, 8)}_${segmentName}_Item Journey.csv`,
            v.data
          );
        });
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `${nameExportFile('itemjourney').slice(0, -4)}.zip`);
        });
      })
      .then(() => {
        logExport({
          companyId: userInfo?.company.companyId,
          shopId: userInfo?.company.shopId,
          page: 'item_journey',
          fileName: `${nameExportFile('itemjourney').slice(0, -4)}.zip`
        });
      });
  };

  const clickApply = () => {
    const { first, second, third, fourth, fifth, ...rest } = clientParameters;
    setBestServerParameters(rest);
    setRequestExplore(true);
    setClientParameters({
      ...rest,
      first: null,
      second: null,
      third: null,
      fourth: null,
      fifth: null
    });
  };

  useEffect(() => {
    const initialClientParameters = initializeClientParameters(dataFromOptionAPI);
    setParameterItems(dataFromOptionAPI);
    setClientParameters(initialClientParameters);
  }, [dataFromOptionAPI]);

  useEffect(() => {
    if (!exploreJourney.data) {
      return;
    }
    if (exploreJourney.data.isFirstData && !exploreJourney.data.isResultReturned) {
      return;
    }
    updateParameterItems(setParameterItems, exploreJourney.data);
  }, [exploreJourney.data]);

  useEffect(() => {
    if (Object.keys(clientParameters).length < 1) {
      return;
    }
    // 최초 1회 호출
    if (!bestJourney.data && !requestExplore) {
      clickApply();
    }

    if (requestExplore) {
      setExploreServerParameters(clientParameters);
      setRequestExplore(false);
    }
  }, [clientParameters]);

  useEffect(() => {
    if (!Object.keys(bestServerParameters).length) {
      return;
    }
    bestJourney.request();
  }, [bestServerParameters]);

  useEffect(() => {
    if (!Object.keys(exploreServerParameters).length) {
      return;
    }
    exploreJourney.request();
  }, [exploreServerParameters]);

  return {
    parameterItems,
    selectedParameter: clientParameters,
    selectedSegmentName: parameterItems?.segment?.find(
      (seg) => seg.id === exploreJourney?.data?.segmentId
    )?.value,
    selectedOutputName: parameterItems?.output?.find((o) => o.id === exploreJourney?.data?.outputId)
      ?.value,
    result: {
      best: bestJourney.data,
      explore: exploreJourney.data
    },
    selectParameter,
    exportFile,
    clickApply
  };
};

const useOption = () => {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();

  const recentOneYearTranslation = {
    overThePastYear: t('journey.over_the_past_year'),
    all: t('journey.all')
  };

  const { data: dataFromOptionAPI } = useQuery(
    [ITEM_JOURNEY, 'options', userInfo?.company.shopId],
    async () =>
      await axiosPrivate.post(customerAPI.OPTION, {
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        page: ITEM_JOURNEY
      }),
    {
      select: (result) => transformOptionData(result.data, recentOneYearTranslation)
    }
  );

  return dataFromOptionAPI;
};

const useBestJourney = (serverParameters) => {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();
  const [requestEnabled, setRequestEnabled] = useState(false);

  const churnTranslation = t('journey.churn');

  const { data } = useQuery(
    [ITEM_JOURNEY, 'bestJourney', serverParameters, userInfo?.company.shopId],
    () =>
      getItemJourneyHandler({
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        segment_id: serverParameters.segment?.id,
        category: serverParameters.output?.id,
        recent_1year: serverParameters.recentOneYear?.id,
        best_journey: true,
        head: 'default'
      }),
    {
      enabled: requestEnabled,
      select: (data) => transformBestJourneyData(data, churnTranslation)
    }
  );

  const request = () => {
    setRequestEnabled(true);
  };
  useEffect(() => {
    if (!data) return;

    setRequestEnabled(false);
  }, [data]);

  return { data, request };
};

const useExploreJourney = (serverParameters, requestStep) => {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();
  const [dataCache, setDataCache] = useState(null);
  const snack = useGlobalSnack();

  const churnTranslation = t('journey.churn');

  const head = useMemo(
    () => serverParameters?.[replaceNumberToOrdinal(requestStep)]?.id ?? 'default',
    [serverParameters, requestStep]
  );
  const { data, isFetching, refetch } = useQuery(
    [ITEM_JOURNEY, 'exploreJourney', serverParameters, userInfo?.company.shopId],
    () =>
      getItemJourneyHandler({
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        segment_id: serverParameters.segment?.id,
        category: serverParameters.output?.id,
        recent_1year: serverParameters.recentOneYear?.id,
        best_journey: false,
        head
      }),
    {
      enabled: false,
      select: (data) => {
        return transformExploreJourneyData(data, head, churnTranslation, snack);
      }
    }
  );

  const request = () => {
    refetch();

    if (head.includes('Churn')) {
      return;
    }
    
    snack({
      errorMessage: t('journey.item_journey_is_being_calculated'),
      refreshButtonEnabled: false
    });
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!isFetching) {
      setDataCache(data);
    }
  }, [data]);

  useEffect(() => {
    if (isFetching) {
      return;
    }
    if (!data) {
      return;
    }

    if (data.isResultEmpty) {
      snack({
        errorMessage: t('journey.theres_no_journey_for_this')
      });
    } else {
      snack({
        toHideSnack: true
      });
    }
  }, [isFetching]);

  return { data: dataCache, request };
};
