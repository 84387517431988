import * as d3 from 'd3';
import { sankey as d3Sankey, sankeyLinkHorizontal } from './utils/sankey';
import { minBy } from 'lodash';
import i18n from '../../i18n';
import { ITEM_JOURNEY_PLUS, ITEM_JOURNEY_PLUS_CIRCLE } from 'constants/images';
import {
  roundedHorizontalRect,
  formatNumber,
  roundedVerticalRect,
  numberWithCommas,
  sumOfArray
} from 'utils';
import { replaceNumberToOrdinal } from 'hooks/service/itemJourney/utils';
import { theme } from 'styles@emotion/theme';
import { limitObjectsPerStep } from './utils';

const guideLineStrokeColor = '#DDDDDD';
let exploreJourneyHeight = 0;

export const Chart = (svg) => {
  const { t } = i18n;
  const chart = {
    segmentation: {},
    mosaic: {},
    itemJourney: {},
    userBased: {}
  };
  let xScale;
  let yScale;
  let zScale;

  chart.width = function (width) {
    svg.attr('width', width);
    return chart;
  };

  chart.height = function (height) {
    svg.attr('height', height);
    return chart;
  };

  chart.xScale = function (values) {
    const { type, domain, range, paddingInner, paddingOuter } = values;
    if (type === d3.scaleLinear) {
      xScale = type(domain, range).nice();
    } else {
      xScale = type(domain, range);
    }
    if (paddingInner) {
      xScale.paddingInner(paddingInner);
    }
    if (paddingOuter) {
      xScale.paddingOuter(paddingOuter);
    }

    xScale.invert = (() => {
      const domain = xScale.domain();
      const range = xScale.range();
      const invertedScale = d3.scaleQuantize().domain(range).range(domain);
      return (x) => invertedScale(x);
    })();
    return chart;
  };

  chart.yScale = function (values) {
    const { type, domain, range } = values;
    const maxCheckedDomain = d3.max(domain) === 0 ? [0, 10] : domain;
    if (type === d3.scaleLinear) {
      yScale = type(maxCheckedDomain, range).nice();
    } else {
      yScale = type(maxCheckedDomain, range);
    }
    return chart;
  };

  chart.zScale = function (values) {
    const { type, domain, range } = values;
    zScale = type(domain, range).nice();
  };

  chart.xAxis = function (values) {
    const { yPosition, interval = 1 } = values;

    const xAxis = svg.append('g').attr('class', 'x-axis');

    xAxis.attr('transform', `translate(0,${yPosition})`).call(
      d3
        .axisBottom(xScale)
        .tickFormat((d) => {
          let tickLabel = d;
          if (typeof d === 'number') {
            tickLabel = formatNumber(d);
          } else {
            if (d.length > 8) {
              tickLabel = d.slice(0, 5) + '...';
            }
          }
          return tickLabel;
        })
        .tickValues(
          xScale.domain().filter((d, i, arr) => {
            if (i > 11 && i > arr.length - 3) return false;
            if (arr.length > 24) {
              return i % (interval * 2) == 0;
            } else if (arr.length > 36) {
              return i % (interval * 3) == 0;
            } else if (arr.length > 48) {
              return i % (interval * 4) == 0;
            }
            return i % interval == 0;
          })
        )
        .tickSizeOuter(0)
    );
    // const xAxisTicks = svg.select('.x-axis').selectAll('.tick');
    // xAxisTicks.selectAll('tspan').data((d) => {
    //   return d;
    // });
    return chart;
  };

  chart.yAxis = function (values) {
    const { xPosition } = values;

    const yAxis = svg.append('g').attr('class', 'y-axis');

    yAxis
      .attr('transform', `translate(${xPosition},0)`)
      .call(
        d3
          .axisLeft(yScale)
          .ticks(7)
          .tickFormat((v) => formatNumber(v))
      )
      .call((g) => g.select('.domain').remove());
    return chart;
  };

  chart.grid = function (values) {
    const { xPosition, tickSize } = values;

    const grid = svg.append('g').attr('class', 'y-grid');
    grid
      .attr('transform', `translate(${xPosition},0)`)
      .call(d3.axisLeft(yScale).ticks(7).tickSize(tickSize).tickFormat(''))
      .call((g) => g.select('path').attr('stroke', 'transparent'))
      .call((g) => g.selectAll('.tick line').attr('stroke', '#DDDDDD'));
    return chart;
  };

  chart.segmentation.populationSalesAndTransactions = function (values) {
    const { data } = values;

    const tooltip = d3
      .select(svg.node().parentNode)
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .call((div) => {
        const name = div.append('div').attr('class', 'tooltip-name');
        name
          .append('span')
          .attr('class', 'segment-color')
          .style('display', 'inline-block')
          .style('width', '9px')
          .style('height', '9px')
          .style('border-radius', '50%')
          .style('margin-right', '5px');
        name.append('span').attr('class', 'segment-name');

        div.append('div').attr('class', 'tooltip-percentage');
      });

    [
      t('segmentation.population') + ':',
      t('segmentation.transactions') + ':',
      t('segmentation.sales') + ':',
      t('segmentation.inactive_customers') + `(${t('segmentation.of_purchase_past_year')})` + ':'
    ].forEach((valueName, i) => {
      tooltip.call((div) =>
        div
          .append('div')
          .attr('class', `tooltip-value${i === 3 ? ' inactive-customers-value' : ''}`)
          .call((div) => div.append('span').attr('class', 'tooltip-value_title').html(valueName))
          .call((div) => div.append('span').attr('class', 'tooltip-value_value'))
          .call((div) => div.append('span').attr('class', 'tooltip-value_variation'))
      );
    });

    const pies = svg.append('g').attr('class', 'pies');

    pies
      .selectAll('g')
      .data(data)
      .join('g')
      .attr('class', 'pies-segment')
      .each((d, i) => {
        d3.select(d3.selectAll('.pies-segment').nodes()[i])
          .selectAll('g')
          .data(d.last ? [d.last.z, d.current.z] : [d.current.z])
          .join('g')
          .attr('class', (_, j, arr) => {
            return j === 0 && arr.length === 2 ? 'pie-last' : 'pie-current';
          })
          .each((pieD, j) => {
            const PIE_X = d3.map(pieD, (v) => v.x);
            const PIE_Y = d3.map(pieD, (v) => v.y);
            const PIE_I = d3.range(PIE_X.length).filter((k) => !isNaN(PIE_Y[k]));

            const outerRadius = zScale(PIE_Y[0] + PIE_Y[1]),
              innerRadius = 0,
              padAngle = 0,
              strokeLinejoin = 'round';
            const arcs = d3
              .pie()
              .padAngle(padAngle)
              .startAngle(Math.PI * 0.5)
              .endAngle(Math.PI * 2.5)
              .sort(null)
              .value((k) => PIE_Y[k])(PIE_I)
              .map((arcObj) => ({
                ...arcObj,
                color: d.color
              }));
            const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
            const xPosition = j === 0 && d.last ? d.last.x : d.current.x,
              yPosition = j === 0 && d.last ? d.last.y : d.current.y;

            const piesSegment = d3.select(d3.selectAll('.pies-segment').nodes()[i]);
            const pie = d3.select(d3.selectAll(piesSegment.selectAll('g > g')).nodes()[j]);

            pie
              .selectAll('path')
              .data(arcs)
              .join('path')
              .attr('d', arc)
              .attr('fill', (arcObj) => (j === 1 || !d.last ? arcObj.color : 'transparent'))
              .attr('fill-opacity', (arcObj, k) => (k === 0 ? '1' : '0.5'))
              .attr('stroke-linejoin', strokeLinejoin)
              .attr('transform', `translate(${xScale(xPosition)},${yScale(yPosition)})`);

            if (j === 0 && d.last) {
              pie.attr('stroke', d.color).attr('stroke-width', 1).attr('stroke-dasharray', 3);
            } else {
              pie
                .append('text')
                .text(d.name)
                .attr('x', xScale(xPosition) - pie.select('text').node().getBBox().width / 2)
                .attr('y', yScale(yPosition));
            }

            pie
              .on('mouseover', () => {})
              .on('mousemove', (event) => {
                if (event.target.parentNode.classList[0] === 'pie-last') {
                  return;
                }
                tooltip.style('visibility', 'visible');

                const [x, y] = d3.pointer(event);
                const { values, variations } = data[i]['tooltipData'];

                Array.from(tooltip.selectAll('.tooltip-value_variation').nodes()).forEach((div) => {
                  div.classList.remove('negative');
                  div.classList.remove('positive');
                  div.classList.remove('inactive_negative');
                  div.classList.remove('inactive_positive');
                });

                Array.from(tooltip.selectAll('div').nodes()).forEach((div, index) => {
                  if (index === 0) {
                    d3.select(div).select('.segment-color').style('background-color', d.color);
                    d3.select(div).select('.segment-name').node().textContent = d.name;
                  }
                  if (index === 1)
                    div.textContent = `${t('segmentation.percentage_of_total')}: ${
                      d.percentageOfTotal
                    }%`;
                  if (index === 2) {
                    d3.select(div).select('.tooltip-value_value').node().textContent =
                      numberWithCommas(values.population);
                    if (variations) {
                      let variation = variations.population;

                      if (variations.population[0] === '-') {
                        d3.select(div)
                          .select('.tooltip-value_variation')
                          .node()
                          .classList.add('negative');
                        variation = variation.slice(1);
                      } else if (variations.population[0] !== '0') {
                        d3.select(div)
                          .select('.tooltip-value_variation')
                          .node()
                          .classList.add('positive');
                      }
                      d3.select(div).select('.tooltip-value_variation').node().textContent =
                        variation;
                    }
                  }
                  if (index === 3) {
                    d3.select(div).select('.tooltip-value_value').node().textContent =
                      numberWithCommas(values.transactions);
                    if (variations) {
                      let variation = variations.transactions;

                      if (variations.transactions[0] === '-') {
                        d3.select(div)
                          .select('.tooltip-value_variation')
                          .node()
                          .classList.add('negative');
                        variation = variation.slice(1);
                      } else if (variations.transactions[0] !== '0') {
                        d3.select(div)
                          .select('.tooltip-value_variation')
                          .node()
                          .classList.add('positive');
                      }
                      d3.select(div).select('.tooltip-value_variation').node().textContent =
                        variation;
                    }
                  }
                  if (index === 4) {
                    d3.select(div).select('.tooltip-value_value').node().textContent =
                      numberWithCommas(values.sales);
                    if (variations) {
                      let variation = variations.sales;

                      if (variations.sales[0] === '-') {
                        d3.select(div)
                          .select('.tooltip-value_variation')
                          .node()
                          .classList.add('negative');
                        variation = variation.slice(1);
                      } else if (variations.sales[0] !== '0') {
                        d3.select(div)
                          .select('.tooltip-value_variation')
                          .node()
                          .classList.add('positive');
                      }

                      d3.select(div).select('.tooltip-value_variation').node().textContent =
                        variation;
                    }
                  }
                  // inactive 지표는 negative/positive 색상을 반대로 표현해준다.
                  if (index === 5) {
                    d3.select(div).select('.tooltip-value_value').node().textContent =
                      numberWithCommas(values.inactive) +
                      ` (${((values.inactive / values.population) * 100).toFixed(0)}%)`;
                    if (variations) {
                      let variation = variations.inactive;

                      if (variations.inactive[0] === '-') {
                        d3.select(div)
                          .select('.tooltip-value_variation')
                          .node()
                          .classList.add('inactive_negative');
                        variation = variation.slice(1);
                      } else if (variations.inactive[0] !== '0') {
                        d3.select(div)
                          .select('.tooltip-value_variation')
                          .node()
                          .classList.add('inactive_positive');
                      }
                      d3.select(div).select('.tooltip-value_variation').node().textContent =
                        variation;
                    }
                  }
                });
                tooltip
                  .style('left', `${x - tooltip.node().getBoundingClientRect().width / 2}px`)
                  .style('top', `${y - (tooltip.node().getBoundingClientRect().height + 15)}px`);
              })
              .on('mouseout', () => {
                tooltip.style('visibility', 'hidden');
              })
              .on('click', () => {
                const copyTextarea = document.createElement('textarea');
                copyTextarea.style.position = 'fixed';
                copyTextarea.style.opacity = '0';

                const { values, variations } = data[i]['tooltipData'];
                let textContent = '';
                textContent += `${d.name}\n`;
                textContent += `Percentage Of Total: ${d.percentageOfTotal}%\n`;
                textContent += `${t('segmentation.population')}: ${values.population} `;
                if (variations) {
                  textContent += variations.population;
                }
                textContent += '\n';

                textContent += `${t('segmentation.transactions')}: ${values.transactions} `;
                if (variations) {
                  textContent += variations.transactions;
                }
                textContent += '\n';

                textContent += `${t('segmentation.sales')}: ${values.sales} `;
                if (variations) {
                  textContent += variations.sales;
                }
                textContent += '\n';

                textContent += `${t('segmentation.inactive_customers')}(${t(
                  'segmentation.of_purchase_past_year'
                )}): ${values.inactive} (${((values.inactive / values.population) * 100).toFixed(
                  0
                )}%) `;
                if (variations) {
                  textContent += variations.inactive;
                }

                copyTextarea.textContent = textContent;

                document.body.appendChild(copyTextarea);
                copyTextarea.select();
                document.execCommand('copy');
                document.body.removeChild(copyTextarea);
              });
          });
      });
  };
  chart.segmentation.recencyFrequencyAndMonetaryValue = function (values) {
    const { data, options } = values;

    const bars = svg.append('g').attr('class', 'bars').selectAll('g').data(data).join('g');
    const HEIGHT_OF_ROW = 47;
    const HEIGHT_OF_BAR = 39;

    if (options.isLegendSelect.current) {
      bars.call((g) =>
        g
          .append('path')
          .attr('class', 'bar')
          .attr('d', (d) =>
            roundedHorizontalRect(
              1,
              yScale(d.y) + (HEIGHT_OF_ROW - HEIGHT_OF_BAR) / 2,
              xScale(d.xCurrent),
              HEIGHT_OF_BAR,
              [5, 5, 5, 5]
            )
          )
          .attr('fill', (d) => d.color)
          .attr('fill-opacity', '0.5')
      );
    }

    if (options.isLegendSelect.last) {
      bars.call((g) =>
        g
          .append('path')
          .attr('class', 'dashed-bar')
          .attr('d', (d) =>
            roundedHorizontalRect(
              1,
              yScale(d.y) + (HEIGHT_OF_ROW - HEIGHT_OF_BAR) / 2,
              xScale(d.xLast),
              HEIGHT_OF_BAR,
              [5, 5, 5, 5]
            )
          )
          .attr('fill', 'transparent')
          .attr('stroke-width', '1.5')
          .attr('stroke', (d) => d.color)
          .attr('stroke-dasharray', 3)
      );
    }

    bars.call((g) =>
      g
        .append('text')
        .attr('class', 'bar-value')
        .text((d) => numberWithCommas(d.xCurrent))
        .attr('x', '13px')
        .attr('y', (d) => yScale(d.y) + yScale.step() / 2)
    );
  };
  chart.segmentation.numberOfCategoriesAndCustomers = function (values) {
    const { data, height, margin, options } = values;
    d3.select(svg.node().parentNode).select('.tooltip').node()?.remove();
    const tooltip = d3
      .select(svg.node().parentNode)
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .call((div) => {
        const name = div.append('div').attr('class', 'tooltip-name');
        name
          .append('span')
          .attr('class', 'segment-color')
          .style('display', 'inline-block')
          .style('width', '9px')
          .style('height', '9px')
          .style('border-radius', '50%')
          .style('margin-right', '5px');
        name.append('span').attr('class', 'segment-name');
      })
      .call((div) =>
        div
          .append('div')
          .attr('class', 'tooltip-value')
          .call((div) => div.append('span').attr('class', 'tooltip-value_title'))
          .call((div) => div.append('span').attr('class', 'tooltip-value_value'))
          .call((div) => div.append('span').attr('class', 'tooltip-value_variation'))
      );

    const bars = svg.append('g').attr('class', 'bars').selectAll('g').data(data).join('g');

    if (options.isLegendSelect.current) {
      bars
        .call((g) =>
          g
            .append('path')
            .attr('class', 'bar')
            .attr('d', (d) => {
              return roundedVerticalRect(
                xScale(d.x),
                yScale(d.currentPercentage ?? d.yCurrent),
                xScale.bandwidth(),
                height - margin.bottom - yScale(d.currentPercentage ?? d.yCurrent),
                [5, 5, 0, 0]
              );
            })
            .attr('fill', (d) => d.color)
            .attr('fill-opacity', '0.5')
        )
        .call((g) => {
          // upperside
          g.append('text')
            .attr('class', 'bar-value')
            .text((d) => {
              return d.currentPercentage !== null ? `${d.currentPercentage}%` : '';
            })
            .attr('x', (d, i) => xScale(d.x) + xScale.bandwidth() / 2)
            .attr('text-anchor', 'middle')
            .attr('y', (d) => yScale(d.currentPercentage ?? d.yCurrent) - 20)
            .attr('fill', '#616161');

          // lower side
          g.append('text')
            .attr('class', 'bar-value')
            .text((d) => {
              return d.currentPercentage !== null
                ? `(${formatNumber(d.yCurrent)})`
                : formatNumber(d.yCurrent);
            })
            .attr('x', (d, i) => xScale(d.x) + xScale.bandwidth() / 2)
            .attr('text-anchor', 'middle')
            .attr('y', (d) => yScale(d.currentPercentage ?? d.yCurrent) - 4)
            .attr('fill', '#616161');
        });
    }

    if (options.isLegendSelect.last) {
      bars.call((g) =>
        g
          .append('path')
          .attr('class', 'dashed-bar')
          .attr('d', (d) =>
            roundedVerticalRect(
              xScale(d.x),
              yScale(d.lastPercentage ?? d.yLast),
              xScale.bandwidth(),
              height - margin.bottom - yScale(d.lastPercentage ?? d.yLast),
              [5, 5, 5, 5]
            )
          )
          .attr('fill', 'transparent')
          .attr('stroke-width', '1.5')
          .attr('stroke', (d) => d.color)
          .attr('stroke-dasharray', 3)
      );
    }
    const tooltipFix = {
      isFixed: false,
      fixedSegmentId: null
    };

    bars
      .on('mouseover', () => {})
      .on('mousemove', (event, d) => {
        tooltip.style('visibility', 'visible');
        const [x, y] = d3.pointer(event);
        tooltip.style('width', '194px');
        tooltip.select('.tooltip-name').select('.segment-color').style('background-color', d.color);
        tooltip.select('.tooltip-name').select('.segment-name').node().textContent = d.segmentName;

        tooltip.select('.tooltip-value').style('flex-wrap', 'wrap');
        tooltip.select('.tooltip-value_title').node().textContent = options.tooltipValueTitle;
        tooltip.select('.tooltip-value_title').style('width', '100%');

        tooltip.select('.tooltip-value_value').node().textContent = `${
          d.currentPercentage !== null ? `${d.currentPercentage}%` : d.yCurrent
        } ${d.currentPercentage !== null ? `(${numberWithCommas(d.yCurrent)})` : ''}`;
        tooltip.select('.tooltip-value_value').style('margin', '0');

        if (options.isLegendSelect.last) {
          const isPercentageValue = d.lastPercentage !== null;
          let variationValue;

          if (isPercentageValue) {
            variationValue = d.currentPercentage - d.lastPercentage;
          } else {
            variationValue = Number((d.yCurrent - d.yLast).toFixed(1));
          }

          tooltip.select('.tooltip-value_variation').node().classList.remove('negative');
          tooltip.select('.tooltip-value_variation').node().classList.remove('positive');
          if (variationValue < 0) {
            tooltip.select('.tooltip-value_variation').node().classList.add('negative');
            variationValue = Math.abs(variationValue);
          } else if (variationValue > 0) {
            tooltip.select('.tooltip-value_variation').node().classList.add('positive');
          }

          if (isPercentageValue) {
            tooltip.select('.tooltip-value_variation').node().textContent = variationValue + '%';
          } else {
            tooltip.select('.tooltip-value_variation').node().textContent = variationValue;
          }
        }
        tooltip
          .style('left', `${x - tooltip.node().getBoundingClientRect().width / 2}px`)
          .style('top', `${y - (tooltip.node().getBoundingClientRect().height + 15)}px`);
      })
      .on('mouseout', () => {
        if (tooltipFix.isFixed) {
          return;
        } else {
          tooltip.style('visibility', 'hidden');
        }
      })
      .on('click', (_, d) => {
        const copyTextarea = document.createElement('textarea');
        copyTextarea.style.position = 'fixed';
        copyTextarea.style.opacity = '0';

        let textContent = '';
        textContent += `${d.segmentName}\n`;
        textContent += `${options.tooltipValueTitle} ${d.yCurrent} `;
        if (d.percentage !== null) {
          textContent += `(${d.percentage}%) `;
        }
        if (options.isLegendSelect.last) {
          textContent +=
            d.yLast === 0
              ? d.yCurrent - d.yLast + ' customers'
              : Math.round(((d.yCurrent - d.yLast) / d.yLast) * 100) + '%';
        }

        copyTextarea.textContent = textContent;

        document.body.appendChild(copyTextarea);
        copyTextarea.select();
        document.execCommand('copy');
        document.body.removeChild(copyTextarea);
      });
  };
  chart.comparison = function (values) {
    const { data } = values;
    const _data = Array.from(
      d3.group(data, (d) => d.segmentId),
      ([key, values]) => ({ key, values })
    );

    const line = svg.append('g').attr('class', 'line');

    line
      .selectAll('path')
      .data(_data)
      .join('path')
      .attr('fill', 'none')
      .attr('stroke', (d) => d.values[0].color)
      .attr('stroke-width', 3)
      .attr('transform', `translate(${xScale.step() / 2},0)`)
      .attr('d', (d) =>
        d3
          .line()
          .x((d) => xScale(d.x))
          .y((d) => yScale(d.y))
          .curve(d3.curveMonotoneX)(d.values)
      );
  };
  chart.mosaic.bar = function ({ data, options }) {
    const { actualData, legendAndColors, tooltipYPositionAid } = options;

    // html element들을 d3로 제어하기 위한 selection 처리
    const tooltip = d3.select(svg.node().parentNode).select('.tooltip-container');

    const tooltipHeader = tooltip.select('.tooltip-header');
    tooltipHeader.select('.tooltip-header-element').remove();
    tooltipHeader.append('div').attr('class', 'tooltip-header-element');
    const tooltipHeaderElement = tooltipHeader.select('.tooltip-header-element');
    tooltipHeaderElement.append('span').attr('class', 'title');

    const tooltipBody = tooltip.select('.tooltip-body');
    tooltipBody.selectAll('*').remove();
    legendAndColors
      .filter((legend) => legend.isActive)
      .forEach(() => {
        tooltipBody
          .append('div')
          .attr('class', 'tooltip-body-element')
          .call((div) => {
            div.append('span').attr('class', 'color');
            div.append('span').attr('class', 'bold');
            div.append('span').attr('class', 'normal');
            div.append('span').attr('class', 'light');
          });
      });
    const tooltipBodyElements = tooltipBody.selectAll('.tooltip-body-element');

    // stacked bar chart 를 그린다.
    const bars = svg.append('g').selectAll('g').data(data).join('g');
    bars
      .attr('fill', (d) => legendAndColors.find((color) => color.name === d.key).chartColor)
      .selectAll('path')
      .data((d) => d)
      .join('path')
      .attr('d', (d) =>
        roundedVerticalRect(
          xScale(d.data.x),
          yScale(d[1]),
          xScale.bandwidth(),
          yScale(d[0]) - yScale(d[1]),
          100 - d[1] < 1 ? [2, 2, 0, 0] : [0, 0, 0, 0]
        )
      )
      // tooltip 처리
      .call((path) =>
        path
          .on('mousemove', (event, d) => {
            const [x, y] = d3.pointer(event);

            const legendAreaHeight = d3
              .select(svg.node().parentNode)
              .selectAll('div')
              .nodes()[0]
              .getBoundingClientRect().height;

            // 툴팁 위치 설정
            tooltip
              .style('visibility', 'visible')
              .style('left', `${x - tooltip.node().getBoundingClientRect().width / 2}px`)
              .style(
                'top',
                `${
                  y -
                  tooltip.node().getBoundingClientRect().height +
                  tooltipYPositionAid +
                  legendAreaHeight
                }px`
              );

            // tooltip body를 채울 data(tooltipBodyElementsData)를 준비하는 부분
            let tooltipBodyElementsData;

            // 각 카테고리의 비율값들을 실제 값들로 변환
            const hoveredBarData = JSON.parse(JSON.stringify(d.data));
            for (const property in hoveredBarData) {
              if (property === 'x' || property === 'segmentId') continue;
              hoveredBarData[property] = actualData.find(
                (segment) => segment.segmentId === hoveredBarData.segmentId
              )[property];
            }

            const dataEntry = Object.entries(hoveredBarData).filter(
              ([key]) => key !== 'x' && key !== 'segmentId'
            );
            const sumOfDataEntryValues = sumOfArray(dataEntry.map(([, value]) => value));

            tooltipBodyElementsData = dataEntry
              .map(([key, value]) => ({
                id: legendAndColors.find((legend) => legend.name === key).id,
                name: key,
                value: numberWithCommas(value),
                percentage: Math.round((value / sumOfDataEntryValues) * 100),
                color: legendAndColors.find((legend) => legend.name === key).chartColor
              }))
              .sort((a, b) => a.id - b.id);

            // 툴팁 표시 값 설정
            tooltipHeaderElement.select('.title').node().textContent = d.data.x;
            tooltipBodyElements.data(tooltipBodyElementsData).each((tooltipD, index, nodes) => {
              d3.select(nodes[index]).select('.color').style('background-color', tooltipD.color);
              d3.select(nodes[index]).select('.bold').node().textContent = `${tooltipD.name}:`;
              d3.select(nodes[index]).select('.normal').node().textContent = tooltipD.value;
              d3
                .select(nodes[index])
                .select('.light')
                .node().textContent = `(${tooltipD.percentage}%)`;
            });
          })
          .on('mouseout', () => {
            tooltip.style('visibility', 'hidden');
          })
      );
  };
  chart.mosaic.boxPlot = function (values) {
    const { data, options } = values;
    const { legendAndColors, tooltipYPositionAid } = options;
    const boxWidth = 18;

    // html element들을 d3로 제어하기 위한 selection 처리
    const tooltip = d3.select(svg.node().parentNode).select('.tooltip-container');

    const tooltipHeader = tooltip.select('.tooltip-header');
    tooltipHeader.select('.tooltip-header-element').remove();
    tooltipHeader.append('div').attr('class', 'tooltip-header-element');
    const tooltipHeaderElement = tooltipHeader.select('.tooltip-header-element');
    tooltipHeaderElement.append('span').attr('class', 'color');
    tooltipHeaderElement.append('span').attr('class', 'title');
    tooltipHeader.selectAll('.tooltip-header-additional').remove();
    for (let i = 0; i < 2; i++) {
      tooltipHeader
        .append('div')
        .attr('class', 'tooltip-header-additional')
        .call((div) => {
          div.append('span').attr('class', 'name');
          div.append('span').attr('class', 'value');
        });
    }
    const tooltipHeaderAdditionals = tooltipHeader.selectAll('.tooltip-header-additional');

    const tooltipBody = tooltip.select('.tooltip-body');
    tooltipBody.selectAll('*').remove();
    for (let i = 0; i < 6; i++) {
      tooltipBody
        .append('div')
        .attr('class', 'tooltip-body-element')
        .call((div) => {
          div.append('span').attr('class', 'bold');
          div.append('span').attr('class', 'normal');
        });
    }
    const tooltipBodyElements = tooltipBody.selectAll('.tooltip-body-element');

    svg
      .append('g')
      .selectAll('g')
      .data(data)
      .join('g')
      .call((g) => {
        g.append('line')
          .attr('x1', (d) => xScale(d.x))
          .attr('x2', (d) => xScale(d.x))
          .attr('y1', (d) => yScale(d.value.min))
          .attr('y2', (d) => yScale(d.value.max))
          .attr('stroke', (d) => legendAndColors.find((color) => color.name === d.x).chartColor)
          .attr('transform', `translate(${xScale.step() / 2}, 0)`)
          .style('width', 40);
        g.append('rect')
          .attr('x', (d) => xScale(d.x) - boxWidth / 2)
          .attr('y', (d) => yScale(d.value.q3))
          .attr('height', (d) => yScale(d.value.q1) - yScale(d.value.q3))
          .attr('width', boxWidth)
          .attr('transform', `translate(${xScale.step() / 2}, 0)`)
          .attr('stroke', (d) => legendAndColors.find((color) => color.name === d.x).chartColor)
          .style(
            'fill',
            (d) => legendAndColors.find((color) => color.name === d.x).lightChartColor
          );
        g.append('line')
          .attr('x1', (d) => xScale(d.x) - boxWidth / 2)
          .attr('x2', (d) => xScale(d.x) + boxWidth / 2)
          .attr('y1', (d) => yScale(d.value.median))
          .attr('y2', (d) => yScale(d.value.median))
          .attr('transform', `translate(${xScale.step() / 2}, 0)`)
          .attr('stroke', (d) => legendAndColors.find((color) => color.name === d.x).chartColor)
          .style('width', 80);
        g.append('circle')
          .attr('r', 3)
          .attr('cx', (d) => xScale(d.x))
          .attr('cy', (d) => yScale(d.value.mean))
          .style('fill', (d) => legendAndColors.find((color) => color.name === d.x).chartColor)
          .attr('transform', `translate(${xScale.step() / 2}, 0)`);
        g.append('line')
          .attr('x1', (d) => xScale(d.x) - boxWidth / 2)
          .attr('x2', (d) => xScale(d.x) + boxWidth / 2)
          .attr('y1', (d) => yScale(d.value.min))
          .attr('y2', (d) => yScale(d.value.min))
          .attr('transform', `translate(${xScale.step() / 2}, 0)`)
          .attr('stroke', (d) => legendAndColors.find((color) => color.name === d.x).chartColor)
          .style('width', 80);
        g.append('line')
          .attr('x1', (d) => xScale(d.x) - boxWidth / 2)
          .attr('x2', (d) => xScale(d.x) + boxWidth / 2)
          .attr('y1', (d) => yScale(d.value.max))
          .attr('y2', (d) => yScale(d.value.max))
          .attr('transform', `translate(${xScale.step() / 2}, 0)`)
          .attr('stroke', (d) => legendAndColors.find((color) => color.name === d.x).chartColor)
          .style('width', 80);
      })
      // tooltip 처리
      .call((g) => {
        g.on('mousemove', (event, d) => {
          const [x, y] = d3.pointer(event);

          // tooltip header additional를 채울 data를 준비하는 부분
          let tooltipHeaderAdditionalsData = [];
          tooltipHeaderAdditionalsData.push({
            name: 'Population',
            value: numberWithCommas(d.value.population)
          });
          tooltipHeaderAdditionalsData.push({
            name: 'Sample size',
            value: `${numberWithCommas(d.value.sample)}(${Math.round(
              (d.value.sample / d.value.population) * 100
            )}%)`
          });

          // tooltip body를 채울 data를 준비하는 부분
          let tooltipBodyElementsData = [];
          tooltipBodyElementsData.push({ name: 'Avg', value: numberWithCommas(d.value.mean) });
          tooltipBodyElementsData.push({ name: 'Min', value: numberWithCommas(d.value.min) });
          tooltipBodyElementsData.push({ name: '25%', value: numberWithCommas(d.value.q1) });
          tooltipBodyElementsData.push({ name: 'Med', value: numberWithCommas(d.value.median) });
          tooltipBodyElementsData.push({ name: '75%', value: numberWithCommas(d.value.q3) });
          tooltipBodyElementsData.push({ name: 'Max', value: numberWithCommas(d.value.max) });

          const legendAreaHeight = d3
            .select(svg.node().parentNode)
            .selectAll('div')
            .nodes()[0]
            .getBoundingClientRect().height;

          tooltip
            .style('visibility', 'visible')
            .style('left', `${x - tooltip.node().getBoundingClientRect().width / 2}px`)
            .style(
              'top',
              `${y - tooltip.node().getBoundingClientRect().height + legendAreaHeight}px`
            );
          tooltipHeaderElement
            .select('.color')
            .style(
              'background-color',
              legendAndColors.find((legend) => legend.name === d.x).chartColor
            );
          tooltipHeaderElement.select('.title').node().textContent = d.x;
          tooltipHeaderAdditionals
            .data(tooltipHeaderAdditionalsData)
            .each((additionalD, index, nodes) => {
              d3.select(nodes[index]).select('.name').node().textContent = `${additionalD.name}:`;
              d3.select(nodes[index]).select('.value').node().textContent = additionalD.value;
            });

          tooltipBodyElements.data(tooltipBodyElementsData).each((tooltipD, index, nodes) => {
            d3.select(nodes[index]).select('.bold').node().textContent = `${tooltipD.name}:`;
            d3.select(nodes[index]).select('.normal').node().textContent = tooltipD.value;
          });
        }).on('mouseout', () => {
          tooltip.style('visibility', 'hidden');
        });
      });
  };
  chart.comparisonTooltip = function (values) {
    const { margin, width, height, data } = values;
    const _data = Array.from(
      d3.group(data, (d) => d.segmentId),
      ([key, values]) => ({ key, values })
    );

    const overlay = svg.append('rect').attr('class', 'overlay');
    const guideLine = svg.append('line').attr('class', 'guide-line');
    const guideCircle = svg.append('circle').attr('class', 'guide-circle');
    const tooltip = d3
      .select(svg.node().parentNode)
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('pointer-events', 'none')
      .call((div) => div.append('div').attr('class', 'x'));

    _data.forEach((v) => {
      tooltip.call((div) =>
        div
          .append('div')
          .attr('class', 'y')
          .call((div) =>
            div.append('span').attr('class', 'label').html(`${v.values[0].segmentValue}: `)
          )
          .call((div) => div.append('span').attr('class', 'value'))
      );
    });

    guideLine
      .attr('stroke', guideLineStrokeColor)
      .attr('stroke-width', '3')
      .style('visibility', 'hidden')
      .style('pointer-events', 'none');
    guideCircle.attr('r', 4).style('visibility', 'hidden').style('pointer-events', 'none');

    overlay
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .attr('transform', `translate(${margin.left},0)`)
      .attr('width', width - margin.left - margin.right)
      .attr('height', height)
      .on('mouseover', () => {})
      .on('mousemove', (event) => {
        tooltip.style('visibility', 'visible');
        guideLine.style('visibility', 'visible');
        guideCircle.style('visibility', 'visible');

        const [x, y] = d3.pointer(event);

        const xValue = xScale.invert(x + margin.left);
        const yValue = data.filter((v) => v.x === xValue)[0].y;

        const xTargetedValues = data.filter((v) => v.x === xValue);
        const mouseClosestSegment = minBy(xTargetedValues, (v) => Math.abs(y - yScale(v.y)));
        const guideCircleYValue = mouseClosestSegment.y;
        let guideCircleColor = '';
        xTargetedValues.forEach((v, i) => {
          if (v.segmentId === mouseClosestSegment.segmentId) guideCircleColor = v.color;
        });

        const xPosition = xScale(xValue) + xScale.step() / 2;
        const yPosition = yScale(yValue);
        const guideCircleYPosition = yScale(guideCircleYValue);

        const tooltipX = tooltip.select('.x');
        const tooltipY = tooltip.select('.y');
        tooltipX.html(xValue);
        d3.selectAll('.y')
          .nodes()
          .forEach((node, i) => {
            d3.select(node).select('.value').html(numberWithCommas(xTargetedValues[i].y));
          });

        tooltip
          .style('left', xPosition + 'px')
          .style('transform', 'translateX(-50%)')
          .style('top', guideCircleYPosition - 160 + 'px');

        guideLine
          .transition()
          .duration(0.1)
          .attr('x1', xPosition)
          .attr('y1', margin.top)
          .attr('x2', xPosition)
          .attr('y2', height - margin.bottom);

        guideCircle
          .transition()
          .duration(0.1)
          .style('fill', guideCircleColor)
          .attr('stroke', guideCircleColor)
          .attr('cx', xPosition)
          .attr('cy', guideCircleYPosition);
      })
      .on('mouseout', () => {
        tooltip.style('visibility', 'hidden');
        guideLine.style('visibility', 'hidden');
        guideCircle.style('visibility', 'hidden');
      });
  };
  chart.itemJourney.bestJourney = function (values) {
    const { data, styles } = values;
    const outputName = data?.selectedOutputName;

    const sankey = d3Sankey()
      .size([882, 254])
      .nodeId((d) => d.id)
      .nodeWidth(22)
      .nodeAlign((d) => d.step)
      .nodeSort(null)
      .nodePadding(30)
      .best();
    const sankeyData = sankey(data);
    let { links, nodes } = sankeyData;

    const tooltip = d3.select(svg.node().parentNode).select('.tooltip-container');
    tooltip.style('visibility', 'hidden');

    const tooltipHeader = tooltip.select('.tooltip-header');
    tooltipHeader.select('.tooltip-header-element').remove();
    tooltipHeader.append('div').attr('class', 'tooltip-header-element');
    const tooltipHeaderElement = tooltipHeader.select('.tooltip-header-element');
    tooltipHeaderElement.append('span').attr('class', 'color');
    tooltipHeaderElement.select('.color').style('background-color', theme.color.neonBlue500);
    tooltipHeaderElement.append('span').attr('class', 'title');

    const columnStartX = styles.position.x + 10;
    const columnSpacing = 172;
    svg
      .append('g')
      .classed('columns', true)
      .selectAll('text')
      .data([
        t('journey.total_population'),
        outputName === 'item' ? t('journey.first_item') : t('journey.first_category'),
        outputName === 'item' ? t('journey.second_item') : t('journey.second_category'),
        outputName === 'item' ? t('journey.third_item') : t('journey.third_category'),
        outputName === 'item' ? t('journey.fourth_item') : t('journey.fourth_category'),
        outputName === 'item' ? t('journey.fifth_item') : t('journey.fifth_category')
      ])
      .join('text')
      .text((d) => d)
      .attr('x', (d, i) => columnStartX + columnSpacing * i)
      .attr('y', 15)
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Pretendard')
      .attr('font-weight', '700')
      .attr('font-size', '14px')
      .attr('fill', theme.color.gray900);

    const linksGroup = svg
      .append('g')
      .classed('links', true)
      .attr('transform', `translate(${styles.position.x}, ${styles.position.y})`)
      .selectAll('path')
      .data(links)
      .join('path')
      .classed('link', true)
      .attr('d', sankeyLinkHorizontal())
      .attr('fill', 'none')
      .attr('stroke', theme.color.neonBlue50)
      .attr('stroke-width', (d) => d.width);

    const nodesGroup = svg
      .append('g')
      .classed('nodes', true)
      .attr('transform', `translate(${styles.position.x}, ${styles.position.y})`)
      .selectAll('g')
      .data(nodes)
      .join('g')
      .classed('node', true);

    nodesGroup
      .append('rect')
      .join('rect')
      .attr('x', (d) => d.x0)
      .attr('y', (d) => d.y0)
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
      .attr('fill', theme.color.neonBlue500);

    nodesGroup
      .on('mousemove', (e, d) => {
        const tooltipBody = tooltip.select('.tooltip-body');
        tooltipBody.selectAll('*').remove();
        for (let i = 0; i < 6; i++) {
          tooltipBody
            .append('div')
            .attr('class', 'tooltip-body-element')
            .call((div) => {
              if (i === 0) {
                div.append('span').attr('class', 'bold');
                div.append('span').attr('class', 'normal');
              } else {
                div.style('flex-direction', 'column');
                div.style('align-items', 'flex-start');
                if (i === 4) {
                  if (d.name !== 'Total') {
                    div.classed('bordered', true);
                  }
                }
                if (d.step === 1) {
                  if (i == 2 || i == 3) {
                    div.classed('invisible', true);
                  }
                }
                div.append('div').attr('class', 'bold');
                div.append('div').attr('class', 'normal');
              }
            });
        }
        const tooltipBodyElements = tooltipBody.selectAll('.tooltip-body-element');

        // tooltip body를 채울 data를 준비하는 부분
        let tooltipBodyElementsData = [];
        tooltipBodyElementsData.push({
          name: t('journey.population'),
          value: numberWithCommas(d.data.population)
        });

        if (d.step > 0) {
          tooltipBodyElementsData.push({
            name: t('journey.per_of_total'),
            value: `${numberWithCommas(d.data.percentageOfTotal)}%`
          });
          tooltipBodyElementsData.push({
            name: t('journey.per_of_previous'),
            value: `${numberWithCommas(d.data.percentaeOfPrevious)}%`
          });
          tooltipBodyElementsData.push({
            name: t('journey.avg_days'),
            value: numberWithCommas(d.data.averageDays) + t('journey.days')
          });
          tooltipBodyElementsData.push({
            name: t('journey.churn_customer'),
            value: `${numberWithCommas(d.data.churnedCustomers)} (${
              d.data.churnedCustomersPercentage
            }%)`
          });
          tooltipBodyElementsData.push({
            name: t('journey.retain_customer'),
            value: `${numberWithCommas(d.data.retainedCustomers)} (${
              d.data.retainedCustomersPercentage
            }%)`
          });
        }

        tooltipHeaderElement.select('.title').node().textContent = d.name;
        tooltipBodyElements.data(tooltipBodyElementsData).each((tooltipD, index, nodes) => {
          d3.select(nodes[index]).select('.bold').node().textContent = `${tooltipD.name}:`;
          if (index === 2 || index === 3) {
            d3.select(nodes[index]).select('.bold').style('white-space', 'pre-line');
          }
          d3.select(nodes[index]).select('.normal').node().textContent = tooltipD.value;
        });

        tooltip
          .style('visibility', 'visible')
          .style(
            'left',
            `${
              styles.position.x +
              d.x1 +
              d3.select(e.currentTarget).node().getBoundingClientRect().width / 2
            }px`
          )
          .style('top', `${styles.position.y + d.y0 - 5}px`);
      })
      .on('mouseout', () => {
        tooltip.style('visibility', 'hidden');
      });

    nodesGroup
      .append('text')
      .join('text')
      .attr('class', 'label')
      .text((d) => d.name)
      .attr('x', (d) => (d.x0 + d.x1) / 2)
      .attr('y', (d) => d.y0 - 10)
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Pretendard')
      .attr('font-weight', '400')
      .attr('font-size', '14px')
      .attr('fill', theme.color.gray900)
      .text((d, i, texts) => {
        if (d.step < 1) {
          return;
        }
        const thisNode = texts[i];
        const ellipsis = '...';
        const maxWidth = 150;

        let text = d3.select(thisNode).text();
        let textLength = text.length;
        let actualWidth = thisNode.getBBox().width;

        if (actualWidth < maxWidth) {
          return text;
        }

        while (actualWidth > maxWidth && textLength > 0) {
          textLength--;
          text = text.slice(0, textLength);
          d3.select(thisNode).text(text + ellipsis);
          actualWidth = thisNode.getBBox().width;
        }
        return text + ellipsis;
      });
  };
  chart.itemJourney.journey = function (values) {
    const { data, styles, request } = values;

    const dataCopy = {
      ...data,
      links: limitObjectsPerStep(data.links.map((link) => ({ ...link }))),
      nodes: limitObjectsPerStep(data.nodes.map((node) => ({ ...node })))
    };

    const lineStartX = 184;
    const lineSpacing = 184;
    const lineCount = 6;
    for (let i = 0; i < lineCount; i++) {
      const xPosition = lineStartX + i * lineSpacing + (i === 4 ? -1 : 0);
      svg
        .append('line')
        .attr('x1', xPosition)
        .attr('y1', 0)
        .attr('x2', xPosition)
        .attr('y2', styles.height)
        .attr('stroke', theme.color.gray300)
        .attr('stroke-width', 1)
        .attr('transform', `translate(0, ${styles.position.y})`);
    }

    /*
    first : 298 px
    */
    const sankey = d3Sankey()
      .size([942, 830])
      .nodeId((d) => d.id)
      .nodeWidth(22)
      .nodeAlign((d) => d.step)
      .nodeSort(null)
      .nodePadding(30);

    const sankeyData = sankey(dataCopy);
    let { links, nodes } = sankeyData;

    const tooltip = d3.select(svg.node().parentNode).select('.tooltip-container');
    tooltip.style('visibility', 'hidden');

    const tooltipHeader = tooltip.select('.tooltip-header');
    tooltipHeader.select('.tooltip-header-element').remove();
    tooltipHeader.append('div').attr('class', 'tooltip-header-element');
    const tooltipHeaderElement = tooltipHeader.select('.tooltip-header-element');
    tooltipHeaderElement.append('span').attr('class', 'color');
    tooltipHeaderElement.select('.color').style('background-color', theme.color.neonBlue500);
    tooltipHeaderElement.append('span').attr('class', 'title');

    const linksGroup = svg
      .append('g')
      .classed('links', true)
      .attr('transform', `translate(0, ${styles.position.y})`)
      .selectAll('path')
      .data(links)
      .join('path')
      .classed('link', true)
      .attr('d', sankeyLinkHorizontal())
      .attr('fill', 'none')
      .attr('stroke', (d) => {
        if (d.target.name === '이탈' || d.target.name.toUpperCase() === 'CHURN') {
          return theme.color.gray100;
        }
        if (d.selected) {
          return theme.color.neonBlue100;
        }
        return theme.color.neonBlue50;
      })
      .attr('stroke-width', (d) => d.width);

    const nodesGroup = svg
      .append('g')
      .classed('nodes', true)
      .attr('transform', `translate(0, ${styles.position.y})`)
      .selectAll('g')
      .data(nodes)
      .join('g')
      .classed('node', true);

    nodesGroup
      .append('rect')
      .attr('x', (d) => d.x0)
      .attr('y', (d) => d.y0)
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
      .attr('fill', (d) => {
        if (d.name === '이탈' || d.name.toUpperCase() === 'CHURN') {
          return theme.color.gray500;
        }
        if (d.selected) {
          return theme.color.neonBlue800;
        }
        return theme.color.neonBlue500;
      });

    nodesGroup
      .append('text')
      .attr('class', 'label')
      .text((d) => d.name)
      .attr('x', (d) => (d.x0 + d.x1) / 2 - 10)
      .attr('y', (d) => {
        if (d.y1 - d.y0 < 11) {
          return d.y0 - 7;
        }
        return d.y0 - 7;
      })
      .attr('text-anchor', 'start')
      .attr('font-family', 'Pretendard')
      .attr('font-weight', (d) => (d.selected ? '700' : '400'))
      .attr('font-size', '14px')
      .attr('fill', theme.color.gray900)
      .text((_, i, texts) => {
        const thisNode = texts[i];
        const ellipsis = '...';
        const maxWidth = 180;

        let text = d3.select(thisNode).text();
        let textLength = text.length;
        let actualWidth = thisNode.getBBox().width;

        if (actualWidth < maxWidth) {
          return text;
        }

        while (actualWidth > maxWidth && textLength > 0) {
          textLength--;
          text = text.slice(0, textLength);
          d3.select(thisNode).text(text + ellipsis);
          actualWidth = thisNode.getBBox().width;
        }
        return text + ellipsis;
      });

    nodesGroup
      .append('image')
      .attr('href', (d, i) => {
        if (i === 0) {
          return;
        }
        if (d.y1 - d.y0 < 11) {
          return ITEM_JOURNEY_PLUS_CIRCLE;
        }
        return ITEM_JOURNEY_PLUS;
      })
      .attr('x', (d) => d.x0 + 4.5)
      .attr('y', (d) => d.y0 + (d.y1 - d.y0) / 2 - 6)
      .attr('width', 12)
      .attr('height', 12)
      .style('visibility', (d) => {
        if (d.name === '이탈' || d.name.toUpperCase() === 'CHURN' || d.step === 5) {
          return 'hidden';
        }
        return 'visible';
      });

    nodesGroup
      .on('mousedown', (e, d) => {
        const item = {
          id: d.tail,
          value: d.name
        };
        const property = replaceNumberToOrdinal(d.step);

        request(item, property);
      })
      .on('mousemove', (e, d) => {
        if (d.name === '이탈' || d.name.toUpperCase() === 'CHURN') {
          return;
        }

        const tooltipBody = tooltip.select('.tooltip-body');
        tooltipBody.selectAll('*').remove();
        for (let i = 0; i < 6; i++) {
          tooltipBody
            .append('div')
            .attr('class', 'tooltip-body-element')
            .call((div) => {
              if (i === 0) {
                div.append('span').attr('class', 'bold');
                div.append('span').attr('class', 'normal');
              } else {
                div.style('flex-direction', 'column');
                div.style('align-items', 'flex-start');
                if (i === 4) {
                  if (d.name !== 'Total') {
                    div.classed('bordered', true);
                  }
                }
                if (d.step === 1) {
                  if (i == 2 || i == 3) {
                    div.classed('invisible', true);
                  }
                }
                div.append('div').attr('class', 'bold');
                div.append('div').attr('class', 'normal');
              }
            });
        }
        const tooltipBodyElements = tooltipBody.selectAll('.tooltip-body-element');

        // tooltip body를 채울 data를 준비하는 부분
        let tooltipBodyElementsData = [];
        tooltipBodyElementsData.push({
          name: t('journey.population'),
          value: numberWithCommas(d.data.population)
        });
        if (d.step > 0) {
          tooltipBodyElementsData.push({
            name: t('journey.per_of_total'),
            value: `${numberWithCommas(d.data.percentageOfTotal)}%`
          });
          tooltipBodyElementsData.push({
            name: t('journey.per_of_previous'),
            value: `${numberWithCommas(d.data.percentaeOfPrevious)}%`
          });

          tooltipBodyElementsData.push({
            name: t('journey.avg_days'),
            value: numberWithCommas(d.data.averageDays) + t('journey.days')
          });
          tooltipBodyElementsData.push({
            name: t('journey.churn_customer'),
            value: `${numberWithCommas(d.data.churnedCustomers)} (${
              d.data.churnedCustomersPercentage
            }%)`
          });
          tooltipBodyElementsData.push({
            name: t('journey.retain_customer'),
            value: `${numberWithCommas(d.data.retainedCustomers)} (${
              d.data.retainedCustomersPercentage
            }%)`
          });
        }

        tooltipHeaderElement.select('.title').node().textContent = d.name;
        tooltipBodyElements.data(tooltipBodyElementsData).each((tooltipD, index, nodes) => {
          d3.select(nodes[index]).select('.bold').node().textContent = `${tooltipD.name}:`;
          if (index === 2 || index === 3) {
            d3.select(nodes[index]).select('.bold').style('white-space', 'pre-line');
          }
          d3.select(nodes[index]).select('.normal').node().textContent = tooltipD.value;
        });

        tooltip
          .style('visibility', 'visible')
          .style(
            'left',
            `${d.x1 + d3.select(e.currentTarget).node().getBoundingClientRect().width}px`
          )
          .style('top', `${styles.position.y + d.y0}px`);

        if (d.step > 0) {
          d3.select(e.currentTarget).style('cursor', 'pointer');
          d3.select(e.currentTarget).select('rect').attr('fill', theme.color.neonBlue400);
        }
      })
      .on('mouseout', (e, d) => {
        if (d.name === '이탈' || d.name.toUpperCase() === 'CHURN') {
          return;
        }
        tooltip.style('visibility', 'hidden');

        d3.select(e.currentTarget)
          .select('rect')
          .attr('fill', d.selected ? theme.color.neonBlue800 : theme.color.neonBlue500);
      });

    if (data.currentDepth === 1) {
      const calcHeight = sankey.calculatedChartHeight();
      exploreJourneyHeight = calcHeight + 70;
    }
    chart.height(exploreJourneyHeight);
  };
  chart.userBased.crmSimulation = function (values) {
    const { data, styles, legendAndColors, total, translation: t } = values;

    const tooltip = d3.select(svg.node().parentNode).select('.tooltip-container');

    const tooltipHeader = tooltip.select('.tooltip-header');
    tooltipHeader.select('.tooltip-header-element').remove();
    tooltipHeader.append('div').attr('class', 'tooltip-header-element');
    const tooltipHeaderElement = tooltipHeader.select('.tooltip-header-element');
    tooltipHeaderElement.append('span').attr('class', 'title');
    tooltipHeader.selectAll('.tooltip-header-additional').remove();
    tooltipHeader
      .append('div')
      .attr('class', 'tooltip-header-additional')
      .call((div) => {
        div.append('span').attr('class', 'name');
        div.append('span').attr('class', 'value');
      });
    const tooltipHeaderAdditionals = tooltipHeader.selectAll('.tooltip-header-additional');

    const tooltipBody = tooltip.select('.tooltip-body');
    tooltipBody.selectAll('*').remove();
    legendAndColors
      .filter((legend) => legend.isActive)
      .forEach(() => {
        tooltipBody
          .append('div')
          .attr('class', 'tooltip-body-element')
          .call((div) => {
            div.append('span').attr('class', 'bold');
            div.append('span').attr('class', 'normal');
            div.append('span').attr('class', 'light');
          });
      });
    const tooltipBodyElements = tooltipBody.selectAll('.tooltip-body-element');

    const xGroupScale = d3
      .scaleBand()
      .domain(data[0].y.map((d) => d.id))
      .rangeRound([0, xScale.bandwidth()])
      .paddingInner(0.2);

    /*
      [
        [
          { x: {id}, y: y[id]},
          { x: {id}, y: y[id]}
        ],
      ]
    */
    const bars = svg
      .append('g')
      .selectAll('g')
      .data(data)
      .join('g')
      .call((g) =>
        g
          .attr('transform', (d) => `translate(${xScale(d.x)}, 0)`)
          .selectAll('path')
          .data((d) => {
            // 인덱스를 id로 사용. legend의 id와 맞춤
            return d.y.map((obj) => ({
              x: obj.id,
              y: obj.value
            }));
          })
          .join('path')
          .attr('d', (d) => {
            return roundedVerticalRect(
              xGroupScale(d.x),
              yScale(d.y),
              xGroupScale.bandwidth(),
              styles.height - yScale(d.y) - styles.margin.bottom,
              [2, 2, 0, 0]
            );
          })
          .attr('fill', (d) => {
            return d.x === 0 ? theme.color.neonBlue100 : theme.color.neonBlue500;
          })
      )
      .on('mousemove', (event, d) => {
        const [x, y] = d3.pointer(event);
        const xPosition = xScale(d.x);
        const legendAreaHeight = d3
          .select(svg.node().parentNode)
          .selectAll('div')
          .nodes()[0]
          .getBoundingClientRect().height;
        tooltip
          .style('visibility', 'visible')
          .style('left', `${xPosition + x - tooltip.node().getBoundingClientRect().width / 2}px`)
          .style(
            'top',
            `${y - tooltip.node().getBoundingClientRect().height + legendAreaHeight}px`
          );

        tooltipHeaderElement.select('.title').node().textContent = d.x;
        tooltipHeaderAdditionals.select('.name').node().textContent = 'Total: ';
        tooltipHeaderAdditionals.select('.value').node().textContent = total;

        let tooltipBodyElementsData = [];
        d.y.forEach((obj) => {
          tooltipBodyElementsData.push({
            name:
              t(legendAndColors.find((legend) => legend.id === obj.id).translation) ||
              legendAndColors.find((legend) => legend.id === obj.id).name,
            value: obj.value
          });
        });
        tooltipBodyElements.data(tooltipBodyElementsData).each((tooltipD, index, nodes) => {
          d3.select(nodes[index]).select('.bold').node().textContent = `${tooltipD.name}:`;
          d3.select(nodes[index]).select('.normal').node().textContent = numberWithCommas(
            tooltipD.value
          );
        });
      })
      .on('mouseout', () => tooltip.style('visibility', 'hidden'));
  };

  return chart;
};
