import { useTranslation } from 'react-i18next';
import { labelToTranslationKey } from 'utils';

import NoData from '../NoData';
import './TableUserBase.scss';

const TableUserBase = ({ data, columns, hasIconHeadrs, icons, helpTexts }) => {
  const { t } = useTranslation();


  if (Array.isArray(data.cells[0])) {
    return (
      <div className="TableUserBase">
        <table>
          <thead>
            <tr className="table_head_row">
              {data.headers.map((item, i) => {
                const hasIconIndex = hasIconHeadrs.indexOf(item);

                return (
                  <th
                    key={`${item}${i}`}
                    className={`${item === 'Item' ? 'largeWidth' : ''} + ${
                      item === 'Top N' || item === 'Overall' || item === 'Filter'
                        ? 'everageWidth'
                        : ''
                    }`}
                  >
                    {t(`user_based.${labelToTranslationKey(item)}`)}
                    {/* {item === 'Normal Distribution' && <img src={info} className="info_logo" />} */}
                    {hasIconHeadrs.indexOf(item) > -1 && (
                      <div className="logo_box">{icons[hasIconHeadrs.indexOf(item)]}</div>
                    )}
                    {hasIconIndex > -1 && (
                      <div className="info_box">
                        <div className="triangle" />
                        {helpTexts[hasIconIndex]}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.cells.map((row, i) => {
              return (
                <tr key={i} className="table_body_row">
                  {row.map((cell, j) => {
                    return (
                      <td key={j} className="table_td">
                        <p className="itemText">{cell}</p>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {data.cells.length === 0 && (
          <div className="no-data">
            <NoData />
          </div>
        )}
      </div>
    );
  }
  
  return (
    <div className="TableUserBase">
      <table>
        <thead>
          <tr className="table_head_row">
            {data.headers.map((item, i) => {
              const hasIconIndex = hasIconHeadrs.indexOf(item);

              return (
                <th
                  key={`${item}${i}`}
                  className={`${item === 'Item' ? 'largeWidth' : ''} + ${
                    item === 'Top N' || item === 'Overall' || item === 'Filter'
                      ? 'everageWidth'
                      : ''
                  }`}
                >
                  {t(`user_based.${labelToTranslationKey(item)}`)}
                  {/* {item === 'Normal Distribution' && <img src={info} className="info_logo" />} */}
                  {hasIconHeadrs.indexOf(item) > -1 && (
                    <div className="logo_box">{icons[hasIconHeadrs.indexOf(item)]}</div>
                  )}
                  {hasIconIndex > -1 && (
                    <div className="info_box">
                      <div className="triangle" />
                      {helpTexts[hasIconIndex]}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.cells.map((item, i) => {
            return (
              <tr key={`${item.customer_name}${item.transactions}${i}`} className="table_body_row">
                {columns.map((column) => {
                  return (
                    <td key={column.header} className="table_td">
                      {item[column.cell] != null ? (
                        <p className="itemText">{item[column.cell]}</p>
                      ) : (
                        '-'
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.cells.length === 0 && (
        <div className="no-data">
          <NoData />
        </div>
      )}
    </div>
  );
};

export default TableUserBase;
