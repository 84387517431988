import * as Styles from './index.styles';

const PageTitle = ({ main, sub }) => {
  return (
    <Styles.PageTitle.Container>
      <Styles.PageTitle.Main>{main}</Styles.PageTitle.Main>
      <Styles.PageTitle.Sub>{sub}</Styles.PageTitle.Sub>
    </Styles.PageTitle.Container>
  );
};
export default PageTitle;
