export function mapReportParameterIdsWithName(option, reportData) {
  const mapped = {};
  mapped.good = reportData.good.map((report) => {
    return {
      ...report,
      data: {
        ...report.data,
        parameters: {
          category: {
            id: report.data.parameters.category_id,
            value:
              option?.category
                .filter((v) => v.id === report.data.parameters.category_id)[0]
                .value[0].toUpperCase() +
              option?.category
                .filter((v) => v.id === report.data.parameters.category_id)[0]
                .value.slice(1),
          },
          channel: {
            id: report.data.parameters.channel_id,
            value:
              option?.channel
                .filter((v) => v.id === report.data.parameters.channel_id)[0]
                .value[0].toUpperCase() +
              option?.channel
                .filter((v) => v.id === report.data.parameters.channel_id)[0]
                .value.slice(1),
          },
          interval: {
            id: report.data.parameters.interval,
            value:
              option?.interval
                .filter((v) => v.id === report.data.parameters.interval)[0]
                .value[0].toUpperCase() +
              option?.interval
                .filter((v) => v.id === report.data.parameters.interval)[0]
                .value.slice(1),
          },
          period: {
            id: report.data.parameters.period,
            value:
              option?.period
                .filter((v) => v.id === report.data.parameters.period)[0]
                .value[0].toUpperCase() +
              option?.period
                .filter((v) => v.id === report.data.parameters.period)[0]
                .value.slice(1),
          },
          item: {
            id: report.data.parameters.product_id,
            value:
              option?.item
                .filter((v) => v.id === report.data.parameters.product_id)[0]
                .value[0].toUpperCase() +
              option?.item
                .filter((v) => v.id === report.data.parameters.product_id)[0]
                .value.slice(1),
          },
          region: {
            id: report.data.parameters.region_id,
            value:
              option?.region
                .filter((v) => v.id === report.data.parameters.region_id)[0]
                .value[0].toUpperCase() +
              option?.region
                .filter((v) => v.id === report.data.parameters.region_id)[0]
                .value.slice(1),
          },
          segment: {
            id: report.data.parameters.segment_id,
            value:
              option?.segment
                .filter((v) => v.id === report.data.parameters.segment_id)[0]
                .value[0].toUpperCase() +
              option?.segment
                .filter((v) => v.id === report.data.parameters.segment_id)[0]
                .value.slice(1),
          },
          trackFirstItemOnly: {
            id: report.data.parameters.same || false,
            value: option?.trackFirstItemOnly.filter(
              (v) => v.id === (report.data.parameters.same || false)
            )[0].value,
          },
          retention: {
            id: report.data.parameters.period,
            value:
              option?.period
                .filter((v) => v.id === report.data.parameters.period)[0]
                .value[0].toUpperCase() +
              option?.period
                .filter((v) => v.id === report.data.parameters.period)[0]
                .value.slice(1),
          },
        },
      },
    };
  });
  mapped.bad = reportData.bad.map((report) => {
    return {
      ...report,
      data: {
        ...report.data,
        parameters: {
          category: {
            id: report.data.parameters.category_id,
            value:
              option?.category
                .filter((v) => v.id === report.data.parameters.category_id)[0]
                .value[0].toUpperCase() +
              option?.category
                .filter((v) => v.id === report.data.parameters.category_id)[0]
                .value.slice(1),
          },
          channel: {
            id: report.data.parameters.channel_id,
            value:
              option?.channel
                .filter((v) => v.id === report.data.parameters.channel_id)[0]
                .value[0].toUpperCase() +
              option?.channel
                .filter((v) => v.id === report.data.parameters.channel_id)[0]
                .value.slice(1),
          },
          interval: {
            id: report.data.parameters.interval,
            value:
              option?.interval
                .filter((v) => v.id === report.data.parameters.interval)[0]
                .value[0].toUpperCase() +
              option?.interval
                .filter((v) => v.id === report.data.parameters.interval)[0]
                .value.slice(1),
          },
          period: {
            id: report.data.parameters.period,
            value:
              option?.period
                .filter((v) => v.id === report.data.parameters.period)[0]
                .value[0].toUpperCase() +
              option?.period
                .filter((v) => v.id === report.data.parameters.period)[0]
                .value.slice(1),
          },
          item: {
            id: report.data.parameters.product_id,
            value:
              option?.item
                .filter((v) => v.id === report.data.parameters.product_id)[0]
                .value[0].toUpperCase() +
              option?.item
                .filter((v) => v.id === report.data.parameters.product_id)[0]
                .value.slice(1),
          },
          region: {
            id: report.data.parameters.region_id,
            value:
              option?.region
                .filter((v) => v.id === report.data.parameters.region_id)[0]
                .value[0].toUpperCase() +
              option?.region
                .filter((v) => v.id === report.data.parameters.region_id)[0]
                .value.slice(1),
          },
          segment: {
            id: report.data.parameters.segment_id,
            value:
              option?.segment
                .filter((v) => v.id === report.data.parameters.segment_id)[0]
                .value[0].toUpperCase() +
              option?.segment
                .filter((v) => v.id === report.data.parameters.segment_id)[0]
                .value.slice(1),
          },
          trackFirstItemOnly: {
            id: report.data.parameters.same || false,
            value: option?.trackFirstItemOnly.filter(
              (v) => v.id === (report.data.parameters.same || false)
            )[0].value,
          },
          retention: {
            id: report.data.parameters.period,
            value:
              option?.period
                .filter((v) => v.id === report.data.parameters.period)[0]
                .value[0].toUpperCase() +
              option?.period
                .filter((v) => v.id === report.data.parameters.period)[0]
                .value.slice(1),
          },
        },
      },
    };
  });
  return mapped;
}
