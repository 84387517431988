import { useTranslation, Trans } from 'react-i18next';
import { NODATA } from 'constants/images';
import './index.scss';

export default function NoData({ isFeatureBased }) {
  const { t } = useTranslation();

  return (
    <div className="no-data">
      <img src={NODATA} alt="no-data" />
      <p className="main">{t("error.theres_no_data")}</p>
      {isFeatureBased ? (
        <p className="sub">
          <Trans i18nKey={"error.at_least_one_filter_should"} components={{l: <span className="stress" />}}/>
        </p>
      ) : (
        <p className="sub">
          {t("error.please_select_another_parameter_to")}
        </p>
      )}
    </div>
  );
}
