import styled from '@emotion/styled';
import { theme } from 'styles@emotion/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const Title = styled.div`
  ${theme.font.chartTitle}
  color: ${theme.color.gray900};
`;
export const ExampleLink = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  ${theme.font.buttonRegular}
  color: ${theme.color.neonBlue500};
  cursor: pointer;
`;
export const ExportIcon = styled.img``;

export const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SubmitButton = styled.button`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 138px;
  height: 48px;
  border-radius: 100px;
  background-color: ${({ disabled }) =>
    disabled ? theme.color.neonBlue50 : theme.color.neonBlue500};
  ${theme.font.buttonRegular}
  color: ${({ disabled }) => (disabled ? theme.color.gray100 : theme.color.white)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? theme.color.neonBlue50 : theme.color.neonBlue400};
  }
  &:active {
    background-color: ${({ disabled }) =>
      disabled ? theme.color.neonBlue50 : theme.color.neonBlue600};
  }
`;

export const SubmitProcessingIcon = styled.img`
  margin-left: 2px;
  animation: spin 1200ms infinite linear;
`;

export const FileInput = {
  Container: styled.div`
    width: 100%;
  `,
  InputContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;
    width: 100%;
    height: 56px;
    border: 1px solid ${theme.color.gray300};
    border-radius: 4px;
  `,
  FileInformation: styled.div`
    display: flex;
    align-items: center;
    width: 267px;
    height: 100%;
  `,
  FileIcon: styled.img`
    margin-right: 8px;
  `,
  FileName: styled.div`
    width: 193px;
    ${theme.font.bodySmall}
    color: ${theme.color.gray900};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  `,
  FileErrorIcon: styled.img``,
  ChooseFile: {
    Label: styled.label`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 112px;
      height: 28px;
      border: 1px solid ${theme.color.neonBlue500};
      border-radius: 100px;
      color: ${theme.color.neonBlue500};
      ${theme.font.buttonSmall}
      cursor: pointer;
    `,
    Input: styled.input`
      display: none;
    `
  },
  ErrorContainer: styled.div``,
  HelperText: styled.div`
    padding-left: 24px;
    ${theme.font.bodySmall}
    color: ${theme.color.red500};
  `,
  ErrorMessage: styled.div`
    display: flex;
    align-items: center;
    padding-left: 17px;
    ${theme.font.bodySmall}
    color: ${theme.color.gray900};
  `,
  ErrorMessageIcon: styled.img`
    margin-right: 5px;
  `
};
