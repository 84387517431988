import { useTranslation } from 'react-i18next';
import { mutationKeys, queryKeys } from 'constants/keys';
import { Navigate } from 'react-router-dom';
import PrimaryButton from 'components/buttons/PrimaryButton';
import MainHeader from 'components/MainHeader';
import { useAccountFetch, useAccountMutation } from 'hooks/account';
import './index.scss';
import { useState, useEffect } from 'react';
import { useUserInfoContext } from 'context';
import { useCustomerServiceFetch } from 'hooks';

import Export from 'assets/icons/export.svg';
import { exportCSV } from 'utils';

export default function Organization() {
  const { t } = useTranslation()
  const { userInfo } = useUserInfoContext();
  const [inputOrganization, setInputOrganization] = useState({
    // name: '',
    businessNumber: '',
    address: '',
    fax: '',
  });
  const { data } = useAccountFetch(true, queryKeys.ACCOUNT.ORGANIZATION);

  const {
    isResultRender,
    result: exportData,
    fetchResult,
  } = useCustomerServiceFetch({
    serviceQueryKey: queryKeys.SERVICE.GET_EXPORT,
  });
  const [editOrganization] = useAccountMutation({
    mutationKey: mutationKeys.ACCOUNT.UPDATE_ORGANIZATION,
  });

  const handleOrganizationInput = (key) => (e) => {
    setInputOrganization((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  function handleSave() {
    editOrganization({
      companyId: userInfo?.company?.companyId,
      // companyName: inputOrganization.name,
      phone: inputOrganization.businessNumber,
      address: inputOrganization.address,
      fax: inputOrganization.fax,
    });
  }
  useEffect(() => {
    fetchResult();
  }, []);

  useEffect(() => {
    setInputOrganization({
      name: '',
      businessNumber: '',
      address: '',
      fax: '',
    });
  }, [data]);

  const handleExport = async (e, url, page) => {
    e.preventDefault();
    if (userInfo?.user.role === 'observer') return;

    await exportCSV(url, page);
  };

  if (
    userInfo?.user?.role !== 'admin' &&
    userInfo?.user?.role !== 'master' &&
    userInfo?.user?.role !== 'manager'
  )
    return <Navigate to="/" />;

  return (<>
    <MainHeader main={t("settings.organization_settings")} />
    <div className="box organization edit-information">
      <div className="title">{t("settings.edit_information")}</div>
      <div className="boxes">
        <div className="input-box">
          <div className="label">{t("settings.organization_name")}</div>
          <div className="input-wrapper disabled">
            <input
              className="input"
              placeholder={data?.company.korean_name}
              disabled
              // value={inputOrganization.name}
              // onChange={handleOrganizationInput('name')}
            />
          </div>
        </div>
        <div className="input-box">
          <div className="label">{t("settings.business_number")}</div>
          <div className="input-wrapper">
            <input
              className="input"
              placeholder={data?.company.phone}
              value={inputOrganization.businessNumber}
              onChange={handleOrganizationInput('businessNumber')}
            />
          </div>
        </div>
        <div className="input-box">
          <div className="label">{t("settings.organization_address")}</div>
          <div className="input-wrapper">
            <input
              className="input"
              placeholder={data?.company.address}
              value={inputOrganization.address}
              onChange={handleOrganizationInput('address')}
            />
          </div>
        </div>
        <div className="input-box">
          <div className="label">{t("settings.fax")}</div>
          <div className="input-wrapper">
            <input
              className="input"
              placeholder={data?.company.fax}
              value={inputOrganization.fax}
              onChange={handleOrganizationInput('fax')}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="info">
          <div className="membership">
            {t("settings.membership_early_mover")}:{' '}
            {data?.subscription_status.description ? data?.company.subscription.description : ''}
          </div>
          <div className="period">
            {t("settings.contract_period")}:{' '}
            {data?.subscription_status.from_time
              ? data?.subscription_status.from_time.slice(0, 10)
              : ''}{' '}
            ~{' '}
            {data?.subscription_status.to_time
              ? data?.subscription_status.to_time?.slice(0, 10)
              : ''}
          </div>
        </div>
        <div className="button-box">
          <PrimaryButton value={t("settings.save")} onClick={handleSave} style={{ width: '138px' }} />
        </div>
      </div>
    </div>
    {isResultRender && (
      <div className="box organization data-history">
        <div className="title">{t("settings.data_history")}</div>
        <table>
          <thead>
            <tr>
              <th>{t("inquiries.no")}</th>
              <th>{t("settings.data_menu")}</th>
              <th>{t("settings.data_name")}</th>
              <th>{t("settings.dashboard_name")}</th>
              <th>{t("inquiries.user_name")}</th>
              <th>{t("settings.download_date")}</th>
              <th>{t("settings.export")}</th>
            </tr>
          </thead>
          <tbody>
            {exportData.list.map((v, i) => (
              <tr key={`${v.created_at}${i}`}>
                <td>{v.no}</td>
                <td>{v.dataMenu}</td>
                <td>{v.dataName}</td>
                <td>{v.dashboardName}</td>
                <td>{v.userName}</td>
                <td>{v.date}</td>
                <td>
                  {v?.options && (
                    <button onClick={(e) => handleExport(e, v.options, v.dataMenu)}>
                      <img src={Export} className="export_img" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
  </>);
}
