import { axiosBase, axiosPrivate, authAPI, accountAPI, analyzeAPI } from 'api';
import { getStore, setNewMemberInfo, setStore } from 'utils/userStorage';
import { getTokenHeader } from 'utils';
import { NOT_ANALYZED } from 'constants';

const store = {
  token: {
    idToken: '',
    accessToken: '',
    refreshAfter: '',
    expireAfter: ''
  },
  user: {
    username: '',
    provider: '',
    role: ''
  },
  company: {
    domain: '',
    companyId: '',
    companyName: '',
    shopId: '',
    shops: []
  },
  dashboard: {
    currency: '',
    status: ''
  }
};

export const mutationFns = {
  async email({ email }) {
    await axiosBase.post('authenticator/forgot_password', {
      user: {
        primary_email: email
      }
    });
  },

  async signin({ email, password } = null) {
    const { data: authData } = await axiosBase.post(authAPI.SIGNIN, {
      user: { primary_email: email, password }
    });

    // If the user are registered for the first time
    if (authData.challenge) {
      const newMemberInfo = {
        email: email,
        username: authData.challenge.username,
        session: authData.challenge.session
      };
      setNewMemberInfo(newMemberInfo);

      return;
    }

    const { data: accountData } = await axiosBase.post(
      accountAPI.PROFILE,
      {
        username: authData.user.username
      },
      {
        headers: {
          authorization: `Bearer ${authData.token.id_token}`
        }
      }
    );
    const { data: dashboardData } = await axiosBase.post(
      analyzeAPI.DASHBOARD,
      {
        company_id: accountData.employee.company_id,
        shop_id: accountData.employee.company.shops[0].id,
        last: 5
      },
      {
        headers: {
          authorization: `Bearer ${authData.token.id_token}`
        }
      }
    );

    if ('token' in authData && 'user' in authData) {
      store.token.idToken = authData.token.id_token;
      store.token.accessToken = authData.token.access_token;
      store.user.username = authData.user.username;
      store.user.provider = authData.user.identity_provider;
      store.token.refreshAfter = new Date(authData.token.expire_after) - new Date() - 60000;
      store.token.expireAfter = authData.token.expire_after;
    }
    if ('employee' in accountData) {
      store.user.role = accountData.employee.roles[0];
      store.company.domain = accountData.employee.company.domain;
      store.company.companyId = accountData.employee.company_id;
      store.company.companyName = accountData.employee.company.english_name;
      store.company.shopId = accountData.employee.company.shops[0].id;
      store.company.shops = accountData.employee.company.shops;
    }
    if (dashboardData.result.length !== 0) {
      const validDashboard = dashboardData.result.find((dashboard) => dashboard.valid);

      if (!validDashboard) {
        store.dashboard.currency = null;
        store.dashboard.createdAt = '';
      } else {
        store.dashboard.currency = validDashboard.currency;
        store.dashboard.createdAt = validDashboard.created_at;
      }

      store.dashboard.isFirstDashboard = dashboardData.result.length === 1 ? true : false;
      store.dashboard.latestAnalysisStatus = dashboardData.result[0].status;
    } else if (dashboardData.result.length === 0) {
      store.dashboard.currency = null;
      store.dashboard.createdAt = '';

      store.dashboard.isFirstDashboard = null;
      store.dashboard.latestAnalysisStatus = NOT_ANALYZED;
    }

    setStore(store);
  },

  async googleSignin({ password }) {
    const { data: authData } = await axiosBase.post(authAPI.GOOGLE_SIGNIN, {
      user: { password }
    });

    const { data: accountData } = await axiosBase.post(
      accountAPI.PROFILE,
      {
        username: authData.user.username
      },
      {
        headers: {
          authorization: `Bearer ${authData.token.id_token}`,
          'X-SSO-Provider': 'google'
        }
      }
    );
    const { data: dashboardData } = await axiosBase.post(
      analyzeAPI.DASHBOARD,
      {
        company_id: accountData.employee.company_id,
        shop_id: accountData.employee.company.shops[0].id,
        last: 5
      },
      {
        headers: {
          authorization: `Bearer ${authData.token.id_token}`,
          'X-SSO-Provider': 'google'
        }
      }
    );

    if ('token' in authData && 'user' in authData) {
      store.token.idToken = authData.token.id_token;
      store.token.accessToken = authData.token.access_token;
      store.user.username = authData.user.username;
      store.user.provider = authData.user.identity_provider;
      store.token.refreshAfter = new Date(authData.token.expire_after) - new Date() - 60000;
      store.token.expireAfter = authData.token.expire_after;
    }
    if ('employee' in accountData) {
      store.user.role = accountData.employee.roles[0];
      store.company.domain = accountData.employee.company.domain;
      store.company.companyId = accountData.employee.company_id;
      store.company.companyName = accountData.employee.company.english_name;
      store.company.shopId = accountData.employee.company.shops[0].id;
      store.company.shops = accountData.employee.company.shops;
    }
    if (dashboardData.result.length !== 0) {
      const validDashboard = dashboardData.result.find((dashboard) => dashboard.valid);

      if (!validDashboard) {
        store.dashboard.currency = null;
        store.dashboard.createdAt = '';
      } else {
        store.dashboard.currency = validDashboard.currency;
        store.dashboard.createdAt = validDashboard.created_at;
      }

      store.dashboard.isFirstDashboard = dashboardData.result.length === 1 ? true : false;
      store.dashboard.latestAnalysisStatus = dashboardData.result[0].status;
    } else if (dashboardData.result.length === 0) {
      store.dashboard.currency = null;
      store.dashboard.createdAt = '';

      store.dashboard.isFirstDashboard = null;
      store.dashboard.latestAnalysisStatus = NOT_ANALYZED;
    }

    setStore(store);
  },

  async refresh() {
    const store = { ...getStore() };
    let endpoint = authAPI.REFRESH;
    const headers = {
      ...getTokenHeader(getStore().token.idToken)
    };
    if (store.user.provider === 'google') {
      headers['X-SSO-Provider'] = 'google';
      endpoint = authAPI.GOOGLE_REFRESH;
    }

    const { data } = await axiosBase.post(
      endpoint,
      {},
      {
        data: null,
        headers
      }
    );
    if ('id_token' in data) {
      store.token.idToken = data.id_token;
      store.token.accessToken = data.access_token;
      store.token.refreshAfter = new Date(data.expire_after) - new Date() - 60000;
      store.token.expireAfter = data.expire_after;
      setStore(store);
    }
  },

  async signout() {
    return await axiosPrivate.post(authAPI.SIGNOUT, {
      user: {
        username: getStore().user.username
      },
      access_token: getStore().token.accessToken
    });
  },

  async postEmail(email) {
    return await axiosBase.post('authenticator/forgot_password', {
      user: {
        primary_email: email
      }
    });
  },

  async confirmCode(data) {
    return await axiosBase.post('authenticator/confirm_forgot_password', {
      user: {
        primary_email: data.email,
        password: data.password
      },
      forgot_password_confirmation_code: data.code
    });
  },

  async respondChallenge({ password, email, username, session }) {
    return await axiosBase.post(authAPI.RESPOND_CHALLENGE, {
      user: { username, password, primary_email: email },
      session
    });
  }
};
