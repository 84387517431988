import styled from '@emotion/styled';
import { theme } from 'styles@emotion/theme';
import { SELECT_SIZE } from 'constants';

const Container = styled.div`
  position: relative;
  z-index: 1;
  display: inline-flex;
  flex-direction: column;
  width: ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return '180px';
      default:
        return '260px';
    }
  }};
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
  ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return theme.font.chartLegend;
      default:
        return theme.font.bodyRegular;
    }
  }}
  color: ${theme.color.gray900};
`;

const InputContainer = styled.div`
  position: relative;
  background-color: ${({ disabled }) => (disabled ? theme.color.gray100 : theme.color.white)};
  border-radius: 4px;
  &:hover {
    background-color: ${theme.color.gray100};
  }
`;

const Input = styled.input`
  padding: ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return '9px 68px 9px 10px';
      default:
        return '16px 48px 16px 16px';
    }
  }};
  width: 100%;
  height: ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return '32px';
      default:
        return '56px';
    }
  }};
  outline: none;
  border: 1px solid ${theme.color.gray300};
  border-radius: 4px;
  background-color: transparent;
  ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return theme.font.chartLegend;
      default:
        return theme.font.bodyRegular;
    }
  }}
  color: ${theme.color.gray900};
  cursor: default;
  &:focus {
    background-color: ${theme.color.white};
    border-color: ${theme.color.neonBlue500};
    color: ${theme.color.gray900};
    caret-color: ${theme.color.neonBlue500};
    cursor: text;
  }
  &::placeholder {
    color: ${theme.color.gray500};
  }
  &[disabled] {
    border: 1px solid ${theme.color.gray100};
    &::placeholder {
      color: ${theme.color.gray300};
    }
  }
`;

const InputRemove = styled.img`
  position: absolute;
  z-index: 1;
  top: ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return '4px';
      default:
        return '16px';
    }
  }};
  right: ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return '36px';
      default:
        return '48px';
    }
  }};
  cursor: pointer;
`;

const InputArrow = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  width: ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return '18px';
      default:
        return '24px';
    }
  }};
  margin: ${({ size, isReverse }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return `7px 10px 7px ${isReverse ? 10 : 0}px`;
      default:
        return `16px 16px 16px ${isReverse ? 16 : 0}px`;
    }
  }};
  background-color: transparent;
  transform: rotate(${({ isReverse }) => (isReverse ? 180 : 0)}deg);
`;

const List = styled.ul`
  position: absolute;
  top: 90px;
  top: ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return '56px';
      default:
        return '90px';
    }
  }};
  list-style: none;
  width: 100%;
  height: fit-content;
  max-height: ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return '128px';
      default:
        return '218px';
    }
  }};
  overflow-y: scroll;
  border: 1px solid ${theme.color.gray300};
  background-color: ${theme.color.white};
  border-radius: 4px;
`;
const Item = styled.li`
  display: flex;
  align-items: center;
  padding: ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return '9px 10px';
      default:
        return '12px 16px';
    }
  }};
  width: 100%;
  height: fit-content;
  min-height: ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return '32px';
      default:
        return '56px';
    }
  }};
  ${({ size }) => {
    switch (size) {
      case SELECT_SIZE.SMALL:
        return theme.font.chartLegend;
      default:
        return theme.font.bodyRegular;
    }
  }}
  color: ${({ isSelect }) => (isSelect ? theme.color.neonBlue500 : theme.color.gray900)};
  cursor: pointer;
  &:hover {
    background-color: ${theme.color.gray100};
  }
`;

export default {
  Container,
  Label,
  InputContainer,
  Input,
  InputRemove,
  InputArrow,
  List,
  Item
};
