import { useDashboardContext, useUserInfoContext } from 'context';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { queryFns, transformData } from './queryHelpers';

export default function useResultFetch(
  isResultFetchAllowed,
  setIsResultFetchAllowed,
  feature,
  parameters
) {
  const { currency } = useDashboardContext();
  const { userInfo } = useUserInfoContext();
  const { data, status, isSuccess } = useQuery(
    [feature, parameters, userInfo?.company.shopId],
    (context) => queryFns[feature](context, userInfo),
    {
      enabled: isResultFetchAllowed,
      select: (data) => transformData[feature](data, currency)
    }
  );

  useEffect(() => {
    setIsResultFetchAllowed(true);
  }, [userInfo?.company.shopId]);

  return { data, status, isSuccess };
}
