import { useTranslation } from 'react-i18next';
import { labelToTranslationKey } from 'utils';

const CrossSellingTable = ({ selling }) => {
  const { t } = useTranslation();

  const columns = [
    {
      header: 'Antecedents',
      cell: '',
    },
    {
      header: 'Consequents',
      cell: '',
    },
    {
      header: 'Median',
      cell: '',
    },
    {
      header: 'Min',
      cell: '',
    },
    {
      header: 'Max',
      cell: '',
    },
    {
      header: 'Support',
      cell: '',
    },
    {
      header: 'Confidence',
      cell: '',
    },
  ];
  return (
    <div className="CrossSellingTable">
      <table>
        <thead>
          <tr className="table_head_row">
            {columns.map((column) => {
              return (
                <th key={column.header}>
                  {t(`cross.${labelToTranslationKey(column.header)}`)}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {selling.cells.map((item, i) => {
            return (
              <tr key={`${item.std}${i}`} className="table_body_row">
                <td className="table_td">{item.antecedent_name}</td>
                <td className="table_td">{item.consequent_name}</td>
                <td className="table_td">{item.median}{t("cross.days")}</td>
                <td className="table_td">{item.min}{t("cross.days")}</td>
                <td className="table_td">{item.max}{t("cross.days")}</td>
                <td className="table_td">{item.support}</td>
                <td className="table_td">{item.confidence}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CrossSellingTable;