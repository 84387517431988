import { useQueryClient, useMutation } from 'react-query';
import { mutationFns } from './mutationHelpers';
import { useGlobalToast } from 'context';
import { cloneDeep } from 'lodash';
import { mutationKeys } from 'constants/keys';

export function useCustomerServiceMutation({ mutationKey, serviceQueryKey = null }) {
  const queryClient = useQueryClient();
  const toast = useGlobalToast();

  const { mutate } = useMutation(mutationFns[mutationKey], {
    // onMutate returns context which is passed to onError
    // 'newPayload', which is 'payload' passed to useMutation
    onMutate: async (newPayload) => {
      if (mutationKey === mutationKeys.REPORT.EVALUATE) {
        // cancel any outgoing queries for suer data so that old server data, doesn't overwrite our optimistic update
        queryClient.cancelQueries([serviceQueryKey, {}]);

        // snapshot of previous value
        const previousPayload = queryClient.getQueryData([serviceQueryKey, {}]);
        const previousPayloadRetention = cloneDeep(previousPayload.retention).map((v) => {
          if (v.id === newPayload.reportId) {
            const copied = cloneDeep(v);
            copied.evaluation = newPayload.evaluation;
            return copied;
          }
          return v;
        });
        const previousPayloadLTV = cloneDeep(previousPayload.life_time_value).map((v) => {
          if (v.id === newPayload.reportId) {
            const copied = cloneDeep(v);
            copied.evaluation = newPayload.evaluation;
            return copied;
          }
          return v;
        });
        const tobePayload = {
          retention: previousPayloadRetention,
          life_time_value: previousPayloadLTV,
        };
        // optimistically update the cache with the new value
        queryClient.setQueryData([serviceQueryKey, {}], tobePayload);
        // return context object with snapshotted value
        return { previousPayload, tobePayload };
      }
    },
    onError: (error, newPayload, context) => {
      const message = error instanceof Error ? error.message : 'error message not defined';
      if (context.previousPayload) {
        queryClient.setQueryData([serviceQueryKey, {}], context.previousPayload);
        toast({ errorMessage: message });
      }
    },
    onSuccess: (data) => {
      if (mutationKey !== mutationKeys.REPORT.EVALUATE)
        queryClient.invalidateQueries([serviceQueryKey, {}]);
    },
    onSettled: () => {
      if (mutationKey === mutationKeys.REPORT.EVALUATE)
        // invalidate query to make sure we're in sync with server data
        queryClient.invalidateQueries([serviceQueryKey]);
    },
  });

  return [mutate];
}
