import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MainHeader from 'components/MainHeader';
import OriginalSearchInput from 'components/inputs/OriginalSearchInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { useGetCustomerService } from 'hooks';
import { queryKeys, mutationKeys } from 'constants/keys';
import './LeadToCustomer.scss';
import NoData from 'components/NoData';
import { numberWithCommas } from 'utils';
import { INFO24PX, COHORT_TOOLTIP_LTV, COHORT_TOOLTIP_RETENTION, EXPORT } from 'constants/images';
import { extractMonth, nameExportFile } from 'utils';
import { useDashboardContext } from 'context';
import { saveAs } from 'file-saver';
import Excel from 'exceljs';
import { useUserInfoContext } from 'context';
import { useCustomerServiceMutation, usePageInit } from 'hooks';
import { ParametersSkeleton, ParameterSkeleton, DynamicSkeleton } from 'components/skeletons';

const LeadToCustomer = () => {
  const { userInfo } = useUserInfoContext();
  const { t } = useTranslation();

  const [isLtcColorOn, setIsLtcColorOn] = useState({
    ltc: true
  });
  const [logExport] = useCustomerServiceMutation({
    mutationKey: mutationKeys.CUSTOMER.LOG_EXPORT
  });

  const handleToggle = (category) =>
    setIsLtcColorOn((prev) => {
      return {
        ...prev,
        [category]: !prev[category]
      };
    });

  const { option, result, parameters, handlers } = useGetCustomerService({
    optionQueryKey: queryKeys.OPTION.LEAD_TO_CUSTOMER,
    serviceQueryKey: queryKeys.SERVICE.LEAD_TO_CUSTOMER
  });

  const handleExport = () => {
    if (userInfo?.user.role === 'observer') return;
    if (result === 'no data') return;

    const firstWorksheetColumns = [
      { header: 'parameter', key: 'parameter' },
      { header: 'value', key: 'value' }
    ];
    const secondWorksheetColumns = result.leadToCustomerTable.headers.map((v) => ({
      header: v,
      key: v.replace(' ', '').toLowerCase()
    }));
    const thirdWorksheetColumns = result.leadToCustomerTable.headers.map((v) => ({
      header: v,
      key: v.replace(' ', '').toLowerCase()
    }));

    const firstWorksheetRows = [
      {
        parameter: 'Period',
        value: parameters.period.value
      },
      {
        parameter: 'From',
        value:
          parameters.period.value === 'monthly'
            ? parameters.monthlyFrom.value
            : parameters.monthlyFrom.value
      },
      {
        parameter: 'To',
        value:
          parameters.period.value === 'monthly'
            ? parameters.monthlyTo.value
            : parameters.monthlyTo.value
      },
      {
        parameter: 'Segment',
        value: parameters.segment.value
      }
    ];
    const secondWorksheetRows = result.leadToCustomerTable.cells
      .filter((v, i) => i % 2 === 0)
      .map((v) => {
        const row = {};
        v.forEach((w, j) => {
          if (j === 0) {
            parameters.period.value === 'weekly'
              ? (row[secondWorksheetColumns[j].key] =
                  w.value.slice(0, 4) + '-' + w.value.slice(5, 7) + '-' + w.value.slice(8))
              : (row[secondWorksheetColumns[j].key] =
                  w.value.slice(0, 4) + '-' + w.value.slice(5, 7));
          } else {
            row[secondWorksheetColumns[j].key] = w.value;
          }
        });
        return row;
      });
    const thirdWorksheetRows = result.leadToCustomerTable.cells
      .filter((v, i) => i % 2 === 1)
      .map((v, i) => {
        const row = {};
        v.forEach((w, j) => {
          if (j === 0) {
            row[thirdWorksheetColumns[j].key] =
              parameters.period.value === 'weekly'
                ? secondWorksheetRows[i].week
                : secondWorksheetRows[i].month;
          } else if (j == 1) {
            row[thirdWorksheetColumns[j].key] = secondWorksheetRows[i].l;
          } else if (j == 2) {
            row[thirdWorksheetColumns[j].key] = secondWorksheetRows[i].c;
          } else {
            row[thirdWorksheetColumns[j].key] = w.value / 100;
          }
        });
        return row;
      });

    const workbook = new Excel.Workbook();
    const firstWorksheetName = 'Parameters';
    const secondWorksheetName = 'Lead to Customer';
    const thirdWorksheetName = '% of LTC';
    const workbookName = `${nameExportFile('LeadToCustomer').slice(0, -4)}.xlsx`;
    const saveExcel = async () => {
      try {
        const firstWorksheet = workbook.addWorksheet(firstWorksheetName);
        const secondWorksheet = workbook.addWorksheet(secondWorksheetName);
        const thirdWorksheet = workbook.addWorksheet(thirdWorksheetName);

        /**
         * First worksheet : parameteers
         */
        firstWorksheet.columns = firstWorksheetColumns;
        firstWorksheet.getRow(1).font = { bold: true };
        firstWorksheet.columns.forEach((column, i) => {
          if (i === 0) {
            column.width = 15;
          } else {
            column.width = column.header.length + 5;
          }
          column.alignment = { horizontal: 'center' };
        });
        firstWorksheetRows.forEach((singleData) => {
          firstWorksheet.addRow(singleData);
        });
        firstWorksheet.eachRow({ includeEmpty: false }, (row) => {
          const currentCell = row._cells;
          currentCell.forEach((singleCell) => {
            const cellAddress = singleCell._address;
            firstWorksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        });

        /**
         * Second worksheet : ltv
         */
        const ltcOriginData = result.leadToCustomerTable.cells.filter((v, i) => i % 2 === 0);
        const ltcColors = {
          'neon-blue-900': '060073',
          'neon-blue-800': '0b03a6',
          'neon-blue-700': '2118d9',
          'neon-blue-600': '362ef2',
          'neon-blue-500': '4b43ff',
          'neon-blue-400': '6a62fe',
          'neon-blue-300': '8681fc',
          'neon-blue-200': 'a8a3fb',
          'neon-blue-100': 'c1bef7',
          'neon-blue-50': 'e0dff8'
        };

        secondWorksheet.columns = secondWorksheetColumns;
        secondWorksheet.getRow(1).font = { bold: true };
        secondWorksheet.columns.forEach((column) => {
          column.width = column.header.length + 8;
          column.alignment = { horizontal: 'center' };
        });
        secondWorksheetRows.forEach((singleData) => {
          secondWorksheet.addRow(singleData);
        });
        secondWorksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          const currentCell = row._cells;

          currentCell.forEach((singleCell, i) => {
            const cellAddress = singleCell._address;
            secondWorksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
            if (cellAddress.replace(/\D/g, '') !== '1') {
              const className = ltcOriginData[rowNumber - 2][i].className;
              if (className && isLtcColorOn.ltc) {
                secondWorksheet.getCell(cellAddress).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: ltcColors[className] }
                };
                if (Number(className.replace(/\D/g, '')) >= 500) {
                  secondWorksheet.getCell(cellAddress).font = {
                    color: { argb: 'ffffff' }
                  };
                }
              }
            }
          });
        });

        /**
         * Third worksheet : retention
         */
        const LtcPercentOriginData = result.leadToCustomerTable.cells.filter((v, i) => i % 2 === 1);

        thirdWorksheet.columns = thirdWorksheetColumns;
        thirdWorksheet.getRow(1).font = { bold: true };
        thirdWorksheet.columns.forEach((column) => {
          column.width = column.header.length + 8;
          column.alignment = { horizontal: 'center' };
        });
        thirdWorksheetRows.forEach((singleData) => {
          thirdWorksheet.addRow(singleData);
        });
        thirdWorksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          const currentCell = row._cells;
          currentCell.forEach((singleCell, i) => {
            const cellAddress = singleCell._address;
            thirdWorksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
            if (cellAddress.replace(/\D/g, '') !== '1') {
              const className = LtcPercentOriginData[rowNumber - 2][i].className;
              if (className && isLtcColorOn.ltc) {
                thirdWorksheet.getCell(cellAddress).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: ltcColors[className] }
                };
                if (Number(className.replace(/\D/g, '')) >= 700) {
                  thirdWorksheet.getCell(cellAddress).font = {
                    color: { argb: 'ffffff' }
                  };
                }
              }
            }
          });
        });

        /**
         * Export it!
         */
        const buf = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buf]), workbookName);
        logExport({
          companyId: userInfo?.company.companyId,
          shopId: userInfo?.company.shopId,
          page: 'lead_time',
          // page: 'life_time_value',
          fileName: `${nameExportFile('LeadToCustomer').slice(0, -4)}.xlsx`
        });
      } catch (error) {
        console.error(error);
      } finally {
        workbook.removeWorksheet(firstWorksheetName);
        workbook.removeWorksheet(secondWorksheetName);
        workbook.removeWorksheet(thirdWorksheetName);
      }
    };
    saveExcel();
  };

  useEffect(() => {
    usePageInit();
  }, [])
  

  return (
    <>
      <MainHeader main={t('ltc.lead_to_customer')} sub={t('ltc.see_how_many_days_it')} />
      {!(option && parameters) ? (
        <ParametersSkeleton>
          <ParameterSkeleton />
          <ParameterSkeleton />
          <ParameterSkeleton />
          <ParameterSkeleton />
        </ParametersSkeleton>
      ) : (
        <div className="box LeadToCustomer">
          <div className="row">
            <OriginalSearchInput
              label="period"
              width="260"
              options={option.period}
              selectedValue={parameters.period.value}
              selectValue={handlers.handleParametersSelect}
            />
            <OriginalSearchInput
              label="from"
              width="260"
              options={option.monthlyFrom.filter(
                (v) => new Date(v.id) - new Date(parameters.monthlyTo.id) <= 0
              )}
              selectedValue={parameters.monthlyFrom.value}
              selectValue={handlers.handleParametersSelect}
            />
            <OriginalSearchInput
              label="to"
              width="260"
              options={option.monthlyTo.filter(
                (v) => new Date(v.id) - new Date(parameters.monthlyFrom.id) >= 0
              )}
              selectedValue={parameters.monthlyTo.value}
              selectValue={handlers.handleParametersSelect}
            />
            <OriginalSearchInput
              label="segment"
              width="260"
              options={option.segment}
              selectedValue={parameters.segment.value}
              selectValue={handlers.handleParametersSelect}
            />
          </div>
          <div className="row">
            <PrimaryButton
              value={t('ltc.apply')}
              onClick={handlers.handleResultGet}
              style={{ width: '124px' }}
            />
          </div>
        </div>
      )}
      <>
        <div className="box ltc-container">
          {!result ? (
            <div className="overview-skeleton-container">
              <DynamicSkeleton width={1100} height={800} />
            </div>
          ) : (
            <>
              <div className="ltc-toggles-container">
                <div className="ltc-toggles">
                  <div className="ltc-toggle">
                    <TooltipInfo
                      belong="ltc"
                      category="ltc"
                      text={t('ltc.the_larger_the_difference_from')}
                    />
                    {t('ltc.ltc_heatmap')}
                    <ToggleButton
                      handleToggle={handleToggle}
                      category="ltc"
                      isOn={isLtcColorOn.ltc}
                    />
                  </div>
                </div>
                <div className="ltc-export-button" onClick={handleExport}>
                  <img src={EXPORT} alt="export-button" />
                  {t('settings.export')}
                </div>
              </div>
              {result === 'no data' ? (
                <NoData />
              ) : (
                <div className="ltc-table-container">
                  <table className="ltc-table">
                    <thead>
                      <tr>
                        {result.leadToCustomerTable.headers.map((v, i) => {
                          if (i < 3) {
                            return <th key={`${v}-${i}`}>{t(`ltc.${v}`)}</th>
                          } else {
                            return <th key={`${v}-${i}`}>{v}</th>
                          }
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {result.leadToCustomerTable.cells.map((v, i) => {
                        return (
                          <tr key={`tr${i}`}>
                            {v.map((x, j) => {
                              // 첫째줄 경우
                              if (i % 2 === 0) {
                                if (j === 0) {
                                  const year = x.value.slice(0, 4);
                                  const yearForWeekly = x.value.slice(2, 4);
                                  const MonthForWeekly = x.value.slice(5, 7);

                                  const weekNumber = x.value.slice(8);
                                  let date = x.value.slice(5, 7);

                                  if (date[0] === '0') {
                                    date = date.slice(1);
                                  }
                                  return result.leadToCustomerTable.headers.includes("month") ? (
                                    <td key={`td${j}`}>
                                      {(i === 0 || date === '1') && (
                                        <span className="year">{year}</span>
                                      )}
                                      <span className="month">{extractMonth(x.value)}</span>
                                    </td>
                                  ) : (
                                    <td key={`td${j}`}>
                                      {(i === 0 || weekNumber === '1w') && (
                                        <span className="year">
                                          {yearForWeekly + '.' + MonthForWeekly}
                                        </span>
                                      )}
                                      <span className="month">{weekNumber}</span>
                                    </td>
                                  );
                                }
                                if (j === 1) {
                                  return <td key={`td${j}`}>{numberWithCommas(x.value)}</td>;
                                }
                                return (
                                  <td
                                    className={isLtcColorOn.ltc ? `${x.className}` : ''}
                                    key={`td${j}`}
                                  >
                                    {numberWithCommas(x.value)}
                                  </td>
                                );
                              }

                              // 둘째줄 경우
                              if (i % 2 === 1) {
                                if (j < 3) return <td key={`td${j}`}></td>;
                                return (
                                  <td
                                    className={isLtcColorOn.ltc ? `${x.className}` : ''}
                                    key={`td${j}`}
                                  >
                                    {x.value}%
                                  </td>
                                );
                              }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </>
    </>
  );
};

export default LeadToCustomer;

function TooltipInfo({ belong, text, category }) {
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  return (
    <div className="tooltip-info-container">
      {isTooltipShown && (
        <div className={'tooltip' + (category === 'ltc' ? ' ltc' : '')}>
          {belong === 'ltc' && (
            <>
              {text}
              <br />
              <img src={category === 'ltc' ? COHORT_TOOLTIP_LTV : COHORT_TOOLTIP_RETENTION} />
            </>
          )}
        </div>
      )}

      <img
        src={INFO24PX}
        alt="info"
        onMouseEnter={() => {
          setIsTooltipShown(true);
        }}
        onMouseLeave={() => {
          setIsTooltipShown(false);
        }}
      />
    </div>
  );
}

const ToggleButton = ({ disabled, isOn, handleToggle, category }) => {
  return (
    <div
      className={`toggle ${disabled ? 'disabled' : ''} ${isOn ? 'on' : ''}`}
      onClick={() => !disabled && handleToggle(category)}
    >
      <div className={`toggle_btn ${disabled ? 'disabled' : ''} ${isOn ? 'on' : ''}`} />
    </div>
  );
};
