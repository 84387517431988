import { useState, useEffect, useMemo } from 'react';
import { useIsFetching } from 'react-query';
import useResultFetch from './useResultFetch';
import useOptionFetch from './useOptionFetch';
import { mapReportParameterIdsWithName } from './utils';
import { useLocationInfo } from 'hooks/useLocationInfo';
import { useUserInfoContext } from 'context';

/**
 * @deprecated
 * @description
 * Following API endpoints' design, the hook takes a role for fetching data from two endpoints.
 * one for the OPTION and the other for the RESULT.
 * This hook executed in a declarative way to call those two GET requests.
 */
export function useCustomerServiceFetch({
  optionQueryKey = '',
  serviceQueryKey = '',
  parameters = {}
}) {
  const isFetching = useIsFetching();
  const { isOverview, isSegmentation, isReports, isOrganization } = useLocationInfo();
  const isParametersContained = useMemo(() => Object.keys(parameters).length > 1, []);

  const [option, setOption] = useState(null);
  const [result, setResult] = useState(null);

  const isOptionFetchAllowed = useMemo(() => !(isOverview || isSegmentation || isOrganization), []);
  const [isResultFetchAllowed, setIsResultFetchAllowed] = useState(false);
  const [isOptionReady, setIsOptionReady] = useState(false);
  const [isApplyTriggered, setIsApplyTriggered] = useState(false);

  // A state for whether components render elements for not
  const [isResultRender, setIsResultRender] = useState(false);
  const [isOptionRender, setIsOptionRender] = useState(false);

  const fetchedOption = useOptionFetch(isOptionFetchAllowed, optionQueryKey);
  const fetchedResult = useResultFetch(
    isResultFetchAllowed,
    setIsResultFetchAllowed,
    serviceQueryKey,
    parameters
  );

  function fetchResult() {
    setIsApplyTriggered(true);
  }

  useEffect(() => {
    if (fetchedOption?.isSuccess) {
      setOption(fetchedOption.data);
      setIsOptionReady(true);
    }
    if (fetchedResult?.isSuccess) {
      if (isApplyTriggered) setIsApplyTriggered(false);
      if (isResultFetchAllowed && !isReports) setIsResultFetchAllowed(false);

      setResult(
        isReports ? mapReportParameterIdsWithName(option, fetchedResult.data) : fetchedResult.data
      );
    }
  }, [isFetching]);

  useEffect(() => {
    if (fetchedResult.isSuccess) {
      if (isApplyTriggered) setIsApplyTriggered(false);
      if (isResultFetchAllowed && !isReports) setIsResultFetchAllowed(false);

      if (isResultFetchAllowed) {
        setResult(
          isReports ? mapReportParameterIdsWithName(option, fetchedResult.data) : fetchedResult.data
        );
      }
    }
  }, [fetchedResult]);

  // control isResultFetchAllowed
  useEffect(() => {
    if (isApplyTriggered) {
      // overview, segmentation
      if (!isParametersContained && !isOptionFetchAllowed && !isOptionReady) {
        setIsResultFetchAllowed(true);
      }
      // retention, cohort analysis, forecasting, cross-selling, recommendations
      if (isParametersContained && isOptionFetchAllowed && isOptionReady) {
        setIsResultFetchAllowed(true);
      }
      // reports
      if (!isParametersContained && isOptionFetchAllowed && isOptionReady) {
        setIsResultFetchAllowed(true);
      }
    }
  }, [isApplyTriggered]);

  useEffect(() => {
    if (result) {
      setIsResultRender(true);
    } else {
      setIsResultRender(false);
    }
  }, [result]);

  useEffect(() => {
    if (option) {
      setIsOptionRender(true);
    } else {
      setIsOptionRender(false);
    }
  }, [option]);

  useEffect(() => {
    if (fetchedOption?.isSuccess) {
      setOption(fetchedOption.data);
    }
  }, [fetchedOption]);

  return {
    isOptionRender,
    isResultRender,
    option,
    result,
    fetchResult
  };
}
