import { LEGEND_INDICATOR_CIRCLE, LEGEND_INDICATOR_STAR } from 'constants/images';
import { useChart } from 'hooks';
import { useState } from 'react';
import * as Styles from './index.style';

export default function Chart({ chartInfo }) {
  const { data, legends, chartId, partialSignificantLegends, handlers, tooltip } = chartInfo;
  const [legendAndColors, setLegendAndColors] = useState(legends);

  const { ref, t } = useChart(chartId, data, { legendAndColors, handlers });

  const handleLegendItemClick = (clickedLegend) => (e) => {
    e.preventDefault();

    // legend가 최소 한개 이상 보이도록
    const visibleLegends = legendAndColors.filter((legend) => legend.isActive);
    if (visibleLegends.length === 1 && clickedLegend.id === visibleLegends[0].id) {
      return;
    }

    setLegendAndColors((prev) =>
      prev.map((legend) =>
        legend.id === clickedLegend.id
          ? {
              ...legend,
              isActive: !legend.isActive
            }
          : legend
      )
    );
  };
  return (
    <Styles.Container>
      {/* Legend Container 컴포넌트의 순서가 다른 컴포넌트들과 바뀌지 않도록 주의해야 합니다.
      chartConfig.js에서 이 Legend Container div 를 참조하는 부분(mosaic bar chart의 tooltip을 선언하는 부분)이 있는데, 
      이걸 Styles.Container의 children div들 중 0번째 인덱스값으로 참조합니다. */}
      <Styles.Legend.Container>
        {legends &&
          legends.map((legend, index) => {
            const name = t(legend.translation) || legend.name;

            return (
              <Styles.Legend.Item key={index} onClick={handleLegendItemClick(legend)}>
                {partialSignificantLegends && partialSignificantLegends[name] ? (
                  <LEGEND_INDICATOR_STAR color={legend.chartColor} />
                ) : (
                  <LEGEND_INDICATOR_CIRCLE color={legend.chartColor} />
                )}
                <Styles.Legend.ItemName isActive={legendAndColors[index].isActive}>
                  {name}
                </Styles.Legend.ItemName>
              </Styles.Legend.Item>
            );
          })}
      </Styles.Legend.Container>

      <Styles.Chart ref={ref}></Styles.Chart>

      <Tooltip width={tooltip?.width} arrowPosition={tooltip?.arrowPosition || 'bottom'} />
    </Styles.Container>
  );
}
const Tooltip = ({ width, arrowPosition }) => {
  return (
    <Styles.Tooltip.Container
      className="tooltip-container"
      width={width}
      arrowPosition={arrowPosition}
    >
      <Styles.Tooltip.Header className="tooltip-header" />
      <Styles.Tooltip.Body className="tooltip-body" />
    </Styles.Tooltip.Container>
  );
};
