import { createContext, useState, useContext, useEffect } from 'react';
import DataPreloader from 'components/Util/DataPreloader';
import { useUserInfoContext } from './UserInfo';
import { setStore, clearStore } from 'utils/userStorage';
import { analyzeAPI, axiosPrivate } from 'api';
import { NOT_ANALYZED } from 'constants';

/**
 * This context manages two states
 * (1) if sidebar is minimized. => it gose to the layout UI
 * (2) currency
 */
export const DashboardContext = createContext({});

export const DashboardProvider = ({ children }) => {
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const { userInfo, setUserInfo } = useUserInfoContext();
  const [shouldPreloadData, setShouldPreloadData] = useState(false);

  const handleShopSelect = (shopId) => async (e) => {
    const shopChangedStore = {
      ...userInfo,
      company: {
        ...userInfo.company,
        shopId
      },
      dashboard: {
        ...userInfo.dashboard
      },
      token: {
        ...userInfo.token
      },
      user: {
        ...userInfo.user
      }
    };

    try {
      const { data: dashboardData } = await axiosPrivate.post(analyzeAPI.DASHBOARD, {
        company_id: userInfo.company.companyId,
        shop_id: shopId,
        last: 5
      });

      if (dashboardData.result.length !== 0) {
        const validDashboard = dashboardData.result.find((dashboard) => dashboard.valid);

        if (!validDashboard) {
          shopChangedStore.dashboard.currency = null;
          shopChangedStore.dashboard.createdAt = '';
        } else {
          shopChangedStore.dashboard.currency = validDashboard.currency;
          shopChangedStore.dashboard.createdAt = validDashboard.created_at;
        }
        shopChangedStore.dashboard.isFirstDashboard =
          dashboardData.result.length === 1 ? true : false;
        shopChangedStore.dashboard.latestAnalysisStatus = dashboardData.result[0].status;
      } else if (dashboardData.result.length === 0) {
        shopChangedStore.dashboard.currency = null;
        shopChangedStore.dashboard.createdAt = '';

        shopChangedStore.dashboard.isFirstDashboard = null;
        shopChangedStore.dashboard.latestAnalysisStatus = NOT_ANALYZED;
      }
      setStore(shopChangedStore);
      setUserInfo(shopChangedStore);
    } catch (e) {
      clearStore();
      setUserInfo(null);
    }
  };

  // start preloading data after one second delay
  useEffect(() => {
    if (userInfo) {
      setTimeout(() => {
        setShouldPreloadData(true);
      }, 1000);
    }
    // 의존하는 companyId는, 로그인 완료시점에 단 한번 실행하기 위한 값
  }, [userInfo?.company.shopId]);

  useEffect(() => {
    if (shouldPreloadData) {
      setShouldPreloadData(false);
    }
  }, [shouldPreloadData]);

  return (
    <DashboardContext.Provider
      value={{
        currency: userInfo?.dashboard.currency === 'won' ? '₩' : '$',
        isFirstDashboard: userInfo?.dashboard.isFirstDashboard,
        latestAnalysisStatus: userInfo?.dashboard.latestAnalysisStatus,
        shopId: userInfo?.company.shopId,
        handleShopSelect,
        isSidebarMinimized,
        setIsSidebarMinimized
      }}
    >
      {shouldPreloadData && <DataPreloader />}
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};
