import { useCallback, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { RETENTICS_LOGIN_LOGO, GOOGLE_LOGO, ERROR_FILLED, EYE_OFF, EYE } from 'constants/images';
import { mutationKeys } from 'constants/keys';
import { useAuthMutation } from 'hooks/auth';
import './Login.scss';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { clearNewMemberInfo, getNewMemberInfo } from 'utils/userStorage';
const Login = () => {
  const { t } = useTranslation();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: ''
  });

  const [signin, error, status] = useAuthMutation({ mutationKey: mutationKeys.AUTH.SIGNIN });
  const [googleSignin] = useAuthMutation({
    mutationKey: mutationKeys.AUTH.GOOGLE_SIGNIN
  });

  function handleSignin(provider) {
    if (status !== 'loading') {
      if (provider === 'google') {
        googleSignin();
      } else {
        signin(values);
      }
    }
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      handleSignin();
    }
  }

  const onChange = useCallback((e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, []);

  const isNotValidEmail =
    values.email.length > 0 &&
    !values.email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  const isActive = values.email.length > 0 && values.password.length > 0 && !isNotValidEmail;

  useEffect(() => {
    if (getNewMemberInfo()) clearNewMemberInfo();
  }, []);

  return (
    <div className="Login">
      <div className="Login_box">
        <div className="Login_box_logo">
          <img src={RETENTICS_LOGIN_LOGO} className="Login_box_logo_img" />
        </div>
        <div className="Login_box_inclusion">
          <div className="inclusion">
            <div className="inclusion_text">{t('login.welcome_to_retentics')}</div>

            {/* google login */}
            <>
              <button className="inclusion_google" onClick={() => handleSignin('google')}>
                <img src={GOOGLE_LOGO} />
                {t('login.sign_in_with_google')}
              </button>
              <div className="inclusion_contour">
                <span className="line" />
                <span className="or">{t('login.or')}</span>
              </div>
            </>

            {/* login box */}
            <div className="input_box">
              <div className="input_box_email">
                <p className="input_box_text">{t('login.email')}</p>
                <div className={`input_email ${isNotValidEmail ? 'invalid' : ''}`}>
                  <input
                    type="text"
                    className="email"
                    name="email"
                    value={values.email}
                    onChange={onChange}
                    onKeyPress={handleKeyPress}
                  />
                  {isNotValidEmail && <img src={ERROR_FILLED} />}
                </div>
                {isNotValidEmail && (
                  <p className="error">{t('login.please_check_your_email_address')}</p>
                )}
              </div>
              <div className="input_box_password">
                <p className="input_box_text">{t('login.password')}</p>
                <div
                  className={`input_password ${
                    error?.config.url.includes('signin') && values.password ? 'invalid' : ''
                  }`}
                >
                  <input
                    type={isShowPassword ? 'text' : 'password'}
                    className="password"
                    name="password"
                    value={values.password}
                    onChange={onChange}
                    onKeyPress={handleKeyPress}
                  ></input>

                  {/* 비밀번호 보이게할땐 eye로 교체 */}
                  <div className="eye" onClick={() => setIsShowPassword((prev) => !prev)}>
                    <img src={isShowPassword ? EYE : EYE_OFF} />
                  </div>
                  {error?.config.url.includes('signin') && values.password && (
                    <img src={ERROR_FILLED} />
                  )}
                </div>
                {error?.config.url.includes('signin') && values.password && (
                  <p className="error">{t('login.your_email_or_password_is')}</p>
                )}
              </div>
              <div className="remember_box">
                {/* <input
                  type="checkbox"
                  className="remember_me"
                  name="remember_me"
                  checked={isRemember}
                  onChange={() => setIsRemember((prev) => !prev)}
                />
                <span className="remember_text">Remember me</span> */}
              </div>

              {/* 버튼 액티브 만들어야함 */}
              <button className="login_button" disabled={!isActive} onClick={() => handleSignin()}>
                {t('login.login')}
              </button>
            </div>

            <div className="forget_password">
              <Trans
                i18nKey={'login.forgot_your_password_highlight'}
                components={{
                  span: <span className="highlighted" />,
                  l: <Link to="/forgot-password"></Link>
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
