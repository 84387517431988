import { cloneDeep } from 'lodash';
import { numberWithCommas, formatNewDate } from 'utils';
import { getStore } from 'utils/userStorage';

export const overview = {
  generateSummary(cards) {
    const transaction = numberWithCommas(cards.total_transaction);
    const lead = numberWithCommas(cards.total_lead);
    const customer = {
      value: numberWithCommas(cards.total_customer),
      ltc: (cards.total_customer / cards.total_lead).toFixed(2),
    };
    const segments = cards.num_segment;
    const retention = Math.round(cards.retention_rate * 100);
    const ltv6 = numberWithCommas(Math.round(cards.life_time_value));
    return {
      transaction,
      lead,
      customer,
      segments,
      retention,
      ltv6,
    };
  },
  generateSales(sales, currency) {
    const months = sales
      .filter((v) => v.name.includes('monthly_sales'))
      .sort((a, b) => b.name.localeCompare(a.name));
    const years = sales
      .filter((v) => v.name.includes('yearly_sales'))
      .sort((a, b) => a.name.localeCompare(b.name));
    const monthly = Array.from(
      {
        length: months[0].chart_values.length,
      },
      (_, i) => ({
        date: formatNewDate(months[0].chart_values[i].x, currency),
        total:
          currency === 'won'
            ? Math.round((months[0].chart_values[i].y + months[1].chart_values[i].y).toFixed(2))
            : (months[0].chart_values[i].y + months[1].chart_values[i].y).toFixed(2),
        guest:
          currency === 'won'
            ? Math.round(months[0].chart_values[i].y)
            : months[0].chart_values[i].y,
        signIn:
          currency === 'won'
            ? Math.round(months[1].chart_values[i].y)
            : months[1].chart_values[i].y,
      })
    ).filter((v, i, arr) => i > arr.length - 1 - 13);
    const yearly = Array.from({ length: years[0].chart_values.length }, (_, i) => ({
      date: years[0].chart_values[i].x,
      total:
        currency === 'won'
          ? Math.round(years[0].chart_values[i].y + years[1].chart_values[i].y)
          : years[0].chart_values[i].y + years[1].chart_values[i].y,
      guest:
        currency === 'won' ? Math.round(years[0].chart_values[i].y) : years[0].chart_values[i].y,
      signIn:
        currency === 'won' ? Math.round(years[1].chart_values[i].y) : years[1].chart_values[i].y,
    }));
    return { monthly, yearly };
  },
  generateNewLeadToCustomer(nltc, currency) {
    const newLead = nltc
      .filter((v) => v.name === 'new_leads')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 13)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
    const newCustomer = nltc
      .filter((v) => v.name === 'new_customers')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 13);
    const ltc = nltc
      .filter((v) => v.name === 'ltc')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 13)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
    return {
      newLead,
      newCustomer,
      ltc,
    };
  },
  generateMonthlyCustomer(mc, currency) {
    return mc[0].chart_values
      .filter((v, i, arr) => i > arr.length - 1 - 10)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
  },
  generateMonthlyTransaction(mt, currency) {
    const transactionPerCustomer = mt
      .filter((v) => v.name === 'transaction_per_customer')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 12)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
    const transactionWithoutSignIn = mt
      .filter((v) => v.name === 'without_signin_transaction')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 12)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
    const transaction = mt
      .filter((v) => v.name === 'transaction')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 12);
    return {
      transactionPerCustomer,
      transactionWithoutSignIn,
      transaction,
    };
  },
  generateCategoryRankings(cr) {
    return cr[0].chart_values.filter((v, i) => i < 5);
  },
  generateLocationRankings(lr) {
    return lr[0].chart_values.filter((v, i) => i < 5);
  },
  generateItemRankings(tables) {
    const itemRanking = tables.filter((v) => v.name === 'item_ranking')[0];
    return {
      export: itemRanking.options.export,
      table: {
        headers: itemRanking.headers,
        cells: itemRanking.cells,
      },
    };
  },
  generateCustomerRankings(tables, currency) {
    const customerRanking = tables.filter((v) => v.name === 'customer_ranking')[0];
    const cells = cloneDeep(customerRanking.cells).map((v) => {
      const copied = {
        ...v,
      };
      copied['Last Order'] = formatNewDate(copied['Last Order'], currency);
      return copied;
    });
    return {
      export: customerRanking.options.export,
      table: {
        headers: customerRanking.headers,
        cells,
      },
    };
  },
  generateThirdMonthLtv(ltv, currency) {
    return ltv
      .filter((v) => v.name === 'ltv3')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 13)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
  },
  generateSixthMonthLtv(ltv, currency) {
    return ltv
      .filter((v) => v.name === 'ltv6')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 13)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
  },
  generateAbTesting(abTest) {
    const retention = abTest.filter((v) => v.name === 'Retention')[0].chart_values;
    const revenue = abTest.filter((v) => v.name === 'Revenue')[0].chart_values;
    const withRetentics = [
      { ...retention[0], x: 'retention' },
      { ...revenue[0], x: 'revenue' },
    ];
    const withoutRetentics = [
      { ...retention[1], x: 'retention' },
      { ...revenue[1], x: 'revenue' },
    ];
    return {
      withRetentics,
      withoutRetentics,
    };
  },
};
export const segmentation = {
  generateSegmentSize(segments, ss) {
    return ss[0].chart_values.map((v) => ({
      ...v,
      x: segments.reduce(
        (acc, cur) => (acc ? acc : +cur.segment_id === +v.x ? cur.segment_name : acc),
        ''
      ),
    }));
  },
  generateSales(segments, sales) {
    const averageSalesPerCustomer = sales
      .filter((v) => v.name === 'average_sales_per_customer')[0]
      .chart_values.map((v) => ({
        ...v,
        x: segments.reduce(
          (acc, cur) => (acc ? acc : +cur.segment_id === +v.x ? cur.segment_name : acc),
          ''
        ),
      }));
    const totalSalesPerSegment = sales
      .filter((v) => v.name === 'total_sales_per_segment')[0]
      .chart_values.map((v) => ({
        ...v,
        x: segments.reduce(
          (acc, cur) => (acc ? acc : +cur.segment_id === +v.x ? cur.segment_name : acc),
          ''
        ),
      }));
    return {
      averageSalesPerCustomer,
      totalSalesPerSegment,
    };
  },
  generateTransactions(segments, transactions) {
    const averageTransactionsPerCustomer = transactions
      .filter((v) => v.name === 'average_transactions_per_customer')[0]
      .chart_values.map((v) => ({
        ...v,
        x: segments.reduce(
          (acc, cur) => (acc ? acc : +cur.segment_id === +v.x ? cur.segment_name : acc),
          ''
        ),
      }));
    const totalTransactionsPerSegment = transactions
      .filter((v) => v.name === 'total_transactions_per_segment')[0]
      .chart_values.map((v) => ({
        ...v,
        x: segments.reduce(
          (acc, cur) => (acc ? acc : +cur.segment_id === +v.x ? cur.segment_name : acc),
          ''
        ),
      }));
    return {
      averageTransactionsPerCustomer,
      totalTransactionsPerSegment,
    };
  },
  generateMonthlyCustomers(segments, mc, currency) {
    const sorted = [...mc]
      .map((v) => {
        const newV = { ...v };
        newV.chart_values = v.chart_values.filter((v, i, arr) => i > arr.length - 1 - 13);
        return newV;
      })
      .sort((a, b) => a.options.segment_id - b.options.segment_id);
    return sorted
      .reduce((acc, cur) => {
        const division = segments.reduce(
          (a, c) => (a ? a : +c.segment_id === +cur.options.segment_id ? c.segment_name : a),
          ''
        );
        acc.push.apply(
          acc,
          cur.chart_values.map((v) => ({ ...v, division }))
        );
        return acc;
      }, [])
      .map((v) => {
        const copied = { ...v };
        copied.x = formatNewDate(copied.x, currency);
        return copied;
      });
  },
  generateMonthlySales(segments, ms, currency) {
    const sorted = [...ms]
      .map((v) => {
        const newV = { ...v };
        newV.chart_values = v.chart_values.filter((v, i, arr) => i > arr.length - 1 - 13);
        return newV;
      })
      .sort((a, b) => a.options.segment_id - b.options.segment_id);
    return sorted
      .reduce((acc, cur) => {
        const division = segments.reduce(
          (a, c) => (a ? a : +c.segment_id === +cur.options.segment_id ? c.segment_name : a),
          ''
        );
        acc.push.apply(
          acc,
          cur.chart_values.map((v) => ({ ...v, division }))
        );
        return acc;
      }, [])
      .map((v) => {
        const copied = { ...v };
        copied.x = formatNewDate(copied.x, currency);
        return copied;
      });
  },
  generateLtvSix(segments, ltv, currency) {
    const sorted = [...ltv]
      .map((v) => {
        const newV = { ...v };
        newV.chart_values = v.chart_values.filter((v, i, arr) => i > arr.length - 1 - 13);
        return newV;
      })
      .sort((a, b) => a.options.segment_id - b.options.segment_id);

    /**
     * Temporarily!!!!!!!!!!!!!
     */
    if (getStore().company.companyName === 'Dr.Diary') {
      if (
        sorted[2]['chart_values'][sorted[2]['chart_values'].length - 1].x !==
        sorted[0]['chart_values'][sorted[0]['chart_values'].length - 1].x
      ) {
        sorted[2]['chart_values'].push({
          x: sorted[0]['chart_values'][sorted[0]['chart_values'].length - 1].x,
          y: 0,
          sequence: 50,
        });
        sorted[2]['chart_values'].shift();
      }
    }

    return sorted
      .reduce((acc, cur) => {
        const division = segments.reduce(
          (a, c) => (a ? a : +c.segment_id === +cur.options.segment_id ? c.segment_name : a),
          ''
        );
        acc.push.apply(
          acc,
          cur.chart_values.map((v) => ({ ...v, division }))
        );
        return acc;
      }, [])
      .map((v) => {
        const copied = { ...v };
        copied.x = formatNewDate(copied.x, currency);
        return copied;
      });
  },
  generateMonthlyRetention(segments, mr, currency) {
    const sorted = [...mr]
      .filter((v) => v.name.includes('month_retention'))
      .map((v) => {
        const newV = { ...v };
        newV.chart_values = v.chart_values.filter((v, i, arr) => i > arr.length - 1 - 13);
        return newV;
      })
      .sort((a, b) => a.options.segment_id - b.options.segment_id);

    // FOR TEST
    // const t = sorted.map((v, i) => {
    //   if (i === 0) {
    //     v.chart_values = [{ x: '2021-05', y: 0.93, sequence: 11 }];
    //     return v;
    //   }
    //   return v;
    // });

    // 1 pick the logest "chart_values" array among all.
    const newSorted = [...sorted];
    const longestArray = newSorted.reduce(
      (acc, cur) => {
        return acc.chart_values.length > cur.chart_values.length ? acc : cur;
      },
      { chart_values: [] }
    ).chart_values;

    // 2 make it safe
    const safeSorted = sorted.map((v) => {
      const newV = { ...v };
      const willBeMappedToNewChartValues = [...longestArray];
      const existedChartValues = newV.chart_values;

      const newlyCreatedChartValues = willBeMappedToNewChartValues.map((w) => {
        const newW = { ...w };
        const filteredChartValues = existedChartValues.filter((x) => x.x === w.x);
        if (filteredChartValues.length !== 0) {
          newW.y = filteredChartValues[0].y;
        } else {
          newW.y = 0;
        }

        return newW;
      });
      newV.chart_values = newlyCreatedChartValues;
      return newV;
    });

    return safeSorted
      .reduce((acc, cur) => {
        const division = segments.reduce(
          (a, c) => (a ? a : +c.segment_id === +cur.options.segment_id ? c.segment_name : a),
          ''
        );
        acc.push.apply(
          acc,
          cur.chart_values.map((v) => ({ ...v, division }))
        );
        return acc;
      }, [])
      .map((v) => {
        const copied = { ...v };
        copied.x = formatNewDate(copied.x, currency);
        return copied;
      });
  },
  generateTables(segments, tables, currency) {
    return segments.map((v) => {
      const ownTables = tables.filter((t) => +t.options.segment_id === +v.segment_id);
      // const segmentNameAddedOwnTables = ownTables.map((v) => {
      //   const segmentName = segments.filter((s) => s.segment_id === v.options.segment_id)[0]
      //     .segment_name;
      //   v.options.segment_name = segmentName;
      //   return v;
      // });
      ownTables.sort((a, b) => b.name.localeCompare(a.name));

      return ownTables.map((v, i) => {
        if (i === 0) return v;
        const copied = cloneDeep(v);
        const newCells = copied.cells.map((x) => {
          x['Last Order'] = formatNewDate(x['Last Order'], currency);
          return x;
        });
        copied.cells = newCells;
        return copied;
      });
    });
  },
};
export const retention = {
  generateRetentionRate(data, currency) {
    return data.map((v) => ({
      x: formatNewDate(v.x, currency),
      y: v.ratio,
    }));
  },
  generateWaterfall(data, currency) {
    let cum = 0;
    const waterfall = [];
    data.forEach((v, i) => {
      if (i === 0) {
        cum += v.active;
        waterfall.push({
          customerType: 'customer',
          value: 0,
          start: cum,
          end: 0,
          date: formatNewDate(v.x, currency),
        });
      } else {
        waterfall.push({
          customerType: 'new',
          value: +v.new,
          start: cum,
          end: cum + +v.new,
          date: formatNewDate(v.x, currency),
        });
        cum += +v.new;
        waterfall.push({
          customerType: 'reactive',
          value: +v.reactive,
          start: cum,
          end: cum + +v.reactive,
          date: formatNewDate(v.x, currency),
        });
        cum += +v.reactive;
        waterfall.push({
          customerType: 'churned',
          value: -1 * +v.churned,
          start: cum,
          end: cum - +v.churned,
          date: formatNewDate(v.x, currency),
        });
        cum -= +v.churned;
        waterfall.push({
          customerType: 'customer',
          value: 0,
          start: cum,
          end: 0,
          date: formatNewDate(v.x, currency),
        });
      }
    });
    return waterfall;
  },
};
export const ltv = {
  generateCustomerValueProgress(data) {
    return data.chart_values;
  },
  generateLtv3(data, currency) {
    const copied = cloneDeep(data.chart_values).map((v) => {
      const copiedV = { ...v };
      copiedV.x = formatNewDate(copiedV.x, currency);
      return copiedV;
    });
    return copied;
  },
  generateLtv6(data, currency) {
    const copied = cloneDeep(data.chart_values).map((v) => {
      const copiedV = { ...v };
      copiedV.x = formatNewDate(copiedV.x, currency);
      return copiedV;
    });
    return copied;
  },
  generateCohortAnalysis(data, currency) {
    function setColorsByLength(length) {
      let v;
      switch (length) {
        case 9:
          v = [2, 3, 4, 5, 6, 7, 8, 9, 10];
          break;
        case 8:
          v = [2, 3, 4, 5, 6, 8, 9, 10];
          break;
        case 7:
          v = [2, 3, 4, 5, 6, 8, 10];
          break;
        case 6:
          v = [2, 4, 5, 6, 8, 10];
          break;
        case 5:
          v = [2, 4, 6, 8, 10];
          break;
        case 4:
          v = [2, 4, 6, 10];
          break;
        case 3:
          v = [2, 6, 10];
          break;
        case 2:
          v = [6, 10];
          break;
        case 1:
          v = [6];
          break;
        default:
          v = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      }
      return v;
    }
    function setColorClassNameByOrder(sort, order) {
      let className = '';
      switch (order) {
        case 1:
          className = sort === 't' ? 'neon-blue-50' : 'red-50';
          break;
        case 2:
          className = sort === 't' ? 'neon-blue-100' : 'red-100';
          break;
        case 3:
          className = sort === 't' ? 'neon-blue-200' : 'red-200';
          break;
        case 4:
          className = sort === 't' ? 'neon-blue-300' : 'red-300';
          break;
        case 5:
          className = sort === 't' ? 'neon-blue-400' : 'red-400';
          break;
        case 6:
          className = sort === 't' ? 'neon-blue-500' : 'red-500';
          break;
        case 7:
          className = sort === 't' ? 'neon-blue-600' : 'red-600';
          break;
        case 8:
          className = sort === 't' ? 'neon-blue-700' : 'red-700';
          break;
        case 9:
          className = sort === 't' ? 'neon-blue-800' : 'red-800';
          break;
        default:
          className = sort === 't' ? 'neon-blue-900' : 'red-900';
      }
      return className;
    }
    function assignTransactionColor(newCustomer, transactions) {
      const t = [...transactions].filter((v, i) => i !== 0);
      // const increments = t.map((v, i) => (i === 0 ? v - newCustomer : t[i] - t[i - 1]));
      const increments = t.map((v, i) => (i === 0 ? v - transactions[0] : t[i] - t[i - 1]));
      const sortedIncrements = [...increments].sort((a, b) => a - b);
      const oneTenth = t.length / 10;
      const colorVariations = setColorsByLength(t.length);
      const colorAssignedTransactions = increments.map((increment, i) => {
        if (t.length > 9) {
          return {
            className: setColorClassNameByOrder(
              't',
              Math.ceil((sortedIncrements.indexOf(increment) + 1) / oneTenth)
            ),
            value: t[i],
          };
        }
        return {
          className: setColorClassNameByOrder(
            't',
            colorVariations[sortedIncrements.indexOf(increment)]
          ),
          value: t[i],
        };
      });
      return colorAssignedTransactions;
    }
    function assignRetentionRateColor(retentionRates) {
      const rates = [...retentionRates].filter((v, i) => i !== 0);
      const sortedRates = [...retentionRates].sort((a, b) => a - b);
      const oneTenth = rates.length / 10;

      const colorVariations = setColorsByLength(rates.length);

      const colorAssignedRates = rates.map((rate) => {
        if (rates.length > 9) {
          return {
            className: setColorClassNameByOrder(
              'r',
              Math.ceil((sortedRates.indexOf(rate) + 1) / oneTenth)
            ),
            value: rate,
          };
        }
        return {
          className: setColorClassNameByOrder('r', colorVariations[sortedRates.indexOf(rate)]),
          value: rate,
        };
      });
      return colorAssignedRates;
    }

    const headers = ['Month', 'NC'];
    data[0].periods.forEach((v) => {
      headers.push(`LTV ${v}`);
    });

    const rows = [];
    data.forEach((v) => {
      const firstRow = [];
      const secondRow = [];
      firstRow.push({ className: '', value: v.x });
      firstRow.push({ className: '', value: v.nc });
      firstRow.push({ className: '', value: v.values[0] });
      secondRow.push({ className: '', value: '' });
      secondRow.push({ className: '', value: '' });
      secondRow.push({ className: '', value: (v.ratio[0] * 100).toFixed(2) });

      const colorAssignedTransactions = assignTransactionColor(v.nc, v.values);
      colorAssignedTransactions.forEach((x) => {
        firstRow.push(x);
      });
      const colorAssignedRetentionRates = assignRetentionRateColor(v.ratio);
      colorAssignedRetentionRates.forEach((x) => {
        secondRow.push({
          className: x.className,
          value: (x.value * 100).toFixed(2),
        });
      });

      rows.push(firstRow);
      rows.push(secondRow);
    });
    return {
      headers,
      rows,
    };
  },
};
export const forecasting = {
  generateTransactionForecasting(data, currency) {
    return data
      .map((v) => ({
        ...v,
        x: formatNewDate(v.x, currency),
        y: Math.round(v.y),
      }))
      .filter((v, i, arr) => i > arr.length - 1 - 16);
  },
};
