import './index.scss';

export default function MainHeader({ main, sub }) {
  return (
    <header>
      <div className="main-title">{main}</div>
      <div className={`sub-title${sub ? '' : ' hide'}`}>{sub ? sub : 'none'}</div>
    </header>
  );
}
