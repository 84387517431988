import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react';
import { Navigate } from 'react-router-dom';
import { useAccountFetch, useAccountMutation } from 'hooks/account';
import { mutationKeys, queryKeys } from 'constants/keys';
import MainHeader from 'components/MainHeader';
import { EDIT, ERROR_FILLED, LOADING } from 'constants/images';
import {
  CHEVRON_DOWN_18px,
  CREATE_NEW_BUTTON,
  MEMBERS_REMOVE,
  MEMBERS_SAVE,
} from 'constants/images';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import { useGlobalModal, useUserInfoContext } from 'context';
import './index.scss';

export default function Member() {
  const { t } = useTranslation()
  const { modal } = useGlobalModal();

  const { userInfo } = useUserInfoContext();
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState(['master', 'manager', 'analyst', 'observer']);
  const [isError, setIsError] = useState([]);
  const [savingMemberIndex, setSavingMemberIndex] = useState(null);

  const { data } = useAccountFetch(!!userInfo, queryKeys.ACCOUNT.MEMBERS);
  const [, , createUserAsync] = useAccountMutation({
    mutationKey: mutationKeys.ACCOUNT.CREATE_USER,
  });
  const [, , assignEmployeeAsync] = useAccountMutation({
    mutationKey: mutationKeys.ACCOUNT.ASSIGN_EMPLOYEE,
  });
  const [, , editProfileAsync] = useAccountMutation({
    mutationKey: mutationKeys.ACCOUNT.EDIT_PROFILE,
  });
  const [, , updateEmployeeRoleAsync] = useAccountMutation({
    mutationKey: mutationKeys.ACCOUNT.UPDATE_EMPLOYEE_ROLE,
  });
  const [deactivateUser] = useAccountMutation({
    mutationKey: mutationKeys.ACCOUNT.DEACTIVATE_USER,
  });

  function handleCreateNewClick() {
    const prevMembers = cloneDeep(members);
    prevMembers.push({
      authority: '',
      createdAt: '',
      email: '',
      lastActivity: '',
      team: '',
      userName: '',
    });
    const prevIsError = cloneDeep(isError);
    prevIsError.push({
      userName: false,
      authority: false,
      email: false,
      userNameMessage: '',
      emailMessage: '',
    });

    setMembers(prevMembers);
    setIsError(prevIsError);
    window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
  }

  function removeUserFn(index) {
    return {
      fn: deactivateUser,
      parameters: {
        userName: members[index].userName,
        action: 'removeUser' || null,
      },
    };
  }
  function handleRemove(i) {
    const index = i;
    modal({
      content: {
        title: t("settings.delete_confirm"),
        description: t("settings.do_you_really_want_to"),
        cancelButton: t("settings.cancel"),
        confirmButton: t("settings.delete"),
      },
      onConfirm: removeUserFn(index),
      isGlobal: true
    });
  }
  async function handleSave(i) {
    setSavingMemberIndex(i);
    try {
      const inputEmail = members[i].email;
      const isInvalidEmail =
        inputEmail.length > 0 &&
        !inputEmail.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      // check if the required inputs are filled and if email is valid
      if (!members[i].userName || !members[i].authority || !members[i].email || isInvalidEmail) {
        const prevIsError = cloneDeep(isError).map((v, index) => {
          const copy = cloneDeep(v);
          if (i === index) {
            copy.userName = members[index].userName ? false : true;
            copy.authority = members[index].authority ? false : true;
            copy.email = members[index].email && !isInvalidEmail ? false : true;
            if (isInvalidEmail) {
              copy.emailMessage = t("settings.please_check_the_email_address");
            } else {
              copy.emailMessage = '';
            }
          }
          return copy;
        });
        setIsError(prevIsError);
        return;
      }

      // create a new user
      if (!data[i]) {
        await createUserAsync({
          userName: members[i].userName,
          email: members[i].email,
          companyDomain: userInfo?.company?.domain,
        });
        await assignEmployeeAsync({
          companyId: userInfo?.company?.companyId,
          userName: members[i].userName,
          authority: members[i].authority,
          team: members[i].team,
        }).then(async ({ employee }) => {
          await updateEmployeeRoleAsync({
            id: employee.id,
            authority: members[i].authority,
            companyId: employee.company.id,
          });
          await editProfileAsync({
            id: employee.id,
            userName: employee.username,
            team: members[i].team,
            action: 'createUser',
          });
        });

        // edit the existing user
      } else {
        if (data[i].authority !== members[i].authority) {
          await updateEmployeeRoleAsync({
            id: data[i].id,
            authority: members[i].authority,
            companyId: userInfo?.company?.companyId,
            action: 'editUser',
          });
        }

        if (data[i].team !== members[i].team) {
          await editProfileAsync({
            id: data[i].id,
            userName: data[i].userName,
            team: members[i].team,
            action: 'editUser',
          });
        }
      }
    } catch (e) {
      console.log('error', e);
      if (e.response.status === 400) {
        const prevIsError = cloneDeep(isError);
        if (e.response.data.message === 'Bad request: User email exists') {
          prevIsError[i].email = true;
          prevIsError[i].emailMessage = t("settings.it_already_exists");
        }
        if (e.response.data.message.includes('User account already exists')) {
          prevIsError[i].userName = true;
          prevIsError[i].userNameMessage = t("settings.it_already_exists");
        }
        setIsError(prevIsError);
      }
    }
    setSavingMemberIndex(null);
  }

  const handleNewUserInput = (index, key) => (e) => {
    const value =
      key === 'userName' || key === 'email'
        ? e.target.value.replace(/\s/g, '').toLowerCase()
        : e.target.value;

    const copiedMembers = cloneDeep(members);
    copiedMembers[index][key] = value;

    setMembers(copiedMembers);
  };

  const handleRoleClick = (index, role) => {
    const copiedMembers = cloneDeep(members);
    copiedMembers[index].authority = role;
    setMembers(copiedMembers);
  };

  useEffect(() => {
    // if (data && members === null) {
    if (data) {
      const fetchedMembers = cloneDeep(data);
      setMembers(fetchedMembers);
      setIsError(
        fetchedMembers.map(() => ({
          userName: false,
          authority: false,
          email: false,
          userNameMessage: '',
          emailMessage: '',
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    // initialize errors
    if (isError)
      setIsError((prev) => {
        const init = cloneDeep(prev).map((v) => {
          v.userName = false;
          v.authority = false;
          v.email = false;
          v.userNameMessage = '';
          v.emailMessage = '';
          return v;
        });

        return init;
      });

    // role limitation check
    let availableRoles = ['master', 'manager', 'analyst', 'observer'];
    const serverMemberMasterCount = data?.filter((v) => v.authority === 'master').length;
    const clientMemberMasterCount = members?.filter((v) => v.authority === 'master').length;
    const serverMemberManagerCount = data?.filter((v) => v.authority === 'manager').length;
    const clientMemberManagerCount = members?.filter((v) => v.authority === 'manager').length;
    if (serverMemberMasterCount >= 2 || clientMemberMasterCount >= 2)
      availableRoles = remove(availableRoles, (v) => v !== 'master');
    if (serverMemberManagerCount >= 5 || clientMemberManagerCount >= 5)
      availableRoles = remove(availableRoles, (v) => v !== 'manager');
    setRoles(availableRoles);

    // email format check
    if (members)
      members.forEach((v, i) => {
        const inputEmail = v.email;
        const isInvalidEmail =
          inputEmail.length > 0 &&
          !inputEmail.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        if (isError)
          setIsError((prev) => {
            const obj = cloneDeep(prev);
            if (obj[i])
              if (isInvalidEmail) {
                obj[i].email = true;
                obj[i].emailMessage = t("settings.please_check_the_email_address");
              } else {
                obj[i].email = false;
                obj[i].emailMessage = '';
              }
            return obj;
          });
      });
  }, [members]);

  if (userInfo?.user?.role !== 'admin' && userInfo?.user?.role !== 'master')
    return <Navigate to="/" />;
  return (<>
    <MainHeader main={t("settings.member_settings")} />
    <div className="box members">
      <div className="title center">{t("settings.members")}</div>
      <div className="button-wrapper">
        <button onClick={handleCreateNewClick}>
          <img src={EDIT}/>
          {t("settings.create_new")}
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>{t("settings.no")}</th>
            <th>{t("settings.user_name")}</th>
            <th>{t("settings.authority")}</th>
            <th>{t("settings.team")}</th>
            <th>{t("settings.email")}</th>
            <th>{t("settings.createdat")}</th>
            <th>{t("settings.last_activity")}</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {members?.map((v, i) => {
            return (
              <tr
                key={`${i}${v.createdAt}${v.lastActivity}`}
                className={`${
                  isError[i].userNameMessage || isError[i].emailMessage ? 'error' : ''
                }`}
              >
                <td>
                  <div>{i + 1}</div>
                  {(isError[i].userNameMessage || isError[i].emailMessage) && (
                    <p className="error-message unseen">t</p>
                  )}
                </td>
                <td>
                  {data[i] ? (
                    data[i].userName
                  ) : (
                    <>
                      <input
                        type="text"
                        className={`user-name ${isError[i]?.userName ? 'error' : ''}`}
                        value={v.userName}
                        onChange={handleNewUserInput(i, 'userName')}
                        placeholder="Name*"
                      />
                      {isError[i].userNameMessage && (
                        <img src={ERROR_FILLED} className="inputbox-error" />
                      )}
                      {isError[i].userNameMessage && (
                        <p className="error-message">{isError[i].userNameMessage} </p>
                      )}
                      {isError[i].emailMessage && <p className="error-message unseen">t</p>}
                    </>
                  )}
                </td>
                <td>
                  <Dropdown
                    index={i}
                    isErrorStyle={isError[i]?.authority}
                    authority={v.authority}
                    handleRoleClick={handleRoleClick}
                    roles={roles}
                  />
                  {(isError[i].userNameMessage || isError[i].emailMessage) && (
                    <p className="error-message unseen">t</p>
                  )}
                </td>
                <td>
                  <input
                    type="text"
                    className="team"
                    value={v.team || ''}
                    onChange={handleNewUserInput(i, 'team')}
                    placeholder="Team"
                  />
                  {(isError[i].userNameMessage || isError[i].emailMessage) && (
                    <p className="error-message unseen">t</p>
                  )}
                </td>
                <td>
                  {data[i] ? (
                    data[i].email
                  ) : (
                    <>
                      <input
                        type="text"
                        className={`email ${isError[i]?.email ? 'error' : ''}`}
                        value={v.email}
                        onChange={handleNewUserInput(i, 'email')}
                        placeholder="Email address*"
                      />
                      {isError[i].emailMessage && (
                        <img src={ERROR_FILLED} className="inputbox-error" />
                      )}
                      {isError[i].emailMessage && (
                        <p className="error-message">{isError[i].emailMessage} </p>
                      )}
                    </>
                  )}
                </td>
                <td>
                  {v.createdAt}{' '}
                  {(isError[i].userNameMessage || isError[i].emailMessage) && (
                    <p className="error-message unseen">t</p>
                  )}
                </td>
                <td>
                  {v.lastActivity}{' '}
                  {(isError[i].userNameMessage || isError[i].emailMessage) && (
                    <p className="error-message unseen">t</p>
                  )}
                </td>
                <td>
                  <div>
                    <img src={MEMBERS_REMOVE} onClick={() => handleRemove(i)} />
                  </div>
                  {(isError[i].userNameMessage || isError[i].emailMessage) && (
                    <p className="error-message unseen">t</p>
                  )}
                </td>
                <td>
                  <div>
                    <img
                      src={savingMemberIndex === i ? LOADING : MEMBERS_SAVE}
                      className={savingMemberIndex === i ? 'saving' : ''}
                      onClick={() => {
                        if (savingMemberIndex !== null) return;
                        handleSave(i);
                      }}
                    />
                  </div>
                  {(isError[i].userNameMessage || isError[i].emailMessage) && (
                    <p className="error-message unseen">t</p>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </>);
}

function Dropdown({ index, isErrorStyle, authority, handleRoleClick, roles }) {
  const buttonStyle = {};
  if (!authority) buttonStyle.color = '#9E9E9E';
  if (isErrorStyle) buttonStyle.border = '1px solid #F94C4C';
  return (
    <Menu>
      <div className="dropdown">
        <Menu.Button style={buttonStyle}>
          <img src={CHEVRON_DOWN_18px} />
          {authority ? authority : 'Authority*'}
        </Menu.Button>
        <Menu.Items>
          {roles.map((v, i) => (
            <Menu.Item key={`${i}${v}`} onClick={() => handleRoleClick(index, v)}>
              {({ active }) => <div className={active ? 'active' : ''}>{v}</div>}
            </Menu.Item>
          ))}
        </Menu.Items>
      </div>
    </Menu>
  );
}
