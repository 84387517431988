import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import DataCenterUpLoading from './DataCenterUpLoading';
import DataCenterAnalyze from './DataCenterAnalyze';
import DataCenterAnalyzing from './DataCenterAnalyzing';
import { useDataCenter } from 'hooks/service';
import './DataCenter.scss';

const DataCenter = () => {
  /* 현재 단계 표시 -> upload 파일 업로드, analyze 분석 */
  /**
   * The initial data might not contain "results".
   * Even though that is the case, the UI should be displayed so that user can upload files.
   */
  const [currentStep, setCurrentStep] = useState('upload');

  const {
    userInfo,
    getAnalysisInfo,
    errorText,
    setErrorText,
    lastUpdatedTime,
    isLoading,
    setIsLoading,
    dataHandler,
    analysisInfo,
    setAnalysisInfo,
    isAnalyzeAvailable,
    setIsAnalyzeAvailable,
    generateUploadingUrl
  } = useDataCenter();

  if (userInfo?.user.role === 'observer') {
    return <Navigate to="/" />;
  }

  if (isAnalyzeAvailable === null || isAnalyzeAvailable === undefined) {
    return null;
  } else if (!isAnalyzeAvailable) {
    return (
      <div className="DataCenter_wrap">
        <div className="DataCenter">
          <DataCenterAnalyzing />
        </div>
      </div>
    );
  }

  return (
    (<div className="DataCenter_wrap">
      <div className="DataCenter">
        {currentStep === 'upload' ? (
          <DataCenterUpLoading
            setCurrentStep={setCurrentStep}
            setAnalysisInfo={setAnalysisInfo}
            dataHandler={dataHandler}
            errorText={errorText}
            getAnalysisInfo={getAnalysisInfo}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setErrorText={setErrorText}
            lastUpdatedTime={lastUpdatedTime}
            userInfo={userInfo}
            generateUploadingUrl={generateUploadingUrl}
          />
        ) : (
          <DataCenterAnalyze
            setCurrentStep={setCurrentStep}
            analysisInfo={analysisInfo}
            isAnalyzeAvailable={isAnalyzeAvailable}
            setIsAnalyzeAvailable={setIsAnalyzeAvailable}
            lastUpdatedTime={lastUpdatedTime}
          />)
        }
      </div>
    </div>)
  );
};

export default DataCenter;
