import { cloneDeep } from 'lodash';
import { numberWithCommas, formatNewDate, orderByProperty } from 'utils';
import { getStore } from 'utils/userStorage';
import i18n from '../../../../i18n';

export const COLORS = ['#4B43FF', '#00DCFF', '#A12AFF', '#FE59F7', '#FFB800', '#FD6A00', '#18D1A0'];

export const overview = {
  generateSummary(cards) {
    const transaction = numberWithCommas(cards.total_transaction);
    const lead = numberWithCommas(cards.total_lead);
    const customer = {
      value: numberWithCommas(cards.total_customer),
      ltc: (cards.total_customer / cards.total_lead).toFixed(2)
    };
    const segments = cards.num_segment;
    const retention = Math.round(cards.retention_rate * 100);
    const ltv6 = numberWithCommas(Math.round(cards.life_time_value));
    return {
      transaction,
      lead,
      customer,
      segments,
      retention,
      ltv6
    };
  },
  generateSales(sales, currency) {
    const months = sales
      .filter((v) => v.name.includes('monthly_sales'))
      .sort((a, b) => b.name.localeCompare(a.name));
    const years = sales
      .filter((v) => v.name.includes('yearly_sales'))
      .sort((a, b) => a.name.localeCompare(b.name));
    const monthly = Array.from(
      {
        length: months[0].chart_values.length
      },
      (_, i) => ({
        date: formatNewDate(months[0].chart_values[i].x, currency),
        total:
          currency === 'won'
            ? Math.round((months[0].chart_values[i].y + months[1].chart_values[i].y).toFixed(2))
            : (months[0].chart_values[i].y + months[1].chart_values[i].y).toFixed(2),
        guest:
          currency === 'won'
            ? Math.round(months[0].chart_values[i].y)
            : months[0].chart_values[i].y,
        signIn:
          currency === 'won' ? Math.round(months[1].chart_values[i].y) : months[1].chart_values[i].y
      })
    ).filter((v, i, arr) => i > arr.length - 1 - 13);
    const yearly = Array.from({ length: years[0].chart_values.length }, (_, i) => ({
      date: years[0].chart_values[i].x,
      total:
        currency === 'won'
          ? Math.round(years[0].chart_values[i].y + years[1].chart_values[i].y)
          : years[0].chart_values[i].y + years[1].chart_values[i].y,
      guest:
        currency === 'won' ? Math.round(years[0].chart_values[i].y) : years[0].chart_values[i].y,
      signIn:
        currency === 'won' ? Math.round(years[1].chart_values[i].y) : years[1].chart_values[i].y
    }));
    return { monthly, yearly };
  },
  generateNewLeadToCustomer(nltc, currency) {
    const newLead = nltc
      .filter((v) => v.name === 'new_leads')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 13)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
    const newCustomer = nltc
      .filter((v) => v.name === 'new_customers')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 13);
    const ltc = nltc
      .filter((v) => v.name === 'ltc')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 13)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
    return {
      newLead,
      newCustomer,
      ltc
    };
  },
  generateMonthlyCustomer(mc, currency) {
    return mc[0].chart_values
      .filter((v, i, arr) => i > arr.length - 1 - 10)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
  },
  generateMonthlyTransaction(mt, currency) {
    const transactionPerCustomer = mt
      .filter((v) => v.name === 'transaction_per_customer')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 12)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
    const transactionWithoutSignIn = mt
      .filter((v) => v.name === 'without_signin_transaction')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 12)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
    const transaction = mt
      .filter((v) => v.name === 'transaction')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 12);
    return {
      transactionPerCustomer,
      transactionWithoutSignIn,
      transaction
    };
  },
  generateCategoryRankings(cr) {
    return cr[0].chart_values.filter((v, i) => i < 5);
  },
  generateLocationRankings(lr) {
    return lr[0].chart_values.filter((v, i) => i < 5);
  },
  generateItemRankings(tables) {
    const itemRanking = tables.filter((v) => v.name === 'item_ranking')[0];
    return {
      export: itemRanking.options.export,
      table: {
        headers: itemRanking.headers,
        cells: itemRanking.cells
      }
    };
  },
  generateCustomerRankings(tables, currency) {
    const customerRanking = tables.filter((v) => v.name === 'customer_ranking')[0];
    const cells = cloneDeep(customerRanking.cells).map((v) => {
      const copied = {
        ...v
      };
      copied['Last Order'] = formatNewDate(copied['Last Order'], currency);
      return copied;
    });
    return {
      export: customerRanking.options.export,
      table: {
        headers: customerRanking.headers,
        cells
      }
    };
  },
  generateThirdMonthLtv(ltv, currency) {
    return ltv
      .filter((v) => v.name === 'ltv3')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 13)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
  },
  generateSixthMonthLtv(ltv, currency) {
    return ltv
      .filter((v) => v.name === 'ltv6')[0]
      .chart_values.filter((v, i, arr) => i > arr.length - 1 - 13)
      .map((v) => {
        const date = formatNewDate(v.x, currency);
        const copied = cloneDeep(v);
        copied.x = date;
        return copied;
      });
  },
  generateAbTesting(abTest) {
    const retention = abTest.filter((v) => v.name === 'Retention')[0].chart_values;
    const revenue = abTest.filter((v) => v.name === 'Revenue')[0].chart_values;
    const withMomentum = [
      { ...retention[0], x: 'retention' },
      { ...revenue[0], x: 'revenue' }
    ];
    const withoutMomentum = [
      { ...retention[1], x: 'retention' },
      { ...revenue[1], x: 'revenue' }
    ];
    return {
      withMomentum,
      withoutMomentum
    };
  }
};
export const segmentation = {
  generatePopulationSalesAndTransactions(segments, lastChart, currentChart) {
    function getValue(chart, chartName, segmentId) {
      const chartValues = chart.filter((chart) => chart.name === chartName)[0]['chart_values'];
      return chartValues.filter((value) => Number(value.x) === segmentId)[0]['y'];
    }

    const segmentsByAscendingId = orderByProperty(segments, 'segment_id', 'asc').map((v, i) => ({
      ...v,
      color: COLORS[i],
      percentage: 20
    }));

    const updateDates = [];
    const current = {
      churnExcluded: [],
      churnIncluded: []
    };
    const last = {
      churnExcluded: null,
      churnIncluded: null
    };

    updateDates.push(transformDateFormat(currentChart[0].options.date_updated));
    current.churnIncluded = segmentsByAscendingId.map((segment) => ({
      x: {
        average: getValue(currentChart, 'average_transactions_churn_included', segment.segment_id),
        total: getValue(currentChart, 'total_transactions_churn_included', segment.segment_id)
      },
      y: {
        average: getValue(currentChart, 'average_sales_churn_included', segment.segment_id),
        total: getValue(currentChart, 'total_sales_churn_included', segment.segment_id)
      },
      z: {
        radius: getValue(currentChart, 'population_churn_included', segment.segment_id),
        pie: [
          {
            x: 'churn',
            y:
              getValue(currentChart, 'population_churn_included', segment.segment_id) -
              getValue(currentChart, 'population_churn_excluded', segment.segment_id)
          },
          {
            x: 'rest',
            y: getValue(currentChart, 'population_churn_excluded', segment.segment_id)
          }
        ]
      }
    }));

    current.churnExcluded = segmentsByAscendingId.map((segment) => ({
      x: {
        average: getValue(currentChart, 'average_transactions_churn_excluded', segment.segment_id),
        total: getValue(currentChart, 'total_transactions_churn_excluded', segment.segment_id)
      },
      y: {
        average: getValue(currentChart, 'average_sales_churn_excluded', segment.segment_id),
        total: getValue(currentChart, 'total_sales_churn_excluded', segment.segment_id)
      },
      z: {
        radius: getValue(currentChart, 'population_churn_excluded', segment.segment_id),
        pie: [
          {
            x: 'churn',
            y: 0
          },
          {
            x: 'rest',
            y: getValue(currentChart, 'population_churn_excluded', segment.segment_id)
          }
        ]
      }
    }));

    if (lastChart !== null) {
      updateDates.push(transformDateFormat(lastChart[0].options.date_updated));

      last.churnIncluded = segmentsByAscendingId.map((segment) => ({
        x: {
          average: getValue(lastChart, 'average_transactions_churn_included', segment.segment_id),
          total: getValue(lastChart, 'total_transactions_churn_included', segment.segment_id)
        },
        y: {
          average: getValue(lastChart, 'average_sales_churn_included', segment.segment_id),
          total: getValue(lastChart, 'total_sales_churn_included', segment.segment_id)
        },
        z: {
          radius: getValue(lastChart, 'population_churn_included', segment.segment_id),
          pie: [
            {
              x: 'churn',
              y:
                getValue(lastChart, 'population_churn_included', segment.segment_id) -
                getValue(lastChart, 'population_churn_excluded', segment.segment_id)
            },
            {
              x: 'rest',
              y: getValue(lastChart, 'population_churn_excluded', segment.segment_id)
            }
          ]
        }
      }));

      last.churnExcluded = segmentsByAscendingId.map((segment) => ({
        x: {
          average: getValue(lastChart, 'average_transactions_churn_excluded', segment.segment_id),
          total: getValue(lastChart, 'total_transactions_churn_excluded', segment.segment_id)
        },
        y: {
          average: getValue(lastChart, 'average_sales_churn_excluded', segment.segment_id),
          total: getValue(lastChart, 'total_sales_churn_excluded', segment.segment_id)
        },
        z: {
          radius: getValue(lastChart, 'population_churn_excluded', segment.segment_id),
          pie: [
            {
              x: 'churn',
              y: 0
            },
            {
              x: 'rest',
              y: getValue(lastChart, 'population_churn_excluded', segment.segment_id)
            }
          ]
        }
      }));
    }

    return {
      segments: segmentsByAscendingId,
      updateDates,
      last,
      current
    };
  },
  generateRecencyFrequencyMonetaryValue(segments, lastChart, chart, population) {
    const onlyChurnIncludingChart = chart.filter((v) => v.name.includes('_included'));

    function transformCurrentDataByCategory(category) {
      const currenctChartValuesAscending = orderByProperty(
        onlyChurnIncludingChart.filter((v) => v.name.includes(category))[0].chart_values,
        'x',
        'asc'
      );
      return segmentsByAscendingId.map((v, i) => {
        const currentValue = currenctChartValuesAscending[i].y;

        return {
          color: COLORS[i],
          segmentId: v.segment_id,
          current: currentValue,
          xCurrent: currentValue,
          y: v.segment_id,
          last: null,
          xLast: null
        };
      });
    }
    function transformLastDataByCategory(category, dataset, onlyChurnIncludingLastChart) {
      const lastChartValuesAscending = orderByProperty(
        onlyChurnIncludingLastChart.filter((v) => v.name.includes(category))[0].chart_values,
        'x',
        'asc'
      );
      return dataset.map((v, i) => ({
        ...v,
        last: lastChartValuesAscending[i].y,
        xLast: lastChartValuesAscending[i].y
      }));
    }

    const segmentsByAscendingId = orderByProperty(segments, 'segment_id', 'asc').map((v, i) => ({
      ...v,
      color: COLORS[i]
    }));

    const updateDates = [];
    let recency = {};
    let frequency = {};
    let monetaryValue = {};

    updateDates.push(transformDateFormat(onlyChurnIncludingChart[0].options.date_updated));
    recency = transformCurrentDataByCategory('recency');
    frequency = transformCurrentDataByCategory('frequency');
    monetaryValue = transformCurrentDataByCategory('monetary');

    if (lastChart !== null) {
      const onlyChurnIncludingLastChart = lastChart.filter((v) => v.name.includes('_included'));
      updateDates.push(transformDateFormat(onlyChurnIncludingLastChart[0].options.date_updated));
      recency = transformLastDataByCategory('recency', recency, onlyChurnIncludingLastChart);
      frequency = transformLastDataByCategory('frequency', frequency, onlyChurnIncludingLastChart);
      monetaryValue = transformLastDataByCategory(
        'monetary',
        monetaryValue,
        onlyChurnIncludingLastChart
      );
    }
    const populations = orderByProperty(
      population
        .filter((v) => transformDateFormat(v.options.date_updated) === updateDates[0])
        .filter((v) => v.name === 'population_churn_included')[0].chart_values,
      'x',
      'asc'
    );
    return {
      segments: segmentsByAscendingId,
      populations,
      updateDates,
      recency,
      frequency,
      monetaryValue,
      isLastExist: lastChart ? true : false
    };
  },
  generateNumberOfCategoriesAndCustomers(segments, lastChart, chart, population) {
    const segmentsByAscendingId = orderByProperty(segments, 'segment_id', 'asc').map((v, i) => ({
      ...v,
      color: COLORS[i]
    }));

    const onlyChurnIncludingChart = chart.filter((v) => v.name.includes('_included'));

    function transformCurrentDataByCategory(category) {
      const currenctChartValuesAscending = orderByProperty(
        onlyChurnIncludingChart.filter((v) => v.name.includes(category))[0].chart_values,
        'x',
        'asc'
      );

      return segmentsByAscendingId.map((v, i) => {
        const currentValue = currenctChartValuesAscending[i].y;

        const populations = orderByProperty(
          population
            .filter((v) => transformDateFormat(v.options.date_updated) === updateDates[0])
            .filter((v) => v.name === 'population_churn_included')[0].chart_values,
          'x',
          'asc'
        );
        const segmentPopulation = populations.find((p) => Number(p.x) === v.segment_id).y;

        return {
          color: COLORS[i],
          currentPercentage:
            category === 'some_bought'
              ? null
              : Math.round((currentValue / segmentPopulation) * 100),
          segmentId: v.segment_id,
          segmentName: v.segment_name,
          current: currentValue,
          x: v.segment_id,
          yCurrent: currentValue,
          last: null,
          yLast: null
        };
      });
    }
    function transformLastDataByCategory(category, dataset, onlyChurnIncludingLastChart) {
      const lastChartValuesAscending = orderByProperty(
        onlyChurnIncludingLastChart.filter((v) => v.name.includes(category))[0].chart_values,
        'x',
        'asc'
      );
      const populations = orderByProperty(
        population
          .filter((v) => transformDateFormat(v.options.date_updated) === updateDates[1])
          .filter((v) => v.name === 'population_churn_included')[0].chart_values,
        'x',
        'asc'
      );

      return dataset.map((v, i) => {
        const segmentPopulation = populations.find((p) => Number(p.x) === v.segmentId).y;
        return {
          ...v,
          lastPercentage:
            category === 'some_bought'
              ? null
              : Math.round((lastChartValuesAscending[i].y / segmentPopulation) * 100),
          last: lastChartValuesAscending[i].y,
          yLast: lastChartValuesAscending[i].y
        };
      });
    }

    const updateDates = [];
    let purchaseOnce = {};
    let purchaseAllCategories = {};
    let purchasedCategories = {};
    updateDates.push(transformDateFormat(onlyChurnIncludingChart[0].options.date_updated));
    purchaseOnce = transformCurrentDataByCategory('one_bought');
    purchaseAllCategories = transformCurrentDataByCategory('all_bought');
    purchasedCategories = transformCurrentDataByCategory('some_bought');

    if (lastChart !== null) {
      const onlyChurnIncludingLastChart = lastChart.filter((v) => v.name.includes('_included'));
      updateDates.push(transformDateFormat(onlyChurnIncludingLastChart[0].options.date_updated));
      purchaseOnce = transformLastDataByCategory(
        'one_bought',
        purchaseOnce,
        onlyChurnIncludingLastChart
      );
      purchaseAllCategories = transformLastDataByCategory(
        'all_bought',
        purchaseAllCategories,
        onlyChurnIncludingLastChart
      );
      purchasedCategories = transformLastDataByCategory(
        'some_bought',
        purchasedCategories,
        onlyChurnIncludingLastChart
      );
    }

    return {
      segments: segmentsByAscendingId,
      updateDates,
      purchaseOnce,
      purchaseAllCategories,
      purchasedCategories,
      isLastExist: lastChart ? true : false
    };
  },
  generateSegmentSize(segments, ss) {
    return ss[0].chart_values.map((v) => ({
      ...v,
      x: segments.reduce(
        (acc, cur) => (acc ? acc : +cur.segment_id === +v.x ? cur.segment_name : acc),
        ''
      )
    }));
  },
  generateSales(segments, sales) {
    const averageSalesPerCustomer = sales
      .filter((v) => v.name === 'average_sales_per_customer')[0]
      .chart_values.map((v) => ({
        ...v,
        x: segments.reduce(
          (acc, cur) => (acc ? acc : +cur.segment_id === +v.x ? cur.segment_name : acc),
          ''
        )
      }));
    const totalSalesPerSegment = sales
      .filter((v) => v.name === 'total_sales_per_segment')[0]
      .chart_values.map((v) => ({
        ...v,
        x: segments.reduce(
          (acc, cur) => (acc ? acc : +cur.segment_id === +v.x ? cur.segment_name : acc),
          ''
        )
      }));
    return {
      averageSalesPerCustomer,
      totalSalesPerSegment
    };
  },
  generateTransactions(segments, transactions) {
    const averageTransactionsPerCustomer = transactions
      .filter((v) => v.name === 'average_transactions_per_customer')[0]
      .chart_values.map((v) => ({
        ...v,
        x: segments.reduce(
          (acc, cur) => (acc ? acc : +cur.segment_id === +v.x ? cur.segment_name : acc),
          ''
        )
      }));
    const totalTransactionsPerSegment = transactions
      .filter((v) => v.name === 'total_transactions_per_segment')[0]
      .chart_values.map((v) => ({
        ...v,
        x: segments.reduce(
          (acc, cur) => (acc ? acc : +cur.segment_id === +v.x ? cur.segment_name : acc),
          ''
        )
      }));
    return {
      averageTransactionsPerCustomer,
      totalTransactionsPerSegment
    };
  },
  generateMonthlyCustomers(segments, mc, currency) {
    const sorted = [...mc]
      .map((v) => {
        const newV = { ...v };
        newV.chart_values = v.chart_values.filter((v, i, arr) => i > arr.length - 1 - 13);
        return newV;
      })
      .sort((a, b) => a.options.segment_id - b.options.segment_id);
    return sorted
      .reduce((acc, cur) => {
        const division = segments.reduce(
          (a, c) => (a ? a : +c.segment_id === +cur.options.segment_id ? c.segment_name : a),
          ''
        );
        acc.push.apply(
          acc,
          cur.chart_values.map((v) => ({ ...v, division }))
        );
        return acc;
      }, [])
      .map((v) => {
        const copied = { ...v };
        copied.x = formatNewDate(copied.x, currency);
        return copied;
      });
  },
  generateMonthlySales(segments, ms, currency) {
    const sorted = [...ms]
      .map((v) => {
        const newV = { ...v };
        newV.chart_values = v.chart_values.filter((v, i, arr) => i > arr.length - 1 - 13);
        return newV;
      })
      .sort((a, b) => a.options.segment_id - b.options.segment_id);
    return sorted
      .reduce((acc, cur) => {
        const division = segments.reduce(
          (a, c) => (a ? a : +c.segment_id === +cur.options.segment_id ? c.segment_name : a),
          ''
        );
        acc.push.apply(
          acc,
          cur.chart_values.map((v) => ({ ...v, division }))
        );
        return acc;
      }, [])
      .map((v) => {
        const copied = { ...v };
        copied.x = formatNewDate(copied.x, currency);
        return copied;
      });
  },
  generateLtvSix(segments, ltv, currency) {
    const sorted = [...ltv]
      .map((v) => {
        const newV = { ...v };
        newV.chart_values = v.chart_values.filter((v, i, arr) => i > arr.length - 1 - 13);
        return newV;
      })
      .sort((a, b) => a.options.segment_id - b.options.segment_id);

    /**
     * Temporarily!!!!!!!!!!!!!
     */
    if (getStore().company.companyName === 'Dr.Diary') {
      if (
        sorted[2]['chart_values'][sorted[2]['chart_values'].length - 1].x !==
        sorted[0]['chart_values'][sorted[0]['chart_values'].length - 1].x
      ) {
        sorted[2]['chart_values'].push({
          x: sorted[0]['chart_values'][sorted[0]['chart_values'].length - 1].x,
          y: 0,
          sequence: 50
        });
        sorted[2]['chart_values'].shift();
      }
    }

    return sorted
      .reduce((acc, cur) => {
        const division = segments.reduce(
          (a, c) => (a ? a : +c.segment_id === +cur.options.segment_id ? c.segment_name : a),
          ''
        );
        acc.push.apply(
          acc,
          cur.chart_values.map((v) => ({ ...v, division }))
        );
        return acc;
      }, [])
      .map((v) => {
        const copied = { ...v };
        copied.x = formatNewDate(copied.x, currency);
        return copied;
      });
  },
  generateMonthlyRetention(segments, mr, currency) {
    const sorted = [...mr]
      .filter((v) => v.name.includes('month_retention'))
      .map((v) => {
        const newV = { ...v };
        newV.chart_values = v.chart_values.filter((v, i, arr) => i > arr.length - 1 - 13);
        return newV;
      })
      .sort((a, b) => a.options.segment_id - b.options.segment_id);

    // FOR TEST
    // const t = sorted.map((v, i) => {
    //   if (i === 0) {
    //     v.chart_values = [{ x: '2021-05', y: 0.93, sequence: 11 }];
    //     return v;
    //   }
    //   return v;
    // });

    // 1 pick the logest "chart_values" array among all.
    const newSorted = [...sorted];
    const longestArray = newSorted.reduce(
      (acc, cur) => {
        return acc.chart_values.length > cur.chart_values.length ? acc : cur;
      },
      { chart_values: [] }
    ).chart_values;

    // 2 make it safe
    const safeSorted = sorted.map((v) => {
      const newV = { ...v };
      const willBeMappedToNewChartValues = [...longestArray];
      const existedChartValues = newV.chart_values;

      const newlyCreatedChartValues = willBeMappedToNewChartValues.map((w) => {
        const newW = { ...w };
        const filteredChartValues = existedChartValues.filter((x) => x.x === w.x);
        if (filteredChartValues.length !== 0) {
          newW.y = filteredChartValues[0].y;
        } else {
          newW.y = 0;
        }

        return newW;
      });
      newV.chart_values = newlyCreatedChartValues;
      return newV;
    });

    return safeSorted
      .reduce((acc, cur) => {
        const division = segments.reduce(
          (a, c) => (a ? a : +c.segment_id === +cur.options.segment_id ? c.segment_name : a),
          ''
        );
        acc.push.apply(
          acc,
          cur.chart_values.map((v) => ({ ...v, division }))
        );
        return acc;
      }, [])
      .map((v) => {
        const copied = { ...v };
        copied.x = formatNewDate(copied.x, currency);
        return copied;
      });
  },
  generateTables(segments, tables, currency) {
    return segments.map((v) => {
      const ownTables = tables.filter((t) => +t.options.segment_id === +v.segment_id);

      const sortedOwnTables = [];

      sortedOwnTables.push(ownTables.filter((table) => table.name.includes('item'))[0]);
      sortedOwnTables.push(ownTables.filter((table) => table.name.includes('customer'))[0]);

      if (ownTables.filter((table) => table.name.includes('group')).length > 0) {
        const groupTable = ownTables.filter((table) => table.name.includes('group'))[0];
        groupTable.segmentName = segments.filter(
          (segment) => segment.segment_id === groupTable.options.segment_id
        )[0].segment_name;
        sortedOwnTables.push(groupTable);
      }

      return sortedOwnTables.map((v, i) => {
        if (i === 0) return v;
        if (i === 2) return v;
        const copied = cloneDeep(v);
        const newCells = copied.cells.map((x) => {
          x['Last Order'] = formatNewDate(x['Last Order'], currency);
          return x;
        });
        copied.cells = newCells;
        return copied;
      });
    });
  }
};
export const retention = {
  generateRetentionRate(data, currency) {
    return data.map((v) => ({
      x: formatNewDate(v.x, currency),
      y: v.ratio
    }));
  },
  generateWaterfall(data, currency) {
    let cum = 0;
    const waterfall = [];
    data.forEach((v, i) => {
      if (i === 0) {
        cum += v.active;
        waterfall.push({
          customerType: 'customer',
          value: 0,
          start: cum,
          end: 0,
          date: formatNewDate(v.x, currency)
        });
      } else {
        waterfall.push({
          customerType: 'new',
          value: +v.new,
          start: cum,
          end: cum + +v.new,
          date: formatNewDate(v.x, currency)
        });
        cum += +v.new;
        waterfall.push({
          customerType: 'reactive',
          value: +v.reactive,
          start: cum,
          end: cum + +v.reactive,
          date: formatNewDate(v.x, currency)
        });
        cum += +v.reactive;
        waterfall.push({
          customerType: 'churned',
          value: -1 * +v.churned,
          start: cum,
          end: cum - +v.churned,
          date: formatNewDate(v.x, currency)
        });
        cum -= +v.churned;
        waterfall.push({
          customerType: 'customer',
          value: 0,
          start: cum,
          end: 0,
          date: formatNewDate(v.x, currency)
        });
      }
    });
    return waterfall;
  }
};
export const ltv = {
  generateCustomerValueProgress(data) {
    return data.chart_values;
  },
  generateLtv3(data, currency) {
    const copied = cloneDeep(data.chart_values).map((v) => {
      const copiedV = { ...v };
      copiedV.x = formatNewDate(copiedV.x, currency);
      return copiedV;
    });
    return copied;
  },
  generateLtv6(data, currency) {
    const copied = cloneDeep(data.chart_values).map((v) => {
      const copiedV = { ...v };
      copiedV.x = formatNewDate(copiedV.x, currency);
      return copiedV;
    });
    return copied;
  },
  generateCohortAnalysis(data, currency, parameters) {
    function setColorsByLength(length) {
      let v;
      switch (length) {
        case 9:
          v = [2, 3, 4, 5, 6, 7, 8, 9, 10];
          break;
        case 8:
          v = [2, 3, 4, 5, 6, 8, 9, 10];
          break;
        case 7:
          v = [2, 3, 4, 5, 6, 8, 10];
          break;
        case 6:
          v = [2, 4, 5, 6, 8, 10];
          break;
        case 5:
          v = [2, 4, 6, 8, 10];
          break;
        case 4:
          v = [2, 4, 6, 10];
          break;
        case 3:
          v = [2, 6, 10];
          break;
        case 2:
          v = [6, 10];
          break;
        case 1:
          v = [6];
          break;
        default:
          v = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      }
      return v;
    }
    function setColorClassNameByOrder(sort, order) {
      let className = '';
      switch (order) {
        case 1:
          className = sort === 't' ? 'neon-blue-50' : 'red-50';
          break;
        case 2:
          className = sort === 't' ? 'neon-blue-100' : 'red-100';
          break;
        case 3:
          className = sort === 't' ? 'neon-blue-200' : 'red-200';
          break;
        case 4:
          className = sort === 't' ? 'neon-blue-300' : 'red-300';
          break;
        case 5:
          className = sort === 't' ? 'neon-blue-400' : 'red-400';
          break;
        case 6:
          className = sort === 't' ? 'neon-blue-500' : 'red-500';
          break;
        case 7:
          className = sort === 't' ? 'neon-blue-600' : 'red-600';
          break;
        case 8:
          className = sort === 't' ? 'neon-blue-700' : 'red-700';
          break;
        case 9:
          className = sort === 't' ? 'neon-blue-800' : 'red-800';
          break;
        default:
          className = sort === 't' ? 'neon-blue-900' : 'red-900';
      }
      return className;
    }
    function assignTransactionColor(newCustomer, transactions) {
      const t = [...transactions].filter((v, i) => i !== 0);
      // const increments = t.map((v, i) => (i === 0 ? v - newCustomer : t[i] - t[i - 1]));
      const increments = t.map((v, i) => (i === 0 ? v - transactions[0] : t[i] - t[i - 1]));

      const sortedIncrements = [...increments].sort((a, b) => a - b);

      const oneTenth = t.length / 10;
      const colorVariations = setColorsByLength(t.length);
      const colorAssignedTransactions = increments.map((increment, i) => {
        if (t.length > 9) {
          return {
            className: setColorClassNameByOrder(
              't',
              Math.ceil((sortedIncrements.indexOf(increment) + 1) / oneTenth)
            ),
            value: t[i]
          };
        }
        return {
          className: setColorClassNameByOrder(
            't',
            colorVariations[sortedIncrements.indexOf(increment)]
          ),
          value: t[i]
        };
      });
      return colorAssignedTransactions;
    }
    function assignRetentionRateColor(retentionRates) {
      const rates = [...retentionRates].filter((v, i) => i !== 0);
      const sortedRates = [...retentionRates].sort((a, b) => a - b);
      const oneTenth = rates.length / 10;

      const colorVariations = setColorsByLength(rates.length);

      const colorAssignedRates = rates.map((rate) => {
        if (rates.length > 9) {
          return {
            className: setColorClassNameByOrder(
              'r',
              Math.ceil((sortedRates.indexOf(rate) + 1) / oneTenth)
            ),
            value: rate
          };
        }
        return {
          className: setColorClassNameByOrder('r', colorVariations[sortedRates.indexOf(rate)]),
          value: rate
        };
      });
      return colorAssignedRates;
    }

    const intervalParameter = parameters.interval.id;
    const periodParameter = parameters.period.id;
    const anyMomentParameter = parameters.anyMoment.id;

    const headers = [
      periodParameter === 'week' ? 'Week' : 'Month',
      anyMomentParameter ? 'AC' : 'NC'
    ];
    data[0].periods.forEach((v) => {
      let ltvCount = `LTV ${v}`;
      if (intervalParameter === 'quarter') ltvCount += 'Q';
      if (intervalParameter === 'year') ltvCount += 'Y';
      headers.push(ltvCount);
    });
    //week 관련 함수
    function getWeekCount(dateStr) {
      const lastdateOfMonth = new Date(dateStr.slice(0, 4), dateStr.slice(5, 7), 0).getDate();

      const date = new Date(dateStr).getDate();
      const remainder = date % 7;
      const quotient = Math.ceil(date / 7);

      let yearAndMonth;
      let week;

      // 예외 주 처리
      if (lastdateOfMonth - date < 3) {
        if (dateStr.slice(5, 7) === '12') {
          let year = parseInt(dateStr.slice(0, 4)) + 1;
          let month = '01';

          yearAndMonth = year.toString() + '-' + month;
        } else {
          let year = dateStr.slice(0, 4);
          let month = parseInt(dateStr.slice(5, 7)) + 1;
          month = month < 10 ? '-0' + month.toString() : '-' + month.toString();
          yearAndMonth = year + month;
        }
        week = yearAndMonth + '-1w';
        return week;
      }

      yearAndMonth = dateStr.slice(0, 8);
      // 기본 주 처리
      if (remainder < 5 && remainder != 0) {
        week = yearAndMonth + quotient + 'w';
      } else {
        let newQuotient = quotient + 1;
        week = yearAndMonth + newQuotient + 'w';
      }

      return week;
    }

    const rows = [];
    data.forEach((v) => {
      const firstRow = [];
      const secondRow = [];
      firstRow.push({ className: '', value: periodParameter === 'week' ? getWeekCount(v.x) : v.x });
      firstRow.push({ className: '', value: v.nc });
      firstRow.push({ className: '', value: v.values[0] });
      secondRow.push({ className: '', value: '' });
      secondRow.push({ className: '', value: '' });
      secondRow.push({ className: '', value: (v.ratio[0] * 100).toFixed(2) });

      const colorAssignedTransactions = assignTransactionColor(v.nc, v.values);
      colorAssignedTransactions.forEach((x) => {
        firstRow.push(x);
      });
      const colorAssignedRetentionRates = assignRetentionRateColor(v.ratio);
      colorAssignedRetentionRates.forEach((x) => {
        secondRow.push({
          className: x.className,
          value: (x.value * 100).toFixed(2)
        });
      });

      rows.push(firstRow);
      rows.push(secondRow);
    });
    return {
      headers,
      rows
    };
  }
};
export const forecasting = {
  generateTransactionForecasting(data, currency) {
    return data
      .map((v) => ({
        ...v,
        x: formatNewDate(v.x, currency),
        y: Math.round(v.y)
      }))
      .filter((v, i, arr) => i > arr.length - 1 - 16);
  }
};

export const leadToCustomer = {
  generateLtcAnalysis(data, currency) {
    const newData = data.map((each) => {
      const keyAndValues = Object.entries(each);

      const count = keyAndValues
        .filter((v) => typeof Number(v[0]) === 'number' && !isNaN(Number(v[0])))
        .sort((a, b) => a[0] - b[0])
        .map((v) => v[1]);

      const ratio = keyAndValues
        .filter((v) => v[0].includes('r_'))
        .sort((a, b) => {
          return Number(a[0].slice(3)) - Number(b[0].slice(3));
        })
        .map((v) => v[1]);

      return { ...each, count, ratio };
    });
    // 중복값 제외한 렝쓰로
    function setColorsByLength(length) {
      let v;
      switch (length) {
        case 9:
          v = [2, 3, 4, 5, 6, 7, 8, 9, 10];
          break;
        case 8:
          v = [2, 3, 4, 5, 6, 8, 9, 10];
          break;
        case 7:
          v = [2, 3, 4, 5, 6, 8, 10];
          break;
        case 6:
          v = [2, 4, 5, 6, 8, 10];
          break;
        case 5:
          v = [2, 4, 6, 8, 10];
          break;
        case 4:
          v = [2, 4, 6, 10];
          break;
        case 3:
          v = [2, 6, 10];
          break;
        case 2:
          v = [6, 10];
          break;
        case 1:
          v = [6];
          break;
        default:
          v = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      }
      return v;
    }
    // 색칠해주기위해서 있는거
    function setColorClassNameByOrder(sort, order) {
      let className = '';
      switch (order) {
        case 1:
          className = sort === 't' ? 'neon-blue-50' : '';
          break;
        case 2:
          className = sort === 't' ? 'neon-blue-100' : '';
          break;
        case 3:
          className = sort === 't' ? 'neon-blue-200' : '';
          break;
        case 4:
          className = sort === 't' ? 'neon-blue-300' : '';
          break;
        case 5:
          className = sort === 't' ? 'neon-blue-400' : '';
          break;
        case 6:
          className = sort === 't' ? 'neon-blue-500' : '';
          break;
        case 7:
          className = sort === 't' ? 'neon-blue-600' : '';
          break;
        case 8:
          className = sort === 't' ? 'neon-blue-700' : '';
          break;
        case 9:
          className = sort === 't' ? 'neon-blue-800' : '';
          break;
        default:
          className = sort === 't' ? 'neon-blue-900' : '';
      }
      return className;
    }
    // 첫째줄 컬러링 계산
    function assignLtcColor(leads, count) {
      const newCount = [...count].filter((v, i) => i !== 0);
      // 순차적으로 빼기 계산
      const increments = newCount.map((v, i) =>
        i === 0 ? v - count[0] : newCount[i] - newCount[i - 1]
      );
      // 오름차순으로 정리
      const sortedIncrements = [...increments].sort((a, b) => a - b);
      // 중복값을 없애기위한 작업
      const rearrangedIncrements = new Set(sortedIncrements);
      const deduplicatedIncrements = [...rearrangedIncrements];
      // 10개의 색이라 10으로 나눈거
      const oneTenth = deduplicatedIncrements.length / 10;
      // 중복값이 없는 상태로 추출하여 케이스 선별하고 이거로 색깔 선별하기 위해 필요
      const colorVariations = setColorsByLength(deduplicatedIncrements.length);
      const colorAssignedLtc = increments.map((increment, i) => {
        if (deduplicatedIncrements.length > 9) {
          return {
            className: setColorClassNameByOrder(
              't',
              Math.ceil((deduplicatedIncrements.indexOf(increment) + 1) / oneTenth)
            ),
            value: newCount[i]
          };
        }
        // 증가값이 10 미만이면 각 케이스별로 색깔 선별색으로 색칠
        return {
          className: setColorClassNameByOrder(
            't',
            colorVariations[deduplicatedIncrements.indexOf(increment)]
          ),
          value: newCount[i]
        };
      });
      return colorAssignedLtc;
    }

    // 둘째줄 컬러링 계산
    function assignLtcRateColor(count, leads) {
      const newCount = [...count].filter((v, i) => i !== 0);
      // const newCount = count.filter((v, idx) => idx !== 0);
      // 순차적으로 빼기 계산
      const increments = newCount.map((v, i) =>
        i === 0 ? v - count[0] : newCount[i] - newCount[i - 1]
      );
      // 오름차순으로 정리
      const sortedIncrements = [...increments].sort((a, b) => a - b);

      // 중복값을 없애기위한 작업
      const rearrangedIncrements = new Set(sortedIncrements);
      const deduplicatedIncrements = [...rearrangedIncrements];
      // 10개의 색이라 10으로 나눈거
      const oneTenth = deduplicatedIncrements.length / 10;
      // 중복값이 없는 상태로 추출하여 케이스 선별하고 이거로 색깔 선별하기 위해 필요
      const colorVariations = setColorsByLength(deduplicatedIncrements.length);

      const colorAssignedRates = increments.map((increment, i) => {
        // 중복값이 10개를 넘어가면 10가지 색을 다 쓰기위해 밑에 함수
        if (deduplicatedIncrements.length > 9) {
          if (leads === 0) {
            return {
              className: setColorClassNameByOrder(
                't',
                Math.ceil((deduplicatedIncrements.indexOf(increment) + 1) / oneTenth)
                // Math.ceil((newCount.indexOf(x) + 1) / oneTenth)
              ),
              value: 0
            };
          }
          return {
            className: setColorClassNameByOrder(
              't',
              Math.ceil((deduplicatedIncrements.indexOf(increment) + 1) / oneTenth)
              // Math.ceil((newCount.indexOf(x) + 1) / oneTenth)
            ),
            value: (newCount[i] / leads) * 100
          };
        }
        if (leads === 0) {
          return {
            className: setColorClassNameByOrder(
              't',
              colorVariations[deduplicatedIncrements.indexOf(increment)]
              // Math.ceil((newCount.indexOf(x) + 1) / oneTenth)
            ),
            value: 0
          };
        }
        // 증가값이 10 미만이면 각 케이스별로 색깔 선별색으로 색칠
        return {
          className: setColorClassNameByOrder(
            't',
            colorVariations[deduplicatedIncrements.indexOf(increment)]
          ),
          value: (newCount[i] / leads) * 100
        };
      });

      return colorAssignedRates;
    }
    //week 관련 함수
    function getWeekCount(dateStr) {
      const lastdateOfMonth = new Date(dateStr.slice(0, 4), dateStr.slice(5, 7), 0).getDate();

      const date = new Date(dateStr).getDate();
      const remainder = date % 7;
      const quotient = Math.ceil(date / 7);

      let yearAndMonth;
      let week;

      // 예외 주 처리
      if (lastdateOfMonth - date < 3) {
        if (dateStr.slice(5, 7) === '12') {
          let year = parseInt(dateStr.slice(0, 4)) + 1;
          let month = '01';

          yearAndMonth = year.toString() + '.' + month;
        } else {
          let year = dateStr.slice(0, 4);
          let month = parseInt(dateStr.slice(5, 7)) + 1;
          month = month < 10 ? '.0' + month.toString() : '.' + month.toString();
          yearAndMonth = year + month;
        }
        return (week = yearAndMonth + '.1w');
      }

      yearAndMonth = yearAndMonth = dateStr.slice(0, 8);
      // 기본 주 처리
      if (remainder < 5 && remainder != 0) {
        week = yearAndMonth + quotient + 'w';
      } else {
        let newQuotient = quotient + 1;
        week = yearAndMonth + newQuotient + 'w';
      }

      return week;
    }

    // 헤더부분
    const headers = [];

    const header = new Array(29).fill(0).map((v, index) => 'D+' + index);

    headers.push('month' in newData[0] ? 'month' : 'week', 'l', 'c', ...header);

    // 바디부분
    const rows = [];
    newData.forEach((v) => {
      const firstRow = [];
      const secondRow = [];
      // 가로줄 기준으로 첫번째 두번째 세번째는 색이 안들어가니까 값을 미리 채워준거
      // 첫번째칸은 먼쓰로 받거나 week으로 받거나 달라짐
      firstRow.push({ className: '', value: 'month' in v ? v.month : getWeekCount(v.week) });
      firstRow.push({ className: '', value: v.leads });
      firstRow.push({ className: '', value: v.customers });
      firstRow.push({ className: '', value: v.count[0] });
      secondRow.push({ className: '', value: '' });
      secondRow.push({ className: '', value: '' });
      secondRow.push({ className: '', value: '' });
      secondRow.push({
        className: '',
        value: v.leads === 0 || v.count[0] === 0 ? 0 : ((v.count[0] / v.leads) * 100).toFixed(1)
      });

      // 첫번째줄 색칠
      const colorAssignedLtc = assignLtcColor(v.leads, v.count);
      colorAssignedLtc.forEach((x) => {
        firstRow.push(x);
      });
      // 두번째줄 색칠 겸 백분율
      const colorAssignedLtcRates = assignLtcRateColor(v.count, v.leads);
      colorAssignedLtcRates.forEach((x) => {
        secondRow.push({
          className: x.className,
          value: v.leads === 0 ? 0 : x.value.toFixed(1)
        });
      });

      rows.push(firstRow);
      rows.push(secondRow);
    });

    return {
      headers,
      rows
    };
  }
};

function transformDateFormat(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString(i18n.language, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
}
