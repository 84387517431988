import { useEffect, useReducer, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from 'hooks/UI';
import { CHEVRON_DOWN, REMOVETEXT } from 'constants/images';
import './index.scss';
import { labelToTranslationKey } from 'utils';

function reducer(state, action) {
  switch (action.type) {
    case 'FOCUS':
      return {
        ...state,
        isFullOptionShow: true,
        isFocused: true,
        inputValue: '',
      };
    case 'UNFOCUS':
      return {
        ...state,
        isFullOptionShow: true,
        isFocused: false,
        inputValue: action.inputValue,
      };
    case 'TYPE':
      return {
        ...state,
        isFullOptionShow: false,
        isFocused: true,
        inputValue: action.inputValue,
      };
    case 'SELECT_VALUE':
      return {
        ...state,
        isFocused: false,
        isFullOptionShow: true,
        inputValue: action.inputValue,
      };
    case 'REMOVE_VALUE':
      return {
        ...state,
        inputValue: '',
      };
    default:
      return state;
  }
}
const initialState = {
  isFullOptionShow: true,
  isFocused: false,
  inputValue: '',
};

export default function SearchInput({
  label,
  labelTooltip,
  width = 300,
  style = {},
  options,
  interval,
  parameter,
  setParameter,
  disabled,
}) {
  const { t } = useTranslation();
  const containerEl = useRef(null);
  const inputEl = useRef(null);
  const [isClickedInside, setIsClickedInside] = useClickOutside(containerEl);

  const [{ isFocused, isFullOptionShow, inputValue }, dispatch] = useReducer(reducer, {
    ...initialState,
    inputValue: parameter.value,
  });

  const matchedOptionsIncludingInputValue = useMemo(() => {
    return options.filter((option) => option.value.includes(inputValue));
  }, [inputValue]);

  const matchedOptionWithInputValue = useMemo(() => {
    return options.filter((option) => option.value === inputValue)[0];
  }, [inputValue]);

  useEffect(() => {
    if (isFocused && !isClickedInside) {
      dispatch({
        type: 'UNFOCUS',
        inputValue: !matchedOptionWithInputValue
          ? parameter.value
          : matchedOptionWithInputValue.value,
      });
      if (matchedOptionWithInputValue) setParameter(matchedOptionWithInputValue, label);
    }
  }, [isClickedInside]);

  // when changing interval, update some parameters which is dependent on it.
  useEffect(() => {
    dispatch({
      type: 'SELECT_VALUE',
      inputValue: parameter.value,
    });
    setParameter(parameter, label);
  }, [interval]);

  let optionsDropdown;
  if (isFocused) {
    if (isFullOptionShow) {
      optionsDropdown = (
        <ul className="options-list">
          {options.map((option, i) => (
            <li
              key={`${option.value}${i}`}
              className={'option' + (parameter.value === option.value ? ' selected' : '')}
              onClick={() => {
                dispatch({
                  type: 'SELECT_VALUE',
                  inputValue: option.value,
                });
                setParameter(option, label);
              }}
            >
              {option.value}
            </li>
          ))}
        </ul>
      );
    } else if (matchedOptionsIncludingInputValue.length === 0) {
      optionsDropdown = <></>;
    } else {
      optionsDropdown = (
        <ul className="options-list">
          {matchedOptionsIncludingInputValue.map((option, i) => (
            <li
              key={`${option.value}${i}`}
              className={'option' + (parameter.value === option.value ? ' selected' : '')}
              onClick={() => {
                dispatch({
                  type: 'SELECT_VALUE',
                  inputValue: option.value,
                });
                setParameter(option, label);
              }}
            >
              {option.value}
            </li>
          ))}
        </ul>
      );
    }
  }

  return (
    <div
      ref={containerEl}
      style={{ ...style, width: `${width}px`, minWidth: `${width}px` }}
      className="search-input"
    >
      <label className="input-label" onClick={() => setIsClickedInside(false)}>
        {
          t(`options.${labelToTranslationKey(
            label === 'trackFirstItemOnly'
            ? 'track identical item only'
            : label === 'anyMoment'
            ? 'any moment'
            : label.includes('_')
            ? label.replace('_', ' ')
            : label === 'differenceBy'
            ? 'Difference By'
            : label === 'comparisonTo'
            ? 'Comparison To'
            : label === 'comparisonBy'
            ? 'Comparison By'
            : label === 'avgMonths'
            ? 'Avg (LTV) of recent N months'
            : label === 'avgQuarters'
            ? 'Avg (LTV) of recent N quarters'
            : label === 'avgYears'
            ? 'Avg (LTV) of recent N years'
            : label.includes('value')
            ? 'value'
            : label 
          )}`)
        }
        {labelTooltip}
      </label>
      <div className={`input-wrapper ${label}`}>
        <input
          disabled={disabled}
          ref={inputEl}
          value={inputValue}
          placeholder="Search"
          className="input"
          onClick={() => {
            dispatch({
              type: 'FOCUS',
            });
          }}
          onInput={(e) => {
            dispatch({
              type: 'TYPE',
              inputValue: e.target.value,
            });
          }}
          // onKeyDown={(e) => {
          //   if (e.key === 'ArrowDown') {
          //   }
          //   if (e.key === 'ArrowUp') {
          //   }
          //   if (e.key === 'Enter') {
          //   }
          // }}
        />
        {isFocused && (
          <img
            src={REMOVETEXT}
            alt="remove-text"
            className="remove-text-icon"
            onClick={() => {
              dispatch({ type: 'REMOVE_VALUE' });
            }}
          />
        )}
        <img
          src={CHEVRON_DOWN}
          alt="drop-down"
          className={`chevron-down${isFocused ? ' reverse' : ''} ${disabled ? 'disabled' : ''}`}
          onClick={() => {
            if (disabled) return;
            if (isFocused) {
              dispatch({
                type: 'UNFOCUS',
                inputValue: !matchedOptionWithInputValue
                  ? parameter.value
                  : matchedOptionWithInputValue.value,
              });
              if (matchedOptionWithInputValue) setParameter(matchedOptionWithInputValue, label);
            } else {
              dispatch({
                type: 'FOCUS',
              });
              inputEl.current.focus();
            }
          }}
        />
      </div>
      {optionsDropdown}
    </div>
  );
}
