import { useState, useEffect, createContext, useContext } from 'react';
import { axiosPrivate } from 'api';
import { Outlet } from 'react-router-dom';
import { useUserInfoContext } from './UserInfo';
import { useLocationInfo, useNavigateRoute } from 'hooks';
import { useAuthMutation } from 'hooks/auth';
import { mutationKeys } from 'constants/keys';
import { getStore } from 'utils/userStorage';

/**
 * This context manages the authorization and routes.
 * Also, add headers to axios instance and set Refresh.
 */

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [isAuthHeaderReady, setIsAuthHeaderReady] = useState(false);
  const { isNewMember, isLogin, isForgotpassword } = useLocationInfo();
  const { userInfo } = useUserInfoContext();
  const handleNavigate = useNavigateRoute();
  const [refresh] = useAuthMutation({
    mutationKey: mutationKeys.AUTH.REFRESH,
  });

  useEffect(() => {
    /**
     * conditional routes
     */
    if (isNewMember) return handleNavigate('/new-member');
    if (isForgotpassword) return handleNavigate('/forgot-password');

    // refreshAfter update : redirect to the login page if there isn't refreshAfter
    if (!userInfo?.token.refreshAfter) return handleNavigate('/login');

    // shop list update : redirect to the login page if there isn't shops list
    if (!userInfo?.company.shops) return handleNavigate('/login');

    // date notation update : redirect to the login page if there isn't dashboard value (which is only for the currency at the moment)
    if (!userInfo?.dashboard) return handleNavigate('/login');

    if (!userInfo || !getStore()) return handleNavigate('/login');

    if (userInfo && isLogin) handleNavigate('/marketing-overview');

    /**
     * add headers to the axios instance
     */
    if (userInfo) {
      const requestIntercept = axiosPrivate.interceptors.request.use(
        (config) => {
          if (!config.headers['Authorization']) {
            config.headers['Authorization'] = `Bearer ${userInfo?.token?.idToken}`;
          }
          if (userInfo?.user?.provider === 'google') {
            config.headers['X-SSO-Provider'] = 'google';
          }
          return config;
        },
        (error) => Promise.reject(error)
      );

      /**
       * set Refresh execution timing
       */
      const remainTime = new Date(userInfo?.token.expireAfter) - new Date();

      let refreshAfterTime = 0;

      if (remainTime > 30 * 60 * 1000) {
        refreshAfterTime = remainTime - 30 * 60 * 1000;
      } else if (remainTime < 30 * 1000) {
        refreshAfterTime = 1000;
      } else {
        refreshAfterTime = remainTime - 30 * 1000;
      }

      window.setTimeout(async () => {
        refresh();
      }, refreshAfterTime);

      setIsAuthHeaderReady(true);

      return () => {
        axiosPrivate.interceptors.request.eject(requestIntercept);
      };
    }
  }, [userInfo?.token?.idToken]);

  return (
    <AuthContext.Provider value={{}}>
      {(isAuthHeaderReady || isLogin || isForgotpassword || isNewMember) && children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
