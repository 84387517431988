import { useState, useCallback } from 'react'
import Styled from './styled'

export default function Legend({labels, colors, values=[], types=[], isVertical=false, drawFunction=null}) {
  if( values.length < labels.length ) {
    values = labels
  }

  const [ activeList, setActiveList ] = useState(labels.map(val => true))
  
  const handleLabelClick = useCallback((i) => {
    const newActiveList = [...activeList]
    newActiveList[i] = !newActiveList[i]

    // draw with new labels
    if (drawFunction !== null)
      drawFunction(values.filter((v, i) => newActiveList[i]))
    
    setActiveList(newActiveList)
  }, [activeList, drawFunction])

  return (
    <>
      <Styled.Container isVertical={isVertical}>
        {
          labels.map((label, i) => {
            return (
              <Styled.LabelContainer key={label} onClick={
                  () => {
                    handleLabelClick(i)
                    
                  }
                }>
                {
                  types[i] === 'line' && 
                    <Styled.LineCircle
                      disabled={drawFunction === null ? false : !activeList[i]}
                      color={colors[i]} />
                }
                {
                  types[i] === 'circle' && 
                    <Styled.FilledCircle
                      disabled={drawFunction === null ? false : !activeList[i]}
                      color={colors[i]} />
                }
                <Styled.Label disabled={drawFunction === null ? false : !activeList[i]} interactive={drawFunction !== null}>
                  {label}
                </Styled.Label>
              </Styled.LabelContainer>
            )
          })
        }
        
      </Styled.Container>
    </>
  )
}

