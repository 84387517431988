import { useState } from 'react';
import { useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';

export function useCollapse() {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const [ref, bounds] = useMeasure();
  const animatedCollapse = useSpring({
    height: isCollapsed ? 0 : bounds.height,
    from: {
      overflow: isCollapsed ? 'hidden' : 'visible',
    },
  });

  function handleCollapse(init = false, isCurrentPage = false) {
    if (init && isCurrentPage) {
      setIsCollapsed(false);
      return;
    }
    setIsCollapsed((prevState) => !prevState);
  }

  return [ref, animatedCollapse, isCollapsed, handleCollapse];
}
