import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MainHeader from 'components/MainHeader';
import OverviewSalesChart from 'components/charts/OverviewSalesChart';
import OverviewMonthlyCustomersChart from 'components/charts/OverviewMonthlyCustomersChart';
import OverviewRankingChart from 'components/charts/OverviewRankingChart';
import OverviewMonthlyTransactionChart from 'components/charts/OverviewMonthlyTransactionChart';
import OverviewLineChart from 'components/charts/OverviewLineChart';
import OverviewNewLeadToCustomerChart from 'components/charts/OverviewNewLeadToCustomerChart';
import OverviewABTestingChart from 'components/charts/OverviewABTestingChart';
import { TabButton } from 'components/buttons/TabButton';
import { EXPORT } from 'constants/images/icons';
import { useCustomerServiceFetch, useCustomerServiceMutation, usePageInit } from 'hooks';
import { mutationKeys, queryKeys } from 'constants/keys';
import { exportXLSX, labelToTranslationKey, nameExportFile } from 'utils';
import { useUserInfoContext, useDashboardContext } from 'context';
import './index.scss';
import { DynamicSkeleton, OverviewCardSkeleton, TitleSkeleton } from 'components/skeletons';

export default function Overview() {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();
  const { currency } = useDashboardContext();
  const [tabSelectIdx, setTabSelectIdx] = useState({
    salesChart: 0,
  });

  const { isResultRender, result, fetchResult } = useCustomerServiceFetch({
    serviceQueryKey: queryKeys.SERVICE.OVERVIEW,
  });
  const [logExport] = useCustomerServiceMutation({
    mutationKey: mutationKeys.CUSTOMER.LOG_EXPORT,
  });

  function handleLogExport(params) {
    logExport(params);
  }
  function handleTabSelect(key, idx) {
    setTabSelectIdx((prev) => ({ ...prev, [key]: idx }));
  }
  useEffect(() => {
    fetchResult();
  }, []);
  
  useEffect(() => {
    usePageInit();
  }, [])

  return (<>
    <MainHeader main="Marketing Overview" sub={isResultRender ? result?.dates : null} />
    <>

      <div className="overview-cards">
        {!isResultRender ? (
          <OverviewCardSkeleton width={181} />
        ) : (
          <div className="box card">
            <div className="card-title">{t("overview.transaction")}</div>
            <div className="card-value">{result.summary.transaction}</div>
          </div>
        )}
        {!isResultRender ? (
          <OverviewCardSkeleton width={170} />
        ) : (
          <div className="box card">
            <div className="card-title">{t("overview.lead")}</div>
            <div className="card-value">{result.summary.lead}</div>
          </div>
        )}
        {!isResultRender ? (
          <OverviewCardSkeleton width={240} />
        ) : (
          <div className="box card">
            <div className="card-title">{t("overview.customerltc")}</div>
            <div className="card-value">
              {result.summary.customer.value}
              <span className="ltc">({result.summary.customer.ltc})</span>
            </div>
          </div>
        )}
        {!isResultRender ? (
          <OverviewCardSkeleton width={104} />
        ) : (
          <div className="box card">
            <div className="card-title">{t("overview.segments")}</div>
            <div className="card-value">{result.summary.segments}</div>
          </div>
        )}
        {!isResultRender ? (
          <OverviewCardSkeleton width={132} />
        ) : (
          <div className="box card">
            <div className="card-title">{t("overview.retention_rate")}</div>
            <div className="card-value">
              {result.summary.retention}
              <span className="retention-rate">%</span>
            </div>
          </div>
        )}
        {!isResultRender ? (
          <OverviewCardSkeleton width={205} />
        ) : (
          <div className="box card">
            <div className="card-title">{t("overview.ltv_6")}</div>
            <div className="card-value">
              <span className="ltv-currency">{currency}</span>
              {result.summary.ltv6}
            </div>
          </div>
        )}
      </div>
      {/* {isResultRender && ( */}
      <>
        <div className="box sales-chart-container">
          {isResultRender && (
            <TabButton
              options={[t("overview.monthly"), t("overview.yearly")]}
              select={tabSelectIdx.salesChart}
              selectKey={'salesChart'}
              handleSelect={handleTabSelect}
            />
          )}

          {!isResultRender ? (
            <div className="overview-skeleton-container">
              <TitleSkeleton width={400} />
              <DynamicSkeleton width={1070} height={447} />
            </div>
          ) : (
            <OverviewSalesChart
              title={t("overview.sales")}
              chartId="osc"
              data={
                tabSelectIdx.salesChart === 0
                  ? result.salesChart.monthly
                  : result.salesChart.yearly
              }
              svgWidth={1072}
              svgHeight={472}
            />
          )}
        </div>
        <div className="overview-third-row-charts">
          <div className="box new-lead-to-customer-chart-container">
            {!isResultRender ? (
              <div className="overview-skeleton-container">
                <TitleSkeleton width={400} />
                <DynamicSkeleton width={516} height={315} />
              </div>
            ) : (
              <OverviewNewLeadToCustomerChart
                title={t("overview.new_lead_to_customer")}
                chartId="nltc"
                data={result.newLeadToCustomerChart}
                svgWidth={516}
                svgHeight={286 + 30}
              />
            )}
          </div>
          <div className="box monthly-customers-chart-container">
            {!isResultRender ? (
              <div className="overview-skeleton-container">
                <TitleSkeleton width={150} />
                <DynamicSkeleton width={221} height={315} />
              </div>
            ) : (
              <OverviewMonthlyCustomersChart
                title={t("overview.monthly_customers")}
                data={result.monthlyCustomersChart.monthlyCustomers}
                chartId="mc"
                svgWidth={222}
                svgHeight={315}
              />
            )}
          </div>
          <div className="box monthly-transaction-chart-container">
            {!isResultRender ? (
              <div className="overview-skeleton-container">
                <TitleSkeleton width={150} />
                <DynamicSkeleton width={221} height={315} />
              </div>
            ) : (
              <OverviewMonthlyTransactionChart
                title={t("overview.monthly_transaction")}
                chartId="mt"
                data={result.monthlyTransactionChart}
                svgWidth={222}
                svgHeight={250}
              />
            )}
          </div>
        </div>

        <div className="overview-rankings">
          <div className="box category-ranking-chart-container">
            {!isResultRender ? (
              <div className="overview-skeleton-container">
                <TitleSkeleton width={150} />
                <DynamicSkeleton width={221} height={280} />
              </div>
            ) : (
              <OverviewRankingChart
                title={t("overview.category_ranking")}
                data={result.categoryRankingsChart.categoryRankings}
              />
            )}
          </div>
          <div className="box item-ranking-table-container">
            {!isResultRender ? (
              <div className="overview-skeleton-container">
                <TitleSkeleton width={300} />
                <DynamicSkeleton width={515} height={280} />
              </div>
            ) : (
              <OverviewTable
                onLogExport={handleLogExport}
                logExportParameters={{
                  companyId: userInfo?.company.companyId,
                  shopId: userInfo?.company.shopId,
                  page: 'overview',
                }}
                title={t("overview.item_ranking")}
                data={result.itemRankingsTable.itemRankings}
                url={result.itemRankingsTable.export}
              />
            )}
          </div>
          <div className="box location-ranking-chart-container">
            {!isResultRender ? (
              <div className="overview-skeleton-container">
                <TitleSkeleton width={150} />
                <DynamicSkeleton width={221} height={280} />
              </div>
            ) : (
              <OverviewRankingChart
                title={t("overview.location_ranking")}
                data={result.locationRankingsChart.locationRankings}
              />
            )}
          </div>
        </div>
        <div className="overview-fifth-row-charts">
          <div className="box month3-ltv-chart-container">
            {!isResultRender ? (
              <div className="overview-skeleton-container">
                <TitleSkeleton width={200} />
                <DynamicSkeleton width={300} height={200} />
              </div>
            ) : (
              <OverviewLineChart
                title={t("overview.3rd_months_ltv")}
                data={result.thirdMonthLtvChart.thirdMonthLtv}
                chartId="tml"
                svgWidth={320}
                svgHeight={235}
              />
            )}
          </div>
          <div className="box month6-ltv-chart-container">
            {!isResultRender ? (
              <div className="overview-skeleton-container">
                <TitleSkeleton width={200} />
                <DynamicSkeleton width={300} height={200} />
              </div>
            ) : (
              <OverviewLineChart
                title={t("overview.6th_months_ltv")}
                data={result.sixthMonthLtvChart.sixthMonthLtv}
                chartId="sml"
                svgWidth={320}
                svgHeight={235}
              />
            )}
          </div>
          <div className="box abtesting-result-chart-container">
            {!isResultRender ? (
              <div className="overview-skeleton-container">
                <TitleSkeleton width={200} />
                <DynamicSkeleton width={300} height={200} />
              </div>
            ) : (
              <OverviewABTestingChart
                title={t("overview.ab_testing_result")}
                data={result.abTestingChart}
                chartId="abt"
                svgWidth={320}
                svgHeight={207}
              />
            )}
          </div>
        </div>
        <div className="box customers-table-container">
          {!isResultRender ? (
            <div className="overview-skeleton-container">
              <TitleSkeleton width={200} />
              <DynamicSkeleton width={1104} height={283} />
            </div>
          ) : (
            <OverviewTable
              onLogExport={handleLogExport}
              logExportParameters={{
                companyId: userInfo?.company.companyId,
                shopId: userInfo?.company.shopId,
                page: 'overview',
              }}
              title={t("overview.customers")}
              url={result.customersTable.export}
              data={result.customersTable.customers}
            />
          )}
        </div>
      </>
      {/* )} */}
    </>
  </>);
}

function OverviewTable({ onLogExport, logExportParameters, title, data, url }) {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();

  async function handleExport() {
    if (userInfo?.user.role === 'observer') return;
    await exportXLSX(url, title)
      .then(() => {
        onLogExport({
          companyId: logExportParameters.companyId,
          shopId: logExportParameters.shopId,
          page: logExportParameters.page,
          fileName: `${nameExportFile(title).slice(0, -4)}.xlsx`,
        });
      })
      .catch((err) => {
        console.log('export failed');
        console.log(err);
      });
  }
  return (
    (<div className="overview-table-container">
      <div className="overview-table-export-button" onClick={handleExport}>
        <img src={EXPORT} alt="export-button" />{t("settings.export")}</div>
      <div className="overview-table-title">{title}</div>
      <table className="overview-table">
        <thead>
          {/* TODO: Need to find a way to translate headers */}
          <tr>
            {data.headers.map((d, i) => (
              <th key={`${d}${i}`}>{t(`overview.${labelToTranslationKey(d)}`)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {title === t("overview.item_ranking") &&
            data.cells.map((r, i) => (
              <tr key={`row-${i}`}>
                <td>{r.Item}</td>
                <td>{r.Price}</td>
                <td>{r.Transaction}</td>
                <td>{r.Repurchase}</td>
              </tr>
            ))}
          {title === t("overview.customers") &&
            data.cells.map((r, i) => (
              <tr key={`row-${i}`}>
                <td>{r.Name}</td>
                <td>{r.Transaction}</td>
                <td>{r.Sale}</td>
                <td>{r['Last Order']}</td>
                <td>{r.Order}</td>
                <td>{r.Frequency}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>)
  );
}
