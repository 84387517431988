import { useQuery } from 'react-query';
import { axiosPrivate, customerAPI } from 'api';
import { useUserInfoContext } from 'context';
import { cloneDeep } from 'lodash';

export default function useGetOption(page) {
  const { userInfo } = useUserInfoContext();
  const { data, status, isSuccess } = useQuery(
    [page, 'options', userInfo?.company.shopId],
    async () => {
      const { data } = await axiosPrivate.post(customerAPI.OPTION, {
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        page
      });
      return data;
    },
    {
      enabled: !!page,
      select: (data) => transformOptions(data)
    }
  );
  return { data, status, isSuccess };
}

function transformOptions(data) {
  const toBeReturn = {
    interval: data.intervals.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    monthlyFrom: data.ranges.month.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    monthlyTo: data.ranges.month.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    quarterlyFrom: data.ranges.quarter.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    quarterlyTo: data.ranges.quarter.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    yearlyFrom: data.ranges.year.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    yearlyTo: data.ranges.year.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    retention: data.intervals.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    period: data.periods.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    segment: data.segments.map((v) => ({ id: +v.id, value: v.name ? v.name : '' })),
    channel: data.channels.map((v) => ({ id: +v.id, value: v.name })),
    item: data.items.map((v) => ({ id: +v.id, value: v.name })),
    trackFirstItemOnly: [
      {
        id: false,
        value: 'No'
      },
      {
        id: true,
        value: 'Yes'
      }
    ],
    anyMoment: [
      {
        id: false,
        value: 'No'
      },
      {
        id: true,
        value: 'Yes'
      }
    ],
    status: data.statuses.map((v) => ({ id: +v.id, value: v.name })),
    category: data.categories.map((v) => ({ id: +v.id, value: v.name })),
    region: data.regions.map((v) => ({ id: +v.id, value: v.name }))
  };

  if (data.extras) {
    const extraParameters = cloneDeep(data.extras);

    toBeReturn.extras = Object.keys(extraParameters) || [];

    for (let [key, value] of Object.entries(extraParameters)) {
      toBeReturn[key] = value.map((v) => ({
        id: +v.id,
        value: v.name
      }));
    }
  }

  return toBeReturn;
}
