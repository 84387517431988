const STEPS = 5;
const ordinals = [
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth'
];
export const replaceNumberToOrdinal = (num) => {
  return ordinals[num - 1];
};
export const replaceOrdinalToNumber = (ordinal) => {
  return ordinals.indexOf(ordinal) + 1;
};
export const checkIfHeadParameter = (property) => {
  return ordinals.indexOf(property) > -1;
};
const nullifyStepValue = (steps) => {
  return ordinals.reduce((obj, ordinal, i) => {
    if (i < steps) {
      obj[ordinal] = null;
    }
    return obj;
  }, {});
};

export const fillNullToFollowingHeadParameters = (parameters, property, item) => {
  const { segment, output, recentOneYear, ...heads } = parameters;
  const headParameters = { ...heads };
  const keys = Object.keys(headParameters).sort(
    (a, b) => replaceOrdinalToNumber(a) - replaceOrdinalToNumber(b)
  );
  const index = keys.indexOf(property);

  for (const [i, key] of keys.entries()) {
    if (i == index) {
      headParameters[key] = item;
    } else if (i > index) {
      headParameters[key] = null;
    }
  }

  return headParameters;
};
const fillNullToFollowingHeadItems = (currentDepth) => {
  const obj = nullifyStepValue(STEPS);
  const keys = Object.keys(obj).sort(
    (a, b) => replaceOrdinalToNumber(a) - replaceOrdinalToNumber(b)
  );
  const index = keys.indexOf(replaceNumberToOrdinal(currentDepth));

  for (const [i, key] of keys.entries()) {
    if (i < index || i === index) {
      delete obj[key];
    }
  }
  return obj;
};

export function updateParameterItems(setParameterItems, data) {
  const { currentDepth, nodes } = data;
  const nodesOnCurrentDepth = nodes.filter((node) => node.step === currentDepth);

  const parameterItemSet = nodesOnCurrentDepth.map((node) => ({
    id: node.tail,
    value: node.name
  }));
  setParameterItems((prev) => ({
    ...prev,
    [replaceNumberToOrdinal(currentDepth)]: parameterItemSet,
    ...fillNullToFollowingHeadItems(currentDepth)
  }));
}

export function initializeClientParameters(parameterItems) {
  if (!parameterItems) return {};

  return {
    segment: parameterItems.segment[0],
    output: parameterItems.output[0],
    recentOneYear: parameterItems.recentOneYear[0], // default값음 [0]임
    ...nullifyStepValue(STEPS)
  };
}

export function transformOptionData(data, recentOneYearTranslation) {
  return {
    segment: data.segments.map((v) => ({ id: +v.id, value: v.name ? v.name : '' })),
    output: [
      {
        id: true,
        value: 'category'
      },
      {
        id: false,
        value: 'item'
      }
    ],
    recentOneYear: [
      {
        id: true,
        value: recentOneYearTranslation.overThePastYear
      },
      {
        id: false,
        value: recentOneYearTranslation.all
      }
    ],
    ...nullifyStepValue(STEPS)
  };
}

export function transformBestJourneyData(data, churnTranslation) {
  let nodes;
  let links;

  const bestJourneyNodes = [];
  const bestJourneyLinks = [];

  const response = data.result
    .map((step, i) => {
      const t = step.sort((a) =>
        a[`category_${i + 1}`]?.toUpperCase() === 'CHURN' ||
        a[`item_${i + 1}`]?.toUpperCase() === 'CHURN'
          ? 1
          : -1
      );
      return t;
    })
    .filter((_, i) => i < 5);
  const responseExports = data.exports;
  if (response.length) {
    bestJourneyNodes.push({
      id: 'default',
      head: null,
      tail: 'default',
      name: 'Total',
      step: 0,
      total: response[0][0]?.total_pop,
      data: {
        population: response[0][0]?.total_pop
      }
    });

    response.forEach((step, i) => {
      const sumOfStep = i < 1 ? step[0].total_pop : response[i - 1][0].population;
      const sumOfChurn = step[1]?.population ?? 0;
      const sumOfRetain = sumOfStep - sumOfChurn;

      step.forEach((obj) => {
        let name = obj[`category_${i + 1}`] || obj[`item_${i + 1}`];
        if (name.toUpperCase() === 'CHURN') {
          name = churnTranslation;
        }
        bestJourneyNodes.push({
          id: obj.head + obj.tail,
          head: obj.head,
          tail: obj.tail,
          name,
          step: i + 1,
          firstOfStep: i !== bestJourneyNodes[bestJourneyNodes.length - 1]?.step ?? true,
          data: {
            population: obj.population,
            percentageOfTotal: Math.round(obj.total_pop_rate * 100),
            percentaeOfPrevious: Math.round(obj.part_pop_rate * 100),
            averageDays: Math.round(obj.avg_days),
            churnedCustomers: sumOfChurn,
            retainedCustomers: sumOfRetain,
            churnedCustomersPercentage: Math.round((sumOfChurn / sumOfStep) * 100),
            retainedCustomersPercentage: Math.round((sumOfRetain / sumOfStep) * 100)
          }
        });

        bestJourneyLinks.push({
          source: bestJourneyNodes.find((t) => t.tail === obj.head).id,
          target: obj.head + obj.tail,
          value: obj.population
        });
      });
    });
  }

  nodes = bestJourneyNodes;
  links = bestJourneyLinks;

  return { nodes, links, exports: responseExports };
}

const moveNodeToFirstOfSameStep = (nodes, sourceNode) => {
  const index = nodes.findIndex((node) => node.step === sourceNode.step);

  // sourceNode를 같은 스텝들 중에서 가장 앞으로 움직임
  const newNodes = nodes.filter((node) => node.id !== sourceNode.id);
  newNodes.splice(index, 0, sourceNode);

  // sourceNode를 가장 앞으로 보낸 뒤 같은 스템텝의 나머지 노드들은 다시 순서대로 정렬
  const nodesOnSameStep = newNodes.filter(
    (node) => node.step === sourceNode.step && node.id !== sourceNode.id
  );
  nodesOnSameStep.sort((a, b) => b.ratePerSum - a.ratePerSum);
  newNodes.splice(index + 1, nodesOnSameStep.length, ...nodesOnSameStep);

  return newNodes;
};

const sortLinksBySortedNodes = (sortedNodes, links) => {
  const newLinks = links.map((link) => ({ ...link }));

  const SortedNodesMap = new Map(sortedNodes.map((node) => [node.id, node]));

  newLinks.sort((linkA, linkB) => {
    const targetNodeA = SortedNodesMap.get(linkA.target);
    const targetNodeB = SortedNodesMap.get(linkB.target);

    return sortedNodes.indexOf(targetNodeA) - sortedNodes.indexOf(targetNodeB);
  });

  return newLinks;
};

const addColorToSelected = (arr, step, head) => {
  const newArr = arr.map((obj) => ({
    ...obj,
    selected: false
  }));

  if (head === 'default') {
    return newArr;
  }

  // head가 없는 경우는 (1) sourceNode가 churn이거나, (2) 마지막 노드를 클릭했을 때이다.
  // 이런 경우는 색상에 강조를 줘야 하므로 limit 값에 1을 추가한다.
  const limit = !head ? step + 1 : step;

  for (let i = 1; i < limit; i++) {
    const index = newArr.findIndex((obj) => obj.step === i);

    if (newArr[index].name.toUpperCase() !== 'CHURN') {
      newArr[index].selected = true;
    }
  }
  return newArr;
};

const stackedJourney = {
  nodes: [],
  links: []
};
export function transformExploreJourneyData(data, requestHead, churnTranslation) {
  if (requestHead === 'default' && data.result.length === 0) {
    return {
      segmentId: data.segment_id,
      isResultEmpty: data.result.length === 0,
      isFirstData: requestHead === 'default'
    };
  }

  const response = data.result;
  const segmentId = data.segment_id;
  let outputId;

  if (response.length !== 0) {
    if ('category_1' in data.result[0]) {
      outputId = true;
    } else if ('item_1' in data.result[0]) {
      outputId = false;
    }
  }
  // response에 있는 rank index는 0부터 시작하지만,
  // UI 상 [1st, 2nd...] 명칭과 맞추기 위해 + 1씩
  const responseStep = data.rank_from + 1;
  const requestStep = stackedJourney.nodes.find((node) => node.tail === requestHead)?.step ?? 0;
  const sourceNode = { ...stackedJourney.nodes.find((node) => node.tail === requestHead) };
  if (sourceNode.id === 'default') {
    sourceNode.total = response[0].total_pop;
    sourceNode.data.population = response[0].total_pop;
  }
  let nodes = [];
  let links = [];

  // sourceNode가 churn 이거나 마지막 step의 노드일 때, 순서만 정렬
  if (
    sourceNode?.name === '이탈' ||
    sourceNode?.name?.toUpperCase() === 'CHURN' ||
    requestStep === 5
  ) {
    nodes = nodes.concat(stackedJourney.nodes.filter((node) => node.step < requestStep + 1));
    links = links.concat(stackedJourney.links.filter((link) => link.step < requestStep + 1));

    nodes = moveNodeToFirstOfSameStep(nodes, sourceNode);
    nodes = addColorToSelected(nodes, requestStep);
    links = sortLinksBySortedNodes(nodes, links);
    links = addColorToSelected(links, requestStep);
    stackedJourney.nodes = nodes;
    stackedJourney.links = links;

    return { segmentId, outputId, nodes, links, currentDepth: requestStep };
  }

  // 새로운 데이터를 fetch 하기전에 아래 로직이 실행되어 참조 오류가 나는 것 막기
  // sourceNode가 churn인 경우도 이 케이스에 포함되기 때문에 churn 분기 이후에 위치
  if (requestStep !== responseStep - 1) {
    return;
  }

  if (requestHead === 'default') {
    nodes.push({
      id: 'default',
      head: null,
      tail: 'default',
      name: 'Total',
      step: 0,
      total: response[0].total_pop,
      ratePerSum: 1,
      selected: false,
      data: {
        population: response[0].total_pop
      }
    });
  } else {
    nodes = nodes.concat(stackedJourney.nodes.filter((node) => node.step < requestStep + 1));
    links = links.concat(stackedJourney.links.filter((link) => link.step < requestStep + 1));
  }

  const sum = response.reduce((acc, obj) => acc + obj.population, 0);

  const sumOfChurn = response
    .filter(
      (obj) =>
        obj?.[`category_${responseStep}`]?.toUpperCase() === 'CHURN' ||
        obj?.[`item_${responseStep}`]?.toUpperCase() === 'CHURN'
    )
    .reduce((acc, obj) => acc + obj.population, 0);

  response.forEach((obj) => {
    let name = obj[`category_${responseStep}`] || obj[`item_${responseStep}`];
    if (name.toUpperCase() === 'CHURN') {
      name = churnTranslation;
    }

    nodes.push({
      id: obj.head + obj.tail,
      head: obj.head,
      tail: obj.tail,
      name,
      step: responseStep,
      total: obj.total_pop,
      ratePerSum: obj.population / sum,
      data: {
        population: obj.population,
        percentageOfTotal: Math.round(obj.total_pop_rate * 100),
        percentaeOfPrevious: Math.round(obj.part_pop_rate * 100),
        averageDays: Math.round(obj.avg_days),
        churnedCustomers: sumOfChurn,
        retainedCustomers: sourceNode?.data?.population - sumOfChurn,
        churnedCustomersPercentage: Math.round((sumOfChurn / sourceNode?.data?.population) * 100),
        retainedCustomersPercentage: Math.round(
          ((sourceNode?.data?.population - sumOfChurn) / sourceNode?.data?.population) * 100
        )
      },
      selected: false
    });
  });

  response.forEach((obj) => {
    links.push({
      source: nodes.find((node) => node.tail === obj.head).id,
      target: obj.head + obj.tail,
      value: obj.population,
      name: obj[`category_${responseStep}`] || obj[`item_${responseStep}`],
      step: responseStep,
      selected: false
    });
  });

  nodes = moveNodeToFirstOfSameStep(nodes, sourceNode);
  nodes = addColorToSelected(nodes, responseStep, requestHead);
  links = sortLinksBySortedNodes(nodes, links);
  links = addColorToSelected(links, responseStep, requestHead);

  stackedJourney.nodes = nodes;
  stackedJourney.links = links;

  return {
    segmentId,
    outputId,
    nodes,
    links,
    currentDepth: responseStep,
    isResultEmpty: data.result.length === 0
  };
}
