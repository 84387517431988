import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CHECK_FILLED } from 'constants/images';
import { Link } from 'react-router-dom';
import DataCenterAnalyzingModal from './DataCenterAnalyzingModal';

const DataCenterAnalyzing = () => {
  const { t } = useTranslation()
  // 모달창 노출 여부 state
  const [modalOpen, setModalOpen] = useState(false);

  // 모달창 노출
  const showModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div className={'DataCenter_phrases_box' + (modalOpen ? ' modalOpen' : '')}>
        {/* <div className="DataCenter_phrases_box"> */}
        <div className="analyzing_message">{t("data_center.analyzing_data")}</div>
      </div>

      {/* 중앙 박스 */}
      <div className={'DataCenter_analyzing_box' + (modalOpen ? ' modalOpen' : '')}>
        <div className="submitted_info_box">
          <div className="check_filled">
            <img src={CHECK_FILLED} />
          </div>
        <div className="data_submitted">{t("data_center.data_submitted")}</div>
        <div className="data_submitted_time">
          {t("data_center.were_analyzing_your_data_quickly")}
        </div>

        <div className="status_animation_box"></div>
      </div>
      {/* 3개 파일이 모두 추가되지 않았거나, 업로딩이 진행중일 시 disabled */}
      <div className="btn_box">
        <Link to="/marketing-overview">
          <button className="dashboeard_btn">{t("data_center.return_to_dashboard")}</button>
        </Link>

        <div className="cancellation" onClick={showModal}>{t("data_center.request_for_cancellation")}</div>
        {modalOpen && (
          <DataCenterAnalyzingModal setModalOpen={setModalOpen} modalOpen={modalOpen} />
        )}
      </div>
    </div>
  </>);
};

export default DataCenterAnalyzing;
