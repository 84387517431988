import { TOASTDEFAULT, TOASTSUCCESS, TOASTCLOSE } from 'constants/images';
import { useDashboardContext } from 'context';
import { useLocationInfo } from 'hooks';
import './index.scss';

// export default function Toast({ response, message, isGoogle, setIsShown }) {
export default function Toast({ response, message, setIsShown }) {
  const { isSidebarMinimized } = useDashboardContext();
  const { isLogin, isDatacenter, isProfile, isMember, isOrganization } = useLocationInfo();

  const toast = (
    <div
      className={`toast ${response === 'success' ? 'green' : 'red'} 
      ${isProfile || isMember || isOrganization ? 'no-sidebar' : ''}
      ${
        !isLogin &&
        !isDatacenter &&
        isSidebarMinimized &&
        !isProfile &&
        !isMember &&
        !isOrganization
          ? 'sidebar-minimized'
          : ''
      } ${
        !isLogin &&
        !isDatacenter &&
        !isSidebarMinimized &&
        !isProfile &&
        !isMember &&
        !isOrganization
          ? 'sidebar-expanded'
          : ''
      }`}
    >
      {response === 'success' ? <img src={TOASTSUCCESS} /> : <img src={TOASTDEFAULT} />}

      <div className="message">
        {message || 'We are sorry, but something went wrong. Please try refreshing this page.'}
      </div>
      <button
        className="close-button"
        onClick={() => {
          setIsShown(false);
        }}
      >
        <img src={TOASTCLOSE} alt="toast-close-button" />
      </button>
    </div>
  );
  return toast;
}
