import { axiosPrivate, accountAPI } from 'api';
import models from './models';

export const queryFns = {
  async profile(context, userInfo) {
    const { data } = await axiosPrivate.post(accountAPI.PROFILE, {
      username: userInfo.user.username,
    });
    return data;
  },
  async members(context, userInfo) {
    const { data } = await axiosPrivate.post(accountAPI.MEMBERS, {
      company_id: userInfo.company.companyId,
    });
    return data;
  },
  async organization(context, userInfo) {
    const { data } = await axiosPrivate.post(accountAPI.ORGANIZATION, {
      company_id: userInfo.company.companyId,
    });
    return data;
  },
};

export const transformData = {
  profile(data) {
    const profile = {
      ...models.profile(),
    };
    profile.employeeId = data.employee.id;
    profile.userName = data.employee.user.username;
    profile.firstName = data.employee.user.given_name;
    profile.lastName = data.employee.user.family_name;
    profile.emailAddress = data.employee.user.primary_email;
    profile.phoneNumber = data.employee.user.phone;
    return profile;
  },
  members(data) {
    if (data.employees === null) return [];

    const employees = data.employees
      .filter((v) => v.user.active)
      .map((v, i) => {
        const date = new Date(v.created_at);
        const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        let month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(date);
        let day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
        if (month.length === 1) month = '0' + month;
        if (day.length === 1) day = '0' + day;
        const createdAt = `${year.slice(2)}/${month}/${day}`;

        return {
          id: v.id,
          userName: v.user.username,
          authority: v.roles[0],
          team: v.team,
          email: v.user.primary_email,
          createdAt,
          lastActivity: v.last_activity,
        };
      });
    return employees;
  },
  organization(data) {
    return data;
  },
};
