import './index.scss';

export const TitleSkeleton = ({ width }) => {
  return <div className="skeleton-title" style={{ width: `${width}px` }}></div>;
};
export const SubTitleSkeleton = ({ width }) => {
  return <div className="skeleton-subtitle" style={{ width: `${width}px` }}></div>;
};

export const TextFieldSkeleton = ({ width }) => {
  return <div className="skeleton-textfield" style={{ width: `${width}px` }}></div>;
};

export const OverviewCardSkeleton = ({ width }) => {
  return (
    <div className="skeleton-overviewcard" style={{ width: `${width}px` }}>
      <div className="skeleton-overviewcard-title"></div>
      <div className="skeleton-overviewcard-value"></div>
    </div>
  );
};

export const DynamicSkeleton = ({ width, height }) => {
  return (
    <div className="skeleton-dynamic" style={{ width: `${width}px`, height: `${height}px` }}></div>
  );
};

export const ParametersSkeleton = ({ children }) => {
  return (
    <div className="skeleton-parameters">
      <div className="skeleton-parameters-container">{children}</div>
      <div className="skeleton-parameters-apply-container">
        <div className="skeleton-parameters-apply"></div>
      </div>
    </div>
  );
};
export const ParameterSkeleton = () => {
  return (
    <div className="skeleton-parameter">
      <div className="skeleton-parameter-title"></div>
      <div className="skeleton-parameter-value"></div>
    </div>
  );
};
