export const columns = [
  {
    header: 'Name',
    cell: 'customer_name',
  },
  {
    header: 'Segment',
    cell: 'segment_name',
  },
  {
    header: 'Status',
    cell: 'status',
  },
  {
    header: 'Transaction',
    cell: 'transactions',
  },
  {
    header: 'Latest Transaction',
    cell: 'latest_transaction',
    firstSubCell: 'since',
    secondSubCell: 'at',
  },
  {
    header: 'Next Category',
    cell: 'category_name',
  },
  {
    header: 'Ground',
    cell: 'ground',
  },
];
export const columnsForCustomers = [
  {
    header: 'Name',
    cell: 'customer_name',
  },
  {
    header: 'Segment',
    cell: 'segment_name',
  },
  {
    header: 'Status',
    cell: 'status',
  },
  {
    header: 'Transaction',
    cell: 'transactions',
  },
  {
    header: 'Latest Transaction',
    cell: 'latest_transaction',
    firstSubCell: 'since',
    secondSubCell: 'at',
  },
  {
    header: 'Next Item',
    cell: 'top1_name',
  },
  {
    header: 'Top 2',
    cell: 'top2_name',
  },
  {
    header: 'Top 3',
    cell: 'top3_name',
  },
  {
    header: 'Top 4',
    cell: 'top4_name',
  },
  {
    header: 'Top 5',
    cell: 'top5_name',
  },
];

export const columnsForTopRanking = [
  {
    header: 'Item',
    cell: 'Item',
  },
  {
    header: 'Top 1',
    cell: 'Top 1',
  },
  {
    header: 'Top 2',
    cell: 'Top 2',
  },
  {
    header: 'Top 3',
    cell: 'Top 3',
  },
  {
    header: 'Top 4',
    cell: 'Top 4',
  },
  {
    header: 'Top 5',
    cell: 'Top 5',
  },
];
export const columnsForModel = [
  {
    header: 'Top N',
    cell: 'Top N',
  },
  {
    header: 'Overall',
    cell: 'Overall',
  },
  {
    header: 'Filter',
    cell: 'Filter',
  },
];
