import { useState, useEffect, useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { CHEVRON_DOWN, REMOVETEXT } from 'constants/images';
import { CheckButton } from 'components/buttons';
import { useClickOutside } from 'hooks';
import { useInfiniteQuery, useQuery } from 'react-query';
import { axiosPrivate, customerAPI } from 'api';
import { useUserInfoContext } from 'context';
import { debounce } from 'lodash';
import './index.scss';
import { clearReportsParameters } from 'utils/userStorage';
import Radio from 'pages/DataCenter/Radio';

const transformOption = (data) => {
  const categories = data.pages[0].data.categories.map((v) => ({
    id: +v.id,
    value: v.name,
  }));
  const items = categories.reduce((acc, cur) => {
    acc[cur.id] = data.pages
      .flatMap(
        (page) => page.data.categories.filter((category) => category.id === cur.id)[0].products
      )
      .map((item) => ({
        id: +item.id,
        value: item.name,
      }));
    return acc;
  }, {});
  return {
    categories,
    items,
    combination: categories.map((category) => ({
      id: category.id,
      value: category.value,
      products: items[category.id],
    })),
  };
};

const OptionsDropdown = ({
  moment,
  isFocused,
  isSearchRequestEnabled,
  queryKey,
  parameters,
  setParametersState,
  reportsParameters,
  clickedCategory,
  clickedAnyMoment,
  handleCategoryClick,
  handleAnyMomentClick,
  handleClickItemValueAll,
  // handleSelectValue: user based 컴포넌트에서부터 시작된 selectedParameters를 컨트롤하는 핸들러와 연관이 있는 prop
  handleSelectValue,
  handleClickMomet,
  momentOptions,
  searchedValues,
  inputValue,
}) => {
  const { t } = useTranslation();
  const itemListRef = useRef(null);
  const [isParametersSetFinished, setIsParametersSetFinished] = useState(false);

  const { userInfo } = useUserInfoContext();
  const [target, setTarget] = useState(null);

  const { data: optionWithCategoryAndItem, fetchNextPage: fetchNextItemList } = useInfiniteQuery(
    [queryKey, 'option-category'],
    async ({ pageParam = 1 }) => {
      const { data } = await axiosPrivate.post(customerAPI.OPTION_CATEGORY, {
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        page: pageParam,
      });
      return { data, nextPage: pageParam + 1 };
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      select: (data) => transformOption(data),
    }
  );
  // set the initial parameters right after fetching the list
  useEffect(() => {
    if (optionWithCategoryAndItem && !isParametersSetFinished) {
      if (reportsParameters) {
        setParametersState((prev) => ({
          ...prev,
          category: {
            id: reportsParameters.category,
            value: optionWithCategoryAndItem.categories.filter(
              (option) => option.id === reportsParameters.category
            )[0].value,
          },
          item: {
            id: reportsParameters.item,
            value: optionWithCategoryAndItem.items[reportsParameters.category].filter(
              (option) => option.id === reportsParameters.item
            )[0].value,
          },
        }));
        clearReportsParameters();
      } else {
        setParametersState((prev) => ({
          ...prev,
          category: {
            id: optionWithCategoryAndItem.combination[0].id,
            value: optionWithCategoryAndItem.combination[0].value,
          },
          item: {
            id: optionWithCategoryAndItem.combination[0].products[0].id,
            value: optionWithCategoryAndItem.combination[0].products[0].value,
          },
        }));
      }

      setIsParametersSetFinished(true);
    }
  }, [optionWithCategoryAndItem]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) fetchNextItemList();
      },
      {
        root: itemListRef.current,
      }
    );

    target && intersectionObserver.observe(target);

    return () => {
      intersectionObserver.disconnect();
    };
  }, [target]);

  // 검색 결과가 있을 때에만 보여지는 UI
  const searchedList = searchedValues.map((searched, i) => {
    const lengthOfInputValue = inputValue.length;

    const indexOfCategory = searched.category.value.toUpperCase().indexOf(inputValue.toUpperCase());
    const indexOfItem = searched.item.value.toUpperCase().indexOf(inputValue.toUpperCase());

    let remainInputValueCharacterCountInCategory = lengthOfInputValue;
    let remainInputValueCharacterCountInItem = lengthOfInputValue;

    const categoryValue =
      indexOfCategory === -1 ? (
        <span>{searched.category.value}</span>
      ) : (
        searched.category.value.split('').map((character, i) => {
          if (
            (indexOfCategory === i || indexOfCategory < i) &&
            remainInputValueCharacterCountInCategory !== 0
          ) {
            remainInputValueCharacterCountInCategory -= 1;
            return (
              <span className="selected" key={`${character}${i}`}>
                {character}
              </span>
            );
          }
          return <span key={`${character}${i}`}>{character}</span>;
        })
      );
    const itemValue =
      indexOfItem === -1 ? (
        <span>{searched.item.value}</span>
      ) : (
        searched.item.value.split('').map((character, i) => {
          if (
            (indexOfItem === i || indexOfItem < i) &&
            remainInputValueCharacterCountInItem !== 0
          ) {
            remainInputValueCharacterCountInItem -= 1;
            return (
              <span className="selected" key={`${character}${i}`}>
                {character}
              </span>
            );
          }
          return <span key={`${character}${i}`}>{character}</span>;
        })
      );

    return (
      <li
        key={`a${i}`}
        className={`purchased-item_options-list-item`}
        onClick={() => {
          if (searched.item.id === 0) {
            handleClickItemValueAll(searched.category);
            return;
          }
          // 이런 handleSelectValue 함수 prop이 실행되는 포인트가
          // User based 컴포넌트의 selectedParameters를 컨트롤하는 작업의 시작점

          handleSelectValue(searched.category, searched.item, moment);
        }}
      >
        {categoryValue}
        &nbsp;&gt;&nbsp;
        {itemValue}
      </li>
    );
  });

  return (
    (<div className={`purchased-item_options${isFocused ? ' open' : ''}`}>
      {!optionWithCategoryAndItem ? (
        <></>
      ) : isSearchRequestEnabled ? (
        <div className="purchased-item_options-search">
          <div className="purchased-item_options-title">Category &gt; Item</div>
          <ul className="purchased-item_options-list">{searchedList}</ul>
        </div>
      ) : (
        <>
          <div className="purchased-item_options-left">
            <div className="purchased-item_options-title">{t("retention.category")}</div>
            <ul className="purchased-item_options-list">
              {optionWithCategoryAndItem.categories.map((option, i) => (
                <li
                  key={`${option.id}${option.value}${i}`}
                  className={`purchased-item_options-list-item${
                    option.id === clickedCategory.id ? ' selected' : ''
                  }`}
                  onClick={() => {
                    handleCategoryClick(option);
                    itemListRef.current.scrollTop = 0;
                  }}
                >
                  {option.value}
                </li>
              ))}
            </ul>
          </div>
          <div className="purchased-item_options-right">
            <div className="purchased-item_options-title">{t("overview.item")}</div>
            <ul className="purchased-item_options-list" ref={itemListRef}>
              {clickedCategory.value ? (
                optionWithCategoryAndItem.items[clickedCategory.id].map((option, i) => (
                  <li
                    className={`purchased-item_options-list-item${
                      option.id === parameters.item.id ? ' selected' : ''
                    }`}
                    key={`${option.id}${option.value}${i}`}
                    onClick={() => {
                      handleSelectValue(clickedCategory, option, moment);
                    }}
                    ref={
                      i === optionWithCategoryAndItem.items[clickedCategory.id].length - 100
                        ? setTarget
                        : null
                    }
                  >
                    {option.value}
                  </li>
                ))
              ) : (
                <></>
              )}
            </ul>
          </div>
        </>
      )}
      <div className="purchased-item_options-bottom-long">
        <div>{t("user_based.the_moment_for_purchased_item")}</div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '19px' }}>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Radio
              value="1"
              onClick={(value) => handleClickMomet({ id: Number(value), value: 'First Purchase' })}
              isChecked={moment.id === 1}
              disabled={parameters.category.id === 0 && parameters.item.id === 0 ? 'disabled' : ''}
            />
            <span>{t("user_based.first_purchase")}</span>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Radio
              value="0"
              onClick={(value) => handleClickMomet({ id: Number(value), value: 'Any Moment' })}
              isChecked={moment.id === 0}
              disabled={parameters.category.id === 0 && parameters.item.id === 0 ? 'disabled' : ''}
            />

            <span>{t("user_based.any_moment")}</span>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Radio
              value="-1"
              isChecked={moment.id === -1}
              onClick={(value) => handleClickMomet({ id: Number(value), value: 'Last Purchase' })}
              disabled={parameters.category.id === 0 && parameters.item.id === 0 ? 'disabled' : ''}
            />
            <span>{t("user_based.last_purchase")}</span>
          </div>
        </div>
      </div>
    </div>)
  );
};

function reducer(state, action) {
  switch (action.type) {
    case 'FOCUS':
      return { ...state, isFocused: true, inputValue: '' };
    case 'UNFOCUS':
      return {
        ...state,
        isFocused: false,
        isSearchRequestEnabled: false,
        inputValue: action.inputValue,
      };
    case 'TYPE':
      return { ...state, isSearchRequestEnabled: true, inputValue: action.inputValue };
    case 'CLICK_CATEGORY':
      return { ...state, clickedCategory: action.clickedCategory };
    case 'CLICK_ITEM_ALL':
      return { ...state, isSearchRequestEnabled: false, clickedCategory: action.clickedCategory };
    case 'SELECT_VALUE':
      return {
        ...state,
        isFocused: false,
        isSearchRequestEnabled: false,
        inputValue: action.inputValue,
      };
    case 'REMOVE_VALUE':
      return { ...state, inputValue: '' };
    case 'CLICK_MOMENT':
      return { ...state, clickedMoment: action.clickedMoment };
    case 'SEARCH':
      return { ...state, searchedValues: action.searchedValues };
    default:
      return state;
  }
}
const initialState = {
  isFocused: false,
  isSearchRequestEnabled: false,
  clickedCategory: {},
  clickedMoment: { id: 1, value: 'First Purchase' },
  inputValue: '',
  searchedValues: [],
};

const PurchasedItemSearchInputRecommendation = ({
  label,
  width = 300,
  style = {},
  queryKey,
  momentOptions,
  parameters,
  // setParameter: User based 페이지 컴포넌트의 selectedParameters를 컨트롤하는 핸들러
  setParameter,
  setParametersState,
  reportsParameters,
}) => {
  const { userInfo } = useUserInfoContext();
  const containerEl = useRef(null);
  const inputEl = useRef(null);
  const [isClickedInside, setIsClickedInside] = useClickOutside(containerEl);

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const { data: searchedOptions, refetch: searchRefetch } = useQuery(
    ['option-search'],
    async () => {
      const { data } = await axiosPrivate.post(customerAPI.OPTION_SEARCH, {
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        parameter: 'category',
        term: inputEl.current.value,
      });
      return data;
    },
    {
      enabled: state.isSearchRequestEnabled,
    }
  );

  const handleClickMomet = (option) => {
    dispatch({
      type: 'CLICK_MOMENT',
      clickedMoment: option,
    });
  };

  const handleClickCategory = (option) => {
    dispatch({
      type: 'CLICK_CATEGORY',
      clickedCategory: option,
    });
  };
  const handleClickAnyMoment = (option) => {
    dispatch({
      type: 'CLICK_ANY_MOMENT',
      clickedAnyMoment: option,
    });
  };
  const handleClickItemValueAll = (category) => {
    dispatch({
      type: 'CLICK_ITEM_ALL',
      clickedCategory: category,
    });
  };
  // selectedParameters를 컨트롤하는핸들러인 setParameter prop을
  // 필요에 따라 사용하고 있는 PurchasedItem의 핸들러
  const handleSelectValue = (category, item, moment) => {
    dispatch({
      type: 'SELECT_VALUE',
      inputValue: `${category.value} > ${item.value}`,
    });

    setParameter(category, 'category');
    setParameter(item, 'item');
    if (category.id === 0 && item.id === 0) {
      setParameter(momentOptions.filter((v) => v.id === false)[0], 'anyMoment');
    }
    setParameter(moment, 'moment');
  };

  useEffect(() => {
    if (parameters.category.value && parameters.item.value) {
      dispatch({
        type: 'SELECT_VALUE',
        inputValue: `${parameters.category.value} > ${parameters.item.value}`,
      });
      dispatch({
        type: 'CLICK_CATEGORY',
        clickedCategory: parameters.category,
      });
      dispatch({
        type: 'CLICK_ANY_MOMENT',
        clickedAnyMoment: parameters.anyMoment,
      });
    }
  }, [parameters]);

  useEffect(() => {
    if (searchedOptions) {
      if (searchedOptions.length === 0) {
        dispatch({
          type: 'SEARCH',
          searchedValues: [],
        });
      } else {
        dispatch({
          type: 'SEARCH',
          searchedValues: searchedOptions.flatMap((category) =>
            category.products.map((item) => ({
              category: {
                id: +category.id,
                value: category.name,
              },
              item: {
                id: +item.id,
                value: item.name,
              },
            }))
          ),
        });
      }
    }
  }, [searchedOptions]);

  useEffect(() => {
    // 바깥 클릭
    if (state.isFocused && !isClickedInside) {
      dispatch({
        type: 'UNFOCUS',
        inputValue: `${parameters.category.value} > ${parameters.item.value}`,
      });
      dispatch({
        type: 'CLICK_CATEGORY',
        clickedCategory: parameters.category,
      });
      if (parameters.category.id === 0 && parameters.item.id === 0) {
        setParameter({ id: 1, value: 'First Purchase' }, 'moment');
        // setParameter(momentOptions.filter((v) => v.id === false)[0], 'anyMoment');
      } else {
        setParameter(state.clickedMoment, 'moment');
      }
    }
  }, [isClickedInside]);

  return (
    <div className="purchased-item">
      <div
        ref={containerEl}
        style={{ ...style, width: `${width}px`, minWidth: `${width}px` }}
        className="search-input"
      >
        <label className="purchased-item_input-label" onClick={() => setIsClickedInside(false)}>
          {label}
        </label>
        <div className="purchased-item_input-wrapper">
          <input
            ref={inputEl}
            value={state.inputValue}
            placeholder="Search"
            className="purchased-item_input"
            onClick={() => {
              dispatch({
                type: 'FOCUS',
              });
            }}
            onInput={(e) => {
              dispatch({
                type: 'TYPE',
                inputValue: e.target.value,
              });
              debounce(() => {
                searchRefetch();
              }, 500)();
            }}
          />
          {state.isFocused && (
            <img
              src={REMOVETEXT}
              alt="remove-text"
              className="remove-text-icon"
              onClick={() => {
                dispatch({ type: 'REMOVE_VALUE' });
                debounce(() => {
                  searchRefetch();
                }, 500)();
              }}
            />
          )}
          <img
            src={CHEVRON_DOWN}
            alt="drop-down"
            className={`chevron-down${state.isFocused ? ' reverse' : ''}`}
            onClick={() => {
              if (state.isFocused) {
                dispatch({
                  type: 'UNFOCUS',
                });
              } else {
                dispatch({
                  type: 'FOCUS',
                });
                inputEl.current.focus();
              }
            }}
          />
        </div>
        <OptionsDropdown
          isFocused={state.isFocused}
          isSearchRequestEnabled={state.isSearchRequestEnabled}
          queryKey={queryKey}
          parameters={parameters}
          setParametersState={setParametersState}
          reportsParameters={reportsParameters}
          clickedCategory={state.clickedCategory}
          clickedAnyMoment={state.clickedAnyMoment}
          handleCategoryClick={handleClickCategory}
          handleAnyMomentClick={handleClickAnyMoment}
          handleClickItemValueAll={handleClickItemValueAll}
          handleSelectValue={handleSelectValue}
          handleClickMomet={handleClickMomet}
          momentOptions={momentOptions}
          searchedValues={state.searchedValues}
          inputValue={state.inputValue}
          moment={state.clickedMoment}
        />
      </div>
    </div>
  );
};
export default PurchasedItemSearchInputRecommendation;
