import { useState, useEffect } from 'react';

export function useSnackActivation() {
  const [isShown, setIsShown] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [prevTimer, setPrevTimer] = useState(null);

  useEffect(() => {
    if (remainingTime === 0) {
      setIsShown(false);
    } else if (remainingTime > 0) {
      // 한번 initializ된 snack이 사라지기 전에 새로운 snack이 다시 initialize된 경우에는 기존의 timeout아 clear되어야 합니다.
      // 이전의 timer를 clearTimeout 해주지 않으면 그 timeout함수가 새로운 snack의 remainingTime state에 영향을 주기 때문입니다.
      clearTimeout(prevTimer);

      const timer = setTimeout(() => {
        setRemainingTime((prev) => prev - 1000);
      }, 1000);

      setPrevTimer(timer);
    }

    return () => {
      clearTimeout(prevTimer);
    };
  }, [remainingTime]);

  function initializeRemainingTime() {
    setIsShown(true);
    setRemainingTime(4000);
  }
  function hideSnack() {
    setIsShown(false);
  }

  return { isShown, initializeRemainingTime, hideSnack };
}
