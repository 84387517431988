import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next'
import PrimaryButton from 'components/buttons/PrimaryButton';
import {
  ERROR_FILLED,
  CHECK_FILLED,
  INPUT_VALID_MARK,
  EYE,
  EYE_OFF,
  X_ICON,
} from 'constants/images';
import { useNavigateRoute } from 'hooks';

export default function ChangePassword({
  error,
  handleInputPasswords,
  inputPasswords,
  handleChangePassword,
}) {
  const { t } = useTranslation()
  const handleNavigate = useNavigateRoute();
  const [isPasswordShown, setIsPasswordShown] = useState({
    current: false,
    new: false,
  });
  const [isPasswordValid, setIsPasswordValid] = useState({
    new: false,
    confirm: false,
  });
  const [newPasswordValidation, setNewPasswordValidation] = useState({
    minChar: false,
    oneUp: false,
    oneLow: false,
    oneSpecial: false,
  });
  useEffect(() => {
    const newPassword = inputPasswords.new;
    const confirmPassword = inputPasswords.confirm;

    if (newPassword) {
      setNewPasswordValidation({
        minChar: newPassword.match(/^.{8,}$/) !== null,
        oneUp: newPassword.match(/^(?=.*[A-Z]).{1,}$/) !== null,
        oneLow: newPassword.match(/^(?=.*?[a-z]).{1,}$/) !== null,
        oneSpecial:
          newPassword.match(/^(?=.*?[`\\/~!@#$%^&*()_|+\-=?;:'",.<>}{\][]).{1,}$/) !== null,
      });
      if (isPasswordValid.new) {
        setIsPasswordValid((prev) => ({
          ...prev,
          confirm: isPasswordValid.new && newPassword === confirmPassword,
        }));
      }
    }
  }, [inputPasswords]);

  useEffect(() => {
    setIsPasswordValid((prev) => ({
      ...prev,
      new:
        newPasswordValidation.minChar &&
        newPasswordValidation.oneUp &&
        newPasswordValidation.oneLow &&
        newPasswordValidation.oneSpecial,
    }));
  }, [newPasswordValidation]);

  return (<>
    <div className="title">{t("settings.change_password")}</div>
    <div className="boxes">
      <form autoComplete="off">
        <div className="input-box">
          <div className="label">{t('login.current_password')}</div>
          <div
            className={`input-wrapper ${
              error?.response.data.message === 'Bad request: Incorrect username or password.'
                ? 'invalid'
                : ''
            }`}
          >
            <input
              type={isPasswordShown.current ? 'text' : 'password'}
              className="input"
              placeholder={t('login.password')}
              value={inputPasswords.current || ''}
              onChange={handleInputPasswords('current')}
              autoComplete="new-password"
            />
            <img
              className="input-eye"
              src={isPasswordShown.current ? EYE : EYE_OFF}
              onClick={() => setIsPasswordShown((prev) => ({ ...prev, current: !prev.current }))}
            />
            {error?.response.data.message === 'Bad request: Incorrect username or password.' && (
              <img className="input-error" src={ERROR_FILLED} />
            )}
          </div>
          {error?.response.data.message === 'Bad request: Incorrect username or password.' && (
            <div className="input-error-message">{t("login.your_email_or_password_is")}</div>
          )}
        </div>
        <div className="input-box">
          <div className="label">{t("login.new_password")}</div>
          <div
            className={`input-wrapper ${
              inputPasswords.new && !isPasswordValid.new ? 'invalid' : ''
            }`}
          >
            <input
              type={isPasswordShown.new ? 'text' : 'password'}
              className="input"
              placeholder={t("login.enter_a_new_password")}
              value={inputPasswords.new || ''}
              onChange={handleInputPasswords('new')}
              autoComplete="new-password"
            />
            <img
              className="input-eye"
              src={isPasswordShown.new ? EYE : EYE_OFF}
              onClick={() => setIsPasswordShown((prev) => ({ ...prev, new: !prev.new }))}
            />
            {inputPasswords.new && (
              <img
                className="input-error"
                src={isPasswordValid.new ? INPUT_VALID_MARK : ERROR_FILLED}
              />
            )}
          </div>
          {inputPasswords.new && !isPasswordValid.new && (
            <div className="input-error-message">{t('login.password_doesnt_meet_the_guidelines')}</div>
          )}
          {inputPasswords.new && (
            <ul className="validation-list">
              <li>
                {newPasswordValidation.minChar ? (
                  <img src={CHECK_FILLED} />
                ) : (
                  <img src={X_ICON} />
                )}{' '}{t("login.must_have_a_minimum_of")}</li>
              <li>
                {newPasswordValidation.oneUp ? <img src={CHECK_FILLED} /> : <img src={X_ICON} />}{' '}{t("login.must_include_at_least_1_upper")}</li>
              <li>
                {newPasswordValidation.oneLow ? <img src={CHECK_FILLED} /> : <img src={X_ICON} />}{' '}{t("login.must_include_at_least_1_lower")}</li>
              <li>
                {newPasswordValidation.oneSpecial ? (
                  <img src={CHECK_FILLED} />
                ) : (
                  <img src={X_ICON} />
                )}{' '}{t("login.must_include_at_least_1_special")}</li>
            </ul>
          )}
        </div>
        <div className="input-box">
          <div className="label">{t("login.confirm_password")}</div>
          <div
            className={`input-wrapper ${
              inputPasswords.confirm && !isPasswordValid.confirm ? 'invalid' : ''
            }`}
          >
            <input
              type={'password'}
              className="input"
              value={inputPasswords.confirm || ''}
              onChange={handleInputPasswords('confirm')}
              autoComplete="new-password"
            />
            {inputPasswords.confirm && (
              <img
                className="input-error"
                src={isPasswordValid.confirm ? INPUT_VALID_MARK : ERROR_FILLED}
              />
            )}
          </div>
          {inputPasswords.confirm && !isPasswordValid.confirm && (
            <div className="input-error-message">{t("login.password_must_be_same")}</div>
          )}
        </div>
      </form>
    </div>
    <div className="boxes">
      <div className="forgot-password-box">
        <Trans
          i18nKey={"login.dont_remember_password"}
          components={{l:
            <button onClick={() =>
              handleNavigate('/forgot-password', {
                from: 'profile',
              })
            }/>
          }}
        />
      </div>
      <div className="button-box">
        <PrimaryButton
          value={t("settings.save")}
          onClick={handleChangePassword}
          disabled={!(inputPasswords.current && isPasswordValid.new && isPasswordValid.confirm)}
          style={{ width: '138px' }}
        />
      </div>
    </div>
  </>);
}
