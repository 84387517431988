import { axiosPrivate, axiosBase, customerAPI } from 'api';

export const getCurrentHandler = async ({ companyId, shopId }) => {
  const { data } = await axiosPrivate.post(customerAPI.MOSAIC, {
    company_id: companyId,
    shop_id: shopId
  });
  return data;
};
export const getHistoryHandler = async ({ companyId, shopId, dashboardId }) => {
  const { data } = await axiosPrivate.post(customerAPI.MOSAIC, {
    company_id: companyId,
    shop_id: shopId,
    dashboard_id: dashboardId
  });
  return data;
};
export const getCanidateHandler = async ({ companyId, shopId }) => {
  const { data } = await axiosPrivate.post(customerAPI.CANDIDATE, {
    company_id: companyId,
    shop_id: shopId
  });
  return data;
};

export const deleteChartByGroupHandler = async ({ companyId, shopId, chartGroup, dashboardId }) => {
  await axiosPrivate.post(customerAPI.DELETE_MOSAIC, {
    company_id: companyId,
    shop_id: shopId,
    chart_group: chartGroup,
    dashboard_id: dashboardId
  });
};
export const editChartByGroupHandler = async ({
  companyId,
  shopId,
  chartGroup,
  dashboardId,
  metadata
}) => {
  await axiosPrivate.post(customerAPI.EDIT_MOSAIC, {
    company_id: companyId,
    shop_id: shopId,
    chart_group: chartGroup,
    dashboard_id: dashboardId,
    metadata
  });
};
export const updateCandidateHandler = async ({ companyId, shopId }) => {
  await axiosPrivate.post(customerAPI.UPDATE_CANDIDATE, {
    company_id: companyId,
    shop_id: shopId
  });
};

export const getUploadURLHandler = async ({ companyId, shopId, location, files }) => {
  const { data } = await axiosPrivate.post(customerAPI.GENERATE_UPLOADING_URL, {
    company_id: companyId,
    shop_id: shopId,
    location,
    files
  });
  return Object.values(data)[0];
};

export const postFileHandler = async ({ url, file }) => {
  await axiosBase.put(url, new Blob([file]));
};

export const getFileSummaryHandler = async ({ companyId, shopId, file, location, type }) => {
  const { data } = await axiosPrivate.post(customerAPI.SUMMARY_UPLOADED_DATASET, {
    company_id: companyId,
    shop_id: shopId,
    file,
    location,
    type
  });
  return data;
};