import { LOADING } from 'constants/images';
import Styled from './styled';

export default function PrimaryButton({ value, onClick, disabled, style, isLoading = false }) {
  return (
    <Styled.Button
      style={{ ...style }}
      onClick={!disabled && !isLoading ? onClick : null}
      disabled={disabled}
      isLoading={isLoading}
    >
      {isLoading ? <Styled.Spinner src={LOADING} /> : value}
    </Styled.Button>
  );
}
