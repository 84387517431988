const Radio = ({ isChecked, onClick, value, disabled }) => {
  return (
    <div
      className={`radio ${isChecked ? 'checked' : ''}`}
      onClick={() => {
        if (disabled) return;
        onClick(value);
      }}
    >
      {isChecked && <div className="radio_circle" />}
    </div>
  );
};

export default Radio;
