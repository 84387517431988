import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDashboardContext, useUserInfoContext } from 'context';
import { fetcher, transformData } from './queryHelpers';
import { useLocationInfo } from 'hooks/useLocationInfo';

export default function useGetResult(feature, parameters = {}) {
  const {
    isRetention,
    isCohortAnalysis,
    isCrossselling,
    isForecasting,
    isReports,
    isUserBased,
    isFeaturebased
  } = useLocationInfo();
  const [dataCache, setDataCache] = useState(null);
  const { currency } = useDashboardContext();
  const { userInfo } = useUserInfoContext();
  const context = useQuery(
    [feature, parameters, userInfo?.company.shopId],
    ({ queryKey }) => fetcher[feature](queryKey, userInfo),
    {
      enabled: !!feature,
      select: (data) => transformData[feature](data, currency, parameters)
    }
  );

  useEffect(() => {
    if (context.data) {
      setDataCache(context.data);
    }
  }, [context.data]);

  useEffect(() => {
    if (
      isRetention ||
      isCohortAnalysis ||
      isCrossselling ||
      isForecasting ||
      isReports ||
      isUserBased ||
      isFeaturebased
    ) {
      setDataCache(null);
    }
  }, [userInfo?.company.shopId]);

  return {
    ...context,
    data: dataCache
  };
}
