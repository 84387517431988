import Styled from './styled';
import { useModalContent } from 'hooks';
import { MODAL_SPINNER } from 'constants/images';
import { useTranslation } from 'react-i18next';

const Modal = ({ content, setIsShown, confirmExecution, isGlobalStyle, isDelay, setIsDelay }) => {
  const { t } = useTranslation();
  const { inner, handleConfirmClick } = useModalContent(content, confirmExecution);
  const { title, cancelButton, confirmButton } = content;

  return (
    <Styled.Container isGlobalStyle={isGlobalStyle}>
      <Styled.Dimmed
        onClick={() => {
          if (isDelay) return;
          setIsShown(false);
        }}
      />
      <Styled.Modal>
        <Styled.ModalTitle>{title}</Styled.ModalTitle>

        {inner}

        <Styled.ModalButtons>
          <Styled.ModalCancelButton onClick={() => setIsShown(false)}>
            {cancelButton}
          </Styled.ModalCancelButton>
          <Styled.ModalConfirmButton
            onClick={() => {
              setIsDelay(true);
              handleConfirmClick(setIsDelay, setIsShown);
            }}
          >
            {confirmButton}
          </Styled.ModalConfirmButton>
        </Styled.ModalButtons>

        {isDelay && (
          <Styled.Delay>
            <Styled.DelayImage src={MODAL_SPINNER} />
            <Styled.DelayText>{t('please_wait')}</Styled.DelayText>
          </Styled.Delay>
        )}
      </Styled.Modal>
    </Styled.Container>
  );
};
export default Modal;
