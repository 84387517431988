const LOCALSTORAGE_KEYS = {
  STORE: 'store',
  NEW_MEMBER: 'newMember',
  IS_GUIDETOOLTIP_SHOWN: 'isGuideTooltipShown',
  IS_GOTOCHECKBUTTON_SHOWN: 'isGoToCheckButtonShown',
  REPORTS_PARAMETERS: 'reportsParameters',
  LANGUAGE: 'language'
};

export function setValueInLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getValueInLocalStorage(key, defaultValue) {
  return JSON.parse(localStorage.getItem(key)) ?? defaultValue
}

// overall dataset for the product
export function setStore(store) {
  localStorage.setItem(LOCALSTORAGE_KEYS.STORE, JSON.stringify(store));
}
export function getStore() {
  const storedToken = localStorage.getItem(LOCALSTORAGE_KEYS.STORE);
  return storedToken ? JSON.parse(storedToken) : null;
}
export function clearStore() {
  localStorage.removeItem(LOCALSTORAGE_KEYS.STORE);
}

// the newly registered member info
export function setNewMemberInfo(newMember) {
  localStorage.setItem(LOCALSTORAGE_KEYS.NEW_MEMBER, JSON.stringify(newMember));
}
export function getNewMemberInfo() {
  const storedToken = localStorage.getItem(LOCALSTORAGE_KEYS.NEW_MEMBER);
  return storedToken ? JSON.parse(storedToken) : null;
}
export function clearNewMemberInfo() {
  localStorage.removeItem(LOCALSTORAGE_KEYS.NEW_MEMBER);
}

// whether tooltip guide is once shown or not, no need to clear it unless user do it deliberately.
export function setIsGuideTooltipShown() {
  localStorage.setItem(LOCALSTORAGE_KEYS.IS_GUIDETOOLTIP_SHOWN, true);
}
export function getIsGuideTooltipShown() {
  const storedToken = localStorage.getItem(LOCALSTORAGE_KEYS.IS_GUIDETOOLTIP_SHOWN);
  return storedToken ? JSON.parse(storedToken) : null;
}

// whether "Go to Check" button on Segmentation is shown or not
export function setIsGoToCheckButtonShown() {
  localStorage.setItem(LOCALSTORAGE_KEYS.IS_GOTOCHECKBUTTON_SHOWN, true);
}

export function getIsGoToCheckButtonShown() {
  const storedValue = localStorage.getItem(LOCALSTORAGE_KEYS.IS_GOTOCHECKBUTTON_SHOWN);
  return storedValue ? JSON.parse(storedValue) : null;
}

// when link to Retention or Cohort from Reports, store values in the localStorage.
export function setReportsParameters(parameters) {
  localStorage.setItem(LOCALSTORAGE_KEYS.REPORTS_PARAMETERS, JSON.stringify(parameters));
}
export function getReportsParameters() {
  const storedReportsParameters = localStorage.getItem(LOCALSTORAGE_KEYS.REPORTS_PARAMETERS);
  return storedReportsParameters ? JSON.parse(storedReportsParameters) : null;
}
export function clearReportsParameters() {
  localStorage.removeItem(LOCALSTORAGE_KEYS.REPORTS_PARAMETERS);
}
