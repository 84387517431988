import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';

export function useD3(renderChartFn, dependencies) {
  const { t } = useTranslation();
  const ref = useRef();

  useEffect(() => {
    renderChartFn(d3.select(ref.current));
    return () => {};
  }, [...dependencies, t]);

  return ref;
}
