import { useTranslation } from 'react-i18next';
import { labelToTranslationKey } from 'utils';

export default function SegmentationTable({ title, data }) {
  const { t } = useTranslation();

  return (
    (<div className="segmentation-table-container">
      <div className="segmentation-table-title">{title}</div>
      <table className="segmentation-table">
        <thead>
          {title === t("segmentation.item_ranking") ? (
            <tr>
              <th className="item-table-th">{t("segmentation.ranking")}</th>
              {data.headers.map((d, i) => (
                <th className="item-table-th" key={`${d}${i}`}>
                  {t(`segmentation.${labelToTranslationKey(d)}`)}
                </th>
              ))}
            </tr>
          ) : (
            <tr>
              {data.headers.map((d, i) => (
                <th className="customers-table-th" key={`${d}${i}`}>
                  {t(`segmentation.${labelToTranslationKey(d)}`)}
                </th>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          {data.cells.map((r, i) => (
            <tr key={`row-${i}`}>
              {title === t("segmentation.item_ranking") ? (
                <>
                  <td>{i + 1}</td>
                  <td>{r.Item}</td>
                  <td>{r.Price}</td>
                  <td>{r.Transaction}</td>
                  <td>{r.Repurchase}</td>
                </>
              ) : (
                <>
                  <td>{r.Name}</td>
                  <td>{r.Transaction}</td>
                  <td>{r.Sale}</td>
                  <td>{r['Last Order']}</td>
                  <td>{r.Order}</td>
                  <td>{r.Frequency}</td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>)
  );
}
