import { NODATA } from 'constants/images';
import Styled from './styled';

const NoData = ({ main, sub }) => {
  return (
    <Styled.Container>
      <Styled.Image src={NODATA} />
      <Styled.Main>{main}</Styled.Main>
      <Styled.Sub>{sub}</Styled.Sub>
    </Styled.Container>
  );
};

export default NoData;
