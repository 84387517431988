import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FILE, CHECK_FILLED, ERROR_FILLED } from 'constants/images';

const DataCenterUpLoadingBox = ({
  title,
  description,
  setFiles,
  errorText,
  setErrorText,
  disabled,
}) => {
  const { t } = useTranslation()
  
  const [fileName, setFileName] = useState('');
  // const [helpText, setHelpText] = useState('');

  const isOverSize = (size) => {
    return size >= Math.pow(2, 30);
  };

  const isCorrectFormat = (type) => {
    return type === 'text/csv';
  };

  return (
    (<div className="DataCenterUpLoadingBox">
      <div className="DataCenterUpLoadingBox_title">{title}</div>
      <div className="DataCenterUpLoadingBox_description">{description}</div>
      {/* 인풋박스  버튼앞에 파일 괜찮으면 체크 아니면 빨간 느낌표 뜨게*/}
      <div className="DataCenterUpLoadingBox_filebox">
        <img src={FILE} className="file_img" />
        <p className="file_name">{fileName ? fileName : t("mosaic.empty")}</p>
        {!errorText && fileName && <img src={CHECK_FILLED} className="file_status" />}

        {/* 헬프텍스트에 따라 빨간에러 보여주기  */}
        {errorText && <img src={ERROR_FILLED} className="file_status" />}

        <label className="btn_label" htmlFor={title}>{t("data_center.choose_file")}</label>
        {/* <input type="file" id="file" placeholder="empty" /> */}
        <input
          disabled={disabled}
          className="btn_file"
          type="file"
          accept={'.csv'}
          onChange={(e) => {
            const file = e?.target?.files[0];

            if (isOverSize(file.size)) {
              setErrorText((prev) => ({ ...prev, [title.toLowerCase()]: 'overSize' }));
              return;
            }

            if (!isCorrectFormat(file.type)) {
              setErrorText((prev) => ({ ...prev, [title.toLowerCase()]: 'format' }));
              return;
            }
            setFiles((prev) => ({ ...prev, [title.toLowerCase()]: e.target.files[0] }));

            setFileName(e?.target?.files[0].name);
          }}
          id={title}
        />
      </div>
      {/* 헬프박스 나타낫다 안나타낫다 */}
      <div className="help_text_box">
        <div className={`help_text ${errorText === 'overSize' ? 'show' : ''}`}>{t("data_center.please_upload_a_file_that")}</div>
        <div className={`help_text ${errorText === 'format' ? 'show' : ''}`}>
          Please upload a file in csv format.
        </div>
        <div className={`help_text ${errorText === '400' ? 'show' : ''}`}>
          Please check the contents of the file again.
        </div>
        <div className={`help_text ${errorText === '500' ? 'show' : ''}`}>
          Please try again because of the server problem.
        </div>
      </div>
    </div>)
  );
};

export default DataCenterUpLoadingBox;
