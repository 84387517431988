const models = {
  overview() {
    return {
      dates: '',
      currency: '',
      summary: {
        transaction: '',
        lead: '',
        customer: {
          value: '',
          ltc: ''
        },
        segments: '',
        retention: '',
        ltv6: ''
      },
      salesChart: {
        monthly: [],
        yearly: []
      },
      newLeadToCustomerChart: {
        ltc: [],
        newLead: [],
        newCustomer: []
      },
      monthlyCustomersChart: {
        monthlyCustomers: []
      },
      monthlyTransactionChart: {
        transactionPerCustomer: [],
        transactionWithoutSignIn: [],
        transaction: []
      },
      categoryRankingsChart: {
        categoryRankings: []
      },
      locationRankingsChart: {
        locationRankings: []
      },
      itemRankingsTable: {
        export: '',
        itemRankings: {
          headers: [],
          rows: []
        }
      },
      customersTable: {
        export: '',
        customers: {
          headers: [],
          rows: []
        }
      },
      thirdMonthLtvChart: {
        thirdMonthLtv: []
      },
      sixthMonthLtvChart: {
        sixthMonthLtv: []
      },
      abTestingChart: {
        withMomentum: [],
        withoutMomentum: []
      }
    };
  },
  newSegmentation() {
    return {
      segments: [],
      colors: [],
      segmentNames: [],
      exportOverall: '',
      exportRankings: [],
      chart: {
        populationSalesAndTransactions: {
          last: {
            churn_excluded: {
              population: [],
              sales: {
                average: [],
                total: []
              },
              transactions: {
                average: [],
                total: []
              }
            },
            churn_included: {
              population: [],
              sales: {
                average: [],
                total: []
              },
              transactions: {
                average: [],
                total: []
              }
            }
          },
          current: {
            churn_excluded: {
              population: [],
              sales: {
                average: [],
                total: []
              },
              transactions: {
                average: [],
                total: []
              }
            },
            churn_included: {
              population: [],
              sales: {
                average: [],
                total: []
              },
              transactions: {
                average: [],
                total: []
              }
            }
          }
        },
        recencyFrequencyMonetaryValue: {
          updateDates: [],
          segments: [],
          recency: [],
          frequency: [],
          monetaryValue: []
        },
        numberOfCategoriesAndCustomers: {
          updateDates: [],
          segments: [],
          purchaseOnce: [],
          purchaseAllCategories: [],
          purchasedCategories: []
        }
      },
      tables: {}
    };
  },
  segmentation() {
    return {
      currency: '',
      segmentsNames: [],
      segmentSizeChart: {
        segmentSize: []
      },
      salesPerChart: {
        averageSalesPerCustomer: [],
        totalSalesPerSegment: []
      },
      transactionsPerChart: {
        averageTransactionsPerCustomer: [],
        totalTransactionsPerSegment: []
      },
      monthlyCustomersChart: {
        monthlyCustomers: []
      },
      monthlySalesChart: {
        monthlySales: []
      },
      ltvSixChart: {
        ltvSix: []
      },
      monthlyRetentionChart: {
        monthlyRetention: []
      },
      tables: []
    };
  },
  retention() {
    return {
      retentionRateChart: {
        retentionRate: []
      },
      waterfallChart: {
        waterfall: []
      },
      exportData: []
    };
  },
  ltv() {
    return {
      customerValueProgressChart: {
        customerValueProgress: []
      },
      changesOverTimeChart: {
        ltv6: [],
        ltv3: []
      },
      cohortAnalysisTable: {
        headers: [],
        cells: []
      }
    };
  },
  forecasting() {
    return {
      transactionForecastingChart: {
        transactionForecasting: []
      }
    };
  },
  crossSelling() {
    return {
      export: '',
      counts: '',
      crossSellingTable: {
        headers: [],
        rows: []
      }
    };
  },
  userBased() {
    return {
      export: '',
      counts: '',
      topRanking: {
        headers: [],
        cells: []
      },
      modelScore: {
        headers: [],
        cells: []
      },
      crmSimulation: {},
      userBasedTable: {
        headers: [],
        cells: []
      }
    };
  },
  featureBased() {
    return {
      export: '',
      counts: '',
      featureBasedTable: {
        headers: [],
        cells: []
      }
    };
  },
  report() {
    return {
      good: [],
      bad: []
    };
  },
  getExport() {
    return {
      list: []
    };
  },
  leadToCustomer() {
    return {
      leadToCustomerTable: {
        headers: [],
        cells: []
      }
    };
  }
};
export default models;
