import React from 'react';
import { useTranslation } from 'react-i18next';

const DataCenterAnalyzingModal = ({ setModalOpen, modalOpen }) => {
  const { t } = useTranslation()
  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    (<div className="DataCenterAnalyzingModalContainer">
      <div className="dim" />
      <div className={'DataCenterAnalyzingModal' + (modalOpen ? ' modalClear' : '')}>
        <div className="alert_message">
          <div className="title">{t("data_center.please_contact_us_to_cancel")}</div>
          <div className="sub">Email: ops@fridayslab.com</div>
        </div>
        <div className="cancel_box">
          <button className="cancel" onClick={closeModal}>{t("login.cancel")}</button>
        </div>
      </div>
    </div>)
  );
};

export default DataCenterAnalyzingModal;
