import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/buttons/PrimaryButton';
import MainHeader from 'components/MainHeader';
import Export from 'assets/icons/export.svg';
import info from 'assets/icons/info.svg';
import { usePageInit } from 'hooks';
import { useCustomerServiceMutation } from 'hooks/service';
import { mutationKeys, queryKeys } from 'constants/keys';
import { columnsForTopRanking, columnsForCustomers } from './utils';
import { exportCSV, nameExportFile } from 'utils';
import './UserBased.scss';
import { useUserInfoContext } from 'context';
import {
  ParametersSkeleton,
  ParameterSkeleton,
  TitleSkeleton,
  DynamicSkeleton
} from 'components/skeletons';
import { useQuery } from 'react-query';
import useGetResult from 'hooks/service/useGetCustomerService/useGetResult';
import { axiosPrivate, customerAPI } from 'api';
import { checkIsAdvanced } from 'hooks/service/useGetCustomerService/utils';
import SearchInput from 'components/inputs/SearchInput';
import PurchasedItemSearchInputRecommendation from 'components/inputs/PurchasedItemSearchInputRecommendation';
import TableUserBase from 'components/TableUserBase/TableUserBase';
import { CheckButton } from 'components/buttons';
import TableCustomers from 'components/TableCustomers/TableCustomers';
import Summary from './Summary';

function adjustPeriodParameter(
  periodOption,
  selectedIntervalId,
  previousPeriodId,
  previousPeriodValue
) {
  let id;
  let value;
  if (selectedIntervalId === 'year') {
    id = previousPeriodId;
    value = previousPeriodValue;
  }
  if (selectedIntervalId === 'quarter') {
    if (
      previousPeriodId === 'week' ||
      previousPeriodId === 'month' ||
      previousPeriodId === 'quarter'
    ) {
      id = previousPeriodId;
      value = previousPeriodValue;
    } else if (previousPeriodId === 'year') {
      id = periodOption.filter((v) => v.id === 'quarter')[0].id;
      value = periodOption.filter((v) => v.id === 'quarter')[0].value;
    }
  }
  if (selectedIntervalId === 'month') {
    if (previousPeriodId === 'week' || previousPeriodId === 'month') {
      id = previousPeriodId;
      value = previousPeriodValue;
    } else if (previousPeriodId === 'quarter' || previousPeriodId === 'year') {
      id = periodOption.filter((v) => v.id === 'month')[0].id;
      value = periodOption.filter((v) => v.id === 'month')[0].value;
    }
  }
  return { id, value };
}

const transformOptions = (data) => {
  const toBeReturn = {
    segment: data.segments.map((v) => ({ id: +v.id, value: v.name ? v.name : '' })),
    goal: [
      {
        id: true,
        value: 'conversion maximization'
      },
      {
        id: false,
        value: 'target maximization'
      }
    ],
    status: data.statuses.map((v) => ({ id: +v.id, value: v.name })),
    retention: data.intervals.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    outPut: [
      {
        id: false,
        value: 'category'
      },
      {
        id: true,
        value: 'item'
      }
    ],
    reduplicate: true,
    moment: [
      {
        id: -1,
        value: 'First Purchase'
      },
      {
        id: 0,
        value: 'Any Moment'
      },
      {
        id: 1,
        value: 'Last Purchase'
      }
    ],

    channel: data.channels.map((v) => ({ id: +v.id, value: v.name })),
    region: data.regions.map((v) => ({ id: +v.id, value: v.name }))
  };
  return toBeReturn;
};

const UserBased = () => {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();

  const [selectedParameters, setSelectedParameters] = useState(null);
  const [isGetResult, setIsGetResult] = useState(false);

  const { data: optionWithoutPurchasedItem } = useQuery(
    [queryKeys.OPTION.USER_BASED, 'options', userInfo?.company.shopId],
    async ({ queryKey }) => {
      const { data } = await axiosPrivate.post(customerAPI.OPTION, {
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        page: queryKey[0]
      });
      return data;
    },
    {
      select: (data) => transformOptions(data)
    }
  );

  const { data: result } = useGetResult(
    isGetResult ? checkIsAdvanced[queryKeys.SERVICE.USER_BASED]() : null,
    selectedParameters
  );

  const [logExport] = useCustomerServiceMutation({
    mutationKey: mutationKeys.CUSTOMER.LOG_EXPORT
  });
  function handleLogExport(params) {
    logExport(params);
  }

  const handleParameterGet = ({ label, interval }) => {
    if (label === 'purchasedItem') {
      return {
        category: selectedParameters.category,
        item: selectedParameters.item,
        anyMoment: selectedParameters.anyMoment
      };
    }

    return selectedParameters[label];
  };

  // selectedParameters 를 컨트롤해주는 핸드러
  const handleParameterSet = (selectedOption, label) => {
    let key = label === 'additional' ? 'region' : label;

    if (key === 'interval') {
      setSelectedParameters((prev) => ({
        ...prev,
        [key]: {
          id: selectedOption.id,
          value: selectedOption.value
        },
        retention: adjustPeriodParameter(
          optionWithoutPurchasedItem.retention,
          selectedOption.id,
          prev.retention.id,
          prev.retention.value
        )
      }));
    } else if (key === 'additional') {
      setSelectedParameters((prev) => ({
        ...prev,
        region: {
          id: selectedOption.id,
          value: selectedOption.value
        }
      }));
    } else {
      setSelectedParameters((prev) => ({
        ...prev,
        [key]: {
          id: selectedOption?.id,
          value: selectedOption?.value
        }
      }));
    }
  };

  const handleResultGet = () => {
    setIsGetResult(true);
  };

  const handleReduplicate = () => {
    setSelectedParameters((prev) => ({ ...prev, reduplicate: !prev.reduplicate }));
  };

  useEffect(() => {
    setIsGetResult(false);
  }, [result]);

  useEffect(() => {
    if (optionWithoutPurchasedItem) {
      setSelectedParameters((prev) => ({
        ...prev,

        segment: {
          id: optionWithoutPurchasedItem.segment.filter((v) => v.id === 0)[0].id,
          value: optionWithoutPurchasedItem.segment.filter((v) => v.id === 0)[0].value
        },
        goal: {
          id: optionWithoutPurchasedItem.goal.filter((v) => v.id === true)[0].id,
          value: optionWithoutPurchasedItem.goal.filter((v) => v.id === true)[0].value
        },
        status: {
          id: optionWithoutPurchasedItem.status.filter((v) => v.id === 0)[0].id,
          value: optionWithoutPurchasedItem.status.filter((v) => v.id === 0)[0].value
        },
        retention: {
          id: optionWithoutPurchasedItem.retention.filter((v) => v.id === 'month')[0].id,
          value: optionWithoutPurchasedItem.retention.filter((v) => v.id === 'month')[0].value
        },
        output: {
          id: optionWithoutPurchasedItem.outPut.filter((v) => v.id === false)[0].id,
          value: optionWithoutPurchasedItem.outPut.filter((v) => v.id === false)[0].value
        },
        region: {
          id: optionWithoutPurchasedItem.region.filter((v) => v.id === 0)[0].id,
          value: optionWithoutPurchasedItem.region.filter((v) => v.id === 0)[0].value
        },
        channel: {
          id: optionWithoutPurchasedItem.channel.filter((v) => v.id === 0)[0].id,
          value: optionWithoutPurchasedItem.channel.filter((v) => v.id === 0)[0].value
        },
        reduplicate: optionWithoutPurchasedItem.reduplicate,
        category: {},
        item: {},
        moment: {}
      }));

      setIsGetResult(true);
    }
  }, [optionWithoutPurchasedItem]);

  const handleExport = async (e, url) => {
    e.preventDefault();
    if (userInfo?.user.role === 'observer') return;

    await exportCSV(url, 'User-based Recommendation')
      .then(() => {
        handleLogExport({
          companyId: userInfo?.company.companyId,
          shopId: userInfo?.company.shopId,
          page: 'user_based',
          fileName: nameExportFile('User-based Recommendation'),
          options: url
        });
      })
      .catch((err) => {
        console.log('export failed');
        console.log(err);
      });
  };

  useEffect(() => {
    if (selectedParameters) {
      setSelectedParameters(null);
    }
  }, [userInfo?.company.shopId]);

  useEffect(() => {
    usePageInit();
  }, []);

  return (
    <div className="UserBased">
      <MainHeader main="User-Based" sub={t('user_based.check_who_will_buy_what')} />

      {!selectedParameters || !optionWithoutPurchasedItem ? (
        <ParametersSkeleton>
          <ParameterSkeleton />
          <ParameterSkeleton />
          <ParameterSkeleton />
          <ParameterSkeleton />
          <ParameterSkeleton />
          <ParameterSkeleton />
          <ParameterSkeleton />
          <ParameterSkeleton />
        </ParametersSkeleton>
      ) : (
        <div className="UserBased_search_box">
          <div className="search_input_row">
            <SearchInput
              label="segment"
              width="260"
              style={{ zIndex: 5 }}
              options={optionWithoutPurchasedItem.segment}
              parameter={handleParameterGet({ label: 'segment' })}
              setParameter={handleParameterSet}
            />
            <SearchInput
              label="goal"
              width="260"
              style={{ zIndex: 5 }}
              options={optionWithoutPurchasedItem.goal}
              parameter={handleParameterGet({ label: 'goal' })}
              setParameter={handleParameterSet}
            />
            <SearchInput
              label="status"
              width="260"
              style={{ zIndex: 5 }}
              options={optionWithoutPurchasedItem.status}
              parameter={handleParameterGet({ label: 'status' })}
              setParameter={handleParameterSet}
            />
            <SearchInput
              label="retention"
              width="260"
              style={{ zIndex: 5 }}
              options={optionWithoutPurchasedItem.retention}
              parameter={handleParameterGet({ label: 'retention' })}
              setParameter={handleParameterSet}
            />
            <SearchInput
              label="output"
              width="260"
              // style={{ zIndex: 5 }}
              options={optionWithoutPurchasedItem.outPut}
              parameter={handleParameterGet({ label: 'output' })}
              setParameter={handleParameterSet}
            />
            <SearchInput
              label="channel"
              width="260"
              // style={{ zIndex: 5 }}
              options={optionWithoutPurchasedItem.channel}
              parameter={handleParameterGet({ label: 'channel' })}
              setParameter={handleParameterSet}
            />
            <PurchasedItemSearchInputRecommendation
              label={t('options.purchased_item')}
              width="260"
              queryKey={queryKeys.OPTION.USER_BASED}
              momentOptions={optionWithoutPurchasedItem.moment}
              parameters={handleParameterGet({ label: 'purchasedItem' })}
              setParameter={handleParameterSet}
              setParametersState={setSelectedParameters} // temporarily
              // reportsParameters={reportsParameters} // temporarily
            />
            <SearchInput
              label="additional"
              width="260"
              // style={{ zIndex: 5 }}
              options={optionWithoutPurchasedItem.region}
              parameter={handleParameterGet({ label: 'region' })}
              setParameter={handleParameterSet}
            />
            <div>
              <CheckButton
                label={t('user_based.show_items_that_have_been')}
                checked={selectedParameters.reduplicate}
                handler={() => handleReduplicate()}
                // disabled={clickedCategory.id === 0 && parameters.item.id === 0}
              />
            </div>
          </div>

          <div className="button_layer">
            <PrimaryButton
              value={t('user_based.apply')}
              onClick={handleResultGet}
              style={{ width: '124px' }}
            />
          </div>
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
        <div
          className="UserBased_small_table_box"
          style={{ width: `784px`, height: `470px`, overflow: 'hidden' }}
        >
          {!result ? (
            <div className="overview-skeleton-container">
              <TitleSkeleton width={400} />
              <DynamicSkeleton width={700} height={30} />
              <DynamicSkeleton width={700} height={30} />
              <DynamicSkeleton width={700} height={30} />
              <DynamicSkeleton width={700} height={30} />
              <DynamicSkeleton width={700} height={30} />
            </div>
          ) : (
            <>
              <p className="UserBased_box_title">{t('user_based.top_ranking')}</p>
              <ul className="UserBased_top_ranking_descriptions">
                <li>{t('user_based.shows_the_number_of_customers')}</li>
                <li>{t('user_based.you_can_expand_crm_volume')}</li>
              </ul>
              <TableUserBase
                data={result.topRanking}
                columns={columnsForTopRanking}
                icons={[<img src={info} className="info_logo" key="[i]" />]}
                hasIconHeadrs={['Latest Transaction']}
                helpTexts={['Last date of transaction from data - last date of purchase']}
              />
            </>
          )}
        </div>
        <div className="UserBased_small_table_box" style={{ width: `344px`, height: `470px` }}>
          <Summary result={result} />
        </div>
      </div>
      <div className="UserBased_table_box" style={{ width: `1152px`, height: `607px` }}>
        {!result ? (
          <div className="overview-skeleton-container">
            <TitleSkeleton width={400} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
          </div>
        ) : (
          <>
            <p className="UserBased_box_title">
              {result?.counts?.toLocaleString()}
              {t('user_based.customers_found')}
            </p>
            <div style={{ margin: '16px' }}>
              <a className="UserBased_box_export" onClick={(e) => handleExport(e, result.export)}>
                <img src={Export} className="export_img" />
                {t('settings.export')}
              </a>
            </div>
            {/* </div> */}
            <TableCustomers
              data={result.userBasedTable}
              columns={columnsForCustomers}
              icons={[<img src={info} className="info_logo" key="[i]" />]}
              hasIconHeadrs={['Latest Transaction']}
              helpTexts={[t('feature_based.last_date_of_transaction')]}
            />
            <div className="csv_help_text">* {t('user_based.you_can_see_all_customers')}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserBased;
