import * as d3 from 'd3';
import { useD3 } from 'hooks';
import { numberWithCommas, formatNumber, extractMonth } from 'utils';
import './index.scss';

export default function OverviewMonthlyCustomersChart({
  title,
  chartId,
  data,
  svgWidth,
  svgHeight,
}) {
  function arc(r, sign) {
    return r ? `a${r * sign[0]},${r * sign[1]} 0 0 1 ${r * sign[2]},${r * sign[3]}` : '';
  }
  function roundedRect(x, y, width, height, r) {
    r = [
      Math.min(r[0], height, width),
      Math.min(r[1], height, width),
      Math.min(r[2], height, width),
      Math.min(r[3], height, width),
    ];

    return `M${x + r[0]},${y}h${width - r[0] - r[1]}${arc(r[1], [1, 1, 1, 1])}v${
      height - r[1] - r[2]
    }${arc(r[2], [1, 1, -1, 1])}h${-width + r[2] + r[3]}${arc(r[3], [1, 1, -1, -1])}v${
      -height + r[3] + r[0]
    }${arc(r[0], [1, 1, 1, -1])}z`;
  }
  const ref = useD3(
    (svg) => {
      document.getElementsByClassName(`overview-bar-chart ${chartId}`)[0].innerHTML = '';
      const prevTooltip = document.getElementsByClassName(`tooltip ${chartId}`)[0];
      if (prevTooltip) prevTooltip.remove();
      svg.attr('width', svgWidth).attr('height', svgHeight);

      const margin = { top: 20, right: 10, bottom: 20, left: 40 };
      const chartWidth = svgWidth,
        chartHeight = svgHeight - 30 - 25; // legend, year indicators
      const xAxis = svg.append('g').attr('class', `x-axis ${chartId}`);
      const yAxis = svg.append('g').attr('class', `y-axis ${chartId}`);
      const grid = svg.append('g').attr('class', `y-grid ${chartId}`);
      const bars = svg.append('g').attr('class', `bar-group ${chartId}`);
      const tooltip = d3
        .select(`.overview-bar-chart-container.${chartId}`)
        .append('div')
        .attr('class', `tooltip ${chartId}`)
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .call((div) => div.append('div').attr('class', 'x'))
        .call((div) =>
          div
            .append('div')
            .attr('class', 'y')
            .call((div) => div.append('span').attr('class', 'label'))
            .call((div) => div.append('span').attr('class', 'value'))
        );
      const yearIndicators = svg.append('g').attr('class', `year-indicators ${chartId}`);

      drawCharts();

      function drawCharts() {
        const D = data.map((v) => ({
          x: v.x,
          y: Math.round(v.y),
        }));
        const X = d3.map(D, (d) => d.x);
        const Y = d3.map(D, (d) => d.y);

        const xScale = d3
          .scaleBand()
          .domain(X)
          .range([margin.left, chartWidth - margin.right])
          .paddingInner(0.3)
          .paddingOuter(0.2)
          .align(0);
        xScale.invert = (() => {
          const domain = xScale.domain();
          const range = xScale.range();
          const invertedScale = d3.scaleQuantize().domain(range).range(domain);
          return (x) => invertedScale(x);
        })();
        const yScale = d3
          .scaleLinear()
          .domain([d3.min(Y), d3.max(Y)])
          .range([chartHeight - margin.bottom, margin.top])
          .nice();

        xAxis
          .attr('transform', `translate(0, ${chartHeight})`)
          .call(
            d3
              .axisBottom(xScale)
              .tickValues(xScale.domain().filter((d, i) => i % 3 == 0))
              .tickFormat((d) => extractMonth(d))
              .tickSizeOuter(0)
          )
          .select('.domain')
          .attr('stroke', '#DDDDDD');
        yAxis
          .attr('transform', `translate(${margin.left},0)`)
          .call(
            d3
              .axisLeft(yScale)
              .ticks(5)
              .tickFormat((v) => formatNumber(v))
          )
          .select('.domain')
          .remove();
        grid
          .attr('transform', `translate(${margin.left},0)`)
          .call(
            d3
              .axisLeft(yScale)
              .ticks(5, '~s')
              .tickSize(-(chartWidth - margin.right - margin.left))
              .tickFormat('')
          )
          .call((g) => g.select('path').attr('stroke', 'transparent'))
          .call((g) => g.selectAll('.tick line').attr('stroke', '#DDDDDD'));

        bars
          .selectAll('g')
          .data(D)
          .join('path')
          .attr('d', (d) =>
            roundedRect(
              xScale(d.x),
              yScale(d.y),
              xScale.bandwidth(),
              chartHeight - yScale(d.y),
              [2, 2, 0, 0]
            )
          )
          .attr('fill', '#4B43FF')
          .on('mouseover', () => {
            tooltip.style('visibility', 'visible');
          })
          .on('mousemove', (event, d) => {
            const tooltipX = tooltip.select('.x');
            const tooltipY = tooltip.select('.y');
            const xPosition = xScale(d.x);
            const [x, y] = d3.pointer(event);
            tooltipX.html(d.x);
            tooltipY.select('.label').html(numberWithCommas(d.y));
            tooltip
              .style(
                'left',
                x -
                  d3.select('.tooltip.mc').node().getBoundingClientRect().width / 2 +
                  //   xPosition
                  'px'
              )
              .style('top', y - 30 + 'px');
          })
          .on('mouseout', () => tooltip.style('visibility', 'hidden'));
        const yearIndicatorsD = X.reduce((acc, cur, idx) => {
          const year = cur.slice(0, 4);
          const yearMatchedObj = acc.filter((e) => e.year === year);
          if (yearMatchedObj.length === 0) {
            acc.push({
              year: year,
              firstIdx: idx,
              lastIdx: idx,
            });
          }
          acc.forEach((e) => {
            if (e.year === year) {
              e.lastIdx = idx;
              return;
            }
          });
          return acc;
        }, []);
        yearIndicatorsD.forEach((v, i) => {
          yearIndicators
            .append('text')
            .attr('class', 'year-indicator')
            .text(v.year)
            .attr('x', margin.left + xScale.step() * v.firstIdx)
            .attr('y', chartHeight + margin.top + 20);
        });
      }
    },
    [data, svgWidth, svgHeight]
  );
  return (
    <div className={`overview-bar-chart-container ${chartId}`}>
      <div className={`overview-bar-chart-title ${chartId}`}>{title}</div>
      <svg className={`overview-bar-chart ${chartId}`} ref={ref}></svg>
    </div>
  );
}
