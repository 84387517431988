import { useEffect } from 'react';
import { createContext, useState, useContext } from 'react';
import { createPortal } from 'react-dom';
import { Outlet } from 'react-router-dom';
import Toast from 'components/Toast';

export const GlobalToastContext = createContext({});

export const GlobalToastProvider = ({ children }) => {
  const [isShown, setIsShown] = useState(false);
  const [message, setMessage] = useState(null);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (isShown) {
      const timer = setTimeout(() => {
        setIsShown(false);
      }, 4000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isShown]);

  function toast({ response = 'error', errorMessage = null }) {
    setMessage(errorMessage);
    setResponse(response);
    setIsShown(true);
  }

  return (
    <GlobalToastContext.Provider value={toast}>
      { children }
      {isShown &&
        createPortal(
          <Toast response={response} message={message} setIsShown={setIsShown} />,
          document.getElementById('toast')
        )}
    </GlobalToastContext.Provider>
  );
};
export const useGlobalToast = () => {
  return useContext(GlobalToastContext);
};
