import { axiosPrivate, customerAPI } from 'api';

export const getItemJourneyHandler = async ({
  company_id,
  shop_id,
  segment_id,
  category,
  recent_1year,
  best_journey,
  head
}) => {
  const { data } = await axiosPrivate.post(customerAPI.ITEM_JOURNEY, {
    company_id,
    shop_id,
    segment_id,
    category,
    recent_1year,
    best_journey,
    head
  });
  return data;
};
