import { useEffect, useState } from 'react';
import { useDashboardContext, useGlobalModal } from 'context';
import { Outlet } from 'react-router-dom';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import FullLayoutError from './FullLayoutError';
import NotAnalyzed from 'components/emotion/NotAnalyzed';
import DashboardIsComing from 'components/emotion/DashboardIsComing';
import './index.scss';
import { NOT_ANALYZED, ANALYSIS_COMPLETED,ANALYSIS_CANCELLED } from 'constants';
import { useTranslation } from 'react-i18next';

export default function FullLayout() {
  const { t } = useTranslation();
  const {
    isSidebarMinimized,
    setIsSidebarMinimized,
    handleShopSelect,
    latestAnalysisStatus,
    shopId,
    isFirstDashboard
  } = useDashboardContext();
  const { modal } = useGlobalModal();

  const [isSidebarHovered, setIsSidebarHovered] = useState(false);

  function handleMinimize() {
    setIsSidebarMinimized((prev) => !prev);
    setIsSidebarHovered(false);
  }
  function handleHover(isEntered) {
    setIsSidebarHovered(isEntered);
  }

  let outlet = <Outlet />;
  let sidebar = (
    <Sidebar
      isMinimized={isSidebarMinimized}
      isSidebarHovered={isSidebarHovered}
      handleMinimize={handleMinimize}
      handleHover={handleHover}
    />
  );

  if (latestAnalysisStatus === NOT_ANALYZED) {
    outlet = <NotAnalyzed />;
    sidebar = null;
  } else if (isFirstDashboard && latestAnalysisStatus !== ANALYSIS_COMPLETED) {
    outlet = <DashboardIsComing />;
    sidebar = null;
  }

  useEffect(() => {
    if (shopId) {
      if (
        !isFirstDashboard &&
        latestAnalysisStatus !== NOT_ANALYZED &&
        latestAnalysisStatus !== ANALYSIS_COMPLETED &&
        latestAnalysisStatus !== ANALYSIS_CANCELLED
      ) {
        modal({
          content: {
            title: t('error.additional_dashboard_being_generated'),
            description: t('error.update_old_dashboard_to_new'),
            confirmButton: t('error.additional_generated_confirm')
          },
          onConfirm: {
            fn: () => {},
            parameters: ''
          },
          isGlobal: true
        });
      }
    }
  }, [isFirstDashboard, latestAnalysisStatus, shopId]);

  return (
    <div className="full-layout-container">
      <Header
        moveLeft={isSidebarMinimized && !isSidebarHovered && latestAnalysisStatus !== NOT_ANALYZED}
        handleShopSelect={handleShopSelect}
      />
      {sidebar}
      <main
        className={
          'layout-main' +
          (isSidebarMinimized ? ' minimized' : '') +
          (latestAnalysisStatus === NOT_ANALYZED ||
          (isFirstDashboard && latestAnalysisStatus !== ANALYSIS_COMPLETED)
            ? ' full'
            : '')
        }
      >
        <div className="main-contents">
          <FullLayoutError>{latestAnalysisStatus && outlet}</FullLayoutError>
        </div>
      </main>
      <Footer isSidebarOpen={!isSidebarMinimized && latestAnalysisStatus !== NOT_ANALYZED} />
    </div>
  );
}
