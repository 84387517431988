import './index.scss';

export default function PrimaryButton({ value, onClick, disabled, style }) {
  return (
    <button
      className={`primary-button ${disabled ? 'disabled' : ''}`}
      style={{ ...style }}
      onClick={
        disabled
          ? () => {
              return;
            }
          : onClick
      }
    >
      {value}
    </button>
  );
}
