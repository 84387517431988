import Styled from './styled';
import Description from '../Description';
import Uploader from '../Uploader';
import Examples from '../Examples';

const PrepareFile = () => {
  return (
    <Styled.Container>
      <Description />
      <Uploader />
      <Examples />
    </Styled.Container>
  );
};
export default PrepareFile;
