import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import './index.scss';

export default function BaseLayout() {
  return (
    <div className="base-layout-container">
      <main className="layout-main">
        <div className="main-contents">
          <Outlet />
        </div>
      </main>
      <Footer isFixed />
    </div>
  );
}
