import { theme } from 'styles@emotion/theme';

export const MONTH = 'month';
export const QUARTER = 'quarter';
export const YEAR = 'year';

export const MOSAIC = 'mosaic';
export const ITEM_JOURNEY = 'item_journey';

export const CHART_COLORS = [
  theme.color.neonBlue500,
  theme.color.aquaBlue500,
  theme.color.neonPurple500,
  theme.color.neonPink500,
  theme.color.neonOrange500,
  theme.color.neonYellow500
];
export const CHART_COLORS_LIGHT = [
  theme.color.neonBlue300,
  theme.color.aquaBlue300,
  theme.color.neonPurple300,
  theme.color.neonPink300,
  theme.color.neonOrange300,
  theme.color.neonYellow300
];

export const CHART_COLORS_BOOLEAN = [theme.color.neonBlue500, theme.color.gray300];

// in the case a shop hasn't analyzed any data yet.
export const NOT_ANALYZED = 'not-analyzed';
export const ANALYSIS_INITIAL = 'initial';
export const ANALYSIS_STARTED = 'started';
export const ANALYSIS_COMPLETED = 'completed';
export const ANALYSIS_CANCELLED = 'cancelled';

export const CANDIDATE_STATUS = {
  INITIAL: 'initial',
  UPDATED: 'updated',
  UPDATING: 'updating',
  NOT_UPDATED: 'not_updated'
};
export const SELECT_SIZE = {
  DEFAULT: 'default',
  SMALL: 'small'
};
