import { accountAPI, authAPI, axiosPrivate } from 'api';

export const mutationFns = {
  async editProfile({ id, authority, team, userName, firstName, lastName, phoneNumber }) {
    const inputProfile = { employee: { user: {} } };

    if (id) inputProfile.employee.id = id;
    if (userName) {
      inputProfile.employee.username = userName;
      inputProfile.employee.user.username = userName;
    }
    if (authority) inputProfile.employee.roles = [authority];
    if (team) inputProfile.employee.team = team;
    if (firstName) {
      if (!inputProfile.employee.user) inputProfile.employee.user = {};
      inputProfile.employee.user.given_name = firstName;
    }
    if (lastName) {
      if (!inputProfile.employee.user) inputProfile.employee.user = {};
      inputProfile.employee.user.family_name = lastName;
    }
    if (phoneNumber) {
      if (!inputProfile.employee.user) inputProfile.employee.user = {};
      inputProfile.employee.user.phone = phoneNumber;
    }

    await axiosPrivate.post(accountAPI.EDIT_PROFILE, inputProfile);
  },
  async changePassword({ accessToken, userName, currentPassword, newPassword }) {
    await axiosPrivate.post(authAPI.CHANGE_PASSWORD, {
      user: { username: userName, password: currentPassword },
      access_token: accessToken,
      new_password: newPassword,
    });
  },
  async createUser({ userName, email, companyDomain }) {
    await axiosPrivate.post(authAPI.CREATE_USER, {
      user: {
        username: userName,
        primary_email: email,
        family_name: userName,
        given_name: userName,
        organization: companyDomain,
      },
    });
  },
  async assignEmployee({ companyId, userName }) {
    const { data } = await axiosPrivate.post(accountAPI.ASSIGN_EMPLOYEE, {
      company_id: companyId,
      username: userName,
    });
    return data;
  },
  async deactivateUser({ userName }) {
    await axiosPrivate.post(accountAPI.DEACTIVATE_USER, {
      username: userName,
    });
  },
  async updateEmployeeRole({ id, authority, companyId }) {
    await axiosPrivate.post(accountAPI.UPDATE_EMPLOYEE_ROLE, {
      employee: { id: id, roles: [authority], company: { id: companyId } },
    });
  },
  async updateOrganization({ companyId, phone, address, fax }) {
    const inputCompany = { company: {} };
    inputCompany.company.id = companyId;
    // if (companyName) inputCompany.company.korean_name = companyName;
    if (phone) inputCompany.company.phone = phone;
    if (address) inputCompany.company.address = address;
    if (fax) inputCompany.company.fax = fax;

    await axiosPrivate.post(accountAPI.UPDATE_ORGANIZATION, inputCompany);
  },
};
