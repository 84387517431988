import { createContext, useState, useContext, useEffect } from 'react';
import { clearStore, getStore, setStore } from 'utils/userStorage';
import { analyzeAPI, axiosPrivate } from 'api';
import { NOT_ANALYZED } from 'constants';
/**
 * This context contains userInfo which is exactly same with the one in localStorage.
 * Most of the time, the necessary user-related values are used from this context, not from the localStorage one.
 * "userInfo" in this context and "store" in localStorage should be separated following their own role and requirement.
 * And the values they have should be differentiated as well.
 *
 * (wip)
 * localStorage should contain: token, currency, ...
 * UserInfoContext should contain: rest of the user-related values
 */
export const UserInfoContext = createContext({});

export const UserInfoProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(getStore());

  useEffect(async () => {
    const initialStore = {
      ...userInfo,
      company: {
        ...userInfo?.company
      },
      dashboard: {
        ...userInfo?.dashboard
      },
      token: {
        ...userInfo?.token
      },
      user: {
        ...userInfo?.user
      }
    };
    try {
      const { data: dashboardData } = await axiosPrivate.post(analyzeAPI.DASHBOARD, {
        company_id: userInfo.company.companyId,
        shop_id: userInfo.company.shopId,
        last: 5
      });

      if (dashboardData.result.length !== 0) {
        const validDashboard = dashboardData.result.find((dashboard) => dashboard.valid);

        if (!validDashboard) {
          initialStore.dashboard.currency = null;
          initialStore.dashboard.createdAt = '';
        } else {
          initialStore.dashboard.currency = validDashboard.currency;
          initialStore.dashboard.createdAt = validDashboard.created_at;
        }

        initialStore.dashboard.isFirstDashboard = dashboardData.result.length === 1 ? true : false;
        initialStore.dashboard.latestAnalysisStatus = dashboardData.result[0].status;
      } else if (dashboardData.result.length === 0) {
        initialStore.dashboard.currency = null;
        initialStore.dashboard.createdAt = '';

        initialStore.dashboard.isFirstDashboard = null;
        initialStore.dashboard.latestAnalysisStatus = NOT_ANALYZED;
      }

      setStore(initialStore);
      setUserInfo(initialStore);
    } catch (e) {
      clearStore();
      setUserInfo(null);
    }
  }, []);

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
};
export const useUserInfoContext = () => useContext(UserInfoContext);
