import { createContext, useContext } from 'react';

export const GoogleIdentityContext = createContext({});

export const GoogleIdentityProvider = ({ children }) => {
  const initClient = (mutate) => {
    const client = window.google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: 'email openid profile',
      ux_mode: 'popup',
      callback: (response) => {
        mutate({ password: response.code });
      }
    });
    client.requestCode();
  };

  return (
    <GoogleIdentityContext.Provider value={{ initClient }}>
      {children}
    </GoogleIdentityContext.Provider>
  );
};

export const useGoogleIdentityContext = () => {
  return useContext(GoogleIdentityContext);
};
