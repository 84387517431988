import { useState } from 'react';
import ChartSVG from 'components/charts/ChartSVG';
import { chartKeys } from 'constants/keys';

const COLORS = ['#4B43FF', '#00DCFF', '#A12AFF', '#FE59F7', '#FFB800', '#FD6A00', '#18D1A0'];
const ComparisonMultiline = ({ data, segments }) => {
  const [isLegendSelect, setIsLegendSelect] = useState(
    segments.reduce((acc, cur) => {
      acc[cur.id] = true;
      return acc;
    }, {})
  );

  const handleLegendClick = (segmentId) => (e) => {
    e.preventDefault();
    const isOneTrue = Object.values(isLegendSelect).filter((v) => v).length < 2;
    if (isOneTrue && isLegendSelect[segmentId]) return;
    setIsLegendSelect((prev) => ({
      ...prev,
      [segmentId]: !prev[segmentId],
    }));
  };

  return (
    <>
      <div className="comparison-multiline-legends">
        {segments.map((seg, i) => {
          return (
            <div
              key={`${seg.id}`}
              className={`comparison-multiline-legends_item ${
                !isLegendSelect[seg.id] ? 'off' : ''
              }`}
              onClick={handleLegendClick(seg.id)}
            >
              <div
                className="comparison-multiline-legends_color"
                style={{ backgroundColor: COLORS[i] }}
              />
              {seg.value}
            </div>
          );
        })}
      </div>
      <ChartSVG
        chartInfo={{
          data: data,
          chartId: chartKeys.COMPARISON,
          options: {
            isLegendSelect,
          },
        }}
      />
    </>
  );
};

export default ComparisonMultiline;
