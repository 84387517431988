import './index.scss';

export function CheckButton({ label, checked, disabled = false, handler = () => {} }) {
  return (
    <label className="legend-checkbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={() => {
          if (disabled) return;
          handler();
        }}
      />
      <span className={`legend-checkbox_checkmark ${disabled ? 'disable' : ''}`}></span>
      <div className={`legend-checkbox_label ${disabled ? 'disable' : ''}`}>{label}</div>
    </label>
  );
}
