import { analyzeAPI, axiosPrivate, customerAPI } from 'api';
import { getStore } from 'utils/userStorage';

export const queryFns = {
  async postCsv({ type, file }) {
    const company_id = getStore().company.companyId;
    const shop_id = getStore().company.shopId;

    const { data } = await axiosPrivate.post(
      `${analyzeAPI.UPLOAD}/${company_id}/${shop_id}/${type}`,
      new Blob([file]),
      {
        headers: {
          'Content-Type': 'text/csv',
        },
      }
    );

    return data;
  },

  async postDashBoard() {
    const { data } = await axiosPrivate.post(analyzeAPI.DASHBOARD, {
      company_id: getStore().company.companyId,
      shop_id: getStore().company.shopId,
    });

    return data;
  },

  async generateUploadingUrl({ companyId, shopId }) {
    const { data } = await axiosPrivate.post(customerAPI.GENERATE_UPLOADING_URL, {
      company_id: companyId,
      shop_id: shopId,
      location: 'datacenter',
    });
    return data;
  },

  async summaryUploadedDataset({ companyId, shopId, fileType }) {
    const { data } = await axiosPrivate.post(customerAPI.SUMMARY_UPLOADED_DATASET, {
      company_id: companyId,
      shop_id: shopId,
      location: 'datacenter',
      file: fileType,
    });
    return data;
  },
};
