import { createContext, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import Modal from 'components/emotion/Modal';

export const GlobalModalContext = createContext({});

export const GlobalModalProvider = ({ children }) => {
  const [isDelay, setIsDelay] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [content, setContent] = useState(null);
  const [confirmExecution, setConfirmExecution] = useState(null);
  const [isGlobalStyle, setIsGlobalStyle] = useState(false);

  function modal({ content = null, onConfirm = null, isGlobal = false }) {
    setIsShown(true);
    setContent(content);
    setConfirmExecution(onConfirm);
    setIsGlobalStyle(isGlobal);
  }

  return (
    <GlobalModalContext.Provider value={{ modal }}>
      {children}
      {isShown &&
        createPortal(
          <Modal
            content={content}
            setIsShown={setIsShown}
            confirmExecution={confirmExecution}
            isGlobalStyle={isGlobalStyle}
            isDelay={isDelay}
            setIsDelay={setIsDelay}
          />,
          document.getElementById('modal')
        )}
    </GlobalModalContext.Provider>
  );
};

export const useGlobalModal = () => {
  return useContext(GlobalModalContext);
};
