import styled from '@emotion/styled';
import { theme } from 'styles@emotion/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Image = styled.img``;

const Main = styled.p`
  margin: 0;
  margin-top: 26px;
  color: $gray-900;
  ${theme.font.h5}
`;

const Sub = styled.p`
  margin: 0;
  margin-top: 8px;
  color: $gray-900;
  ${theme.font.strong}
`;

export default {
  Container,
  Image,
  Main,
  Sub
};
