import { queryKeys } from 'constants/keys';

export const initialParameters = {
  forecasting: {
    segment: {
      id: 0,
      value: ''
    },
    item: {
      id: 0,
      value: ''
    }
  },
  cohort: {
    interval: {
      id: '',
      value: ''
    },
    monthlyFrom: {
      id: '',
      value: ''
    },
    monthlyTo: {
      id: '',
      value: ''
    },
    quarterlyFrom: {
      id: '',
      value: ''
    },
    quarterlyTo: {
      id: '',
      value: ''
    },
    yearlyFrom: {
      id: '',
      value: ''
    },
    yearlyTo: {
      id: '',
      value: ''
    },
    segment: {
      id: 0,
      value: ''
    },
    period: {
      id: '',
      value: ''
    },
    channel: {
      id: 0,
      value: ''
    },
    item: {
      id: 0,
      value: ''
    },
    trackFirstItemOnly: {
      id: false,
      value: ''
    },
    category: {
      id: 0,
      value: ''
    },
    region: {
      id: 0,
      value: ''
    },
    anyMoment: {
      id: 0,
      value: ''
    }
  },
  userBased: {
    segment: {
      id: 0,
      value: ''
    },
    status: {
      id: 0,
      value: ''
    },
    retention: {
      id: '',
      value: ''
    },
    moment: {
      id: 0,
      value: ''
    },
    reduplicate: {
      id: false,
      value: ''
    },
    channel: {
      id: 0,
      value: ''
    },
    region: {
      id: 0,
      value: ''
    },
    isItem: {
      id: false,
      value: ''
    },
    goal: {
      id: false,
      value: ''
    },
    category: {
      id: 0,
      value: ''
    }
  },
  featureBased: {
    region: {
      id: 0,
      value: ''
    },
    category: {
      id: 0,
      value: ''
    }
  },
  leadToCustomer: {
    period: {
      id: '',
      value: ''
    },
    monthlyFrom: {
      id: '',
      value: ''
    },
    monthlyTo: {
      id: '',
      value: ''
    },
    segment: {
      id: 0,
      value: ''
    }
  }
};

export function selectInitialParametersLocation(
  service,
  optionData,
  setParameters,
  reportsParameters
) {
  if (service === queryKeys.SERVICE.COHORT_BASIC || service === queryKeys.SERVICE.COHORT_ADVANCED) {
    setParameters((prev) => ({
      ...prev,
      interval: {
        id: reportsParameters.interval,
        value: optionData.interval.filter((v) => v.id === reportsParameters.interval)[0].value
      },
      monthlyFrom: {
        id: reportsParameters.monthlyFrom,
        value: reportsParameters.monthlyFrom
      },
      monthlyTo: {
        id: reportsParameters.monthlyTo,
        value: reportsParameters.monthlyTo
      },
      quarterlyFrom: {
        id: optionData.quarterlyFrom[0].id,
        value: optionData.quarterlyFrom[0].value
      },
      quarterlyTo: {
        id: optionData.quarterlyTo[optionData.quarterlyTo.length - 1].id,
        value: optionData.quarterlyTo[optionData.quarterlyTo.length - 1].value
      },
      yearlyFrom: {
        id: optionData.yearlyFrom[0].id,
        value: optionData.yearlyFrom[0].value
      },
      yearlyTo: {
        id: optionData.yearlyTo[optionData.yearlyTo.length - 1].id,
        value: optionData.yearlyTo[optionData.yearlyTo.length - 1].value
      },
      segment: {
        id: reportsParameters.segment,
        value: optionData.segment.filter((v) => v.id === reportsParameters.segment)[0].value
      },
      period: {
        id: reportsParameters.period,
        value: optionData.period.filter((v) => v.id === reportsParameters.period)[0].value
      },
      channel: {
        id: reportsParameters.channel,
        value: optionData.channel.filter((v) => v.id === reportsParameters.channel)[0].value
      },
      item: {
        id: reportsParameters.item,
        value: optionData.item.filter((v) => v.id === reportsParameters.item)[0].value
      },
      trackFirstItemOnly: {
        id: reportsParameters.trackFirstItemOnly,
        value: optionData.trackFirstItemOnly.filter(
          (v) => v.id === reportsParameters.trackFirstItemOnly
        )[0].value
      },
      category: {
        id: reportsParameters.category,
        value: optionData.channel.filter((v) => v.id === 0)[0].value
      },
      region: {
        id: reportsParameters.region,
        value: optionData.region.filter((v) => v.id === 0)[0].value
      },
      anyMoment: {
        id: reportsParameters.anyMoment,
        value: optionData.anyMoment.filter((v) => v.id === false)[0].value
      }
    }));
  }
}

export function selectInitialParameters(service, optionData, setParameters) {
  if (service === queryKeys.SERVICE.FORECASTING) {
    setParameters((prev) => ({
      ...prev,
      segment: {
        id: optionData.segment.filter((v) => v.id === 0)[0].id,
        value: optionData.segment.filter((v) => v.id === 0)[0].value
      },
      item: {
        id: 0,
        value: ''
      }
      // item: {
      //   id: optionData.item.filter((v) => v.id === 0)[0].id,
      //   value: optionData.item.filter((v) => v.id === 0)[0].value,
      // },
    }));
  }
  if (service === queryKeys.SERVICE.COHORT_BASIC || service === queryKeys.SERVICE.COHORT_ADVANCED) {
    setParameters((prev) => ({
      ...prev,
      interval: {
        id: optionData.interval.filter((v) => v.id === 'month')[0].id,
        value: optionData.interval.filter((v) => v.id === 'month')[0].value
      },
      monthlyFrom: {
        id:
          optionData.monthlyFrom.length > 24
            ? optionData.monthlyFrom[optionData.monthlyFrom.length - 24].id
            : optionData.monthlyFrom[0].id,
        value:
          optionData.monthlyFrom.length > 24
            ? optionData.monthlyFrom[optionData.monthlyFrom.length - 24].value
            : optionData.monthlyFrom[0].value
      },
      monthlyTo: {
        id: optionData.monthlyTo[optionData.monthlyTo.length - 1].id,
        value: optionData.monthlyTo[optionData.monthlyTo.length - 1].value
      },
      quarterlyFrom: {
        id: optionData.quarterlyFrom[0].id,
        value: optionData.quarterlyFrom[0].value
      },
      quarterlyTo: {
        id: optionData.quarterlyTo[optionData.quarterlyTo.length - 1].id,
        value: optionData.quarterlyTo[optionData.quarterlyTo.length - 1].value
      },
      yearlyFrom: {
        id: optionData.yearlyFrom[0].id,
        value: optionData.yearlyFrom[0].value
      },
      yearlyTo: {
        id: optionData.yearlyTo[optionData.yearlyTo.length - 1].id,
        value: optionData.yearlyTo[optionData.yearlyTo.length - 1].value
      },
      segment: {
        id: optionData.segment.filter((v) => v.id === 0)[0].id,
        value: optionData.segment.filter((v) => v.id === 0)[0].value
      },
      period: {
        id: optionData.period.filter((v) => v.id === 'month')[0].id,
        value: optionData.period.filter((v) => v.id === 'month')[0].value
      },
      channel: {
        id: optionData.channel.filter((v) => v.id === 0)[0].id,
        value: optionData.channel.filter((v) => v.id === 0)[0].value
      },
      item: {
        id: optionData.item.filter((v) => v.id === 0)[0].id,
        value: optionData.item.filter((v) => v.id === 0)[0].value
      },
      trackFirstItemOnly: {
        id: optionData.trackFirstItemOnly.filter((v) => v.id === false)[0].id,
        value: optionData.trackFirstItemOnly.filter((v) => v.id === false)[0].value
      },
      category: {
        id: optionData.category.filter((v) => v.id === 0)[0].id,
        value: optionData.channel.filter((v) => v.id === 0)[0].value
      },
      region: {
        id: optionData.region.filter((v) => v.id === 0)[0].id,
        value: optionData.region.filter((v) => v.id === 0)[0].value
      },
      anyMoment: {
        id: optionData.anyMoment.filter((v) => v.id === false)[0].id,
        value: optionData.anyMoment.filter((v) => v.id === false)[0].value
      }
    }));
  }
  if (service === queryKeys.SERVICE.USER_BASED) {
    setParameters((prev) => ({
      ...prev,
      segment: {
        id: optionData.segment.filter((v) => v.id === 0)[0].id,
        value: optionData.segment.filter((v) => v.id === 0)[0].value
      },
      status: {
        id: optionData.status.filter((v) => v.id === 0)[0].id,
        value: optionData.status.filter((v) => v.id === 0)[0].value
      },
      retention: {
        id: optionData.retention.filter((v) => v.id === 'month')[0].id,
        value: optionData.retention.filter((v) => v.id === 'month')[0].value
      },
      channel: {
        id: optionData.channel.filter((v) => v.id === 0)[0].id,
        value: optionData.channel.filter((v) => v.id === 0)[0].value
      },
      region: {
        id: optionData.region.filter((v) => v.id === 0)[0]?.id,
        value: optionData.region.filter((v) => v.id === 0)[0]?.value
      }
      // reduplicate: {
      //   id: optionData.reduplicate,
      //   value: optionData.reduplicate.filter((v) => v.id === optionData.reduplicate)[0].value,
      // },
      // population: {
      //   id: optionData.population.filter((v) => v.id === 0)[0].id,
      //   value: optionData.population.filter((v) => v.id === 0)[0].value,
      // },
    }));
  }
  if (service === queryKeys.SERVICE.FEATURE_BASED) {
    const toBeSet = {
      region: {
        id: optionData.region.filter((v) => v.id === 0)[0]?.id,
        value: optionData.region.filter((v) => v.id === 0)[0]?.value
      },
      category: {
        id: optionData.category.filter((v) => v.id === 0)[0]?.id,
        value: optionData.category.filter((v) => v.id === 0)[0]?.value
      }
    };
    if (optionData.extras.length > 0) {
      optionData.extras.forEach((v) => {
        toBeSet[v] = optionData[v].filter((x) => x.id === 0)[0];
      });
    }

    setParameters((prev) => ({
      ...prev,
      ...toBeSet
    }));
  }
  if (service === queryKeys.SERVICE.LEAD_TO_CUSTOMER) {
    setParameters((prev) => ({
      ...prev,
      monthlyFrom: {
        id:
          optionData.monthlyFrom.length > 24
            ? optionData.monthlyFrom[optionData.monthlyFrom.length - 24].id
            : optionData.monthlyFrom[0].id,
        value:
          optionData.monthlyFrom.length > 24
            ? optionData.monthlyFrom[optionData.monthlyFrom.length - 24].value
            : optionData.monthlyFrom[0].value
      },
      monthlyTo: {
        id: optionData.monthlyTo[optionData.monthlyTo.length - 1].id,
        value: optionData.monthlyTo[optionData.monthlyTo.length - 1].value
      },
      period: {
        id: optionData.period.filter((v) => v.id === 'week')[0].id,
        value: optionData.period.filter((v) => v.id === 'week')[0].value
      },
      segment: {
        id: optionData.segment.filter((v) => v.id === 0)[0].id,
        value: optionData.segment.filter((v) => v.id === 0)[0].value
      }
    }));
  }
}

export function mapReportParameterIdsWithName(option, reportData) {
  const mapped = {};
  mapped.good = reportData.good.map((report) => {
    return {
      ...report,
      data: {
        ...report.data,
        parameters: {
          interval: {
            id: report.data.parameters.interval,
            value:
              option?.interval
                .filter((v) => v.id === report.data.parameters.interval)[0]
                .value[0].toUpperCase() +
              option?.interval
                .filter((v) => v.id === report.data.parameters.interval)[0]
                .value.slice(1)
          },
          segment: {
            id: report.data.parameters.segment_id,
            value:
              option?.segment
                .filter((v) => v.id === report.data.parameters.segment_id)[0]
                .value[0].toUpperCase() +
              option?.segment
                .filter((v) => v.id === report.data.parameters.segment_id)[0]
                .value.slice(1)
          },
          period: {
            id: report.data.parameters.period,
            value:
              option?.period
                .filter((v) => v.id === report.data.parameters.period)[0]
                .value[0].toUpperCase() +
              option?.period.filter((v) => v.id === report.data.parameters.period)[0].value.slice(1)
          },
          retention: {
            id: report.data.parameters.period,
            value:
              option?.period
                .filter((v) => v.id === report.data.parameters.period)[0]
                .value[0].toUpperCase() +
              option?.period.filter((v) => v.id === report.data.parameters.period)[0].value.slice(1)
          },
          channel: {
            id: report.data.parameters.channel_id,
            value:
              option?.channel
                .filter((v) => v.id === report.data.parameters.channel_id)[0]
                .value[0].toUpperCase() +
              option?.channel
                .filter((v) => v.id === report.data.parameters.channel_id)[0]
                .value.slice(1)
          },
          // category: {
          //   id: report.data.parameters.category_id,
          //   value:
          //     option?.category
          //       .filter((v) => v.id === report.data.parameters.category_id)[0]
          //       .value[0].toUpperCase() +
          //     option?.category
          //       .filter((v) => v.id === report.data.parameters.category_id)[0]
          //       .value.slice(1),
          // },
          // item: {
          //   id: report.data.parameters.product_id,
          //   value:
          //     option?.item
          //       .filter((v) => v.id === report.data.parameters.product_id)[0]
          //       .value[0].toUpperCase() +
          //     option?.item
          //       .filter((v) => v.id === report.data.parameters.product_id)[0]
          //       .value.slice(1),
          // },
          trackFirstItemOnly: {
            id: report.data.parameters.same || false,
            value: option?.trackFirstItemOnly.filter(
              (v) => v.id === (report.data.parameters.same || false)
            )[0].value
          },
          region: {
            id: report.data.parameters.region_id,
            value:
              option?.region
                .filter((v) => v.id === report.data.parameters.region_id)[0]
                .value[0].toUpperCase() +
              option?.region
                .filter((v) => v.id === report.data.parameters.region_id)[0]
                .value.slice(1)
          },
          anyMoment: {
            id: report.data.parameters.any_moment,
            value:
              option?.anyMoment
                .filter((v) => v.id === report.data.parameters.any_moment)[0]
                .value[0].toUpperCase() +
              option?.anyMoment
                .filter((v) => v.id === report.data.parameters.any_moment)[0]
                .value.slice(1)
          }
        }
      }
    };
  });
  mapped.bad = reportData.bad.map((report) => {
    return {
      ...report,
      data: {
        ...report.data,
        parameters: {
          interval: {
            id: report.data.parameters.interval,
            value:
              option?.interval
                .filter((v) => v.id === report.data.parameters.interval)[0]
                .value[0].toUpperCase() +
              option?.interval
                .filter((v) => v.id === report.data.parameters.interval)[0]
                .value.slice(1)
          },
          segment: {
            id: report.data.parameters.segment_id,
            value:
              option?.segment
                .filter((v) => v.id === report.data.parameters.segment_id)[0]
                .value[0].toUpperCase() +
              option?.segment
                .filter((v) => v.id === report.data.parameters.segment_id)[0]
                .value.slice(1)
          },
          period: {
            id: report.data.parameters.period,
            value:
              option?.period
                .filter((v) => v.id === report.data.parameters.period)[0]
                .value[0].toUpperCase() +
              option?.period.filter((v) => v.id === report.data.parameters.period)[0].value.slice(1)
          },
          retention: {
            id: report.data.parameters.period,
            value:
              option?.period
                .filter((v) => v.id === report.data.parameters.period)[0]
                .value[0].toUpperCase() +
              option?.period.filter((v) => v.id === report.data.parameters.period)[0].value.slice(1)
          },
          channel: {
            id: report.data.parameters.channel_id,
            value:
              option?.channel
                .filter((v) => v.id === report.data.parameters.channel_id)[0]
                .value[0].toUpperCase() +
              option?.channel
                .filter((v) => v.id === report.data.parameters.channel_id)[0]
                .value.slice(1)
          },
          trackFirstItemOnly: {
            id: report.data.parameters.same || false,
            value: option?.trackFirstItemOnly.filter(
              (v) => v.id === (report.data.parameters.same || false)
            )[0].value
          },
          region: {
            id: report.data.parameters.region_id,
            value:
              option?.region
                .filter((v) => v.id === report.data.parameters.region_id)[0]
                .value[0].toUpperCase() +
              option?.region
                .filter((v) => v.id === report.data.parameters.region_id)[0]
                .value.slice(1)
          },
          anyMoment: {
            id: report.data.parameters.any_moment,
            value:
              option?.anyMoment
                .filter((v) => v.id === report.data.parameters.any_moment)[0]
                .value[0].toUpperCase() +
              option?.anyMoment
                .filter((v) => v.id === report.data.parameters.any_moment)[0]
                .value.slice(1)
          }
        }
      }
    };
  });
  return mapped;
}

export const checkIsAdvanced = {
  segmentation(advancedButton) {
    return queryKeys.SERVICE.SEGMENTATION;
  },
  retention(advancedButton) {
    if (advancedButton === 'pressed') {
      return queryKeys.SERVICE.RETENTION_ADVANCED;
    }
    return queryKeys.SERVICE.RETENTION_BASIC;
  },
  cohort(advancedButton) {
    if (advancedButton === 'pressed') {
      return queryKeys.SERVICE.COHORT_ADVANCED;
    }
    return queryKeys.SERVICE.COHORT_BASIC;
  },
  forecasting(advancedButton) {
    return queryKeys.SERVICE.FORECASTING;
  },
  report(advancedButton) {
    return queryKeys.SERVICE.REPORT;
  },
  userBased(advancedButton) {
    return queryKeys.SERVICE.USER_BASED;
  },
  featureBased(advancedButton) {
    return queryKeys.SERVICE.FEATURE_BASED;
  },
  leadToCustomer(advancedButton) {
    return queryKeys.SERVICE.LEAD_TO_CUSTOMER;
  }
};

export function adjustPeriodParameter(
  periodOption,
  selectedIntervalId,
  previousPeriodId,
  previousPeriodValue
) {
  let id;
  let value;
  if (selectedIntervalId === 'year') {
    id = previousPeriodId;
    value = previousPeriodValue;
  }
  if (selectedIntervalId === 'quarter') {
    if (
      previousPeriodId === 'week' ||
      previousPeriodId === 'month' ||
      previousPeriodId === 'quarter'
    ) {
      id = previousPeriodId;
      value = previousPeriodValue;
    } else if (previousPeriodId === 'year') {
      id = periodOption.filter((v) => v.id === 'quarter')[0].id;
      value = periodOption.filter((v) => v.id === 'quarter')[0].value;
    }
  }
  if (selectedIntervalId === 'month') {
    if (previousPeriodId === 'week' || previousPeriodId === 'month') {
      id = previousPeriodId;
      value = previousPeriodValue;
    } else if (previousPeriodId === 'quarter' || previousPeriodId === 'year') {
      id = periodOption.filter((v) => v.id === 'month')[0].id;
      value = periodOption.filter((v) => v.id === 'month')[0].value;
    }
  }
  return { id, value };
}
