import { mutationKeys, queryKeys } from 'constants/keys';
import { useGlobalToast } from 'context';
import { useMutation, useQueryClient } from 'react-query';
import { mutationFns } from './mutationHelpers';

export function useAccountMutation({ mutationKey, options }) {
  const toast = useGlobalToast();
  const queryClient = useQueryClient();
  const { mutate, status, mutateAsync, error, data } = useMutation(mutationFns[mutationKey], {
    onSuccess: (data, variables) => {
      const { action } = variables;
      if (mutationKey === mutationKeys.ACCOUNT.UPDATE_EMPLOYEE_ROLE) {
        queryClient.invalidateQueries([queryKeys.ACCOUNT.MEMBERS]);
        if (action === 'editUser') {
          toast({
            response: 'success',
            errorMessage: 'The member’s information has been successfully changed.',
          });
        }
      } else if (mutationKey === mutationKeys.ACCOUNT.EDIT_PROFILE) {
        // Why is the refreshing by invalidating query keys not working correctly?
        queryClient.invalidateQueries([queryKeys.ACCOUNT.MEMBERS]);
        queryClient.invalidateQueries([queryKeys.ACCOUNT.PROFILE]);

        if (action === 'editUser') {
          toast({
            response: 'success',
            errorMessage: 'The member’s information has been successfully changed.',
          });
          // window.location.reload();
        }
        if (action === 'createUser') {
          toast({
            response: 'success',
            errorMessage: 'Welcome! The new member has been successfully added.',
          });
          // window.location.reload();
        }
      } else if (mutationKey === mutationKeys.ACCOUNT.DEACTIVATE_USER) {
        queryClient.invalidateQueries([queryKeys.ACCOUNT.MEMBERS]);
        if (action === 'removeUser') {
          toast({
            response: 'success',
            errorMessage: 'Say goodbye. The member’s account has been successfully deleted.',
          });
          // window.location.reload();
        }
      } else if (mutationKey === mutationKeys.ACCOUNT.UPDATE_ORGANIZATION) {
        queryClient.invalidateQueries([queryKeys.ACCOUNT.ORGANIZATION]);
      } else if (mutationKey === mutationKeys.ACCOUNT.CHANGE_PASSWORD) {
        toast({
          response: 'success',
          errorMessage: 'Your password has been changed successfully.',
        });
        // window.location.reload();
      }
    },
    onError: (error) => {
      // if (error && mutationKey === mutationKeys.ACCOUNT.CHANGE_PASSWORD) {
      //   return 'Your password is incorrect.';
      // }
      toast({});
    },
    ...options,
  });

  return [mutate, status, mutateAsync, error, data];
}
