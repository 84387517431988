import { useUserInfoContext } from 'context';
import { useEffect, useState } from 'react';
import { queryFns } from './queryHelpers';

export const useDataCenter = () => {
  const { userInfo } = useUserInfoContext();

  const [isAnalyzeAvailable, setIsAnalyzeAvailable] = useState(null);

  const [analysisInfo, setAnalysisInfo] = useState({
    date_min: '',
    date_max: '',
    n_leads: '',
    n_customers: '',
    total_revenue: '',
    updated_at: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdatedTime, setLastUpdatedTime] = useState('');
  const [errorText, setErrorText] = useState({
    customer: '',
    items: '',
    transaction: ''
  });

  const injectCustomer = (data) => {
    setAnalysisInfo((prev) => ({
      ...prev,
      n_leads: data.n_customers,
      date_min: data.from_time,
      date_max: data.to_time
    }));
  };

  const injectProduct = (updated_at) => {
    setAnalysisInfo((prev) => ({
      ...prev,
      updated_at: updated_at
    }));
  };

  const injectTransaction = (data) => {
    setAnalysisInfo((prev) => ({
      ...prev,
      n_customers: data.n_customers,
      total_revenue: data.total_revenue.toLocaleString()
    }));
  };

  // const getAnalysisInfo = async ({ type, file }) => {
  //   try {
  //     const data = await queryFns.postCsv({ type, file });
  //     return data;
  //   } catch (e) {
  //     const key = type === 'product' ? 'items' : type;
  //     setErrorText((prev) => ({ ...prev, [key]: e.response.status.toString() }));
  //   }
  // };
  const getAnalysisInfo = async ({ type }) => {
    try {
      const data = await queryFns.summaryUploadedDataset({
        companyId: userInfo?.company.companyId,
        shopId: userInfo?.company.shopId,
        fileType: type
      });
      return data;
    } catch (e) {
      const key = type === 'product' ? 'items' : type;
      setErrorText((prev) => ({ ...prev, [key]: e.response.status.toString() }));
    }
  };

  const generateUploadingUrl = async ({ companyId, shopId }) => {
    try {
      const data = await queryFns.generateUploadingUrl({ companyId, shopId });
      return data;
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    (async function () {
      if (
        userInfo?.dashboard.latestAnalysisStatus === 'initial' ||
        userInfo?.dashboard.latestAnalysisStatus === 'started'
      ) {
        setIsAnalyzeAvailable(false);
      } else {
        setIsAnalyzeAvailable(true);
      }

      setLastUpdatedTime(userInfo?.dashboard.createdAt);
    })();
  }, [userInfo?.company.shopId]);

  return {
    userInfo,
    getAnalysisInfo,
    analysisInfo,
    isLoading,
    setIsLoading,
    dataHandler: {
      injectCustomer,
      injectProduct,
      injectTransaction
    },
    lastUpdatedTime,
    errorText,
    setErrorText,
    isAnalyzeAvailable,
    setIsAnalyzeAvailable,
    generateUploadingUrl
  };
};
