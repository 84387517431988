import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useQueryClient, useQueries } from 'react-query';
import { axiosBase } from 'api';
import { useGlobalSnack, useUserInfoContext } from 'context';
import { transformData, transformCandidate, determineCandidateStatus } from './utils';
import {
  getCurrentHandler,
  getUploadURLHandler,
  getFileSummaryHandler,
  deleteChartByGroupHandler,
  editChartByGroupHandler,
  getHistoryHandler,
  updateCandidateHandler,
  getCanidateHandler
} from './requests';
import { exportCSV } from 'utils';
import { queryKeys } from 'constants/keys';
import { CANDIDATE_STATUS } from 'constants';

export const useMosaic = () => {
  const { t } = useTranslation();
  const snack = useGlobalSnack();
  const [tab, setTab] = useState('current');
  const [historyIds, setHistoryIds] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);

  const [candidateStatus, setCandidateStatus] = useState(null);

  const [candidateData] = useGetCandidate();
  const [currentData, isCurrentDataFetched] = useGetCurrentData(tab === 'current');
  const [historyData, isHistoryDataFetched] = useGetHistoryData(tab === 'history', historyIds);

  const handleTabSelect = (e) => {
    if (e.target.value !== tab) {
      setIsDataReady(false);
    }
    setTab(e.target.value);
  };

  useEffect(() => {
    if (!currentData) {
      return;
    }
    if (currentData) {
      setHistoryIds(currentData.dashboardIds);
    }

    if (currentData && candidateData) {
      const validCharts = currentData.chartData.filter(
        (d) => d.significant.candidate_requirement_pass
      );
      const status = determineCandidateStatus(validCharts, candidateData);
      if (status === CANDIDATE_STATUS.UPDATED) {
        snack({
          response: 'success',
          errorMessage: t('mosaic.candidates_list_has_been_updated'),
          goButtonEnabled: true,
          goTo: {
            link: '/segment-overview',
            navigateState: { from: 'mosaic-candidate' }
          }
        });
      }

      setCandidateStatus(status);
    }
  }, [candidateData, currentData]);

  useEffect(() => {
    if (tab === 'current') {
      if (isCurrentDataFetched) {
        setIsDataReady(true);
      } else {
        setIsDataReady(false);
      }
    } else if (tab === 'history') {
      if (isHistoryDataFetched) {
        setIsDataReady(true);
      } else {
        setIsDataReady(false);
      }
    }
  }, [tab, isCurrentDataFetched, isHistoryDataFetched]);
  return [
    isDataReady,
    { current: currentData?.chartData, history: historyData },
    handleTabSelect,
    tab,
    candidateStatus
  ];
};
const useGetCandidate = () => {
  const { userInfo } = useUserInfoContext();
  const { data } = useQuery(
    [queryKeys.SERVICE.CANDIDATE, userInfo?.company.shopId],
    () =>
      getCanidateHandler({
        companyId: userInfo.company.companyId,
        shopId: userInfo.company.shopId
      }),
    {
      select: (data) => transformCandidate(data)
    }
  );

  return [data];
};

const useGetCurrentData = (enabled) => {
  const { userInfo } = useUserInfoContext();
  const { data, isSuccess } = useQuery(
    [queryKeys.SERVICE.MOSAIC, userInfo?.company.shopId],
    () =>
      getCurrentHandler({
        companyId: userInfo.company.companyId,
        shopId: userInfo.company.shopId
      }),
    {
      select: (data) => transformData(data),
      enabled
    }
  );

  return [data, isSuccess];
};
const useGetHistoryData = (enabled, historyIds) => {
  const { userInfo } = useUserInfoContext();

  const result = useQueries(
    historyIds.map((id) => ({
      queryKey: [queryKeys.SERVICE.MOSAIC, userInfo?.company.shopId, id],
      queryFn: () =>
        getHistoryHandler({
          companyId: userInfo.company.companyId,
          shopId: userInfo.company.shopId,
          dashboardId: id
        }),
      enabled,
      select: (data) => transformData(data)
    }))
  );

  if (result.length === 0) {
    return [];
  }
  if (!result[0].data) {
    return [];
  }

  const data = result.reduce((acc, cur) => {
    if (cur.data) {
      acc.push(...cur.data.chartData);
    }
    return acc;
  }, []);

  if (data.length === 0) {
    return [];
  }

  const isDataFetched = result.every((r) => r.status === 'success');

  return [data, isDataFetched];
};

export const useSubmitMosaicFile = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { userInfo } = useUserInfoContext();
  const snack = useGlobalSnack();

  const { mutateAsync: getUploadURL } = useMutation(getUploadURLHandler, {
    onSuccess: () => {},
    onError: (error) => {
      const message =
        error instanceof Error
          ? JSON.parse(error.response.data.message).message
          : 'error message not defined';
      throw new Error(message);
    }
  });
  const { mutateAsync: getFileSummary } = useMutation(getFileSummaryHandler, {
    onSuccess: () => {
      snack({
        response: 'success',
        errorMessage: t('mosaic.the_data_has_been_sucessfully_submitted'),
        refreshButtonEnabled: false
      });
      setTimeout(() => {
        queryClient.invalidateQueries([queryKeys.SERVICE.MOSAIC]);
      }, 4000);
    },
    onError: (error) => {
      const message =
        error instanceof Error
          ? JSON.parse(error.response.data.message).message
          : 'error message not defined';
      throw new Error(message);
    }
  });

  const uploadFileToAWS = async ({ location, type, file }) => {
    const files = {
      number: [],
      text: [],
      boolean: [],
      mosaic: []
    };
    files[type].push(file.name);

    const url = await getUploadURL({
      companyId: userInfo.company.companyId,
      shopId: userInfo.company.shopId,
      location,
      files
    });
    try {
      await axiosBase.put(url, new Blob([file]));
    } catch (e) {
      throw new Error('there was an error uploading file to the given url');
    }

    await getFileSummary({
      companyId: userInfo.company.companyId,
      shopId: userInfo.company.shopId,
      file: file.name,
      location,
      type
    });
  };

  return { uploadFileToAWS };
};

export const useDeleteMosaicByGroup = () => {
  const queryClient = useQueryClient();
  const { userInfo } = useUserInfoContext();
  const snack = useGlobalSnack();

  const { mutateAsync: deleteChartByGroup } = useMutation(deleteChartByGroupHandler, {
    onSuccess: () => {
      snack({
        response: 'success',
        errorMessage: 'The data has been successfully deleted.'
      });
      setTimeout(() => {
        queryClient.invalidateQueries([queryKeys.SERVICE.MOSAIC]);
      }, 1000);
    },
    onError: () => {
      snack({
        response: 'error',
      });
    }
  });

  const deleteChart = async ({ chartGroup, dashboardId, startDeleteProcess }) => {
    startDeleteProcess();
    await deleteChartByGroup({
      companyId: userInfo.company.companyId,
      shopId: userInfo.company.shopId,
      chartGroup,
      dashboardId
    });
  };

  return { deleteChart };
};

export const useEditMosaicByGroup = () => {
  const queryClient = useQueryClient();
  const { userInfo } = useUserInfoContext();
  const snack = useGlobalSnack();

  const { mutateAsync: editChartByGroup } = useMutation(editChartByGroupHandler, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries([queryKeys.SERVICE.MOSAIC]);
      }, 1000);
    },
    onError: () => {
      snack({});
    }
  });

  const editChart = async ({ chartGroup, dashboardId, metadata }) => {
    await editChartByGroup({
      companyId: userInfo.company.companyId,
      shopId: userInfo.company.shopId,
      chartGroup,
      dashboardId,
      metadata
    });
  };

  return { editChart };
};

export const useUpdateCandidate = () => {
  const queryClient = useQueryClient();
  const { userInfo } = useUserInfoContext();
  const { t } = useTranslation();
  const snack = useGlobalSnack();

  const { mutateAsync: update, isLoading } = useMutation(updateCandidateHandler, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries([queryKeys.SERVICE.CANDIDATE, userInfo?.company.shopId]);
      }, 2000);
    },
    onError: () => {
      snack({});
    }
  });
  const updateCandidate = async () => {
    await update({
      companyId: userInfo.company.companyId,
      shopId: userInfo.company.shopId
    });
  };

  return { updateCandidate, isUpdateLoading: isLoading };
};
/*
  file_name:  base 64 encode of this string c{company_id}s{shop_id} ,
   replace the company_id and shop_id with the ids
*/
export const useGetExampleFile = () => {
  const { userInfo } = useUserInfoContext();
  const env = process.env.REACT_APP_API_ENV === 'v1' ? 'pdt' : 'stg';
  const fileName = btoa(`c${userInfo.company.companyId}s${userInfo.company.shopId}`);

  const getExampleFile = () => {
    exportCSV(
      `https://momentum-exports.s3.ap-northeast-2.amazonaws.com/${env}/mosaic/${fileName}.csv`,
      'customer_id.csv',
      true
    );
  };
  return { getExampleFile };
};
