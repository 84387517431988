import { ERROR_404 } from 'constants/images';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { useTranslation } from 'react-i18next';

export default function Error() {
  const handleNavigate = useNavigate();

  const { t } = useTranslation()

  function handleButtonClick(e) {
    e.preventDefault();
    handleNavigate('/');
  }
  return (
    (<div className="error-container">
      <main className="error-main">
        <img src={ERROR_404} alt="error-404" />
        <div className="error-title">
          {t("error.we_lost_this_page")}
        </div>
        <div className="error-description">
          {t("error.the_page_you_are_looking")}
        </div>
        <button onClick={handleButtonClick} className="error-gohome">{t("error.go_back_home")}</button>
      </main>
    </div>)
  );
}
