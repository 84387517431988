import { Outlet } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import { useDashboardContext } from 'context';
import { useLocationInfo } from 'hooks';
import './index.scss';

export default function WithHeaderLayout() {
  const { handleShopSelect } = useDashboardContext();
  const { isProfile, isDatacenter } = useLocationInfo();

  return (
    <div className="withheader-layout-container">
      <Header handleShopSelect={handleShopSelect} />
      <main className="layout-main">
        <div className="main-contents">
          <Outlet />
        </div>
      </main>
      <Footer isFixed={isProfile || isDatacenter} />
    </div>
  );
}
