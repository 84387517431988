import { useState, useEffect } from 'react';

export function useClickOutside(ref) {
  const [isClickedInside, setIsClickedInside] = useState(false);

  useEffect(() => {
    function handleClick(e) {
      if (e.target.nodeName === 'LABEL') {
        return setIsClickedInside(false);
      } else if (ref.current && ref.current.contains(e.target)) {
        return setIsClickedInside(true);
      }
      return setIsClickedInside(false);
    }
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return [isClickedInside, setIsClickedInside];
}
