import { useState } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next'
import { analyzeAPI, axiosPrivate } from 'api';
import info from 'assets/icons/info.svg';
import Radio from './Radio';
import Toggle from './Toggle';
import { useGlobalToast, useUserInfoContext } from 'context';

const DataCenterAnalyze = ({
  setCurrentStep,
  analysisInfo,
  isAnalyzeAvailable,
  setIsAnalyzeAvailable,
  lastUpdatedTime,
}) => {
  const { t } = useTranslation()
  const toast = useGlobalToast();
  const { userInfo } = useUserInfoContext();
  // 토글 on, off
  const [isOn, setIsOn] = useState(false);

  // clustering 라디오 버튼
  const [clusteringRadioValue, setClusteringRadioValue] = useState('existing');

  //currency 라디오 버튼
  const [currencyRadioValue, setCurrencyRadioValue] = useState('won');

  // 토글 on, off 반복
  const handleToggle = useCallback(() => setIsOn((prev) => !prev), []);

  // value 값으로 라디오 변경
  const onClusteringRadioClick = useCallback((value) => setClusteringRadioValue(value), []);

  const onCurrencyRadioClick = useCallback((value) => setCurrencyRadioValue(value), []);

  const handleAnalyticsButtonClick = async () => {
    if (!isAnalyzeAvailable) return;

    setIsAnalyzeAvailable(false);

    await axiosPrivate
      .post(analyzeAPI.ANALYZE, {
        company_id: userInfo?.company?.companyId,
        shop_id: userInfo?.company?.shopId,
        abtest: isOn,
        currency: currencyRadioValue,
        clustering: lastUpdatedTime.length === 0 ? 'new' : clusteringRadioValue,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        toast({
          errorMessage: t('data_center.we_are_sorry_but_something'),
        });
        setIsAnalyzeAvailable(true);
      });
  };

  return (<>
    {/* 위쪽 글자 */}
    <div className="DataCenter_phrases_box">
      <div className="message">{t("data_center.im_ready_to_analyze_it")}</div>
      <div className="loading_date">
        <span className="loading_message">
          {t("data_center.click_the_start_anlaysis_button")}
        </span>
      </div>
    </div>
    {/* 가운데 두개 컬럼 묶인 */}
    <div className="DataCenter_analyze_box">
      {/* 왼쪽박스 */}
      <div className="left_analyze">
        <div className="analyze_info">
          <ul className="analyze_info_box">
            <li className="info_title">{t("data_center.data_period")}</li>
            <li className="info_number">
              <span>{analysisInfo.date_min}</span>~<span>{analysisInfo.date_max}</span>
            </li>
          </ul>
          <ul className="analyze_info_box">
            <li className="info_title">{t("data_center.total_leads")}</li>
            <li className="info_number">
              <span>{analysisInfo.n_leads.toLocaleString()}</span>
            </li>
          </ul>
          <ul className="analyze_info_box">
            <li className="info_title">{t("data_center.total_customers")}</li>
            <li className="info_number">
              <span>{analysisInfo.n_customers.toLocaleString()}</span>
            </li>
          </ul>
          <ul className="analyze_info_box">
            <li className="info_title">{t("data_center.total_revenue")}</li>
            <li className="info_number">
              <span>{analysisInfo.total_revenue.toLocaleString()}</span>{' '}
            </li>
          </ul>
        </div>

        <div className="line" />

        {/* 왼쪽 하단 */}
        <div className="updated_box">
          <span>{t("data_center.updated_at")}: </span>
          <span>{analysisInfo.updated_at.toLocaleString().slice(0, -3)}</span>
        </div>
      </div>

      {/* 오른쪽 박스 */}
      <div className="right_analyze">
        <div className="check_box">
          {/* A/B TESTING */}
          <div className="ab_testing">
            <div className="ab_testing_title">
              <span>{t("data_center.ab_testing")}</span>
              <div className="info_box">
                <img src={info} className="info_logo" />
                <div className="info_text">{t("data_center.this_excludes_about")}<div className="triangle" />
                </div>
              </div>
            </div>

            <div className="ab_testing_toggle">
              <Toggle handleToggle={handleToggle} isOn={isOn} />
            </div>
          </div>

          {/* Clustering */}
          {lastUpdatedTime != undefined && lastUpdatedTime.length > 0 && (
            <div className="clustering">
              <div className="clustering_title">{t("data_center.clustering")}</div>
              <div className="clustering_selec_box">
                <div className="clustering_selec_item">
                  <span>
                    <Radio
                      value="existing"
                      onClick={onClusteringRadioClick}
                      isChecked={'existing' === clusteringRadioValue}
                    />
                  </span>
                  <span>{t("data_center.assigning_all_customers_to_the")}</span>
                </div>
                <div className="clustering_selec_item">
                  <span>
                    <Radio
                      value="new"
                      onClick={onClusteringRadioClick}
                      isChecked={'new' === clusteringRadioValue}
                    />
                  </span>
                  <span>{t("data_center.new_clustering_with_all_customers")}</span>
                </div>
              </div>
            </div>
          )}

          {/* Currency */}
          <div className="currency">
            <div className="currency_title">{t("data_center.currency")}</div>
            <div className="currency_selec_box">
              <div className="currency_selec_item">
                <span>
                  <Radio
                    value="won"
                    onClick={onCurrencyRadioClick}
                    isChecked={'won' === currencyRadioValue}
                  />
                </span>
                <span>원(₩)</span>
              </div>
              <div className="currency_selec_item">
                <span>
                  <Radio
                    value="dollar"
                    onClick={onCurrencyRadioClick}
                    isChecked={'dollar' === currencyRadioValue}
                  />
                </span>
                <span>US Dollar($)</span>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_buttons">
          <div className="line" />
          <button
            onClick={handleAnalyticsButtonClick}
            className={`right_analyze_submit_btn ${!isAnalyzeAvailable ? 'disable' : ''}`}
          >
            {isAnalyzeAvailable ? t("data_center.start_analysis") : t("data_center.retentics_started_analyzing")}
          </button>
          <div
            className="return_file"
            onClick={() => {
              setCurrentStep('upload');
            }}
          >{t("data_center.return_to_file_uploader")}</div>
        </div>
      </div>
    </div>
    <div></div>
  </>);
};

export default DataCenterAnalyze;
