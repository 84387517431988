import { useChart } from 'hooks';
import './index.scss';

export default function ChartSVG({ chartInfo }) {
  const { data, chartId, options } = chartInfo;

  const { ref } = useChart(chartId, data, options);

  return (
    <div className="chart-container">
      <svg className="chart" ref={ref}></svg>
    </div>
  );
}
