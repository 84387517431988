import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE;

export const axiosBase = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'text/plain;charset=UTF-8',
  },
});
export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
});

export * from './auth';
export * from './account';
export * from './customer';
export * from './analyze';
