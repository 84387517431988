import { useTranslation } from 'react-i18next';
import * as Styles from './index.styles';
import { NODATA } from 'constants/images';

const NotAnalyzed = () => {
  const { t } = useTranslation();
  return (
    <Styles.NotAnalyzed.Container>
      <Styles.NotAnalyzed.Image src={NODATA} />
      <Styles.NotAnalyzed.H5>{t("error.theres_no_data")}</Styles.NotAnalyzed.H5>
      <Styles.NotAnalyzed.Strong>{t("error.please_start_analyzing_to")}</Styles.NotAnalyzed.Strong>
    </Styles.NotAnalyzed.Container>
  );
};

export default NotAnalyzed;
