import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { LOADING } from 'constants/images';
import { Link } from 'react-router-dom';
import DataCenterUpLoadingBox from './DataCenterUpLoadingBox';
import { axiosBase } from 'api';

const DataCenterUpLoading = ({
  setCurrentStep,
  lastUpdatedTime,
  isLoading,
  setIsLoading,
  getAnalysisInfo,
  setErrorText,
  errorText,
  dataHandler,
  userInfo,
  generateUploadingUrl,
}) => {
  const { t } = useTranslation()
  /* files, setFiles를 통해서 각 파일 저장 */
  const [files, setFiles] = useState({
    customer: null,
    items: null,
    transaction: null,
  });

  const handleSubmit = async () => {
    setErrorText({
      customer: '',
      items: '',
      transaction: '',
    });

    try {
      setIsLoading(true);
      const data = await generateUploadingUrl({
        companyId: userInfo?.company.companyId,
        shopId: userInfo?.company.shopId,
      });

      await Promise.all([
        axiosBase.put(data.customer, new Blob([files.customer])),
        axiosBase.put(data.product, new Blob([files.items])),
        axiosBase.put(data.transaction, new Blob([files.transaction])),
      ]);

      await Promise.all([
        getAnalysisInfo({ type: 'customer' }),
        getAnalysisInfo({ type: 'product' }),
        getAnalysisInfo({ type: 'transaction' }),
      ]).then((responses) => {
        if (responses.filter((v) => v !== undefined).length === 3) {
          if (responses[0]) dataHandler.injectCustomer(responses[0].data);
          if (responses[1]) dataHandler.injectProduct(responses[1].updated_at);
          if (responses[2]) dataHandler.injectTransaction(responses[2].data);
          setCurrentStep('analysis');
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (<>
    <div className="DataCenter_phrases_box">
      <div className="message">{t("data_center.upload_the_latest_data_and")}</div>
      <div className="loading_date">
        <span className="loading_message">Last upload : </span>
        <span className="last_date">{lastUpdatedTime?.split('T')[0]}</span>
      </div>
    </div>
    {/* 중앙 박스 */}
    <div className="DataCenter_file_box">
      <DataCenterUpLoadingBox
        title="Customer"
        description={t("data_center.id_name_createdat_acquisition_channeloptional")}
        setFiles={setFiles}
        errorText={errorText.customer}
        setErrorText={setErrorText}
        disabled={isLoading}
      />
      <DataCenterUpLoadingBox
        title="Items"
        description={t("data_center.id_name_category_price")}
        setFiles={setFiles}
        errorText={errorText.items}
        setErrorText={setErrorText}
        disabled={isLoading}
      />
      <DataCenterUpLoadingBox
        title="Transaction"
        description={t("data_center.idcustomer_product_price_shipping_addressstate")}
        setFiles={setFiles}
        errorText={errorText.transaction}
        setErrorText={setErrorText}
        disabled={isLoading}
      />

      {/* 3개 파일이 모두 추가되지 않았거나, 업로딩이 진행중일 시 disabled */}
      <button
        className="submit_btn"
        onClick={() => {
          handleSubmit();
        }}
        disabled={Object.values(files).filter((v) => v === null).length !== 0 || isLoading}
      >
        {!isLoading ? 'Submit' : <img src={LOADING} className="submit_btn_loading" />}
      </button>
      <Link to="/marketing-overview">
        <div className="go_dashboard">{t("data_center.return_to_dashboard")}</div>
      </Link>
    </div>
  </>);
};

export default DataCenterUpLoading;
