import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/buttons/PrimaryButton';
import OriginalSearchInput from 'components/inputs/OriginalSearchInput';
import ForecastingSearchInput from 'pages/Forecasting/ForecastingSearchInput';
import MainHeader from 'components/MainHeader';
import NoData from 'components/NoData';
import { EXPORT, INFO } from 'constants/images';
import Table from 'components/Table/Table';
import { useCustomerServiceMutation, useGetCustomerService, usePageInit } from 'hooks';
import { mutationKeys, queryKeys } from 'constants/keys';
import { columns } from './utils';
import { exportCSV, nameExportFile } from 'utils';
import { useUserInfoContext } from 'context';
import './FeatureBased.scss';
import { axiosPrivate, customerAPI } from 'api';
import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import {
  ParametersSkeleton,
  ParameterSkeleton,
  TitleSkeleton,
  DynamicSkeleton
} from 'components/skeletons';

const FeatureBased = () => {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();
  const [option, setOption] = useState(null);
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);

  const {
    option: cacheOption,
    result,
    parameters,
    handlers
  } = useGetCustomerService({
    optionQueryKey: queryKeys.OPTION.FEATURE_BASED,
    serviceQueryKey: queryKeys.SERVICE.FEATURE_BASED
  });

  useEffect(() => {
    setOption(cacheOption);
  }, [cacheOption]);

  const { data: optionWithCategoryAndItem, fetchNextPage: fetchNextItemList } = useInfiniteQuery(
    [queryKeys.OPTION.FORECASTING, 'option-category', userInfo?.company.shopId],
    async ({ pageParam = 1 }) => {
      const { data } = await axiosPrivate.post(customerAPI.OPTION_CATEGORY, {
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        page: pageParam
      });
      return { data, nextPage: pageParam + 1 };
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      select: (data) => {
        return data.pages[0].data.categories.map((category) => ({
          value: category.name,
          id: category.id
        }));
      }
    }
  );

  useEffect(() => {
    if (cacheOption !== null && cacheOption !== undefined) {
      setOption({
        ...cacheOption,
        category: optionWithCategoryAndItem
      });
    }
  }, [optionWithCategoryAndItem, cacheOption]);

  const [logExport] = useCustomerServiceMutation({
    mutationKey: mutationKeys.CUSTOMER.LOG_EXPORT
  });
  function handleLogExport(params) {
    logExport(params);
  }

  const handleExport = async (e, url) => {
    e.preventDefault();
    if (userInfo?.user.role === 'observer') return;

    await exportCSV(url, 'Feature-based Recommendation')
      .then(() => {
        handleLogExport({
          companyId: userInfo?.company.companyId,
          shopId: userInfo?.company.shopId,
          page: 'feature_based',
          fileName: nameExportFile('Feature-based Recommendation')
        });
      })
      .catch((err) => {
        console.log('export failed');
        console.log(err);
      });
  };

  useEffect(() => {
    if (parameters) {
      const isAvailable =
        Object.entries(parameters)
          .filter((v) => v[0] !== 'region')
          .map((v) => v[1].id)
          .filter((v) => v !== 0).length > 0;
      setIsApplyDisabled(!isAvailable);
    }
  }, [parameters]);

  useEffect(() => {
    usePageInit();
  }, [])

  return (
    (<div className="FeatureBased">
      <MainHeader main="Feature-Based" sub={t("feature_based.check_who_prefers_following_features")}/>
      {!option || !parameters ? (
        <ParametersSkeleton>
          <ParameterSkeleton />
          <ParameterSkeleton />
        </ParametersSkeleton>
      ) : (
        <>
          <div className="FeatureBased_search_box">
            <div className="search_input_row">
              <OriginalSearchInput
                label="region"
                width="260"
                style={{ zIndex: 2 }}
                options={option.region}
                selectedValue={parameters.region.value}
                selectValue={handlers.handleParametersSelect}
              />
              <ForecastingSearchInput
                label="category"
                width="260"
                style={{ zIndex: 2 }}
                options={option.category}
                selectedValue={parameters.category.value ?? 'all'}
                selectValue={handlers.handleParametersSelect}
                fetchNextItemList={fetchNextItemList}
              />
              {option.extras.length >= 1 &&
                parameters[option.extras[0]] &&
                option.extras.map((v, i) => (
                  <OriginalSearchInput
                    key={`${v}${i}`}
                    label={v}
                    width="260"
                    style={{ zIndex: i < 2 ? 2 : 1 }}
                    options={option[v]}
                    selectedValue={parameters[v].value}
                    selectValue={handlers.handleParametersSelect}
                  />
                ))}
            </div>

            <div className="button_layer">
              <PrimaryButton
                value={t("user_based.apply")}
                onClick={handlers.handleResultGet}
                disabled={isApplyDisabled}
                style={{ width: '124px' }}
              />
            </div>
          </div>
        </>
      )}
      <div className={`FeatureBased_table_box${option && !result ? ' no_data' : ''}`}>
        {(!option && !result) || !parameters ? (
          <div className="overview-skeleton-container">
            <TitleSkeleton width={400} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
            <DynamicSkeleton width={1100} height={50} />
          </div>
        ) : option && !result ? (
          (<NoData isFeatureBased />)
        ) : (
          (<>
            <p className="FeatureBased_box_title">
              {result?.counts?.toLocaleString()}{t("feature_based.customers_found")}
            </p>
            <a className="FeatureBased_box_export" onClick={(e) => handleExport(e, result?.export)}>
              <img src={EXPORT} className="export_img" />{t("settings.export")}</a>
            <Table
              data={result?.featureBasedTable}
              columns={columns}
              icons={[<img src={INFO} className="info_logo" key="[i]" />]}
              hasIconHeadrs={[t("feature_based.latest_transaction")]}
              helpTexts={[t("feature_based.last_date_of_transaction")]}
            />
            <div className="csv_help_text">
              * {t("feature_based.you_can_see_all_customers")}
            </div>
          </>)
        )}
      </div>
    </div>)
  );
};

export default FeatureBased;
