import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { useD3 } from 'hooks';
import { numberWithCommas } from 'utils';
import './index.scss';

export default function OverviewABTestingChart({ title, chartId, data, svgWidth, svgHeight }) {
  const { t } = useTranslation();
  function arc(r, sign) {
    return r ? `a${r * sign[0]},${r * sign[1]} 0 0 1 ${r * sign[2]},${r * sign[3]}` : '';
  }
  function roundedRect(x, y, width, height, r) {
    r = [
      Math.min(r[0], height, width),
      Math.min(r[1], height, width),
      Math.min(r[2], height, width),
      Math.min(r[3], height, width),
    ];

    return `M${x + r[0]},${y}h${width - r[0] - r[1]}${arc(r[1], [1, 1, 1, 1])}v${
      height - r[1] - r[2]
    }${arc(r[2], [1, 1, -1, 1])}h${-width + r[2] + r[3]}${arc(r[3], [1, 1, -1, -1])}v${
      -height + r[3] + r[0]
    }${arc(r[0], [1, 1, 1, -1])}z`;
  }
  const ref = useD3(
    (svg) => {
      document.getElementsByClassName(`overview-abt-chart ${chartId}`)[0].innerHTML = '';
      const prevTooltip = document.getElementsByClassName(`tooltip ${chartId}`)[0];
      if (prevTooltip) prevTooltip.remove();
      svg.attr('width', svgWidth).attr('height', svgHeight);

      const margin = { top: 20, right: 30, bottom: 20, left: 30 };
      const chartWidth = svgWidth,
        chartHeight = svgHeight - 30 - 25;
      const categories = ['withRetentics', 'withoutRetentics'];
      const xAxis = svg.append('g').attr('class', `x-axis ${chartId}`);
      const yAxis = svg.append('g').attr('class', `y-axis ${chartId}`);
      const grid = svg.append('g').attr('class', `y-grid ${chartId}`);
      const bars = svg.append('g').attr('class', `bar-group ${chartId}`);
      const tooltip = d3
        .select(`.overview-abt-chart-container.${chartId}`)
        .append('div')
        .attr('class', `tooltip ${chartId}`)
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .call((div) => div.append('div').attr('class', 'x'))
        .call((div) =>
          div
            .append('div')
            .attr('class', 'y')
            .call((div) => div.append('span').attr('class', 'label'))
            .call((div) => div.append('span').attr('class', 'value'))
        );

      const yearIndicators = svg.append('g').attr('class', `year-indicators ${chartId}`);
      const legends = svg.append('g').attr('class', `legends ${chartId}`);
      const legend = legends
        .selectAll(`.legend ${chartId}`)
        .data([t("overview.with_retentics"), t("overview.without_retentics")])
        .enter()
        .append('g')
        .attr('class', `legend ${chartId}`);

      drawCharts(categories);

      legend
        .call((g) =>
          g
            .append('circle')
            .attr('r', 3)
            .style('stroke-width', 3)
            .style('stroke', (d, i) => (i === 0 ? '#4B43FF' : '#C1BEF7'))
            .style('fill', (d, i) => (i === 0 ? '#4B43FF' : '#C1BEF7'))
            .style('cursor', 'pointer')
        )
        .call((g) =>
          g
            .append('text')
            .attr('x', 10)
            .attr('y', 4)
            .text((d) => d)
            .style('cursor', 'pointer')
        )
        .call((g) =>
          g.attr('transform', (d, i) => {
            if (i === 0) return `translate(${chartWidth - 290},${margin.top})`;
            if (i === 1) return `translate(${chartWidth - 160},${margin.top})`;
          })
        )
        .on('click', function () {
          const legends = Array.from(d3.selectAll('.legend.abt'));
          const targetLegend = d3.select(this);
          const isDisablable =
            !targetLegend.classed('disabled') &&
            legends.filter((v) => v.classList.length > 2).length !== 1;

          if (targetLegend.classed('disabled')) {
            targetLegend.classed('disabled', false);
          } else if (isDisablable) {
            targetLegend.classed('disabled', true);
          }

          const disabledIndicators = legends.map((v) => {
            const isDisabled = v.className.baseVal.includes('disabled');
            return isDisabled;
          });

          const selectedCategories = categories.reduce((acc, cur, i) => {
            if (!disabledIndicators[i]) acc.push(cur);
            return acc;
          }, []);
          drawCharts(selectedCategories);
        });

      function drawCharts(selectedCategories) {
        const isWithRetenticsDisabled = !selectedCategories.includes('withRetentics');
        const isWithoutRetenticsDisabled = !selectedCategories.includes('withoutRetentics');

        const barTotalD = data.withRetentics.reduce((acc, cur, i) => {
          const barD = {};
          barD.category = cur.x;
          if (!isWithRetenticsDisabled) barD.withRetentics = cur.y;
          if (!isWithoutRetenticsDisabled) barD.withoutRetentics = data.withoutRetentics[i].y;
          acc.push(barD);
          return acc;
        }, []);
        const barTotalX = d3.map(barTotalD, (v) => v.category);
        const barGroupX = [];
        if (!isWithRetenticsDisabled) barGroupX.push('withRetentics');
        if (!isWithoutRetenticsDisabled) barGroupX.push('withoutRetentics');

        const xBarTotalScale = d3
          .scaleBand()
          .domain(barTotalX)
          .rangeRound([margin.left, chartWidth - margin.right])
          .paddingInner(0.2)
          .paddingOuter(0.1);
        const xBarGroupScale = d3
          .scaleBand()
          .domain(barGroupX)
          .rangeRound([0, xBarTotalScale.bandwidth()])
          .paddingInner(0.2);

        const yScaleMax =
          d3.max([0, d3.max(barTotalD, (d) => d3.max(barGroupX, (x) => d[x]))]) === 0
            ? 1
            : d3.max([0, d3.max(barTotalD, (d) => d3.max(barGroupX, (x) => d[x]))]);
        const yScale = d3
          .scaleLinear()
          .domain([0, yScaleMax])
          .rangeRound([chartHeight, margin.top + 30])
          .nice();

        xAxis
          .attr('transform', `translate(0, ${chartHeight})`)
          .call(
            d3
              .axisBottom(xBarTotalScale)
              .tickSizeOuter(0)
              .tickFormat((d) => d[0].toUpperCase() + d.slice(1))
          )
          .select('.domain')
          .attr('stroke', '#DDDDDD');
        yAxis
          .attr('transform', `translate(${margin.left},0)`)
          .call(d3.axisLeft(yScale).ticks(5))
          .select('.domain')
          .remove();
        grid
          .attr('transform', `translate(${margin.left},0)`)
          .call(
            d3
              .axisLeft(yScale)
              .ticks(5, '~s')
              .tickSize(-(chartWidth - margin.right - margin.left))
              .tickFormat('')
          )
          .call((g) => g.select('path').attr('stroke', 'transparent'))
          .call((g) => g.selectAll('.tick line').attr('stroke', '#DDDDDD'));

        // bars
        const barsUpdate = bars.selectAll('g').data(barTotalD);
        barsUpdate
          .join('g')
          .call((g) =>
            g
              .attr('transform', (d) => `translate(${xBarTotalScale(d.category)},0)`)
              .selectAll('path')
              .data((d) => {
                return barGroupX.map((key) => ({ x: key, y: d[key] }));
              })
              .join('path')
              .attr('d', (d) =>
                roundedRect(
                  xBarGroupScale(d.x),
                  yScale(d.y),
                  xBarGroupScale.bandwidth(),
                  chartHeight - yScale(d.y),
                  [5, 5, 0, 0]
                )
              )
              .attr('fill', (d) => {
                return d.x === 'withoutRetentics' ? '#C1BEF7' : '#4B43FF';
              })
          )
          .on('mouseover', () => {
            tooltip.style('visibility', 'visible');
          })
          .on('mousemove', (event, d) => {
            const tooltipX = tooltip.select('.x');
            const tooltipY = tooltip.select('.y');
            const xTotalPosition = xBarTotalScale(d.category);
            const [x, y] = d3.pointer(event);

            tooltipX.html(d.category);
            if (!isWithRetenticsDisabled && !isWithoutRetenticsDisabled) {
              tooltipY
                .select('.label')
                .html(
                  xBarTotalScale.bandwidth() / 2 < x
                    ? t("overview.without_retentics") + ':&nbsp;'
                    : t("overview.with_retentics") + ':&nbsp;'
                );
              tooltipY
                .select('.value')
                .html(
                  numberWithCommas(
                    xBarTotalScale.bandwidth() / 2 < x ? d.withoutRetentics : d.withRetentics
                  )
                );
            } else if (!isWithRetenticsDisabled) {
              tooltipY.select('.label').html('withRetentics:&nbsp;');
              tooltipY.select('.value').html(numberWithCommas(d.withRetentics));
            } else if (!isWithoutRetenticsDisabled) {
              tooltipY.select('.label').html('withoutRetentics:&nbsp;');
              tooltipY.select('.value').html(numberWithCommas(d.withoutRetentics));
            }

            tooltip
              .style(
                'left',
                x -
                  d3.select('.tooltip.abt').node().getBoundingClientRect().width / 2 +
                  xTotalPosition +
                  'px'
              )
              .style('top', y - 30 + 'px');
          })
          .on('mouseout', () => tooltip.style('visibility', 'hidden'));
      }
    },
    [data, svgWidth, svgHeight]
  );
  return (
    <div className={`overview-abt-chart-container ${chartId}`}>
      <div className={`overview-abt-chart-title ${chartId}`}>{title}</div>
      <svg className={`overview-abt-chart ${chartId}`} ref={ref}></svg>
    </div>
  );
}
