import styled from '@emotion/styled';
import { theme } from 'styles@emotion/theme';

export const Container = styled.div`
  width: 100%;
`;
export const Title = styled.div`
  ${theme.font.chartTitle}
  color: ${theme.color.gray900};
`;
export const GrayBox = styled.div`
  padding: 16px;
  background-color: ${theme.color.gray100};
  & li {
    cursor: text;
  }
`;
export const List = styled.ul`
  margin-left: 20px;
  margin-right: 10px;
  list-style-type: none;
`;
export const ListItem = styled.li`
  position: relative;
  display: flex;
  column-gap: 4px;
  &:before {
    content: '•';
    position: absolute;
    left: -15px;
    font-size: 18px;
  }
  cursor: pointer;
`;
export const ExportIcon = styled.img``;
