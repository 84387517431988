import { useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import BaseLayout from 'components/layout/BaseLayout';
import WithHeaderLayout from 'components/layout/WithHeaderLayout';
import FullLayout from 'components/layout/FullLayout';
import Overview from 'pages/Overview';
import Segmentation from 'pages/Segmentation';
import Retention from 'pages/Retention';
import CohortAnalysis from 'pages/CohortAnalysis';
import Comparison from 'pages/Comparison';
import MosaicAnalysis from 'pages/MosaicAnalysis';
import Forecasting from 'pages/Forecasting';
import CrossSelling from 'pages/CrossSelling/CrossSelling';
import UserBased from 'pages/UserBased/UserBased';
import FeatureBased from 'pages/FeatureBased/FeatureBased';
import Reports from 'pages/Reports';
import DataCenter from 'pages/DataCenter/DataCenter';
import NewMember from 'pages/NewMember';

import Login from 'pages/Login/Login';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import Error from 'pages/Error';
import Profile from 'pages/Profile';
import Member from 'pages/Member';
import Organization from 'pages/Organization';
import { DashboardProvider, AuthProvider, useUserInfoContext } from 'context';
import LeadToCustomer from 'pages/LeadToCustomer/LeadToCustomer';
import { useLocationInfo } from 'hooks';
import { clearStore } from 'utils/userStorage';
import ItemJourney from 'pages/ItemJourney';

function App() {
  const { location } = useLocationInfo();
  if (location.pathname === '/') return <Navigate to="/login" />;

  /**
   * the authorization is controlled by AuthProvider.
   * The AuthContext directs clients' route by one's own authority.
   */

  /**
   * Every layouts (BaseLayout, WithHeaderLayout, FullLayout) are only about UI.
   */

  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider>
        <DashboardProvider>
          <Routes>
            <Route element={<BaseLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="new-member" element={<NewMember />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
            </Route>

            <Route element={<WithHeaderLayout />}>
              <Route path="profile" element={<Profile />} />
              <Route path="member" element={<Member />} />
              <Route path="organization" element={<Organization />} />
              <Route path="data-center" element={<DataCenter />} />
            </Route>

            <Route element={<FullLayout />}>
              <Route path="marketing-overview" element={<Overview />} />
              <Route path="retention" element={<Retention />} />
              <Route path="segment-overview" element={<Segmentation />} />
              <Route path="mosaic-analysis" element={<MosaicAnalysis />} />
              <Route path="cohort-analysis" element={<CohortAnalysis />} />
              <Route path="comparison" element={<Comparison />} />
              <Route path="item-journey" element={<ItemJourney />} />
              <Route path="cross-selling" element={<CrossSelling />} />
              <Route path="user-based" element={<UserBased />} />
              <Route path="feature-based" element={<FeatureBased />} />
              <Route path="lead-to-customer" element={<LeadToCustomer />} />
            </Route>
            <Route path="*" element={<Error />} />
          </Routes>
        </DashboardProvider>
      </AuthProvider>
    </>
  );
}

function QueryClientWrapper() {
  const { setUserInfo } = useUserInfoContext();

  // setUserInfo 때문에 리렌더링되는 것을 막기 위해 cache
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false, // default: 3
            refetchOnWindowFocus: false, // default: true
            onError: (error) => {
              if (error.request?.status >= 400 && error.request?.status < 500) {
                clearStore();
                setUserInfo(null);
              }
            },
            staleTime: 1000 * 60 * 15 // 15 minutes
          }
        }
      }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  );
}

// may need to change this name to something else
export default QueryClientWrapper;
