import { useEffect } from 'react';
import { useMosaic, usePageInit } from 'hooks';
import * as Styles from './index.styles';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/emotion/PageTitle';
import Result from './Result';
import PrepareFile from './PrepareFile';
import { TABLETAB } from 'constants/images';
import { theme } from 'styles@emotion/theme';

const MosaicAnalysis = () => {
  const { t } = useTranslation();
  const [isDataReady, data, handleTabSelect, tab, candidateStatus] = useMosaic();

  useEffect(() => {
    usePageInit();
  }, []);

  return (
    <>
      <PageTitle main="Mosaic Analysis" sub={t('mosaic.find_leading_indicators_that_influence')} />
      <Styles.Buttons>
        <Styles.Button onClick={handleTabSelect} value="current" selected={tab === 'current'}>
          <TABLETAB
            onClick={(e) => {
              e.stopPropagation();
            }}
            color={tab === 'current' ? theme.color.neonBlue500 : theme.color.neonBlue50}
          />
          Current
        </Styles.Button>
        <Styles.Button onClick={handleTabSelect} value="history" selected={tab === 'history'}>
          <TABLETAB
            onClick={(e) => {
              e.stopPropagation();
            }}
            color={tab === 'history' ? theme.color.neonBlue500 : theme.color.neonBlue50}
          />
          History
        </Styles.Button>
        <Styles.ButtonLine />
      </Styles.Buttons>
      <Styles.Container>
        {tab === 'current' && <PrepareFile />}
        {tab === 'current' && isDataReady && (
          <Result data={data.current} tab={tab} candidateStatus={candidateStatus} />
        )}
        {tab === 'history' && isDataReady && (
          <Result data={data.history} tab={tab} candidateStatus={candidateStatus} />
        )}
      </Styles.Container>
    </>
  );
};

export default MosaicAnalysis;
