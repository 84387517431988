import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useClickOutside } from './useClickOutside';

const initialState = {
  isFocus: false,
  value: ''
};

function reducer(state, action) {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isFocus: false,
        value: action.value
      };
    case 'SELECT_CLICK':
      return {
        ...state,
        isFocus: action.isFocus,
        value: ''
      };
    case 'REMOVE_CLICK':
      return {
        ...state,
        value: ''
      };
    case 'ITEM_CLICK':
      return {
        ...state,
        isFocus: false,
        value: action.value
      };
    case 'OUTSIDE_CLICK':
      return {
        ...state,
        isFocus: false,
        value: action.value
      };
    case 'INPUT_TYPE':
      return {
        ...state,
        isFocus: true,
        value: action.value
      };
    default:
      return state;
  }
}

export const useSelect = ({ disabled, property, items, selected, handleSelect }) => {
  const value = useMemo(() => selected?.value ?? '', [selected]);

  const inputContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [isClickedInside] = useClickOutside(inputContainerRef);
  const [list, setList] = useState(items);
  const [state, dispatch] = useReducer(reducer, { ...initialState, value });

  const resetList = useMemo(() => () => setList(items), [items]);

  const handleSelectClick = () => {
    if (disabled) return;

    if (!state.isFocus) {
      inputRef.current.focus();
      dispatch({ type: 'SELECT_CLICK', isFocus: true, value });
    } else {
      inputRef.current.blur();

      const matchedItem = items.find((item) => item.value === state.value);

      if (!matchedItem) {
        dispatch({ type: 'SELECT_CLICK', isFocus: false, value });
      } else {
        dispatch({ type: 'SELECT_CLICK', isFocus: false, value: matchedItem.value });
        handleSelect(matchedItem, property);
      }
      resetList();
    }
  };
  const handleRemoveClick = () => {
    dispatch({ type: 'REMOVE_CLICK' });
  };
  const handleItemClick = (item) => () => {
    dispatch({ type: 'ITEM_CLICK', value: item.value });
    handleSelect(item, property);
    resetList();
  };
  const handleOutsideClick = () => {
    const matchedItem = items?.find((item) => item.value === state.value);

    if (!matchedItem) {
      dispatch({ type: 'OUTSIDE_CLICK', value });
    } else {
      dispatch({ type: 'OUTSIDE_CLICK', value: matchedItem.value });
      handleSelect(matchedItem, property);
    }
    resetList();
  };
  const handleInputType = (e) => {
    dispatch({ type: 'INPUT_TYPE', value: e.target.value });
    setList(items.filter((item) => item.value.includes(e.target.value)));
  };

  useEffect(() => {
    if (!isClickedInside) {
      handleOutsideClick();
    }
  }, [isClickedInside]);

  useEffect(() => {
    setList(items);
  }, [items]);

  // 외부적인 동작 (노드를 클릭하거나, Apply로 새로운 데이터를 불러왔을때)이 있을 때 value 수정
  useEffect(() => {
    if (!selected) {
      dispatch({ type: 'INITIALIZE', value });
    } else {
      dispatch({ type: 'ITEM_CLICK', value: selected.value });
      handleSelect(selected, property);
      resetList();
    }
  }, [selected]);

  return {
    ref: {
      inputContainer: inputContainerRef,
      input: inputRef
    },
    state,
    list,
    handler: {
      handleSelectClick,
      handleInputType,
      handleItemClick,
      handleOutsideClick,
      handleRemoveClick
    }
  };
};
