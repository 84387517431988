import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { theme } from 'styles@emotion/theme';

const spin = keyframes`
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }`;

const Button = styled.button`
  padding: 12px 23px;
  height: 48px;
  border: 0;
  border-radius: 100px;
  background-color: ${({ disabled, isLoading }) =>
    disabled || isLoading ? theme.color.neonBlue50 : theme.color.neonBlue500};
  ${theme.font.buttonRegular};
  color: ${theme.color.white};
  cursor: ${({ disabled, isLoading }) => (disabled || isLoading ? 'default' : 'pointer')};

  &:hover {
    background-color: ${({ disabled, isLoading }) =>
      disabled || isLoading ? theme.color.neonBlue50 : theme.color.neonBlue400};
  }
  &:active {
    background-color: ${({ disabled, isLoading }) =>
      disabled || isLoading ? theme.color.neonBlue50 : theme.color.neonBlue600};
  }
`;
const Spinner = styled.img`
  animation: ${spin} 1200ms infinite linear;
`;

export default { Button, Spinner };
