import { useEffect, useRef } from 'react';
import i18n from '../../i18n';
import * as d3 from 'd3';
import { chartKeys } from 'constants/keys';
import { Chart } from './chartConfig';
import { COLORS } from 'hooks/service/useGetCustomerService/useGetResult/dataHelpers';
import { sumOfArray } from 'utils';
import { useTranslation } from 'react-i18next';

export function useChart(chartId, data, options) {
  const ref = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    /**
     * Below 2 lines for removing pre-drawn elements(chart svg, tooltip div)
     */
    ref.current.innerHTML = '';
    d3.select(ref.current.parentNode).select('.tooltip').node()?.remove();

    charts[chartId](d3.select(ref.current), data, options, t);
  }, [data, options]);

  return { ref, t };
}

const charts = {
  margin: { top: 20, right: 43, bottom: 20, left: 40 },
  lowerPadding: 35,
  [chartKeys.COMPARISON](svg, data, options) {
    const { isLegendSelect } = options;
    const D = data.filter((d) => isLegendSelect[d.segmentId]);
    const X = [...new Set(d3.map(data, (d) => d.x))].sort((a, b) =>
      d3.ascending(Number(a.replace(/^\D+/g, '')), Number(b.replace(/^\D+/g, '')))
    );
    const Y = d3.map(D, (d) => d.y);

    const width = 684;
    const height = 220;
    const margin = { top: 30, right: 0, bottom: 20, left: 40 };

    const chart = Chart(svg);

    chart.width(width);

    chart.height(height);

    chart.xScale({
      type: d3.scaleBand,
      domain: X,
      range: [margin.left, width]
    });
    chart.yScale({
      type: d3.scaleLinear,
      domain: [0, d3.max(Y)],
      range: [height - margin.bottom, margin.top]
    });
    chart.xAxis({
      yPosition: height - margin.bottom,
      interval: 3
    });
    chart.yAxis({
      xPosition: margin.left
    });
    chart.grid({
      xPosition: margin.left,
      tickSize: -(width - margin.left)
    });
    chart.comparison({
      data: D,
      height,
      margin: margin,
      options
    });
    chart.comparisonTooltip({
      margin: margin,
      width: width,
      height: height,
      data: D
    });
  },
  [chartKeys.SEGMENTATION.POPULATION_SALES_AND_TRANSACTIONS](svg, data, options) {
    const { isLegendSelect, isAxisSelect } = options;
    const { churn, last, segments } = isLegendSelect;
    const { transactions, sales } = isAxisSelect;

    /*
      1 d: last on/off 
      2 x: transactions avg/total 
      3 y: sales avg/total
      4 z: churn on/off 
    */
    function formatDataset(input, ...conditions) {
      const [isChurnIncluded, isLast] = conditions;

      let filteredSegmentsData;
      let lastData;
      let currentData;
      let currentWholePopulation;

      let output;

      const filteredColors = COLORS.filter((color, i) => segments[i]);
      const exclusion = isChurnIncluded ? 'churnIncluded' : 'churnExcluded';
      const transactionsCalculation = transactions === 'avg' ? 'average' : 'total';
      const salesCalculation = sales === 'avg' ? 'average' : 'total';

      if (isChurnIncluded) {
        currentWholePopulation = input.current.churnIncluded.reduce(
          (acc, cur) => acc + cur.z.pie[0].y + cur.z.pie[1].y,
          0
        );
      } else {
        currentWholePopulation = input.current.churnExcluded.reduce(
          (acc, cur) => acc + cur.z.pie[1].y,
          0
        );
      }

      if (!isLast || !input.last.churnExcluded || !input.last.churnIncluded) {
        filteredSegmentsData = {
          segments: input.segments.filter((segment, i) => segments[i]),
          last: null,
          current: {
            churnIncluded: input.current.churnIncluded.filter((v, i) => segments[i]),
            churnExcluded: input.current.churnExcluded.filter((v, i) => segments[i])
          }
        };
        lastData = null;
        currentData = filteredSegmentsData.current[exclusion].map((value) => ({
          ...value,
          x: value.x[transactionsCalculation],
          y: value.y[salesCalculation],
          z: value.z.pie
        }));
      } else {
        filteredSegmentsData = {
          segments: input.segments.filter((segment, i) => segments[i]),
          last: {
            churnIncluded: input.last.churnIncluded.filter((v, i) => segments[i]),
            churnExcluded: input.last.churnExcluded.filter((v, i) => segments[i])
          },
          current: {
            churnIncluded: input.current.churnIncluded.filter((v, i) => segments[i]),
            churnExcluded: input.current.churnExcluded.filter((v, i) => segments[i])
          }
        };
        lastData = filteredSegmentsData.last[exclusion].map((value) => ({
          ...value,
          x: value.x[transactionsCalculation],
          y: value.y[salesCalculation],
          z: value.z.pie
        }));
        currentData = filteredSegmentsData.current[exclusion].map((value) => ({
          ...value,
          x: value.x[transactionsCalculation],
          y: value.y[salesCalculation],
          z: value.z.pie
        }));
      }

      if (!isLast || !input.last.churnExcluded || !input.last.churnIncluded) {
        output = filteredSegmentsData.segments.map((segment, index) => {
          const currentTargetData = currentData[index];

          function calculateTooltipValues(targetData) {
            return [
              targetData['z'][0]['y'] + targetData['z'][1]['y'],
              targetData['x'],
              targetData['y'],
              targetData['z'][0]['y']
            ];
          }
          const [currentPopulation, currentTransactions, currentSales, currentInactive] =
            calculateTooltipValues(currentTargetData);

          return {
            name: segment.segment_name,
            percentageOfTotal: Math.round((currentPopulation / currentWholePopulation) * 100),
            color: filteredColors[index],
            last: null,
            current: {
              x: currentTargetData.x,
              y: currentTargetData.y,
              z: currentTargetData.z
            },
            tooltipData: {
              values: {
                population: currentPopulation,
                transactions: currentTransactions,
                sales: currentSales,
                inactive: currentInactive
              },
              variations: null
            }
          };
        });
      } else {
        output = filteredSegmentsData.segments.map((segment, index) => {
          const currentTargetData = currentData[index];
          const lastTargetData = lastData[index];

          function calculateTooltipValues(targetData) {
            return [
              targetData['z'][0]['y'] + targetData['z'][1]['y'],
              targetData['x'],
              targetData['y'],
              targetData['z'][0]['y']
            ];
          }

          const [currentPopulation, currentTransactions, currentSales, currentInactive] =
            calculateTooltipValues(currentTargetData);
          const [lastPopulation, lastTransactions, lastSales, lastInactive] =
            calculateTooltipValues(lastTargetData);

          function calculateTooltipVariations() {
            return [
              lastPopulation === 0
                ? currentPopulation - lastPopulation + i18n.t('overview.customer_unit')
                : Math.round(((currentPopulation - lastPopulation) / lastPopulation) * 100) + '%',
              lastTransactions === 0
                ? currentTransactions - lastTransactions + i18n.t('overview.customer_unit')
                : Math.round(((currentTransactions - lastTransactions) / lastTransactions) * 100) +
                  '%',
              lastSales === 0
                ? currentSales - lastSales + i18n.t('overview.customer_unit')
                : Math.round(((currentSales - lastSales) / lastSales) * 100) + '%',
              lastInactive === 0
                ? currentInactive - lastInactive + i18n.t('overview.customer_unit')
                : Math.round(((currentInactive - lastInactive) / lastInactive) * 100) + '%'
            ];
          }
          const [populationVariation, transactionsVariation, salesVariation, inactiveVariation] =
            calculateTooltipVariations();

          return {
            name: segment.segment_name,
            percentageOfTotal: Math.round((currentPopulation / currentWholePopulation) * 100),
            color: filteredColors[index],
            last: {
              x: lastTargetData.x || 0,
              y: lastTargetData.y || 0,
              z: lastTargetData.z || []
            },
            current: {
              x: currentTargetData.x,
              y: currentTargetData.y,
              z: currentTargetData.z
            },
            tooltipData: {
              values: {
                population: currentPopulation,
                transactions: currentTransactions,
                sales: currentSales,
                inactive: currentInactive
              },
              variations: {
                population: populationVariation,
                transactions: transactionsVariation,
                sales: salesVariation,
                inactive: inactiveVariation
              }
            }
          };
        });
      }

      return output;
    }

    const D = formatDataset(data, churn, last);
    const X = d3.map(D, (d) => ({
      last: last ? d.last?.x : null,
      current: d.current.x
    }));
    const Y = d3.map(D, (d) => ({
      last: last ? d.last?.y : null,
      current: d.current.y
    }));
    const Z = d3.map(D, (d) => ({
      last: last ? d.last?.z : null,
      current: d.current.z
    }));

    const width = 1036;
    const height = 384;

    const chart = Chart(svg);

    chart.width(width);

    chart.height(height);

    chart.xScale({
      type: d3.scaleLinear,
      domain: [
        0,
        d3.max(
          X.reduce((acc, cur) => {
            if (cur.last !== null) acc.push(cur.last);
            acc.push(cur.current);
            return acc;
          }, [])
        )
      ],
      range: [this.margin.left, width - this.margin.right]
    });

    chart.yScale({
      type: d3.scaleLinear,
      domain: [
        0,
        d3.max(
          Y.reduce((acc, cur) => {
            if (cur.last !== null) acc.push(cur.last);
            acc.push(cur.current);
            return acc;
          }, [])
        )
      ],
      range: [height - this.margin.bottom, this.margin.top]
    });

    chart.zScale({
      type: d3.scaleLinear,
      domain: [
        d3.min(
          Z.reduce((acc, cur) => {
            if (cur.last) {
              acc.push(cur.last[0]['y'] + cur.last[1]['y']);
            }
            acc.push(cur.current[0]['y'] + cur.current[1]['y']);
            return acc;
          }, [])
        ),
        d3.max(
          Z.reduce((acc, cur) => {
            if (cur.last) {
              acc.push(cur.last[0]['y'] + cur.last[1]['y']);
            }
            acc.push(cur.current[0]['y'] + cur.current[1]['y']);
            return acc;
          }, [])
        )
      ],
      range: [17, 90]
    });

    chart.xAxis({
      yPosition: height - this.margin.bottom
    });

    chart.yAxis({
      xPosition: this.margin.left
    });

    chart.grid({
      xPosition: this.margin.left,
      tickSize: -(width - this.margin.left)
    });

    chart.segmentation.populationSalesAndTransactions({
      data: D
    });
  },
  [chartKeys.SEGMENTATION.RECENCY_FREQUENCY_AND_MONETARY_VALUE](svg, data, options) {
    const D = data;
    const X_CURRENT = d3.map(D, (d) => d.xCurrent);
    const X_LAST = d3.map(D, (d) => d.xLast);
    const Y = d3.map(D, (d) => d.y);

    const width = 331;
    const height = 47 * Y.length + 10 * Y.length;

    const chart = Chart(svg);

    chart.width(width);
    chart.height(height);
    chart.xScale({
      type: d3.scaleLinear,
      domain: [0, d3.max([...X_LAST, ...X_CURRENT])],
      range: [this.margin.left, width - this.margin.right]
    });
    chart.yScale({
      type: d3.scaleBand,
      domain: Y,
      range: [0, height]
    });
    chart.xAxis({
      yPosition: height
    });
    chart.yAxis({
      xPosition: 0
    });
    chart.segmentation.recencyFrequencyAndMonetaryValue({
      data: D,
      height,
      margin: this.margin,
      options
    });
  },
  [chartKeys.SEGMENTATION.NUMBER_OF_CATEGORIES_AND_CUSTOMERS](svg, data, options) {
    const { isLegendSelect } = options;

    const D = data.filter((_, i) => isLegendSelect.segments[i]);
    const X = d3.map(D, (d) => d.x);
    const Y_CURRENT = d3.map(D, (d) => d.yCurrent);
    const Y_CURRENT_PERCENTAGE = d3.map(D, (d) => d.currentPercentage);
    const Y_LAST = d3.map(D, (d) => d.yLast);
    const Y_LAST_PERCENTAGE = d3.map(D, (d) => d.lastPercentage);
    const width = 352;
    const height = 244;
    const margin = { top: 30, right: 43, bottom: 10, left: 50 };

    const chart = Chart(svg);

    chart.width(width);
    chart.height(height);
    chart.xScale({
      type: d3.scaleBand,
      domain: X,
      range: [this.margin.left, width],
      paddingInner: 0.3,
      paddingOuter: 0.3
    });

    const yDomainCurrent = Y_CURRENT_PERCENTAGE.includes(null) ? Y_CURRENT : Y_CURRENT_PERCENTAGE;
    const yDomainLast = Y_LAST_PERCENTAGE.includes(null) ? Y_LAST : Y_LAST_PERCENTAGE;

    chart.yScale({
      type: d3.scaleLinear,
      domain: [
        0,
        d3.max(isLegendSelect.last ? [...yDomainLast, ...yDomainCurrent] : [...yDomainCurrent])
      ],
      range: [height - margin.bottom, margin.top]
    });

    chart.xAxis({
      yPosition: height
    });
    chart.yAxis({
      xPosition: this.margin.left
    });
    chart.grid({
      xPosition: this.margin.left,
      tickSize: -(width - this.margin.left)
    });
    chart.segmentation.numberOfCategoriesAndCustomers({
      data: D,
      height,
      margin,
      options
    });
  },
  [chartKeys.MOSAIC.BAR](svg, data, options) {
    const { legendAndColors } = options;

    const D = data.map((d) => {
      const values = {
        x: d.options.segment_name,
        segmentId: String(d.options.segment_id)
      };

      d.chart_values.forEach((v) => {
        // legend 클릭 여부에 따라 보여야할 항목들을 필터링한다.
        if (legendAndColors.find((item) => item.name === v.x).isActive) {
          values[v.x] = v.y;
        }
      });

      return values;
    });

    const X = D.map((d) => d.x); // Group1, 높은 활동성, Group3, 일반고객, 충성고객
    const Z = Object.keys(D[0]).filter((d) => d !== 'x' && d !== 'segmentId'); // blue, green, Red, white, Black

    const proportionD = JSON.parse(JSON.stringify(D)).map((d) => {
      let sumOfValues = sumOfArray(Object.values(d).filter((d) => typeof d !== 'string'));
      for (const property in d) {
        if (typeof d[property] === 'string') continue;
        d[property] = (d[property] / sumOfValues) * 100;
      }
      return d;
    });

    const stackedD = d3.stack().keys(Z)(proportionD);

    const width = 336;
    const height = 190;
    const margin = { top: 10, right: 0, bottom: 40, left: 0 };

    Chart(svg)
      .width(width)
      .height(height)
      .xScale({
        type: d3.scaleBand,
        domain: X,
        range: [0, width],
        paddingInner: 0.7,
        paddingOuter: 0.8
      })
      .yScale({
        type: d3.scaleLinear,
        domain: [0, 100],
        range: [height - margin.bottom, margin.top]
      })
      .xAxis({
        yPosition: height - margin.bottom
      })
      .mosaic.bar({
        data: stackedD,
        options: {
          actualData: D,
          legendAndColors,
          tooltipYPositionAid: -5
        }
      });
  },
  [chartKeys.MOSAIC.BOX_PLOT](svg, data, options) {
    const { legendAndColors } = options;

    const D = data
      // legend 클릭 여부에 따라 보여야할 항목들을 필터링한다.
      .filter((d) => legendAndColors.find((legend) => legend.id === d.options.segment_id).isActive)
      .map((d) => ({
        x: d.options.segment_name,
        value: {
          population: d.chart_values.find((v) => v.x === 'population').y,
          sample: d.chart_values.find((v) => v.x === 'sample').y,
          mean: d.chart_values.find((v) => v.x === 'mean').y,
          median: d.chart_values.find((v) => v.x === 'median').y,
          min: d.chart_values.find((v) => v.x === 'min').y,
          max: d.chart_values.find((v) => v.x === 'max').y,
          q1: d.chart_values.find((v) => v.x === 'q25').y,
          q3: d.chart_values.find((v) => v.x === 'q75').y
        }
      }));
    const X = D.map((d) => d.x);

    const width = 336;
    const height = 190;
    const averageLegendPortionHeight = 25;
    const heightWithAverageLegend = height + averageLegendPortionHeight;
    const margin = { top: 10, right: 0, bottom: 40, left: 35 };

    Chart(svg)
      .width(width)
      .height(heightWithAverageLegend)
      .xScale({
        type: d3.scaleBand,
        domain: X,
        range: [margin.left, width]
      })
      .yScale({
        type: d3.scaleLinear,
        domain: [0, d3.max(D.map((d) => d.value.max))],
        range: [heightWithAverageLegend - margin.bottom, margin.top + averageLegendPortionHeight]
      })
      .xAxis({
        yPosition: heightWithAverageLegend - margin.bottom
      })
      .yAxis({
        xPosition: this.margin.left
      })
      .grid({
        xPosition: this.margin.left,
        tickSize: -(width - this.margin.left)
      })
      .mosaic.boxPlot({
        data: D,
        options: {
          legendAndColors,
          tooltipYPositionAid: 35
        }
      });
  },
  [chartKeys.JOURNEY.BEST_JOURNEY](svg, data, options) {
    if (data.nodes.length === 0 && data.links.length === 0) return;

    const styles = {
      width: 1104,
      height: 300,
      position: {
        x: 110,
        y: 46
      }
    };

    Chart(svg).width(styles.width).height(styles.height).itemJourney.bestJourney({ data, styles });
  },
  [chartKeys.JOURNEY.JOURNEY](svg, data, options) {
    if (data.nodes.length === 0 && data.links.length === 0) return;
    const { handlers } = options;

    const styles = {
      width: 1104,
      height: 950,
      position: {
        y: 18
      }
    };
    Chart(svg)
      .width(styles.width)
      .height(styles.height)
      .itemJourney.journey({ data, styles, request: handlers.selectParameter });
  },
  [chartKeys.USER_BASED.CRM_SIMULATION](svg, data, options, t) {
    const { legendAndColors } = options;
    const styles = {
      width: 296,
      height: 290,
      margin: this.margin
    };
    const D = data.values.map((d) => {
      return {
        x: d.x,
        y: [
          { id: 0, value: d.y[0] },
          { id: 1, value: d.y[1] }
        ].filter((obj) => legendAndColors.find((legend) => legend.id === obj.id).isActive)
      };
    });

    const X = D.map((d) => d.x);
    const maxY = D.reduce((prevMax, cur) => {
      const maxOfTwo = d3.max(cur.y.map((obj) => obj.value));
      return d3.max([prevMax, maxOfTwo]);
    }, 0);

    Chart(svg)
      .width(styles.width)
      .height(styles.height)
      .xScale({
        type: d3.scaleBand,
        domain: X,
        range: [this.margin.left, styles.width],
        paddingInner: 0.3,
        paddingOuter: 0.1
      })
      .yScale({
        type: d3.scaleLinear,
        domain: [0, maxY],
        range: [styles.height - this.margin.bottom, this.margin.top]
      })
      .xAxis({
        yPosition: styles.height - this.margin.bottom
      })
      .yAxis({
        xPosition: this.margin.left
      })
      .grid({
        xPosition: this.margin.left,
        tickSize: -(styles.width - this.margin.left)
      })
      .userBased.crmSimulation({
        data: D,
        styles,
        legendAndColors,
        total: data.total,
        translation: t
      });
  }
};
