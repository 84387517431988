import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from 'hooks/UI';
import { CHEVRON_DOWN, REMOVETEXT } from 'constants/images';
import './index.scss';

import { labelToTranslationKey } from 'utils';

export default function OriginalSearchInput({
  label,
  width = 300,
  style = {},
  options = null,
  selectedValue,
  selectValue,
  disabled,
  helpTooltip,
}) {
  const { t } = useTranslation();
  const containerEl = useRef(null);
  const inputEl = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFullOption, setIsFullOption] = useState(true);
  const [isClickedInside, setIsClickedInside] = useClickOutside(containerEl);
  const [inputValue, setInputValue] = useState(selectedValue);

  useEffect(() => {
    if (!isClickedInside) {
      setIsFocused(false);
    }
  }, [isClickedInside]);

  // this is only because of applying change of range values when interval changes in Retention
  useEffect(() => {
    setInputValue(selectedValue);
  }, [selectedValue]);

  // when focus out, if typed input is valid, set it to the parameter
  useEffect(() => {
    if (isFocused) {
      setIsFullOption(true);
      setInputValue('');
    }
    if (!isFocused) {
      const typedInput = options.filter((v) => v.value === inputValue);
      if (typedInput.length === 1) {
        selectValue(typedInput[0], label);
      } else {
        setInputValue(selectedValue);
      }
    }
  }, [isFocused]);

  let newLabel = t(`options.${labelToTranslationKey(
    label === 'trackFirstItemOnly'
      ? 'track identical item only'
      : label === 'anyMoment'
      ? 'any moment'
      : label.includes('_')
      ? label.replace('_', ' ')
      : label
  )}`)

  if (newLabel.includes(".")) {
    newLabel = (
      label === 'trackFirstItemOnly'
      ? 'track identical item only'
      : label === 'anyMoment'
      ? 'any moment'
      : label.includes('_')
      ? label.replace('_', ' ')
      : label
    )
  }
   
  return (
    <div
      ref={containerEl}
      className="search-input"
      style={{ ...style, width: `${width}px`, minWidth: `${width}px` }}
    >
      <label className="input-label" onClick={() => setIsClickedInside(false)}>
        {newLabel}
        
        {helpTooltip}
      </label>
      <div className={`input-wrapper ${label}`}>
        <input
          value={inputValue}
          onInput={(e) => {
            setInputValue(e.target.value);
            if (isFullOption) setIsFullOption(false);
          }}
          ref={inputEl}
          className="input"
          placeholder="Search"
          disabled={disabled}
          onClick={() => {
            setIsFocused(true);
          }}
          // onKeyDown={(e) => {
          //   if (e.key === 'ArrowDown') {
          //   }
          //   if (e.key === 'ArrowUp') {
          //   }
          //   if (e.key === 'Enter') {
          //   }
          // }}
        />
        {isFocused && (
          <img
            src={REMOVETEXT}
            className="remove-text-icon"
            alt="remove-text"
            onClick={() => {
              setInputValue('');
            }}
          />
        )}
        <img
          onClick={() => {
            if (disabled) {
              return;
            }

            setIsFocused((prev) => !prev);
            if (!isFocused) inputEl.current.focus();
          }}
          src={CHEVRON_DOWN}
          className={`chevron-down${isFocused ? ' reverse' : ''} ${disabled ? 'disabled' : ''}`}
          alt="drop-down"
        />
      </div>
      {options &&
        isFocused &&
        (isFullOption ? (
          <ul className="options-list">
            {options.map((option, i) => (
              <li
                onClick={() => {
                  setInputValue(option.value);
                  selectValue(option, label);
                  setIsFocused(false);
                }}
                className={'option' + (selectedValue === option.value ? ' selected' : '')}
                key={`${option.value}${i}`}
              >
                {option.value}
              </li>
            ))}
          </ul>
        ) : options.filter((v) => v.value.includes(inputValue)).length === 0 ? (
          <></>
        ) : (
          <ul className="options-list">
            {options
              .filter((v) => v.value.includes(inputValue))
              .map((option, i) => (
                <li
                  onClick={() => {
                    setInputValue(option.value);
                    selectValue(option, label);
                    setIsFocused(false);
                  }}
                  className={'option' + (selectedValue === option.value ? ' selected' : '')}
                  key={`${option.value}${i}`}
                >
                  {option.value}
                </li>
              ))}
          </ul>
        ))}
    </div>
  );
}
