import styled from '@emotion/styled';
import { theme } from 'styles@emotion/theme';

const Container = styled.div`
  padding-top: 30px;
  border-top: 1px solid ${theme.color.gray400};
  grid-column: span 2;
`;
const CollapseButton = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;
const Title = styled.div`
  ${theme.font.chartTitle}
  color: ${theme.color.gray900};
`;
const ChevronIcon = styled.img`
  transform: ${({ isReverse }) => (isReverse ? 'rotate(180deg)' : 0)};
`;

const Images = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 16px;

  width: 100%;
`;
const Image = styled.img`
  width: 352px;
`;

export default { Container, CollapseButton, Title, ChevronIcon, Images, Image };
