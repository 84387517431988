import React, { useCallback, useState } from 'react';
import { RETENTICS_LOGIN_LOGO } from 'constants/images';

import './ForgotPassword.scss';

import FillEmail from './FillEmail';
import FillCode from './FillCode';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const [values, setValues] = useState({
    email: '',
    code: '',
    password: '',
    repassword: ''
  });

  const [isFilled, setIsFilled] = useState(false);

  const [currentStep, setCurrentStep] = useState('email');

  const renderStep = (currentStep) => {
    const pages = {
      email: (
        <FillEmail
          isFilled={isFilled}
          setIsFilled={setIsFilled}
          values={values}
          setValues={setValues}
          onChange={onChange}
          setCurrentStep={setCurrentStep}
        />
      ),
      reset: (
        <FillCode
          isFilled={isFilled}
          setIsFilled={setIsFilled}
          values={values}
          setValues={setValues}
          onChange={onChange}
          setCurrentStep={setCurrentStep}
        />
      )
    };
    return pages[currentStep];
  };

  const onChange = useCallback((e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, []);

  return (
    <div className="ForgotPassword">
      <div className="ForgotPassword_box">
        <Link to="/login">
          <div className="ForgotPassword_box_logo">
            <img src={RETENTICS_LOGIN_LOGO} className="ForgotPassword_box_logo_img" />
          </div>
        </Link>

        <div className="ForgotPassword_box_insidebox">{renderStep(currentStep)}</div>
      </div>
    </div>
  );
};

export default ForgotPassword;
