export const customerAPI = {
  OPTION: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-option`,
  OPTION_CATEGORY: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-option-category`,
  OPTION_SEARCH: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-option-search`,
  OVERVIEW: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-overview`,
  SEGMENTATION: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-segmentation`,
  RETENTION: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-retention`,
  LTV: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-life-time-value`,
  FORECASTING: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-forecast`,
  CROSS_SELLING: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-cross-selling`,
  USER_BASED: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-user-based-recommendation`,
  FEATURE_BASED: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-feature-based-recommendation`,
  REPORT: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-report`,
  REPORT_EVALUATE: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-evaluate-report`,
  OPEN_REPORT: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-open-report`,
  GET_EXPORT: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-get-export`,
  LOG_EXPORT: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-log-export`,
  LEAD_TO_CUSTOMER: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-lead-time`,
  EDIT_SEGMENT_NAME: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-update-segment-name`,
  GENERATE_UPLOADING_URL: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-generate-uploading-url`,
  SUMMARY_UPLOADED_DATASET: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-summary-uploaded-dataset`,
  MOSAIC: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-mosaic`,
  DELETE_MOSAIC: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-delete-mosaic`,
  ITEM_JOURNEY:`lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-item-journey`,
  EDIT_MOSAIC: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-update-mosaic`,
  CANDIDATE: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-candidate`,
  UPDATE_CANDIDATE: `lambdaro/momentum/customer-${process.env.REACT_APP_API_ENV}-update-candidate`
};
