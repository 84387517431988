import { useGetCustomerService, useCustomerServiceFetch } from 'hooks';
import { initialParameters as crossSellingParams } from 'pages/CrossSelling/utils';
import { queryKeys } from 'constants/keys';
import { useUserInfoContext } from 'context';
import { useQuery } from 'react-query';
import useGetOption from 'hooks/service/useGetCustomerService/useGetOption';
import { axiosPrivate, customerAPI } from 'api';

function DataPreloader() {
  const { userInfo } = useUserInfoContext();

  // segmentation
  // useGetCustomerService에 있는 특정로직 애문에 여기에서는 실제적인 데이터가 preload 되지 않아서 주석처리
  // useGetCustomerService({
  //   serviceQueryKey: queryKeys.SERVICE.SEGMENTATION
  // });

  // retention
  useQuery(
    [queryKeys.OPTION.RETENTION, 'options', userInfo?.company.shopId],
    async ({ queryKey }) => {
      const { data } = await axiosPrivate.post(customerAPI.OPTION, {
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        page: queryKey[0]
      });
      return data;
    }
  );

  // cohort
  useQuery([queryKeys.OPTION.COHORT, 'options', userInfo?.company.shopId], async ({ queryKey }) => {
    const { data } = await axiosPrivate.post(customerAPI.OPTION, {
      company_id: userInfo?.company.companyId,
      shop_id: userInfo?.company.shopId,
      page: queryKey[0]
    });
    return data;
  });

  // forecasting error
  // useGetCustomerService({
  //   optionQueryKey: queryKeys.OPTION.FORECASTING,
  //   serviceQueryKey: queryKeys.SERVICE.FORECASTING,
  // });
  useGetOption('forecasting');

  // cross-selling
  // useCustomerServiceFetch({
  //   optionQueryKey: queryKeys.OPTION.CROSS_SELLING,
  //   serviceQueryKey: queryKeys.SERVICE.CROSS_SELLING,
  //   parameters: crossSellingParams,
  // });

  // // lead to customer error
  // useGetCustomerService({
  //   optionQueryKey: queryKeys.OPTION.LEAD_TO_CUSTOMER,
  //   serviceQueryKey: queryKeys.SERVICE.LEAD_TO_CUSTOMER,
  // });
  useGetOption('lead_time');

  // user based
  // useGetCustomerService({
  //   optionQueryKey: queryKeys.OPTION.USER_BASED,
  //   serviceQueryKey: queryKeys.SERVICE.USER_BASED,
  // });
  useGetOption('user_based');

  // feature based
  // useGetCustomerService({
  //   optionQueryKey: queryKeys.OPTION.FEATURE_BASED,
  //   serviceQueryKey: queryKeys.SERVICE.FEATURE_BASED,
  // });
  useGetOption('feature_based');

  return null;
}

export default DataPreloader; // React.memo(DataPreloader)
