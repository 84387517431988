import {
  CHART_COLORS_LIGHT,
  CHART_COLORS,
  CHART_COLORS_BOOLEAN,
  CANDIDATE_STATUS
} from 'constants';

export function transformData(data) {
  const dashboardIds = data.dashboard_ids.filter((_, i) => i !== 0 && i < 6);

  let chartData;
  chartData = data.groups.map((d) => ({
    ...d,
    segments: d.charts.map((chart) => ({
      id: chart.options.segment_id,
      name: chart.options.segment_name,
      isActive: true
    })),
    dashboardId: data.dashboard.id,
    lastOrder: new Date(data.dashboard.to_time).toLocaleDateString('en-CA').replaceAll('-', '/')
  }));

  // chart type 별로 legend를 구분하는 방식이 다름
  // type이 number인 경우는 segment별로 legend/color가 구분되고,
  // type이 boolean이나 text인 경우는 category 별로 legend/color가 구분된다.
  chartData.forEach((chart) => {
    if (chart.file.type === 'number') {
      chart.legends = chart.charts.map((d, index) => ({
        id: d.options.segment_id,
        name: d.options.segment_name,
        chartColor: CHART_COLORS[index],
        lightChartColor: CHART_COLORS_LIGHT[index],
        isActive: true
      }));
    } else if (chart.file.type === 'boolean') {
      // chart_values에서 항상 True 값이 Fals 값보다 먼저 오도록 Sort.
      chart.charts = chart.charts.map((d) => {
        const obj = JSON.parse(JSON.stringify(d));
        obj.chart_values = obj.chart_values.sort((a, b) => b.x.localeCompare(a.x));
        return obj;
      });
      chart.legends = chart.charts[0].chart_values.map((d, index) => ({
        id: index,
        name: d.x,
        chartColor: CHART_COLORS_BOOLEAN[index],
        isActive: true
      }));
    } else {
      chart.legends = chart.charts[0].chart_values.map((d, index) => ({
        id: index,
        name: d.x,
        chartColor: CHART_COLORS[index],
        isActive: true
      }));
    }
  });

  return { chartData, dashboardIds };
}

export function transformCandidate(data) {
  return data.result.filter((d) => d.status !== 'cancelled');
}

export function determineCandidateStatus(validCharts, candidateData) {
  let status;

  const candidateOptions = candidateData
    .filter((d) => d.status === 'completed')
    .reduce(
      (prev, cur) => (new Date(prev.created_at) - new Date(cur.created_at) > 0 ? prev : cur),
      {}
    ).options;

  const serverCandidateList = !candidateOptions
    ? null
    : Object.keys(candidateOptions).map((d) => ({
        chart_group: d,
        ...candidateOptions[d]
      }));

  // candidateData가 하나도 없고 validCharts가 존재하지 않는 경우
  if (candidateData.length === 0 && validCharts.length === 0) {
    status = CANDIDATE_STATUS.INITIAL;
    // candidateData가 하나도 없고 validCharts가 존재하는 경우
  } else if (candidateData.length === 0 && validCharts.length !== 0) {
    status = CANDIDATE_STATUS.NOT_UPDATED;
    // validCharts가 존재하지 않고 serverCandidateList가 null인 경우 => INITIAL (버튼 HIDE)
  } else if (serverCandidateList === null && validCharts.length === 0) {
    status = CANDIDATE_STATUS.INITIAL;
    // serverData에 initial이나 started 상태가 있을 경우 => UPDATING
  } else if (candidateData.some((d) => d.status === 'initial' || d.status === 'started')) {
    status = CANDIDATE_STATUS.UPDATING;
    // validCharts가 존재하고 serverCandidateList 가 null인 경우 => NOT_UPDATED
  } else if (serverCandidateList === null && validCharts.length !== 0) {
    status = CANDIDATE_STATUS.NOT_UPDATED;
    //  validCharts와 serverCandidateList의 갯수가 다른 경우 => NOT_UPDATED
  } else if (validCharts.length !== serverCandidateList.length) {
    status = CANDIDATE_STATUS.NOT_UPDATED;
    // validCharts와 serverCandidateList의 갯수는 같지만 element가 다른 경우 => NOT_UPDATED
  } else if (
    validCharts.length === serverCandidateList.length &&
    !validCharts.every((objA) =>
      serverCandidateList.some((objB) => objB.chart_group === objA.chart_group)
    )
  ) {
    status = CANDIDATE_STATUS.NOT_UPDATED;
    // validCharts와 serverCandidateList가 갯수도 같고, element도 동일한 경우(갯수가 0인 경우 포함)) => UPDATED
  } else if (
    validCharts.length === serverCandidateList.length &&
    validCharts.every((objA) =>
      serverCandidateList.some((objB) => objB.chart_group === objA.chart_group)
    )
  ) {
    status = CANDIDATE_STATUS.UPDATED;
  }

  return status;
}
