import Styled from './styled';
import { REMOVETEXT, CHEVRON_DOWN } from 'constants/images';
import { useSelect } from 'hooks';
import { SELECT_SIZE } from 'constants';


const Select = ({ disabled, size = SELECT_SIZE.DEFAULT, onSelect, label, property, items, selected }) => {
  const { ref, list, state, handler } = useSelect({
    disabled,
    property,
    items,
    selected,
    handleSelect: onSelect
  });

  return (
    <Styled.Container ref={ref.inputContainer} size={size}>
      <Styled.Label size={size}>{label}</Styled.Label>

      <Styled.InputContainer disabled={disabled}>
        <Styled.Input
          disabled={disabled}
          onClick={handler.handleSelectClick}
          onInput={handler.handleInputType}
          ref={ref.input}
          value={state.value}
          placeholder="Search"
          size={size}
        />

        {state.isFocus && (
          <Styled.InputRemove
            size={size}
            onClick={handler.handleRemoveClick}
            src={REMOVETEXT}
            alt="input-remove"
          />
        )}

        <Styled.InputArrow
          size={size}
          onClick={handler.handleSelectClick}
          src={CHEVRON_DOWN}
          isReverse={state.isFocus}
          alt="input-arrow"
        />
      </Styled.InputContainer>

      {state.isFocus && list?.length > 0 && (
        <Styled.List size={size}>
          {list.map((item, i) => (
            <Styled.Item
              size={size}
              onClick={handler.handleItemClick(item)}
              key={i}
              isSelect={selected?.id === item.id}
            >
              {item.value}
            </Styled.Item>
          ))}
        </Styled.List>
      )}
    </Styled.Container>
  );
};

export default Select;
