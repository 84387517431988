import { useMutation } from 'react-query';
import { mutationFns } from './mutationHelpers';
import { useUserInfoContext, useGlobalToast, useGoogleIdentityContext } from 'context';
import { mutationKeys } from 'constants/keys';
import { clearStore, getNewMemberInfo, getStore } from 'utils/userStorage';
import { useNavigateRoute } from 'hooks/useNavigateRoute';

export function useAuthMutation({ mutationKey, options }) {
  const { setUserInfo } = useUserInfoContext();
  const { initClient } = useGoogleIdentityContext();
  const toast = useGlobalToast();
  const handleNavigate = useNavigateRoute();

  const { mutate, error, status, mutateAsync } = useMutation(mutationFns[mutationKey], {
    onSuccess: () => {
      if (mutationKey === mutationKeys.AUTH.SIGNOUT) {
        clearStore();
        setUserInfo(null);
      } else if (
        mutationKey === mutationKeys.AUTH.SIGNIN ||
        mutationKey === mutationKeys.AUTH.REFRESH
      ) {
        if (getNewMemberInfo()) {
          handleNavigate('/new-member');
        } else {
          setUserInfo(getStore());
        }
      } else {
        setUserInfo(getStore());
      }
    },
    onError: (error) => {
      // token expired
      if (error.request?.status === 401) {
        clearStore();
        setUserInfo(null);
        return;
      }

      if (
        mutationKey === mutationKeys.AUTH.SIGNIN ||
        mutationKey === mutationKeys.AUTH.EMAIL ||
        mutationKey === mutationKeys.AUTH.CONFIRM_CODE
      ) {
        if (error.request?.status === 400) {
          return;
        }
      } else if (mutationKey === mutationKeys.AUTH.GOOGLE_SIGNIN) {
        toast({
          errorMessage:
            'To login with your Google account, your Retentics administrator should register it.'
        });
      } else if (mutationKey === mutationKeys.AUTH.REFRESH) {
        /**
         * 400 : Missing Token
         * 401 : Token expired
         * https://github.com/Fridays-Lab/auth/blob/90f718712da3bf13df339cd828cb1b712fe320d3/docs/APIs.md?plain=1#L276
         */
        if (error.request?.status === 400 || error.request?.status === 401) {
          clearStore();
          setUserInfo(null);
        }
      } else if (mutationKey === mutationKeys.AUTH.SIGNOUT) {
        /**
         * 400 : Token expired
         * https://github.com/Fridays-Lab/auth/blob/90f718712da3bf13df339cd828cb1b712fe320d3/docs/APIs.md?plain=1#L113
         */
        if (error.request?.status === 400) {
          clearStore();
          setUserInfo(null);
        }
      } else {
        toast({});
      }
    },

    ...options
  });

  const googleSignin = () => {
    initClient(mutate);
  };
  const googleSignout = () => {
    clearStore();
    setUserInfo(null);
  };

  if (mutationKey === mutationKeys.AUTH.SIGNOUT && getStore()?.user?.provider === 'google')
    return [googleSignout];

  if (mutationKey === mutationKeys.AUTH.GOOGLE_SIGNIN) return [googleSignin];

  return [mutate, error, status, mutateAsync];
}
