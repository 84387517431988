export const queryKeys = {
  OPTION: {
    RETENTION: 'retention',
    COHORT: 'life_time_value',
    FORECASTING: 'forecasting',
    CROSS_SELLING: 'cross_selling',
    USER_BASED: 'user_based',
    FEATURE_BASED: 'feature_based',
    REPORT: 'report',
    LEAD_TO_CUSTOMER: 'lead_time'
  },
  SERVICE: {
    OVERVIEW: 'overview',
    SEGMENTATION: 'segmentation',
    RETENTION: 'retention',
    RETENTION_BASIC: 'basicRetention',
    RETENTION_ADVANCED: 'advancedRetention',
    COHORT: 'cohort',
    COHORT_BASIC: 'basicCohort',
    COHORT_ADVANCED: 'advancedCohort',
    FORECASTING: 'forecasting',
    CROSS_SELLING: 'crossSelling',
    USER_BASED: 'userBased',
    FEATURE_BASED: 'featureBased',
    REPORT: 'report',
    GET_EXPORT: 'getExport',
    LEAD_TO_CUSTOMER: 'leadToCustomer',
    MOSAIC: 'mosaic',
    CANDIDATE: 'candidate'
  },
  ACCOUNT: {
    PROFILE: 'profile',
    MEMBERS: 'members',
    ORGANIZATION: 'organization'
  }
};
