import { useDashboardContext } from 'context';
import { formatNumber } from 'utils';
import './index.scss';

export default function RankingCard({ priority, name, value }) {
  const { currency } = useDashboardContext();
  return (
    <div className={`ranking-card ${priority}`}>
      <div className="ranking-card-name">{name}</div>
      <div className="ranking-card-value">
        {currency}
        {formatNumber(value)}
      </div>
    </div>
  );
}
