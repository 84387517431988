import { useDashboardContext } from 'context';
import * as d3 from 'd3';
import { useD3 } from 'hooks';
import { numberWithCommas, convertDateToEnglish, formatNumber, extractMonth } from 'utils';
import './index.scss';

export default function OverviewLineChart({ title, chartId, data, svgWidth, svgHeight }) {
  const { currency } = useDashboardContext();
  const ref = useD3(
    (svg) => {
      document.getElementsByClassName(`overview-line-chart ${chartId}`)[0].innerHTML = '';
      const prevTooltip = document.getElementsByClassName(`tooltip ${chartId}`)[0];
      if (prevTooltip) prevTooltip.remove();
      svg.attr('width', svgWidth).attr('height', svgHeight);

      const margin = { top: 20, right: 20, bottom: 25, left: 40 };
      const chartWidth = svgWidth,
        chartHeight = svgHeight - 30;

      const xAxis = svg.append('g').attr('class', `x-axis ${chartId}`);
      const yAxis = svg.append('g').attr('class', `y-axis ${chartId}`);
      const grid = svg.append('g').attr('class', `y-grid ${chartId}`);
      const line = svg.append('g').attr('class', `line ${chartId}`);
      const overlay = svg.append('g').attr('class', `overlay ${chartId}`);
      const guideCircle = svg.append('circle').attr('class', `guide-circle ${chartId}`);
      const tooltip = d3
        .select(`.overview-line-chart-container.${chartId}`)
        .append('div')
        .attr('class', `tooltip ${chartId}`)
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .call((div) => div.append('div').attr('class', 'x'))
        .call((div) =>
          div
            .append('div')
            .attr('class', 'y')
            .call((div) => div.append('span').attr('class', 'label'))
            .call((div) => div.append('span').attr('class', 'value'))
        );
      const yearIndicators = svg.append('g').attr('class', `year-indicators ${chartId}`);

      const D = data.map((v) => ({
        x: v.x,
        y: Math.round(v.y),
      }));
      const X = d3.map(D, (d) => d.x);
      const Y = d3.map(D, (d) => d.y);

      const xScale = d3
        .scaleBand()
        .domain(X)
        .range([margin.left, chartWidth - margin.right]);
      xScale.invert = (() => {
        const domain = xScale.domain();
        const range = xScale.range();
        const invertedScale = d3.scaleQuantize().domain(range).range(domain);
        return (x) => invertedScale(x);
      })();
      const yScale = d3
        .scaleLinear()
        .domain([d3.min(Y), d3.max(Y)])
        .range([chartHeight - margin.bottom, margin.top])
        .nice();

      xAxis
        .attr('transform', `translate(0,${chartHeight - margin.bottom})`)
        .call(
          d3
            .axisBottom(xScale)
            .tickValues(xScale.domain().filter((d, i) => i % 3 == 0))
            .tickSizeOuter(0)
            .tickFormat((d) => extractMonth(d))
        )
        .select('.domain')
        .attr('stroke', '#DDDDDD');

      yAxis
        .attr('transform', `translate(${margin.left},0)`)
        .call(
          d3
            .axisLeft(yScale)
            .ticks(6)
            .tickFormat((v) => formatNumber(v))
        )
        .select('.domain')
        .remove();

      grid
        .attr('transform', `translate(${margin.left},0)`)
        .call(
          d3
            .axisLeft(yScale)
            .tickSize(-(svgWidth - margin.right - margin.left))
            .tickFormat('')
        )
        .call((g) => g.select('path').attr('stroke', 'transparent'))
        .call((g) => g.selectAll('.tick line').attr('stroke', '#DDDDDD'));

      const lineUpdate = line.selectAll('path').data([D]);
      lineUpdate
        .join('path')
        .attr('stroke', '#4B43FF')
        .attr('fill', 'transparent')
        .attr('stroke-linecap', 'round')
        .attr('stroke-width', 3)
        .attr('transform', `translate(${xScale.step() / 2},0)`)
        .attr(
          'd',
          d3
            .line()
            .x((d) => xScale(d.x))
            .y((d) => yScale(d.y))
            .curve(d3.curveMonotoneX)
        );

      overlay
        .append('rect')
        .style('fill', 'none')
        .style('pointer-events', 'all')
        .attr('width', chartWidth)
        .attr('height', chartHeight)
        .on('mouseover', () => {
          tooltip.style('visibility', 'visible');
          guideCircle.style('visibility', 'visible');
        })
        .on('mousemove', (event) => {
          const tooltipX = tooltip.select('.x');
          const tooltipY = tooltip.select('.y');

          const xValue = xScale.invert(d3.pointer(event)[0]);
          const yValue = D.filter((v) => v.x === xValue)[0].y;

          const xPosition = xScale(xValue) + xScale.step() / 2;
          const yPosition = yScale(D.filter((v) => v.x === xValue)[0].y);

          tooltipX.html(xValue);
          tooltipY.select('.label').html('LTV:&nbsp;');
          tooltipY.select('.value').html(numberWithCommas(yValue));

          tooltip
            .style('left', xPosition + 'px')
            .style('transform', 'translateX(-50%)')
            .style('top', yPosition - 48 + 'px');
          guideCircle.transition().duration(1).attr('cx', xPosition).attr('cy', yPosition);
        })
        .on('mouseout', () => {
          tooltip.style('visibility', 'hidden');
          guideCircle.style('visibility', 'hidden');
        });

      guideCircle
        .style('fill', '#4B43FF')
        .attr('stroke', '#4B43FF')
        .attr('r', 5)
        .style('visibility', 'hidden')
        .style('pointer-events', 'none')
        .attr('cx', chartWidth / 2)
        .attr('cy', chartHeight / 2);

      const yearIndicatorsD = X.reduce((acc, cur, idx) => {
        const year = cur.slice(0, 4);
        const yearMatchedObjtec = acc.filter((e) => e.year === year);
        if (yearMatchedObjtec.length === 0) {
          acc.push({
            year: year,
            firstIdx: idx,
            lastIdx: idx,
          });
        }
        acc.forEach((e) => {
          if (e.year === year) {
            e.lastIdx = idx;
            return;
          }
        });

        return acc;
      }, []);

      yearIndicatorsD.forEach((v, i) => {
        yearIndicators
          .append('text')
          .attr('class', 'year-indicator')
          .text(v.year)
          .attr('x', margin.left + xScale.step() * v.firstIdx + xScale.bandwidth() / 5)
          .attr('y', chartHeight + 20);
      });
    },
    [data, svgWidth, svgHeight]
  );
  return (
    <div className={'overview-line-chart-container ' + chartId}>
      <div className="overview-line-chart-title">{title}</div>
      <svg className={'overview-line-chart ' + chartId} ref={ref}></svg>
    </div>
  );
}
