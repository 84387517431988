import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainHeader from 'components/MainHeader';
import { usePageInit } from 'hooks';
import { useCustomerServiceMutation, useGetCustomerService } from 'hooks/service';
import { mutationKeys, queryKeys } from 'constants/keys';
import { useDashboardContext, useUserInfoContext } from 'context';
import { TablesWithTabButton } from './TablesWithTabButton';
import {
  PopulationSalesTransactions,
  RecencyFrequencyMonetaryValue,
  NumberOfCategoriesAndCustomers
} from './charts';
import {
  ARROW_DOWN,
  EDIT,
  EXPORT,
  EXPORT_BUTTON,
  SEGMENTATION_GUIDE_TOOLTIP,
  INFO24PX,
  MODAL_X_BUTTON
} from 'constants/images';
import './index.scss';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { nameExportFile } from 'utils';
import {
  getIsGoToCheckButtonShown,
  getIsGuideTooltipShown,
  setIsGoToCheckButtonShown,
  setIsGuideTooltipShown
} from 'utils/userStorage';
import { saveAs } from 'file-saver';
import axios from 'axios';
import JSZip from 'jszip';
import { DynamicSkeleton, TitleSkeleton } from 'components/skeletons';
import { useLocation } from 'react-router-dom';

export default function Segmentation() {
  const location = useLocation();
  const { t } = useTranslation();
  const [editedSegmentNames, setEditedSegmentNames] = useState(null);
  const [isEditSegmentationOpen, setIsEditSegmentationOpen] = useState(false);
  const [isGuideTooltipShow, setIsGuideTooltipShow] = useState(
    getIsGuideTooltipShown() ? false : true
  );
  const [isGotitClicked, setIsGotitClicked] = useState(getIsGuideTooltipShown());

  const { userInfo } = useUserInfoContext();
  const { isSidebarMinimized } = useDashboardContext();

  const { result } = useGetCustomerService({
    serviceQueryKey: queryKeys.SERVICE.SEGMENTATION
  });

  const [logExport] = useCustomerServiceMutation({
    mutationKey: mutationKeys.CUSTOMER.LOG_EXPORT
  });
  const [editSegmentName] = useCustomerServiceMutation({
    mutationKey: mutationKeys.CUSTOMER.EDIT_SEGMENT_NAME
  });

  const handleEditSegmentName = (segmentId) => (e) => {
    if (e.target.value.length > 10) return;
    setEditedSegmentNames((prev) => {
      const obj = cloneDeep(prev);
      obj.forEach((v) => {
        if (v.segmentId === segmentId) v.editedName = e.target.value;
      });
      return obj;
    });
  };

  const handleSaveEditedSegmentNames = async () => {
    const filteredEditedSegmentNames = editedSegmentNames.filter((v) => v.editedName !== '');
    await Promise.all(
      filteredEditedSegmentNames.map((v) =>
        editSegmentName({
          companyId: userInfo?.company.companyId,
          shopId: userInfo?.company.shopId,
          segmentId: v.segmentId,
          segmentName: v.editedName
        })
      )
    ).then(() =>
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    );
  };

  useEffect(() => {
    if (result)
      setEditedSegmentNames(
        result.segments.map((v) => ({
          segmentId: v.segment_id,
          editedName: ''
        }))
      );
  }, [result]);

  useEffect(() => {
    const groupToBeTable = document.getElementsByClassName('segmentation-table grouptobe')[0];
    if (groupToBeTable) {
      window.addEventListener('scroll', () => {
        if (window.innerHeight + Math.ceil(window.pageYOffset) >= document.body.offsetHeight) {
          if (!getIsGoToCheckButtonShown()) {
            setIsGoToCheckButtonShown();
          }
        }
      });
    }
  }, [result]);

  useEffect(() => {
    usePageInit();
  }, []);

  useEffect(() => {
    if (!location.state?.from || !result) {
      return;
    }
    const from = location.state.from;
    if (from === 'mosaic-candidate') {
      location.state = null;
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }, [location, result]);

  const handleExport = async (e) => {
    if (userInfo?.user.role === 'observer') return;
    e.preventDefault();

    const zip = new JSZip();
    await Promise.all([
      axios.get(result.exportOverall, {
        responseType: 'blob'
      }),
      ...result.exportRankings.map((v) =>
        axios.get(v.url, {
          responseType: 'blob'
        })
      )
    ])
      .then((res) => {
        res.forEach((v, i) => {
          if (i === 0) {
            zip.file(`Segmentation_overall.xlsx`, v.data);
          } else {
            const tableName = result.exportRankings[i - 1].tableName;
            const category = tableName.slice(0, tableName.indexOf('segment') - 1);
            const segmentName = result.exportRankings[i - 1].segmentName;
            zip.file(`${segmentName}_${category}.xlsx`, v.data);
          }
        });
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, `${nameExportFile('Segmentation').slice(0, -4)}.zip`);
        });
      })
      .then(() => {
        logExport({
          companyId: userInfo?.company.companyId,
          shopId: userInfo?.company.shopId,
          page: 'segmentation',
          fileName: `${nameExportFile('Segmentation').slice(0, -4)}.zip`
        });
      });
  };

  return (
    <>
      <div className="segmentation">
        <MainHeader
          main={t('segmentation.segmentation')}
          sub={t('segmentation.break_down_your_customers_into')}
        />

        <>
          <div className={`buttons${!result ? ' hide' : ''}`}>
            <button
              className="segmentation-export-button"
              onClick={() => {
                if (userInfo?.user.role === 'observer') return;
                setIsEditSegmentationOpen(true);
              }}
            >
              <img src={EDIT} alt="edit-segmentation-button" className="edit-segmentation-button" />
              {t('segmentation.edit_segments')}
            </button>
            <button className="segmentation-export-button" onClick={handleExport}>
              <img
                src={EXPORT}
                alt="edit-segmentation-button"
                className="edit-segmentation-button"
              />
              {t('segmentation.export')}
            </button>
          </div>
          {getIsGoToCheckButtonShown() && (
            <div className="box segmentation-gotocheck">
              <p>{t('segmentation.check_a_list_of_candidates')}</p>
              <div
                className="gotocheck-button"
                onClick={() => {
                  window.scrollTo(0, document.body.scrollHeight);
                }}
              >
                <img src={ARROW_DOWN} alt="export-button" />
                {t('segmentation.go_to_check')}
              </div>
            </div>
          )}
          <div className="box segmentation-pst">
            {!result ? (
              <div className="overview-skeleton-container">
                <TitleSkeleton width={400} />
                <DynamicSkeleton width={1104} height={570} />
              </div>
            ) : (
              <>
                <div className="title">
                  <div className="text">
                    {t('segmentation.population_sales_and_transactions')}
                    {isGuideTooltipShow && (
                      <div className="segmentation-pst_guide-tooltip">
                        <div className="segmentation-pst_guide-tooltip_guide">
                          Click the legend to hide/show the data.
                        </div>
                        <img src={SEGMENTATION_GUIDE_TOOLTIP} alt="segmentation-guide-tooltip" />
                        <div className="segmentation-pst_guide-tooltip_button_container">
                          <button
                            onClick={() => {
                              setIsGuideTooltipShow(false);
                              setIsGotitClicked(true);
                              setIsGuideTooltipShown();
                            }}
                          >
                            Got it
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <img
                    src={INFO24PX}
                    alt="info"
                    onMouseEnter={() => setIsGuideTooltipShow(true)}
                    onMouseLeave={() => isGotitClicked && setIsGuideTooltipShow(false)}
                  />
                </div>
                <PopulationSalesTransactions data={result.chart.populationSalesAndTransactions} />
              </>
            )}
          </div>
          <div className="box segmentation-rfm">
            {!result ? (
              <div className="overview-skeleton-container">
                <TitleSkeleton width={400} />
                <DynamicSkeleton width={1104} height={372} />
              </div>
            ) : (
              <>
                <div className="title">
                  {t('segmentation.recency_frequency_monetary_value_rfm')}
                </div>
                <RecencyFrequencyMonetaryValue data={result.chart.recencyFrequencyMonetaryValue} />
              </>
            )}
          </div>
          <div className="box segmentation-ncc">
            {!result ? (
              <div className="overview-skeleton-container">
                <TitleSkeleton width={400} />
                <DynamicSkeleton width={1104} height={372} />
              </div>
            ) : (
              <>
                <div className="title">{t('segmentation.number_of_categories_and_customers')}</div>
                <NumberOfCategoriesAndCustomers
                  data={result.chart.numberOfCategoriesAndCustomers}
                />
              </>
            )}
          </div>
          {result && (
            <div className="segmentation-tables-container">
              <TablesWithTabButton
                data={{
                  segmentNames: result.segmentNames,
                  colors: result.colors,
                  tables: result.tables
                }}
              />
            </div>
          )}
        </>
      </div>
      {result && isEditSegmentationOpen && (
        <div className={`segmentation-edit-segmentation ${isSidebarMinimized ? 'no-sidebar' : ''}`}>
          <div
            className="segmentation-edit-segmentation_dimmed"
            onClick={() => setIsEditSegmentationOpen(false)}
          />
          <div className="segmentation-edit-segmentation_modal">
            <button
              className="segmentation-edit-segmentation_modal_close"
              onClick={() => setIsEditSegmentationOpen(false)}
            >
              <img src={MODAL_X_BUTTON} alt="modal-x-button" />
            </button>
            <div className="segmentation-edit-segmentation_modal_title">
              {t('segmentation.edit_segments')}
            </div>
            <div className="segmentation-edit-segmentation_modal_inputs">
              {result.segments.map((v, i) => {
                return (
                  <div key={v.segment_id} className="input-box">
                    <div className="label">{v.segment_name}</div>
                    <div className="input-wrapper">
                      <input
                        className="input"
                        placeholder={v.segment_name}
                        autoComplete="new-password"
                        onChange={handleEditSegmentName(v.segment_id)}
                        value={editedSegmentNames[i].editedName}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="segmentation-edit-segmentation_modal_button">
              <PrimaryButton
                value="Save"
                onClick={handleSaveEditedSegmentNames}
                style={{ width: '138px' }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
