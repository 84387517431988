import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next'
import { ERROR_FILLED } from 'constants/images';
import { Link, useLocation } from 'react-router-dom';
import { mutationKeys } from 'constants/keys';
import { useAuthMutation } from 'hooks/auth';

const FillEmail = ({ isFilled, setIsFilled, values, setValues, onChange, setCurrentStep }) => {
  const { state } = useLocation();
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('');
  const [isStyleInvalid, setIsStyleInvalid] = useState(false);
  const [isAPIErrorShown, setIsAPIErrorShown] = useState(true);

  const isInvalidEmail =
    values.email.length > 0 &&
    !values.email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  const isActive = values.email.length > 0 && !isInvalidEmail;

  const [sendEmail, error, status] = useAuthMutation({
    mutationKey: mutationKeys.AUTH.EMAIL,
    options: { onSuccess: () => setCurrentStep('reset') }
  });

  const handleEmail = async () => {
    await sendEmail(values.email);
  };

  useEffect(() => {
    if (values.email.length === 0) {
      setErrorMessage('');
    } else if (isInvalidEmail) {
      setErrorMessage(t('login.please_check_your_email_address'));
      
    } else if (!isInvalidEmail) {
      if (error && isAPIErrorShown) {
        setErrorMessage(t('login.we_couldnt_find_your_momentum'));
      } else {
        setErrorMessage('');
      }
    }
  }, [values.email, error]);

  useEffect(() => {
    if (values.email.length === 0) {
      setIsStyleInvalid(false);
    } else if (isInvalidEmail) {
      setIsStyleInvalid(true);
    } else if (!isInvalidEmail) {
      if (isAPIErrorShown) {
        setIsStyleInvalid(true);
      } else if (!isAPIErrorShown) {
        setIsStyleInvalid(false);
      }
    }
  }, [values.email, error, isInvalidEmail]);

  useEffect(() => {
    if (status === 'loading') {
      setIsAPIErrorShown(true);
    } else {
      setIsAPIErrorShown(false);
    }
  }, [status]);

  return (
    (<div className="contents">
      <div className="contents_header">{t("login.forgot_your_password")}</div>
      <div className="contents_text">
        <Trans i18nKey={"login.dont_worry_just_fill_email"} />
      </div>
      <div className="title">{t("login.email")}</div>
      <div className={`inputbox ${isStyleInvalid && 'invalid'}`}>
        <input
          type="text"
          className="inputbox_text"
          name="email"
          value={values.email}
          onChange={onChange}
        />

        {isStyleInvalid && <img src={ERROR_FILLED} className="inputbox_error" />}
      </div>
      {errorMessage && <p className="email_error">{errorMessage} </p>}
      <div className="button_box">
        <Link to={state?.from === 'profile' ? '/profile' : '/login'} state={{ from: '' }}>
          <button className="cancel_button">{t("login.cancel")}</button>
        </Link>
        <button
          className={`send_button ${!isActive ? ' deactive' : ' active'}`}
          disabled={!isActive}
          onClick={() => {
            handleEmail();
          }}
        >{t("login.send")}</button>
      </div>
    </div>)
  );
};

export default FillEmail;
