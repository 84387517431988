import * as d3 from 'd3';
import { useD3 } from 'hooks';
import { numberWithCommas, formatNumber, extractMonth } from 'utils';
import NoData from 'components/NoData';
import './index.scss';
import { useDashboardContext } from 'context';

export default function CohortAreaChart({ title, chartId, data, svgWidth, svgHeight }) {
  const { currency } = useDashboardContext();
  const ref = useD3(
    (svg) => {
      if (data !== 'no data') {
        document.getElementsByClassName(`ltv-area-chart ${chartId}`)[0].innerHTML = '';

        const margin = { top: 20, right: 20, bottom: 20, left: 40 };
        const chartWidth = svgWidth,
          chartHeight = svgHeight - 40;
        const D = data;
        const X = d3.map(D, (d) => d.x);
        const Y = d3.map(D, (d) => d.y);

        svg.attr('width', svgWidth).attr('height', svgHeight);

        // scales
        const xScale = d3
          .scaleBand()
          .domain(X)
          .range([margin.left, chartWidth - margin.right]);
        xScale.invert = (() => {
          const domain = xScale.domain();
          const range = xScale.range();
          const invertedScale = d3.scaleQuantize().domain(range).range(domain);
          return (x) => invertedScale(x);
        })();

        const yScaleMax = d3.max(Y) === 0 ? 1000 : d3.max(Y);
        const yScale = d3
          .scaleLinear()
          .domain([0, yScaleMax])
          .range([chartHeight - margin.bottom, margin.top])
          .nice();
        yScale.invert = (() => {
          var domain = xScale.domain();
          var range = xScale.range();
          var invertedScale = d3.scaleQuantize().domain(range).range(domain);
          return (x) => invertedScale(x);
        })();

        const xAxis = svg.append('g').attr('class', `x-axis ${chartId}`);
        const yAxis = svg.append('g').attr('class', `y-axis ${chartId}`);
        const grid = svg.append('g').attr('class', `y-grid ${chartId}`);
        const area = svg.append('path').attr('class', `area ${chartId}`);
        const line = svg.append('path').attr('class', `line ${chartId}`);
        const overlay = svg.append('rect').attr('class', `overlay ${chartId}`);
        const guideLine = svg.append('line').attr('class', `guide-line ${chartId}`);
        const guideCircle = svg.append('circle').attr('class', `guide-circle ${chartId}`);
        const tooltipXValue =
          title === 'Customer Value Progress'
            ? 'Value Progress'
            : title === 'LTV 6 Changes Over Time'
            ? 'LTV 6'
            : 'LTV 3';
        const tooltip = d3
          .select(`.ltv-area-chart-container.${chartId}`)
          .append('div')
          .attr('class', `tooltip ${chartId}`)
          .style('position', 'absolute')
          .style('visibility', 'hidden')
          .call((div) => div.append('div').attr('class', 'x'))
          .call((div) =>
            div
              .append('div')
              .attr('class', 'y')
              .call((div) => div.append('span').attr('class', 'label').html(`${tooltipXValue}: `))
              .call((div) => div.append('span').attr('class', 'value'))
          );
        const yearIndicators = svg.append('g').attr('class', `year-indicators ${chartId}`);

        xAxis.attr('transform', `translate(0,${chartHeight - margin.bottom})`).call(
          d3
            .axisBottom()
            .scale(xScale)
            .tickValues(
              X.filter((v, i, arr) => {
                const length = arr.length;
                if (length > 24) return i % 3 === 0;
                return true;
              })
            )
            .tickFormat((d) => (d.includes('LTV') ? d.replace('_', '') : extractMonth(d)))
            .tickSizeOuter(0)
        );
        yAxis
          .attr('transform', `translate(${margin.left},0)`)
          .call(
            d3
              .axisLeft(yScale)
              .ticks(8)
              .tickFormat((v) => formatNumber(v))
          )
          .select('.domain')
          .remove();

        grid
          .attr('transform', `translate(${margin.left},0)`)
          .call(
            d3
              .axisLeft(yScale)
              .ticks(8, '~s')
              .tickSize(-(chartWidth - (margin.right + margin.left)))
              .tickFormat('')
          )
          .call((g) => g.select('path').attr('stroke', 'transparent'))
          .call((g) => g.selectAll('.tick line').attr('stroke', '#DDDDDD'));

        guideLine
          .attr('x1', 0)
          .attr('y1', margin.top)
          .attr('x2', 0)
          .attr('y2', chartHeight - margin.bottom)
          .attr('stroke', '#DDDDDD')
          .attr('stroke-width', '3')
          .style('visibility', 'hidden');
        guideCircle
          .style('fill', '#4B43FF')
          .attr('stroke', '#4B43FF')
          .attr('r', 5)
          .style('visibility', 'hidden');

        area
          .datum(D)
          .attr(
            'd',
            d3
              .area()
              .x((d) => xScale(d.x))
              .y0(() => yScale(0))
              .y1((d) => yScale(d.y))
              .curve(d3.curveMonotoneX)
          )
          .attr('fill', '#E0DFF8')
          .attr('fill-opacity', '0.8')
          .attr('transform', `translate(${xScale.step() / 2}, 0)`);

        line
          .data([D])
          .attr(
            'd',
            d3
              .line()
              .x((d) => xScale(d.x))
              .y((d) => yScale(d.y))
              .curve(d3.curveMonotoneX)
          )
          .attr('stroke', '#4B43FF')
          .attr('fill', 'transparent')
          .attr('stroke-width', 3)
          .attr('transform', `translate(${xScale.step() / 2},0)`);

        overlay
          .style('fill', 'none')
          .style('pointer-events', 'all')
          .attr('width', chartWidth)
          .attr('height', chartHeight)
          .on('mouseover', () => {
            guideLine.style('visibility', 'visible');
            guideCircle.style('visibility', 'visible');
          })
          .on('mousemove', (event) => {
            const xValue = xScale.invert(d3.pointer(event)[0]);
            const yValue = D.filter((v) => v.x === xValue)[0].y;

            const xPosition = xScale(xValue) + xScale.step() / 2;
            const yPosition = yScale(D.filter((v) => v.x === xValue)[0].y);

            tooltip
              .style('visibility', 'visible')
              .style('left', xPosition + 'px')
              .style('transform', 'translateX(-50%)')
              .style('top', yPosition - 10 + 'px');
            tooltip.select('.x').html(xValue);
            tooltip.select('.y').select('.value').html(numberWithCommas(yValue));
            guideLine.transition().duration(1).attr('x1', xPosition).attr('x2', xPosition);
            guideCircle.transition().duration(1).attr('cx', xPosition).attr('cy', yPosition);
          })
          .on('mouseout', () => {
            tooltip.style('visibility', 'hidden');
            guideLine.style('visibility', 'hidden');
            guideCircle.style('visibility', 'hidden');
          });

        if (!X[0].includes('LTV')) {
          const yearIndicatorsD = X.reduce((acc, cur, idx) => {
            const year = cur.slice(0, 4);
            const yearMatchedObj = acc.filter((e) => e.year === year);
            if (yearMatchedObj.length === 0) {
              acc.push({
                year: year,
                firstIdx: idx,
                lastIdx: idx,
              });
            }
            acc.forEach((e) => {
              if (e.year === year) {
                e.lastIdx = idx;
                return;
              }
            });
            return acc;
          }, []);
          yearIndicatorsD.forEach((v, i) => {
            yearIndicators
              .append('text')
              .attr('class', 'year-indicator')
              .text(v.year)
              .attr('x', margin.left + xScale.step() * v.firstIdx + xScale.bandwidth() / 4)
              .attr('y', chartHeight + margin.top + 20);
          });
        }
      }
    },
    [data, svgWidth, svgHeight]
  );
  return (
    <div className={`ltv-area-chart-container ${chartId}`}>
      <div className={`ltv-area-chart-title ${chartId}`}>{title}</div>
      {data === 'no data' ? (
        <NoData />
      ) : (
        <svg className={`ltv-area-chart ${chartId}`} ref={ref}></svg>
      )}
    </div>
  );
}
