import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';
import { useD3 } from 'hooks';
import { EXPORT } from 'constants/images/icons';
import NoData from 'components/NoData';
import {
  convertDateToEnglish,
  numberWithCommas,
  formatNumber,
  nameExportFile,
  formatNewDate,
  extractMonth,
} from 'utils';
import './index.scss';
import { useDashboardContext } from 'context';
import Legend from 'components/emotion/Legend';
import { theme } from 'styles@emotion/theme';

export default function RetentionRateChart({
  title,
  chartId,
  data,
  svgWidth,
  svgHeight,
  onExport,
}) {
  const { t } = useTranslation();
  
  const { currency } = useDashboardContext();
  function arc(r, sign) {
    return r ? `a${r * sign[0]},${r * sign[1]} 0 0 1 ${r * sign[2]},${r * sign[3]}` : '';
  }
  function roundedRect(x, y, width, height, r) {
    r = [
      Math.min(r[0], height, width),
      Math.min(r[1], height, width),
      Math.min(r[2], height, width),
      Math.min(r[3], height, width),
    ];

    return `M${x + r[0]},${y}h${width - r[0] - r[1]}${arc(r[1], [1, 1, 1, 1])}v${
      height - r[1] - r[2]
    }${arc(r[2], [1, 1, -1, 1])}h${-width + r[2] + r[3]}${arc(r[3], [1, 1, -1, -1])}v${
      -height + r[3] + r[0]
    }${arc(r[0], [1, 1, 1, -1])}z`;
  }
  const ref = useD3(
    (svg) => {
      if (data !== 'no data') {
        document.getElementsByClassName(`retention-rate-chart ${chartId}`)[0].innerHTML = '';
        const prevTooltip = document.getElementsByClassName(`tooltip ${chartId}`)[0];
        if (prevTooltip) prevTooltip.remove();
        svg.attr('width', svgWidth).attr('height', svgHeight);

        const xWaterfallAxis = svg.append('g').attr('class', `x-waterfall-axis ${chartId}`);
        const xRetentionRateAxis = svg.append('g').attr('class', `x-retentionrate-axis ${chartId}`);
        const yLeftAxis = svg.append('g').attr('class', `y-left-axis ${chartId}`);
        const yRightAxis = svg.append('g').attr('class', `y-right-axis ${chartId}`);
        const yGrid = svg.append('g').attr('class', `y-grid ${chartId}`);
        const barGroup = svg.append('g').attr('class', `bar-group ${chartId}`);
        const line = svg.append('g').attr('class', `line ${chartId}`);
        const invisibleLine = svg.append('g').attr('class', `line ${chartId}`);
        const tooltip = d3
          .select(`.retention-rate-chart-container.${chartId}`)
          .append('div')
          .attr('class', `tooltip ${chartId}`)
          .style('position', 'absolute')
          .style('visibility', 'hidden')
          .call((div) => div.append('div').attr('class', 'x'))
          .call((div) =>
            div
              .append('div')
              .attr('class', 'y')
              .call((div) => div.append('span').attr('class', 'label'))
              .call((div) => div.append('span').attr('class', 'value'))
          );
        const yearIndicators = svg.append('g').attr('class', `year-indicators ${chartId}`);

        const margin = { top: 30, right: 40, bottom: 20, left: 40 };
        const chartWidth = svgWidth,
          chartHeight = svgHeight - 50;

        // data
        const waterfallD = data.waterfallChart.waterfall;
        const waterfallX = d3.map(waterfallD, (v) => `${v.date}${v.customerType}`);
        const waterfallY = d3.map(waterfallD, (v) => v.end);
        const retentionRateD = data.retentionRateChart.retentionRate
          .map((v) => ({
            ...v,
            y: +v.y.toFixed(2),
          }))
          .reduce((prev, cur, idx, arr) => {
            if (idx === 0) {
              prev.push(cur);
            } else {
              prev.push({
                x: cur.x,
                y: arr[idx - 1].y + (cur.y - arr[idx - 1].y) * (1 / 4),
              });
              prev.push({
                x: cur.x,
                y: arr[idx - 1].y + (cur.y - arr[idx - 1].y) * (2 / 4),
              });
              prev.push({
                x: cur.x,
                y: arr[idx - 1].y + (cur.y - arr[idx - 1].y) * (3 / 4),
              });
              prev.push(cur);
            }
            return prev;
          }, []);
        const retentionRateX = d3.map(retentionRateD, (v, i) => `${v.x}%${i}`);
        const retentionRateY = d3.map(retentionRateD, (v) => v.y);
        // scales
        const xWaterfallScale = d3
          .scaleBand()
          .domain(waterfallX)
          .range([margin.left, chartWidth - margin.right])
          .paddingInner(0.2)
          .paddingOuter(0.1);
        const xRetentionRateScale = d3
          .scaleBand()
          .domain(retentionRateX)
          .range([margin.left, chartWidth - margin.right])
          .paddingInner(0.2)
          .paddingOuter(0.1);
        xRetentionRateScale.invert = (() => {
          const domain = xRetentionRateScale.domain();
          const range = xRetentionRateScale.range();
          const invertedScale = d3.scaleQuantize().domain(range).range(domain);
          return (x) => invertedScale(x);
        })();

        // 하나의 값만 있는 경우 차트가 표현될 수 있도록 임시로 코드 추가
        const yWaterfallScale = d3
          .scaleLinear()
          .domain([
            0,
            waterfallY.length === 1 && d3.max(waterfallY) === 0
              ? waterfallD[0].start
              : d3.max(waterfallY),
          ])
          .range([chartHeight - margin.bottom, margin.top])
          .nice();
        const yRetentionRateScale = d3
          .scaleLinear()
          .domain([0, d3.max(retentionRateY)])
          .range([chartHeight - margin.bottom, margin.top])
          .nice();

        xWaterfallAxis
          .attr('transform', `translate(0,${chartHeight - margin.bottom})`)
          .call(d3.axisBottom().scale(xWaterfallScale).tickSizeOuter(0))
          .call((g) =>
            g.selectAll('text').text((name, i, arr) => {
              if (i % 4 !== 0) return;

              const isMoreThanFortyMonths = arr.length >= 161;
              const indiceOfShownValues = Array.from(arr)
                .map((v, i) => i)
                .filter((v, i) => i % 4 === 0);

              if (isMoreThanFortyMonths && indiceOfShownValues.indexOf(i) % 2 === 1) return;

              return extractMonth(name);
            })
          )
          .call((g) => g.select('.domain').attr('stroke', '#d9d9d9'))
          .call((g) =>
            g
              .selectAll('line')
              .attr(
                'transform',
                `translate(${
                  xWaterfallScale.bandwidth() / 2 + xWaterfallScale.bandwidth() * 0.1
                },0)`
              )
          );
        xRetentionRateAxis
          .attr('transform', `translate(0, ${chartHeight - margin.bottom})`)
          .call(
            d3
              .axisBottom()
              .scale(xRetentionRateScale)
              .tickFormat((d) => extractMonth(d.split('%')[0]))
              .tickSizeOuter(0)
          )
          .call((g) =>
            g.selectAll('text').text(() => {
              return;
            })
          );
        yLeftAxis
          .attr('transform', `translate(${margin.left})`)
          .call(
            d3
              .axisLeft(yWaterfallScale)
              .ticks(7)
              .tickFormat((v) => formatNumber(v))
              .tickSizeOuter(0)
          )
          .call((g) => g.select('.domain').attr('stroke', '#d9d9d9'));
        yRightAxis
          .attr('transform', `translate(${chartWidth - margin.right})`)
          .call(d3.axisRight(yRetentionRateScale).ticks(5).tickSizeOuter(0))
          .call((g) => g.select('.domain').attr('stroke', '#d9d9d9'));
        yGrid
          .attr('transform', `translate(${margin.left},0)`)
          .call(
            d3
              .axisLeft(yWaterfallScale)
              .tickSize(-(chartWidth - margin.right - margin.left))
              .tickFormat('')
          )
          .call((g) => {
            g.selectAll('.tick line').attr('stroke', 'lightgray');
          })
          .select('path')
          .attr('stroke', '#ffffff');

        barGroup
          .selectAll('g')
          .data(waterfallD)
          .join('g')
          .call((g) =>
            g
              .append('path')
              .attr('class', (d) => `bar ${d.customerType}`)
              .attr('d', (d) =>
                roundedRect(
                  xWaterfallScale(`${d.date}${d.customerType}`),
                  yWaterfallScale(Math.max(d.start, d.end)),
                  xWaterfallScale.bandwidth(),
                  Math.abs(yWaterfallScale(d.start) - yWaterfallScale(d.end)),
                  [2, 2, 0, 0]
                )
              )
              .attr('fill', (d) => {
                return d.customerType === 'customer'
                  ? '#4B43FF'
                  : d.customerType === 'new'
                  ? '#00DCFF'
                  : d.customerType === 'reactive'
                  ? '#A12AFF'
                  : '#FE59F7';
              })
          )
          .call((g) =>
            g
              .append('line')
              .attr('stroke', '#9E9E9E')
              .attr('stroke-width', 1)
              .attr('stroke-dasharray', '2,2')
              .attr('x1', (d) => xWaterfallScale(`${d.date}${d.customerType}`))
              .attr('x2', (d, i) =>
                i === waterfallD.length - 1
                  ? xWaterfallScale(`${d.date}${d.customerType}`) + xWaterfallScale.bandwidth()
                  : xWaterfallScale(`${d.date}${d.customerType}`) +
                    xWaterfallScale.step() +
                    xWaterfallScale.bandwidth()
              )
              .attr('y1', (d, i) =>
                i % 4 === 3
                  ? yWaterfallScale(Math.min(d.start, d.end))
                  : yWaterfallScale(Math.max(d.start, d.end))
              )
              .attr('y2', (d, i) =>
                i % 4 === 3
                  ? yWaterfallScale(Math.min(d.start, d.end))
                  : yWaterfallScale(Math.max(d.start, d.end))
              )
          )
          .on('mouseover', () => {
            tooltip.style('visibility', 'visible');
          })
          .on('mousemove', (event, d) => {
            const tooltipX = tooltip.select('.x');
            const tooltipY = tooltip.select('.y');
            const [x, y] = d3.pointer(event);
            tooltipX.html(d.date.slice(0, 7));
            const customerType = d.customerType === 'customer' ? 'active_customer' : d.customerType;
            tooltipY.select('.label').html(t(`retention.${customerType}`) + ':');
            tooltipY
              .select('.value')
              .html(
                d.customerType === 'customer'
                  ? numberWithCommas(d.start)
                  : numberWithCommas(d.value)
              );
            tooltip
              .style(
                'left',
                x - d3.select(`.tooltip.${chartId}`).node().getBoundingClientRect().width / 2 + 'px'
              )
              .style('top', y + 50 + 'px');
          })
          .on('mouseout', () => tooltip.style('visibility', 'hidden'));
        
        invisibleLine
          .selectAll('path')
          .data([retentionRateD])
          .join('path')
          .attr('stroke', '#00000000')
          .attr('fill', 'transparent')
          .attr('stroke-linecap', 'round')
          .attr('stroke-width', 30)
          .style('pointer-events', 'stroke')
          .attr('transform', `translate(${xRetentionRateScale.step() / 2},0)`)
          .attr(
            'd',
            d3
              .line()
              .x((d, i) => xRetentionRateScale(`${d.x}%${i}`))
              .y((d) => yRetentionRateScale(d.y))
          )
          .on('mouseover', (event, d) => {
            const [x] = d3.pointer(event);
            tooltip.style('visibility', 'visible');
          })
          .on('mousemove', (event, d) => {
            const tooltipX = tooltip.select('.x');
            const tooltipY = tooltip.select('.y');
            const [x, y] = d3.pointer(event);
            const xValue = xRetentionRateScale.invert(x).slice(0, 7);
            const tmp = retentionRateD.filter((v) => v.x.slice(0, 7) === xValue);
            const yValue = tmp[tmp.length - 1].y;
            tooltipX.html(xValue);
            tooltipY.select('.label').html(t("retention.retention_rate") + ':&nbsp;');
            tooltipY.select('.value').html(yValue);
            tooltip
              .style(
                'left',
                x - d3.select(`.tooltip.${chartId}`).node().getBoundingClientRect().width / 2 + 'px'
              )
              .style('top', y + 50 + 'px');
          })
          .on('mouseout', () => {
            tooltip.style('visibility', 'hidden');
          });


        line
          .selectAll('path')
          .data([retentionRateD])
          .join('path')
          .attr('stroke', '#9E9E9E')
          .attr('fill', 'transparent')
          .attr('stroke-linecap', 'round')
          .attr('stroke-width', 2)
          .style('pointer-events', 'stroke')
          .attr('transform', `translate(${xRetentionRateScale.step() / 2},0)`)
          .attr(
            'd',
            d3
              .line()
              .x((d, i) => xRetentionRateScale(`${d.x}%${i}`))
              .y((d) => yRetentionRateScale(d.y))
          )

        const yearIndicatorsD = waterfallX.reduce((acc, cur, idx) => {
          const year = cur.slice(0, 4);
          const yearMatchedObj = acc.filter((e) => e.year === year);
          if (yearMatchedObj.length === 0) {
            acc.push({
              year: year,
              firstIdx: idx,
              lastIdx: idx,
            });
          }
          acc.forEach((e) => {
            if (e.year === year) {
              e.lastIdx = idx;
              return;
            }
          });
          return acc;
        }, []);
        yearIndicatorsD.forEach((v, i) => {
          yearIndicators
            .append('text')
            .attr('class', 'year-indicator')
            .text(v.year)
            .attr('x', () =>
              i !== 0
                ? margin.left +
                  xWaterfallScale.step() * v.firstIdx +
                  xWaterfallScale.bandwidth() * 3
                : margin.left + xWaterfallScale.step() * v.firstIdx
            )
            .attr('y', chartHeight + 20);
        });
      }
    },
    [data, svgWidth, svgHeight]
  );
  return (
    (<div className={`retention-rate-chart-container ${chartId}`}>
      <div className={`retention-rate-chart-title ${chartId}`}>{title}</div>
      {data?.exportData && (<div className="retention-rate-chart-export-button" onClick={onExport}>
          <img src={EXPORT} alt="export-button" />{t("settings.export")}</div>
        
      )}
      <Legend labels={[t("retention.active_customer"), t("retention.new"), t("retention.reactive"), t("retention.churned")]}
        colors={[theme.color.neonBlue500, theme.color.aquaBlue500, theme.color.neonPurple500, theme.color.neonPink500]}
        types={['circle', 'circle', 'circle', 'circle']}
      />
      {data === 'no data' ? (
        <NoData />
      ) : (
        <svg className={`retention-rate-chart ${chartId}`} ref={ref}></svg>
      )}
    </div>)
  );
}
