import styled from '@emotion/styled';
import { theme } from 'styles@emotion/theme';

export const Container = styled.div`
  ${theme.component.box}
  background-color: ${theme.color.white};
  padding-top: 35px;
  padding-bottom: 40px;
`;
export const MissedData = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
  `,
  NoDataImage: styled.img`
    margin-bottom: 25px;
    width: 208px;
    height: 128px;
  `,
  NoDataBigText: styled.div`
    margin-bottom: 8px;
    ${theme.font.h5}
    color: ${theme.color.gray900};
  `,
  NoDataSmallText: styled.div`
    ${theme.font.strong}
    color: ${theme.color.gray900};
  `
};
export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;
export const Title = styled.div`
  ${theme.font.chartTitle}
`;
export const TitleLastOrder = styled.div`
  ${theme.font.smallText}
  color: ${theme.color.gray500};
`;
export const Description = styled.div`
  ${theme.font.bodyRegular}
  color: ${theme.color.gray900};
`;
export const Charts = styled.div`
  display: flex;
  column-gap: 48px;
  row-gap: 30px;
  flex-wrap: wrap;
  justify-content: start;
  margin-top: 24px;
`;
export const Chart = {
  Container: styled.div`
    position: relative;
    width: 336px;

    &:not(:nth-of-type(3n + 1)):before {
      content: '';
      position: absolute;
      z-index: 0;
      left: -24px;
      height: 100%;
      border-right: 1px solid ${theme.color.gray300};
    }
  `,
  MetaDataContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    min-height: 56px;
    border-radius: 4px;
    background-color: ${theme.color.gray100};
  `,
  Title: styled.div`
    top: 0;
    width: 100%;
    text-align: center;
    ${theme.font.chartCategory}
    color: ${theme.color.gray900};
  `,
  Description: styled.div`
    width: 324px;
    ${theme.font.chartLegend}
    color: ${theme.color.gray900};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  `,
  EditImage: styled.img`
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;
  `,
  EditContainer: styled.div`
    position: absolute;
    top: 27px;
    right: 4px;
    display: flex;
    flex-direction: column;
    width: 102px;
    height: 64px;
    border: 1px solid ${theme.color.gray300};
    border-radius: 4px;
    background-color: ${theme.color.white};
    z-index: 2;
  `,
  EditContainerEditButton: styled.button`
    height: 32px;
    ${theme.font.bodySmall}
    color: ${theme.color.gray900};
    border-radius: 4px 4px 0px 0px;
    &:hover {
      background-color: ${theme.color.gray100};
    }
  `,
  EditContainerDeleteButton: styled.button`
    height: 32px;
    ${theme.font.bodySmall}
    color: ${theme.color.gray900};
    border-radius: 0px 0px 4px 4px;
    &:hover {
      background-color: ${theme.color.gray100};
    }
  `,
  ContentContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // 차트 위에 Last Order가 표기되어있는 경우는 Coantainer의 100% 높이에서 20px를 더 뺌.
    height: ${({ includeLastOrder }) =>
      includeLastOrder ? 'calc(100% - 96px)' : 'calc(100% - 76px)'};
  `,
  ChartContainer: styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  `,
  BottomContainer: styled.div`
    height: fit-content;
  `,
  NoDataImage: styled.img`
    margin-top: 30px;
    margin-bottom: 15px;
    width: 200px;
    height: 112px;
  `,
  InvalidData: styled.div`
    margin-bottom: 4px;
    ${theme.font.strong}
    color: ${theme.color.gray900};
  `,
  ErrorMessage: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.color.gray900};
  `,
  ErrorMessageIcon: styled.img`
    margin-right: 5px;
  `,
  SignificantMessage: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${theme.color.gray900};
  `,
  SignificantIcon: styled.img`
    margin-right: 2px;
  `,
  SignificantMessageMain: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  `,
  SignificantMessageSub: styled.div`
    text-align: center;
    ${theme.font.smallText}
    white-space: pre-line;
  `,
  Delete: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
  `,
  DeleteIcon: styled.img`
    cursor: pointer;
  `,
  DeleteProcessingContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 350px;
  `,
  DeleteProcessingIcon: styled.img`
    margin-left: 2px;
    animation: spin 1200ms infinite linear;
  `,
  LastOrder: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    ${theme.font.smallText}
    color: ${theme.color.gray500};
  `
};
export const UpdateCandidatesWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
`;
