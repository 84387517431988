import { useTranslation } from 'react-i18next';
import { useDashboardContext } from 'context';
import * as d3 from 'd3';
import { useD3 } from 'hooks';
import { numberWithCommas, convertDateToEnglish, extractMonth, formatNumber } from 'utils';
import './index.scss';

export default function OverviewSalesChart({ title, chartId, data, svgWidth, svgHeight }) {
  const { t } = useTranslation();
  const { currency } = useDashboardContext();
  function arc(r, sign) {
    return r ? `a${r * sign[0]},${r * sign[1]} 0 0 1 ${r * sign[2]},${r * sign[3]}` : '';
  }
  function roundedRect(x, y, width, height, r) {
    r = Array(4)
      .fill(null)
      .map((_, i) => Math.min(r[i], height, width));

    return `M${x + r[0]},${y}h${width - r[0] - r[1]}${arc(r[1], [1, 1, 1, 1])}v${
      height - r[1] - r[2]
    }${arc(r[2], [1, 1, -1, 1])}h${-width + r[2] + r[3]}${arc(r[3], [1, 1, -1, -1])}v${
      -height + r[3] + r[0]
    }${arc(r[0], [1, 1, 1, -1])}z`;
  }
  const ref = useD3(
    (svg) => {
      document.getElementsByClassName(`overview-sales-chart ${chartId}`)[0].innerHTML = '';
      const prevTooltip = document.getElementsByClassName(`tooltip ${chartId}`)[0];
      if (prevTooltip) prevTooltip.remove();
      svg.attr('width', svgWidth).attr('height', svgHeight);

      const margin = { top: 30, right: 30, bottom: 30, left: 50 };
      const chartWidth = svgWidth,
        chartHeight = svgHeight - 30 - 20; // legend, year indicators
      const xAxis = svg.append('g').attr('class', `x-axis ${chartId}`);
      const yAxis = svg.append('g').attr('class', `y-axis ${chartId}`);
      const grid = svg.append('g').attr('class', `y-grid ${chartId}`);
      const bars = svg.append('g').attr('class', `bars ${chartId}`);
      const tooltip = d3
        .select(`.overview-sales-chart-container.${chartId}`)
        .append('div')
        .attr('class', `tooltip ${chartId}`)
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .call((div) => div.append('div').attr('class', 'x'))
        .call((div) =>
          div
            .append('div')
            .attr('class', 'y total')
            .call((div) =>
              div
                .append('span')
                .attr('class', 'label')
                .html(t('overview.total') + ':&nbsp;')
            )
            .call((div) => div.append('span').attr('class', 'value'))
        )
        .call((div) =>
          div
            .append('div')
            .attr('class', 'y sign-in')
            .call((div) =>
              div
                .append('span')
                .attr('class', 'label')
                .html(t('overview.sign_in') + ':&nbsp;')
            )
            .call((div) => div.append('span').attr('class', 'value'))
        )
        .call((div) =>
          div
            .append('div')
            .attr('class', 'y guest')
            .call((div) =>
              div
                .append('span')
                .attr('class', 'label')
                .html(t('overview.guest') + ':&nbsp;')
            )
            .call((div) => div.append('span').attr('class', 'value'))
        );
      const yearIndicators = svg.append('g').attr('class', `year-indicators ${chartId}`);
      const legends = svg.append('g').attr('class', `legends ${chartId}`);
      const legend = legends
        .selectAll(`.legend ${chartId}`)
        .data([t('overview.guest'), t('overview.sign_in')])
        .join('g')
        .attr('class', `legend ${chartId}`);

      const D = data;
      const X = d3.map(D, (v) => v.date); // date values
      const T = d3.map(D, (v) => v.total); // total values
      const S = d3.map(D, (v) => v.signIn); // signIn values
      const G = d3.map(D, (v) => v.guest); // guest values

      // scales
      const xScale = d3
        .scaleBand()
        .domain(X)
        .range([margin.left, chartWidth - margin.right])
        .paddingInner(0.4)
        .paddingOuter(0.2);
      const yScale = d3
        .scaleLinear()
        .domain([0, d3.max(T, (d) => Number(d)) * 1.05]) // legend와 bar 사이의 간격을 조금 두기 위해서 1.05를 곱함
        .range([chartHeight - margin.bottom, margin.top])
        .nice();
      const colorScale = d3.scaleOrdinal(['Guest', 'Sign-in'], ['#C1BEF7', '#4B43FF']);

      xAxis
        .attr('transform', `translate(0,${chartHeight - margin.bottom})`)
        .call(
          d3
            .axisBottom(xScale)
            .tickSizeOuter(0)
            // .tickFormat((d) => convertDateToEnglish(d))
            .tickFormat((d) => extractMonth(d))
        )
        .call((g) => g.select('.domain').attr('stroke', '#DDDDDD'))
        .call((g) => g.selectAll('.tick').select('line').attr('visibility', 'hidden'));
      yAxis
        .attr('transform', `translate(${margin.left},0)`)
        .call(
          d3
            .axisLeft(yScale)
            .tickSizeOuter(0)
            .ticks(7)
            .tickFormat((v) => formatNumber(v))
        )
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick').select('line').attr('visibility', 'hidden'));
      grid
        .attr('transform', `translate(${margin.left},0)`)
        .call(
          d3
            .axisLeft(yScale)
            .ticks(7, '~s')
            .tickSize(-(chartWidth - (margin.right + margin.left)))
            .tickFormat('')
        )
        .call((g) => g.select('path').attr('stroke', 'transparent'))
        .call((g) => g.selectAll('.tick line').attr('stroke', '#DDDDDD'));
      bars
        .selectAll('g')
        .data(D)
        .join('g')
        .call((g) =>
          g
            .append('path')
            .attr('d', (d) =>
              roundedRect(
                xScale(d.date),
                yScale(d.total),
                xScale.bandwidth(),
                chartHeight - margin.bottom - yScale(d.total),
                [5, 5, 0, 0]
              )
            )
            .attr('fill', '#4B43FF')
        )
        .call((g) =>
          g
            .append('path')
            .attr('d', (d) =>
              roundedRect(
                xScale(d.date),
                yScale(d.guest),
                xScale.bandwidth(),
                chartHeight - margin.bottom - yScale(d.guest),
                [0, 0, 0, 0]
              )
            )
            .attr('fill', '#C1BEF7')
        )
        .call((g) =>
          g
            .append('text')
            .attr('class', 'overview-sales-bar-value')
            .text((d) => formatNumber(d.total))
            .attr('text-anchor', 'middle')
            .attr('fill', '#4B43FF')
            .attr('x', (d) => xScale(d.date) + xScale.bandwidth() / 2)
            .attr('y', (d) => yScale(d.total) - 11)
        )
        .call((g) => G.select)
        .on('mouseover', () => {
          // tooltip.style('visibility', 'visible');
        })
        .on('mousemove', (event, d) => {
          tooltip.style('visibility', 'visible');
          tooltip.select('.x').html(d.date);
          tooltip
            .select('.y.total')
            .select('.value')
            .html(`${currency}${numberWithCommas(d.total)}`);
          tooltip.select('.x').html(d.date);
          tooltip
            .select('.y.sign-in')
            .select('.value')
            .html(`${currency}${numberWithCommas(d.signIn)}`);
          tooltip.select('.x').html(d.date);
          tooltip
            .select('.y.guest')
            .select('.value')
            .html(`${currency}${numberWithCommas(d.guest)}`);

          const [x, y] = d3.pointer(event);
          tooltip
            .style(
              'left',
              x - d3.select('.tooltip.osc').node().getBoundingClientRect().width / 2 + 'px'
            )
            .style('top', y - 50 + 'px');
        })
        .on('mouseout', () => tooltip.style('visibility', 'hidden'));

      const yearIndicatorsD = X.reduce((acc, cur, idx) => {
        const year = cur.slice(0, 4);
        const yearMatchedObj = acc.filter((e) => e.year === year);
        if (yearMatchedObj.length === 0) {
          acc.push({
            year: year,
            firstIdx: idx,
            lastIdx: idx
          });
        }
        acc.forEach((e) => {
          if (e.year === year) {
            e.lastIdx = idx;
            return;
          }
        });
        return acc;
      }, []);
      yearIndicatorsD.forEach((v, i) => {
        yearIndicators
          .append('text')
          .attr('class', 'year-indicator')
          .text(v.year)
          .attr('x', margin.left + xScale.step() * v.firstIdx + xScale.bandwidth() / 5)
          .attr('y', chartHeight + 10);
      });
      legend
        .call((g) =>
          g
            .append('circle')
            .attr('r', 3)
            .style('stroke-width', 3)
            .style('stroke', (d) => {
              return colorScale(d);
            })
            .style('fill', (d) => {
              return colorScale(d);
            })
            .style('cursor', 'pointer')
        )
        .call((g) =>
          g
            .append('text')
            .attr('x', () => {
              return 10;
            })
            .attr('y', 4)
            .text((d) => d)
            .style('cursor', 'pointer')
        )
        .call((g) =>
          g.attr('transform', (d, i) => {
            if (i === 0) return `translate(${chartWidth - 140},${margin.top - 15})`;
            if (i === 1) return `translate(${chartWidth - 70},${margin.top - 15})`;
          })
        );
    },
    [data, svgWidth, svgHeight]
  );

  return (
    <div className={`overview-sales-chart-container ${chartId}`}>
      <div className={`overview-sales-chart-title ${chartId}`}>{title}</div>
      <svg className={`overview-sales-chart ${chartId}`} ref={ref}></svg>
    </div>
  );
}
