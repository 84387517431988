import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as d3 from 'd3';
import { useD3 } from 'hooks';
import { numberWithCommas, convertDateToEnglish, formatNumber, extractMonth } from 'utils';
import './index.scss';
import Legend from 'components/emotion/Legend';
import { theme } from 'styles@emotion/theme';

export default function OverviewMonthlyTransactionChart({
  title,
  chartId,
  data,
  svgWidth,
  svgHeight,
}) {
  const { t } = useTranslation();
  const [ drawFunction, setDrawFunction ] = useState(() => {})

  function arc(r, sign) {
    return r ? `a${r * sign[0]},${r * sign[1]} 0 0 1 ${r * sign[2]},${r * sign[3]}` : '';
  }
  function roundedRect(x, y, width, height, r) {
    r = [
      Math.min(r[0], height, width),
      Math.min(r[1], height, width),
      Math.min(r[2], height, width),
      Math.min(r[3], height, width),
    ];

    return `M${x + r[0]},${y}h${width - r[0] - r[1]}${arc(r[1], [1, 1, 1, 1])}v${
      height - r[1] - r[2]
    }${arc(r[2], [1, 1, -1, 1])}h${-width + r[2] + r[3]}${arc(r[3], [1, 1, -1, -1])}v${
      -height + r[3] + r[0]
    }${arc(r[0], [1, 1, 1, -1])}z`;
  }
  const ref = useD3(
    (svg) => {
      document.getElementsByClassName(`overview-mt-chart ${chartId}`)[0].innerHTML = '';
      const prevTooltip = document.getElementsByClassName(`tooltip ${chartId}`)[0];
      if (prevTooltip) prevTooltip.remove();
      svg.attr('width', svgWidth).attr('height', svgHeight);

      const margin = { top: 20, right: 30, bottom: 30, left: 35 };
      const chartWidth = svgWidth,
        chartHeight = svgHeight; // legend, year indicators
      const categories = ['transactionPerCustomer', 'transactionWithoutSignIn', 'transaction'];
      const xAxis = svg.append('g').attr('class', `x-axis ${chartId}`);
      const yLeftAxis = svg.append('g').attr('class', `y-left-axis ${chartId}`);
      const yRightAxis = svg.append('g').attr('class', `y-right-axis ${chartId}`);
      const grid = svg.append('g').attr('class', `y-grid ${chartId}`);
      const bars = svg.append('g').attr('class', `bar-group ${chartId}`);
      const line = svg.append('g').attr('class', `line ${chartId}`);
      const invisibleLine = svg.append('g').attr('class', `line ${chartId}`);
      const tooltip = d3
        .select(`.overview-mt-chart-container.${chartId}`)
        .append('div')
        .attr('class', `tooltip ${chartId}`)
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .call((div) => div.append('div').attr('class', 'x'))
        .call((div) =>
          div
            .append('div')
            .attr('class', 'y')
            .call((div) => div.append('span').attr('class', 'label'))
            .call((div) => div.append('span').attr('class', 'value'))
        );
      const guideCircle = svg
        .append('g')
        .attr('class', `tooltip-circle ${chartId}`)
        .append('circle')
        .style('fill', '#A12AFF')
        .attr('stroke', '#A12AFF')
        .attr('r', 5)
        .style('visibility', 'hidden');
      const yearIndicators = svg.append('g').attr('class', `year-indicators ${chartId}`);

      drawCharts(categories);

      function drawCharts(selectedCategories) {
        const isTransactionPerCustomerDisabled =
          !selectedCategories.includes('transactionPerCustomer');
        const isTransactionWithoutSignInDisabled = !selectedCategories.includes(
          'transactionWithoutSignIn'
        );
        const isTransactionDisabled = !selectedCategories.includes('transaction');

        // data-related
        let barTotalD = data.transactionWithoutSignIn.reduce((acc, cur, i) => {
          const barD = {};
          barD.date = cur.x;
          if (!isTransactionWithoutSignInDisabled) barD.transactionWithoutSignIn = cur.y;
          if (!isTransactionDisabled) barD.transaction = data.transaction[i].y;
          acc.push(barD);
          return acc;
        }, []);
        let lineD = isTransactionPerCustomerDisabled ? [] : data.transactionPerCustomer;
        let lineX = isTransactionPerCustomerDisabled ? [] : d3.map(lineD, (v) => v.x);
        let lineY = isTransactionPerCustomerDisabled ? [] : d3.map(lineD, (v) => v.y);

        let barTotalX = d3.map(barTotalD, (v) => v.date);
        let barGroupX = [];
        if (!isTransactionWithoutSignInDisabled) barGroupX.push('transactionWithoutSignIn');
        if (!isTransactionDisabled) barGroupX.push('transaction');

        //scales
        const xLineScale = d3
          .scaleBand()
          .domain(lineX)
          .range([margin.left, chartWidth - margin.right]);
        if (lineD.length !== 0) {
          xLineScale.invert = (() => {
            const domain = xLineScale.domain();
            const range = xLineScale.range();
            const invertedScale = d3.scaleQuantize().domain(range).range(domain);
            return (x) => invertedScale(x);
          })();
        }
        const yLineScale = d3
          .scaleLinear()
          .domain([0, d3.max(lineY)])
          .rangeRound([svgHeight - margin.bottom - 25, margin.top]); // 25: indicator height

        const xBarTotalScale = d3
          .scaleBand()
          .domain(barTotalX)
          .rangeRound([margin.left, chartWidth - margin.right])
          .paddingInner(0.2);
        const xBarGroupScale = d3
          .scaleBand()
          .domain(barGroupX)
          .rangeRound([0, xBarTotalScale.bandwidth()])
          .paddingInner(0.2);

        const barDMax =
          d3.max([0, d3.max(barTotalD, (d) => d3.max(barGroupX, (x) => d[x]))]) === 0
            ? 1
            : d3.max([0, d3.max(barTotalD, (d) => d3.max(barGroupX, (x) => d[x]))]);

        const yScale = d3
          .scaleLinear()
          .domain([0, barDMax])
          .rangeRound([svgHeight - margin.bottom - 25, margin.top]) // 25: indicator height
          .nice();

        // x-axis
        xAxis
          .attr('transform', `translate(0,${svgHeight - margin.bottom - 25})`)
          .call(
            d3
              .axisBottom(xBarTotalScale)
              .tickValues(xBarTotalScale.domain().filter((d, i) => i % 3 == 0))
              .tickSizeOuter(0)
              .tickFormat((d) => extractMonth(d))
          )
          .select('.domain')
          .attr('stroke', '#DDDDDD');

        // y-axes
        yLeftAxis
          .attr('transform', `translate(${margin.left},0)`)
          .call(
            d3
              .axisLeft(yScale)
              .ticks(5)
              .tickFormat((v) => formatNumber(v))
          )
          .select('.domain')
          .remove();

        yRightAxis
          .attr('transform', `translate(${chartWidth - margin.right},0)`)
          .call(d3.axisRight(yLineScale).ticks(5))
          .select('.domain')
          .remove();

        // grid
        grid
          .attr('transform', `translate(${margin.left},0)`)
          .call(
            d3
              .axisLeft(yScale)
              .ticks(5, '~s')
              .tickSize(-(chartWidth - margin.right - margin.left))
              .tickFormat('')
          )
          .call((g) => g.select('path').attr('stroke', 'transparent'))
          .call((g) => g.selectAll('.tick line').attr('stroke', '#DDDDDD'));

        if (barDMax === 1) {
          yLeftAxis.selectAll('.tick').style('opacity', 0);
          grid.selectAll('.tick').style('opacity', 0);
        } else {
          yLeftAxis.selectAll('.tick').style('opacity', 1);
          grid.selectAll('.tick').style('opacity', 1);
        }

        // bars
        const barsUpdate = bars.selectAll('g').data(barTotalD);
        barsUpdate
          .join('g')
          .call((g) =>
            g
              .attr('transform', (d) => `translate(${xBarTotalScale(d.date)},0)`)
              .selectAll('path')
              .data((d) => barGroupX.map((key) => ({ x: key, y: d[key] })))
              .join('path')
              .attr('d', (d) => {
                return roundedRect(
                  xBarGroupScale(d.x),
                  yScale(d.y),
                  xBarGroupScale.bandwidth(),
                  svgHeight - margin.bottom - 25 - yScale(d.y),
                  [2, 2, 0, 0]
                );
              })
              .attr('fill', (d) => {
                return d.x === 'transactionWithoutSignIn' ? '#C1BEF7' : '#4B43FF';
              })
          )
          .on('mouseover', () => {
            tooltip.style('visibility', 'visible');
          })
          .on('mousemove', (event, d) => {
            const tooltipX = tooltip.select('.x');
            const tooltipY = tooltip.select('.y');
            const xTotalPosition = xBarTotalScale(d.date);
            const [x, y] = d3.pointer(event);

            tooltipX.html(d.date);
            if (!isTransactionWithoutSignInDisabled && !isTransactionDisabled) {
              tooltipY
                .select('.label')
                .html(xBarTotalScale.bandwidth() / 2 < x ? 'Sign-in:&nbsp;' : 'Guest:&nbsp;');
              tooltipY
                .select('.value')
                .html(
                  numberWithCommas(
                    xBarTotalScale.bandwidth() / 2 < x ? d.transaction : d.transactionWithoutSignIn
                  )
                );
            } else if (!isTransactionWithoutSignInDisabled) {
              tooltipY.select('.label').html('Guest:&nbsp;');
              tooltipY.select('.value').html(numberWithCommas(d.transactionWithoutSignIn));
            } else if (!isTransactionDisabled) {
              tooltipY.select('.label').html('Sign-in:&nbsp;');
              tooltipY.select('.value').html(numberWithCommas(d.transaction));
            }

            tooltip
              .style(
                'left',
                x -
                  d3.select('.tooltip.mt').node().getBoundingClientRect().width / 2 +
                  xTotalPosition +
                  'px'
              )
              .style('top', y - 30 + 'px');
          })
          .on('mouseout', () => tooltip.style('visibility', 'hidden'));

        // line
        const lineUpdate = line.selectAll('path').data([lineD]);
        lineUpdate
          .join('path')
          .attr('stroke', '#A12AFF')
          .attr('fill', 'transparent')
          .attr('stroke-linecap', 'round')
          .attr('stroke-width', 3)
          .style('pointer-events', 'stroke')
          .attr(
            'd',
            d3
              .line()
              .x((d) => xLineScale(d.x) + xLineScale.bandwidth() / 2)
              .y((d) => yLineScale(d.y))
              .curve(d3.curveMonotoneX)
          )

        const invisibleLineUpdate = invisibleLine.selectAll('path').data([lineD]);
        invisibleLineUpdate
          .join('path')
          .attr('stroke', '#00000000')
          .attr('fill', 'transparent')
          .attr('stroke-linecap', 'round')
          .attr('stroke-width', 30)
          .style('pointer-events', 'stroke')
          .attr(
            'd',
            d3
              .line()
              .x((d) => xLineScale(d.x) + xLineScale.bandwidth() / 2)
              .y((d) => yLineScale(d.y))
              .curve(d3.curveMonotoneX)
          )
          .on('mouseover', () => {
            tooltip.style('visibility', 'visible');
            guideCircle.style('visibility', 'visible');
          })
          .on('mousemove', (event, d) => {
            const tooltipX = tooltip.select('.x');
            const tooltipY = tooltip.select('.y');
            const [x, y] = d3.pointer(event);
            const xValue = xLineScale.invert(x);
            const yValue = d.filter((v) => v.x === xValue)[0].y;

            tooltipX.html(xValue);
            tooltipY.select('.value').html(yValue);
            tooltip
              .style(
                'left',
                d.map((v) => v.x).indexOf(xValue) * xLineScale.step() +
                  xLineScale.bandwidth() / 2 +
                  margin.left -
                  d3.select('.tooltip.mt').node().getBoundingClientRect().width / 2 +
                  'px'
              )
              .style('top', yLineScale(yValue) + 60 + 'px');
            guideCircle
              .transition()
              .duration(1)
              .attr(
                'cx',
                d.map((v) => v.x).indexOf(xValue) * xLineScale.step() +
                  xLineScale.bandwidth() / 2 +
                  margin.left
              )
              .attr('cy', yLineScale(yValue));
          })
          .on('mouseout', () => {
            tooltip.style('visibility', 'hidden');
            guideCircle.style('visibility', 'hidden');
          });

        const yearIndicatorsD = barTotalX.reduce((acc, cur, idx) => {
          const year = cur.slice(0, 4);
          const yearMatchedObj = acc.filter((e) => e.year === year);
          if (yearMatchedObj.length === 0) {
            acc.push({
              year: year,
              firstIdx: idx,
              lastIdx: idx,
            });
          }
          acc.forEach((e) => {
            if (e.year === year) {
              e.lastIdx = idx;
              return;
            }
          });
          return acc;
        }, []);
        yearIndicatorsD.forEach((v, i) => {
          yearIndicators
            .append('text')
            .attr('class', 'year-indicator')
            .text(v.year)
            .attr('x', margin.left + xBarTotalScale.step() * v.firstIdx)
            .attr('y', chartHeight + margin.top + 20);
        });
      }
      setDrawFunction(() => {
        return drawCharts // if simply the function is passed, setState calls the function
      })
    },
    [data, svgWidth, svgHeight]
  );
  return (
    <>
    <div className={`overview-mt-chart-container ${chartId}`}>
      <div className={`overview-mt-chart-title ${chartId}`}>{title}</div>
      <Legend
        labels={[t("overview.transaction_per_customer"), t("overview.transactionguest"), t("overview.transactionsignin")]}
        values={['transactionPerCustomer', 'transactionWithoutSignIn', 'transaction']}
        colors={[theme.color.neonPurple500, theme.color.neonBlue100, theme.color.neonBlue500]}
        types={['line', 'circle', 'circle']}
        isVertical={true}
        drawFunction={drawFunction}
      />
      <svg className={`overview-mt-chart ${chartId}`} ref={ref}></svg>
    </div>
    </>
  );
}
