import styled from '@emotion/styled';
import { theme } from 'styles@emotion/theme';

const WhiteBox = styled.div`
  padding: 25px 24px 30px;
  background-color: ${theme.color.white};
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const ParameterContainer = styled(WhiteBox)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 208px;
`;

const ParameterSelectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 21.33px;
  width: 100%;
`;
const ParameterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const BestJourneyContainer = styled(WhiteBox)`
  width: 100%;
`;

const JourneyContainer = styled(WhiteBox)`
  width: 100%;
  margin-bottom: 40px;
`;

const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${theme.font.chartTitle}
  color: ${theme.color.gray900};
`;
const ExportButton = styled.button`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
  ${theme.font.buttonSmall}
  color: ${theme.color.neonBlue500};
  user-select: none;
  cursor: pointer;
  & > img {
    margin-right: 2px;
  }
`;
const ExportButtonIcon = styled.img``;

const ChartContainer = styled.div``;

const JourneySelectsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 4px;
  margin-top: 30px;
  width: 100%;
`;

export default {
  Container,
  ParameterContainer,
  ParameterSelectsContainer,
  ParameterButtonContainer,
  BestJourneyContainer,
  JourneyContainer,
  ContainerTitle,
  ExportButton,
  ExportButtonIcon,
  ChartContainer,
  JourneySelectsContainer
};
