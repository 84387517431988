import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styles from './index.styles';
import Spacer from 'components/emotion/Spacer';

// Description (좌측 상단 box)
const Description = () => {
  const { t } = useTranslation();
  const descriptionList = useMemo(
    () => [
      { value: t('mosaic.the_data_should_be_based') },
      { value: t('mosaic.there_should_be_no_duplicate') },
      {
        value: t('mosaic.the_data_value_of_each')
      },
      {
        value: t('mosaic.keep_data_proportions_in_line')
      },
      {
        value: t('mosaic.for_multiple_shops_check_shop')
      }
    ],
    [t]
  );
  return (
    <Styles.Container>
      <Styles.Title>{t('mosaic.please_check_the_guide_and')}</Styles.Title>
      <Spacer horizontal size={8} />
      <Styles.GrayBox>
        <Styles.List>
          {descriptionList.map((item, index) => (
            <Styles.ListItem key={index}>{item.value}</Styles.ListItem>
          ))}
        </Styles.List>
      </Styles.GrayBox>
    </Styles.Container>
  );
};
export default Description;
