import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

const initialActiveStyle = {
  left: 0,
  width: 0,
};

export function ChartTabButton({ label, options, selected, handler, isVertical = false }) {
  const [{ left, width }, setActiveStyle] = useState(initialActiveStyle);
  const optionsRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    // without setTimeout, the offsetWidth is weird. But why?
    setTimeout(() => {
      let targetIndex;
      let targetLeft = 0;
      let targetWidth;
      const optionElements = optionsRef.current.childNodes;

      Array.from(optionElements).forEach((v, i) => {
        if (Array.from(v.classList).includes('select')) targetIndex = i;
      });
      Array.from(optionElements).forEach((v, i) => {
        if (i === targetIndex || i > targetIndex) return;
        const optionElement = optionElements[i];
        targetLeft += optionElement.offsetWidth;
      });
      targetWidth = optionElements[targetIndex].offsetWidth;
      setActiveStyle({
        left: targetLeft,
        width: targetWidth,
      });
    }, 0);
  }, [selected]);

  return (
    <div className={`chart-tab-button ${isVertical ? 'vertical' : ''}`}>
      <div className="chart-tab-button_label">{label}</div>
      <div className="chart-tab-button_button">
        <div
          className="chart-tab-button_active"
          style={{
            left: `${left}px`,
            width: `${width}px`,
          }}
        />
        <div className="chart-tab-button_options" ref={optionsRef}>
          {options.map((option, i) => (
            <div
              key={`${option}${i}`}
              className={`chart-tab-button_option ${selected === option ? 'select' : ''}`}
              onClick={() => handler(option)}
            >
              {t(`segmentation.${option}`)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
