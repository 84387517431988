const Toggle = ({ disabled, isOn, handleToggle }) => {
  return (
    <div
      className={`toggle ${disabled ? 'disabled' : ''} ${isOn ? 'on' : ''}`}
      onClick={() => !disabled && handleToggle()}
    >
      <div className={`toggle_btn ${disabled ? 'disabled' : ''} ${isOn ? 'on' : ''}`} />
    </div>
  );
};

export default Toggle;
