export const columns = [
  {
    header: 'Name',
    cell: 'customer_name',
  },
  {
    header: 'Segment',
    cell: 'segment_name',
  },
  {
    header: 'Status',
    cell: 'status',
  },
  {
    header: 'Transaction',
    cell: 'transactions',
  },
  {
    header: 'Latest Transaction',
    cell: 'latest_transaction',
    subCell: 'since',
  },
  {
    header: 'Score',
    cell: 'score',
  },
];
