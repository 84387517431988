import { useState, useMemo } from 'react';
import Styled from './styled';
import { CHEVRON_DOWN } from 'constants/images';
import { useTranslation } from 'react-i18next';

const Examples = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  const options = useMemo(
    () => [
      {
        key: 'number',
        image: t('mosaic.img.mosaic_example_number')
      },
      {
        key: 'text',
        image: t('mosaic.img.mosaic_example_text')
      },
      {
        key: 'boolean',
        image: t('mosaic.img.mosaic_example_boolean')
      }
    ],
    [t]
  );

  return (
    <Styled.Container>
      <Styled.CollapseButton onClick={() => setIsOpen((prev) => !prev)}>
        <Styled.Title>{t('mosaic.example_chart')}</Styled.Title>
        <Styled.ChevronIcon src={CHEVRON_DOWN} isReverse={isOpen} />
      </Styled.CollapseButton>
      {isOpen && (
        <Styled.Images>
          {options.map((option) => (
            <Styled.Image key={option.key} src={option.image} />
          ))}
        </Styled.Images>
      )}
    </Styled.Container>
  );
};
export default Examples;
