import { useEffect } from 'react';
import PageTitle from 'components/emotion/PageTitle';
import PrimaryButton from 'components/emotion/PrimaryButton';
import Select from 'components/emotion/Select';
import Styled from './styled';
import { Trans, useTranslation } from 'react-i18next';
import { useItemJourney } from 'hooks/service/itemJourney';
import { usePageInit } from 'hooks';
import { EXPORT } from 'constants/images';
import Chart from 'components/emotion/Chart';
import { chartKeys } from 'constants/keys';

import { SELECT_SIZE } from 'constants';
import NoData from 'components/emotion/NoData';

const { DEFAULT, SMALL } = SELECT_SIZE;

const ItemJourney = () => {
  const { t } = useTranslation();

  const {
    parameterItems,
    selectParameter,
    selectedSegmentName,
    selectedOutputName,
    selectedParameter,
    result,
    exportFile,
    clickApply
  } = useItemJourney();

  const { best, explore } = result;

  useEffect(() => {
    usePageInit();
  }, []);

  // 이게 종은 방법일까 아니명 애초부터 null이 아닌 값으로 내려받는게 좋은걸까?
  if (!selectedParameter) return null;

  return (
    <>
      <PageTitle main="Item Journey" sub={t('journey.find_the_sequential_purchase_patterns')} />
      <Styled.Container>
        <Styled.ParameterContainer>
          <Styled.ParameterSelectsContainer>
            <Select
              onSelect={selectParameter}
              label={t('options.segment')}
              property={'segment'}
              items={parameterItems?.segment}
              selected={selectedParameter?.segment}
            />
            <Select
              onSelect={selectParameter}
              label={t('options.output')}
              property={'output'}
              items={parameterItems?.output}
              selected={selectedParameter?.output}
            />
            <Select
              onSelect={selectParameter}
              label={t('options.recentOneYear')}
              property={'recentOneYear'}
              items={parameterItems?.recentOneYear} // 한국어 체크
              selected={selectedParameter?.recentOneYear}
            />
          </Styled.ParameterSelectsContainer>
          <Styled.ParameterButtonContainer>
            <PrimaryButton
              onClick={clickApply}
              value={t('journey.apply')}
              style={{ width: '124px' }}
            />
          </Styled.ParameterButtonContainer>
        </Styled.ParameterContainer>
        <Styled.BestJourneyContainer>
          <Styled.ContainerTitle>{t('journey.most_popular_item_journey')}</Styled.ContainerTitle>

          <Styled.ExportButton onClick={exportFile}>
            <Styled.ExportButtonIcon src={EXPORT} />
            {t('journey.export')}
          </Styled.ExportButton>
          <Styled.ChartContainer>
            {best &&
              // 베스트저니의 데이터가 존재하지 않는 경우
              (best.nodes.length < 2 ? (
                // segment가 all일 경우
                selectedSegmentName === 'all' ? (
                  <NoData
                    main={t('journey.best_journey_select_a_segment')}
                    sub={t('journey.best_journey_all_segment_doesnt_have_the')}
                  />
                ) : (
                  // segment가 지정되었으나 데이터가 없는 경우
                  <NoData
                    main={t('journey.best_journey_select_another_segment')}
                    sub={t('journey.best_journey_this_segment_doesnt_have_significant')}
                  />
                )
              ) : (
                <Chart
                  chartInfo={{
                    data: { ...best, selectedOutputName },
                    chartId: chartKeys.JOURNEY.BEST_JOURNEY,
                    tooltip: { width: 233, arrowPosition: 'topLeft' }
                  }}
                />
              ))}
          </Styled.ChartContainer>
        </Styled.BestJourneyContainer>
        <Styled.JourneyContainer>
          <Styled.ContainerTitle>
            {
              <Trans
                i18nKey={'journey.explore_item_journey'}
                values={{ segment: selectedSegmentName }}
              />
            }
          </Styled.ContainerTitle>
          {!(explore?.isFirstData && explore?.isResultEmpty) && (
            <Styled.JourneySelectsContainer>
              <Select
                disabled={!parameterItems?.first}
                size={SMALL}
                onSelect={selectParameter}
                label={
                  selectedOutputName === 'item'
                    ? t('journey.first_item')
                    : t('journey.first_category')
                }
                property={'first'}
                items={parameterItems?.first}
                selected={selectedParameter?.first}
              />
              <Select
                disabled={!parameterItems?.second}
                size={SMALL}
                onSelect={selectParameter}
                label={
                  selectedOutputName === 'item'
                    ? t('journey.second_item')
                    : t('journey.second_category')
                }
                property={'second'}
                items={parameterItems?.second}
                selected={selectedParameter?.second}
              />
              <Select
                disabled={!parameterItems?.third}
                size={SMALL}
                onSelect={selectParameter}
                label={
                  selectedOutputName === 'item'
                    ? t('journey.third_item')
                    : t('journey.third_category')
                }
                property={'third'}
                items={parameterItems?.third}
                selected={selectedParameter?.third}
              />
              <Select
                disabled={!parameterItems?.fourth}
                size={SMALL}
                onSelect={selectParameter}
                label={
                  selectedOutputName === 'item'
                    ? t('journey.fourth_item')
                    : t('journey.fourth_category')
                }
                property={'fourth'}
                items={parameterItems?.fourth}
                selected={selectedParameter?.fourth}
              />
              <Select
                disabled={!parameterItems?.fifth}
                size={SMALL}
                onSelect={selectParameter}
                label={
                  selectedOutputName === 'item'
                    ? t('journey.fifth_item')
                    : t('journey.fifth_category')
                }
                property={'fifth'}
                items={parameterItems?.fifth}
                selected={selectedParameter?.fifth}
              />
            </Styled.JourneySelectsContainer>
          )}

          {best &&
            explore &&
            (explore.isFirstData && explore.isResultEmpty ? (
              <NoData
                main={t('journey.theres_no_data')}
                sub={t('journey.this_segment_not_has_information')}
              />
            ) : (
              <Chart
                chartInfo={{
                  data: explore,
                  chartId: chartKeys.JOURNEY.JOURNEY,
                  handlers: { selectParameter },
                  tooltip: { width: 233, arrowPosition: 'topLeft' }
                }}
              />
            ))}
        </Styled.JourneyContainer>
      </Styled.Container>
    </>
  );
};

export default ItemJourney;
