import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RankingCard from '../../cards/RankingCard';
import './index.scss';

export default function OverviewRankingChart({ title, data }) {
  const { t } = useTranslation();
  const arrangedData = useMemo(() => data, []);
  return (
    (<div className="overview-ranking-chart">
      <div className="legend">
        <div className="legend-color"></div>{t("overview.sales_amount")}</div>
      <div className="overview-ranking-chart-title">{title}</div>
      <div className="overview-ranking-chart-body">
        {arrangedData.map((d, i) => {
          return (
            <RankingCard
              key={`${d}${i}`}
              name={d.x}
              value={d.y}
              priority={i === 0 ? 'primary' : i === 1 ? 'secondary' : i === 2 ? 'tertiary' : 'etc'}
            />
          );
        })}
      </div>
    </div>)
  );
}
