import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import './index.scss';
import { useQuery } from 'react-query';
import MainHeader from 'components/MainHeader';
import SearchInput from 'components/inputs/SearchInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import ComparisonMultiline from './ComparisonMultiline';
import { usePageInit } from 'hooks';
import { axiosPrivate, customerAPI } from 'api';
import { queryKeys } from 'constants/keys';
import { EXPORT } from 'constants/images';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { nameExportFile } from 'utils';
import { useUserInfoContext } from 'context';
import { cloneDeep, reverse, sum } from 'lodash';
import { numberWithCommas, calculateMedian, calculateStd } from 'utils';
import {
  ParametersSkeleton,
  ParameterSkeleton,
  TitleSkeleton,
  DynamicSkeleton
} from 'components/skeletons';
import { MONTH, QUARTER, YEAR } from 'constants';

function makeLtvNameByInterval(ltvCount, selectedParametersIntervalId) {
  return `LTV ${ltvCount}${
    selectedParametersIntervalId === QUARTER
      ? 'Q'
      : selectedParametersIntervalId === YEAR
      ? 'Y'
      : ''
  }`;
}

function monthDiff(d1, d2) {
  const _d1 = new Date(d1);
  const _d2 = new Date(d2);
  return _d2.getMonth() - _d1.getMonth() + 12 * (_d2.getFullYear() - _d1.getFullYear());
}

function transformOptions(data) {
  const tobeReturn = {
    segment: data.segments.map((v) => ({ id: +v.id, value: v.name ? v.name : '' })),
    differenceBy: [
      {
        id: 0,
        value: 'segment'
      }
    ],
    comparisonTo: [
      {
        id: 0,
        value: 'cohort'
      }
    ],
    comparisonBy: [
      {
        id: 0,
        value: 'LTV changes'
      },
      {
        id: 1,
        value: 'specific value'
      }
    ],
    interval: data.intervals.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    avgMonths: data.ranges.month.map((_, i) => ({
      id: i + 1,
      value: String(i + 1)
    })),
    avgQuarters: [
      ...Array(
        Math.max(
          monthDiff(data.ranges.quarter[0], data.ranges.month[data.ranges.month.length - 1]) - 2,
          0
        )
      )
    ].map((_, i) => ({
      id: i + 1,
      value: String(i + 1)
    })),
    avgYears: [
      ...Array(
        Math.max(
          monthDiff(data.ranges.year[0], data.ranges.month[data.ranges.month.length - 1]) - 11,
          0
        )
      )
    ].map((_, i) => ({
      id: i + 1,
      value: String(i + 1)
    })),
    valueMonths: data.ranges.month.map((_, i) => ({
      id: i + 1,
      value: `LTV ${i + 1}`
    })),
    valueQuarters: data.ranges.quarter.map((_, i) => ({
      id: i + 1,
      value: `LTV ${i + 1}Q`
    })),
    valueYears: data.ranges.year.map((_, i) => ({
      id: i + 1,
      value: `LTV ${i + 1}Y`
    })),
    monthlyFrom: data.ranges.month.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    monthlyTo: data.ranges.month.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    quarterlyFrom: data.ranges.quarter.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    quarterlyTo: data.ranges.quarter.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    yearlyFrom: data.ranges.year.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    yearlyTo: data.ranges.year.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    }))
  };
  return tobeReturn;
}

const Comparison = () => {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();
  const [isInitialFetch, setIsInitialFetch] = useState(false);
  const [selectedParameters, setSelectedParameters] = useState(null); // object
  const [resultCategory, setResultCategory] = useState({
    ltvChanges: false,
    specificValue: false
  });
  const [selectedSpecificValue, setSelectedSpecificValue] = useState(null); // string, 결과 차트/테이블 렌더링 시 필요한 텍스트
  const [selectedInterval, setSelectedInterval] = useState(null); // string, 결과 차트/테이블 렌더링 시 필요한 텍스트
  const [result, setResult] = useState(null);

  const { data: option } = useQuery(
    [queryKeys.OPTION.COHORT, 'options', userInfo?.company.shopId],
    async ({ queryKey }) => {
      const { data } = await axiosPrivate.post(customerAPI.OPTION, {
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        page: queryKey[0]
      });
      return data;
    },

    {
      select: (data) => transformOptions(data)
    }
  );
  const handleExport = (flag) => (e) => {
    e.preventDefault();
    if (userInfo?.user.role === 'observer') return;
    if (flag === 'period') {
      const data = result.table;
      const _data = data.map((d) => {
        const _d = { ...d };
        _d.columns = [];
        _d.rows = {};

        const ltvColumns = _d.x.map((x, i) => ({ header: x, key: `ltv${i + 1}` }));
        // const increaseColumns = _d.x
        //   .map((x, i) => {
        //     if (i === data[0].x.length - 1) return;
        //     return {
        //       header: `Increase ${i + 1} to ${i + 2}`,
        //       key: `increase${i + 1}to${i + 2}`,
        //     };
        //   })
        //   .filter((x) => x);

        _d.columns.push({ header: '', key: 'segment' });
        _d.columns.push(...ltvColumns);
        // _d.columns.push(...increaseColumns);

        _d.rows['segment'] = _d.segment.value;
        ltvColumns.forEach((c, i) => {
          _d.rows[c.key] = _d.y[i];
        });
        // increaseColumns.forEach((c, i) => {
        //   _d.rows[c.key] = d.y[i + 1] - d.y[i];
        // });
        return _d;
      });
      const worksheetColumns = _data[0].columns;
      const worksheetRows = _data.map((d) => d.rows);
      const worksheetName = 'sheet 1';
      const workbook = new Excel.Workbook();
      const workBookName = `${nameExportFile(
        `Avg(LTV) of recent ${
          selectedParameters.interval.id === MONTH
            ? selectedParameters.avgMonths.value
            : selectedParameters.interval.id === QUARTER
            ? selectedParameters.avgQuarters.value
            : selectedParameters.avgYears.value
        } ${
          selectedParameters.interval.id === MONTH
            ? 'months'
            : selectedParameters.interval.id === QUARTER
            ? 'quarters'
            : 'years'
        } changes`
      ).slice(0, -4)}.xlsx`;

      const saveExcel = async () => {
        try {
          const worksheet = workbook.addWorksheet(worksheetName);

          worksheet.columns = worksheetColumns;
          worksheet.getRow(1).font = { bold: true };
          worksheet.columns.forEach((column, i) => {
            if (i === 0) {
              column.width = 15;
              column.alignment = { horizontal: 'center' };
            } else {
              column.width = column.header.length + 5;
              column.alignment = { horizontal: 'center' };
            }
          });
          worksheetRows.forEach((singleData) => {
            worksheet.addRow(singleData);
          });
          worksheet.eachRow({ includeEmpty: false }, (row) => {
            const currentCell = row._cells;
            currentCell.forEach((singleCell) => {
              const cellAddress = singleCell._address;
              worksheet.getCell(cellAddress).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
              };
            });
          });
          const buf = await workbook.xlsx.writeBuffer();
          saveAs(new Blob([buf]), workBookName);
        } catch (error) {
          console.error(error);
        } finally {
          workbook.removeWorksheet(worksheetName);
        }
      };
      saveExcel();
    }
    if (flag === 'specificValue') {
      const data = { ...result.table };
      const worksheetColumns = data.header.map((h) => ({
        key: h,
        header: h
      }));
      worksheetColumns.unshift({ key: 'name', header: '' });

      const worksheetRows = data.integratedRows.map((row) => {
        const toBeReturn = {};
        worksheetColumns.forEach((col, i) => {
          toBeReturn[col.key] = row[i];
        });
        return toBeReturn;
      });
      const worksheetName = 'sheet 1';
      const workbook = new Excel.Workbook();
      const workBookName = `${nameExportFile(
        `${
          selectedParameters.interval.id === MONTH
            ? selectedParameters.valueMonths.value
            : selectedParameters.interval.id === QUARTER
            ? selectedParameters.valueQuarters.value
            : selectedParameters.valueYears.value
        } by each group`
      ).slice(0, -4)}.xlsx`;

      const saveExcel = async () => {
        try {
          const worksheet = workbook.addWorksheet(worksheetName);

          worksheet.columns = worksheetColumns;
          worksheet.getRow(1).font = { bold: true };
          worksheet.columns.forEach((column, i) => {
            if (i === 0) {
              column.width = 15;
              column.alignment = { horizontal: 'center' };
            } else {
              column.width = column.header.length + 5;
              column.alignment = { horizontal: 'center' };
            }
          });
          worksheetRows.forEach((singleData) => {
            worksheet.addRow(singleData);
          });
          worksheet.eachRow({ includeEmpty: false }, (row) => {
            const currentCell = row._cells;
            currentCell.forEach((singleCell) => {
              const cellAddress = singleCell._address;
              worksheet.getCell(cellAddress).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
              };
            });
          });
          const buf = await workbook.xlsx.writeBuffer();
          saveAs(new Blob([buf]), workBookName);
        } catch (error) {
          console.error(error);
        } finally {
          workbook.removeWorksheet(worksheetName);
        }
      };
      saveExcel();
    }
  };

  const handleOptionsFilter = ({ label }) => {
    if (label === 'from') {
      if (selectedParameters.interval.id === MONTH) {
        return option.monthlyFrom.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.monthlyTo.id) < 0
        );
      } else if (selectedParameters.interval.id === QUARTER) {
        return option.quarterlyFrom.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.quarterlyTo.id) < 0
        );
      } else {
        return option.yearlyFrom.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.yearlyTo.id) < 0
        );
      }
    }
    if (label === 'to') {
      if (selectedParameters.interval.id === MONTH) {
        return option.monthlyTo.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.monthlyFrom.id) > 0
        );
      } else if (selectedParameters.interval.id === QUARTER) {
        return option.quarterlyTo.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.quarterlyFrom.id) > 0
        );
      } else {
        return option.yearlyTo.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.yearlyFrom.id) > 0
        );
      }
    }
    if (label.includes('avg')) {
      if (selectedParameters.interval.id === MONTH) {
        return option.avgMonths;
      } else if (selectedParameters.interval.id === QUARTER) {
        return option.avgQuarters;
      } else {
        return option.avgYears;
      }
    }
    if (label === 'value') {
      if (selectedParameters.interval.id === MONTH) {
        return option.valueMonths;
      } else if (selectedParameters.interval.id === QUARTER) {
        return option.valueQuarters;
      } else {
        return option.valueYears;
      }
    }
    return;
  };

  const handleParameterSet = (selectedOption, label) => {
    let key = label;
    if (key === 'from') {
      key =
        selectedParameters.interval.id === MONTH
          ? 'monthlyFrom'
          : selectedParameters.interval.id === QUARTER
          ? 'quarterlyFrom'
          : 'yearlyFrom';
    }
    if (key === 'to') {
      key =
        selectedParameters.interval.id === MONTH
          ? 'monthlyTo'
          : selectedParameters.interval.id === QUARTER
          ? 'quarterlyTo'
          : 'yearlyTo';
    }

    if (key === 'interval') {
      setSelectedParameters((prev) => ({
        ...prev,
        [key]: {
          id: selectedOption.id,
          value: selectedOption.value
        },
        avgMonths: {
          id: 1,
          value: '1'
        },
        avgQuarters: {
          id: 1,
          value: '1'
        },
        avgYears: {
          id: 1,
          value: '1'
        }
      }));
    } else {
      setSelectedParameters((prev) => ({
        ...prev,
        [key]: {
          id: selectedOption.id,
          value: selectedOption.value
        }
      }));
    }
  };

  const handleParameterGet = ({ label, interval }) => {
    if (label === 'from') {
      switch (interval) {
        case MONTH:
          return selectedParameters.monthlyFrom;
        case QUARTER:
          return selectedParameters.quarterlyFrom;
        default:
          return selectedParameters.yearlyFrom;
      }
    }

    if (label === 'to') {
      switch (interval) {
        case MONTH:
          return selectedParameters.monthlyTo;
        case QUARTER:
          return selectedParameters.quarterlyTo;
        default:
          return selectedParameters.yearlyTo;
      }
    }
    if (label.includes('avg')) {
      switch (interval) {
        case MONTH:
          return selectedParameters.avgMonths;
        case QUARTER:
          return selectedParameters.avgQuarters;
        default:
          return selectedParameters.avgYears;
      }
    }
    if (label === 'value') {
      switch (interval) {
        case MONTH:
          return selectedParameters.valueMonths;
        case QUARTER:
          return selectedParameters.valueQuarters;
        default:
          return selectedParameters.valueYears;
      }
    }

    return selectedParameters[label];
  };

  const handlePeriodResultGet = async () => {
    const segmentsWithoutAll = option.segment.filter((seg) => seg.id !== 0);
    const chartData = [];
    const tableData = [];
    const COLORS = ['#4B43FF', '#00DCFF', '#A12AFF', '#FE59F7', '#FFB800', '#FD6A00', '#18D1A0'];
    let colorIndex = 0;

    // segment별로 한번씩 모두 호출을 한다
    // 리턴된 값들을 일정한 형태로 처리하여 한데 모은다
    for (const seg of segmentsWithoutAll) {
      const { data } = await axiosPrivate.post(customerAPI.LTV, {
        company_id: userInfo.company.companyId,
        shop_id: userInfo.company.shopId,
        interval: selectedParameters.interval.id,
        from_time:
          selectedParameters.interval.id === MONTH
            ? option.monthlyFrom[0].id
            : selectedParameters.interval.id === QUARTER
            ? option.quarterlyFrom[0].id
            : option.yearlyFrom[0].id,
        to_time:
          selectedParameters.interval.id === MONTH
            ? option.monthlyTo[option.monthlyTo.length - 1].id
            : selectedParameters.interval.id === QUARTER
            ? option.quarterlyTo[option.quarterlyTo.length - 1].id
            : option.yearlyTo[option.yearlyTo.length - 1].id,
        segment_id: seg.id
      });

      const reversedCohort = reverse(cloneDeep(data.result));
      const recentN =
        selectedParameters[
          selectedParameters.interval.id === MONTH
            ? 'avgMonths'
            : selectedParameters.interval.id === QUARTER
            ? 'avgQuarters'
            : 'avgYears'
        ];
      /*
        x: LTV X
        y: value
      */
      const tableDataBySegment = {
        segment: seg,
        x: [],
        y: []
      };
      let flag = true;
      let columnLtvIndex = 0;
      let rowStartIndexInReversedCohort = 0;
      // column(LTV X)를 가능할때까지 순차적으로 진행
      while (flag) {
        // reversedCohort 배열 기준으로, 다음 column(LTV X)에서 최초로 값이 존재하는 인덱스(rowStartIndexInReversedCohort)를 찾기 위함
        rowStartIndexInReversedCohort = reversedCohort.reduce((acc, cur, i) => {
          if (columnLtvIndex === 0) {
            return 0;
          }
          if (typeof cur.values[columnLtvIndex] === 'number' && acc === 0) {
            acc = i;
          }
          return acc;
        }, 0);
        // rowStartIndexInReversedCohort를 구할 때 최대치 이후 다시 0으로 연산하는 로직 오류가 있다. 이를 막기위해 임시로 아래 조건문을 추가해둔다.
        if (columnLtvIndex !== 0 && rowStartIndexInReversedCohort === 0) {
          flag = false;
          break;
        }

        // quaterly인경우와 yaerly인 경우 현재 데이터 로직의 결함(?) 아래의 조건문이 각각 추가된다.
        if (selectedParameters.interval.id === QUARTER) {
          if (columnLtvIndex === 0) {
            rowStartIndexInReversedCohort += 2;
          } else {
            rowStartIndexInReversedCohort += 3;
          }
        }
        if (selectedParameters.interval.id === YEAR) {
          if (columnLtvIndex === 0) {
            rowStartIndexInReversedCohort += 11;
          } else {
            rowStartIndexInReversedCohort += 12;
          }
        }

        // 더이상 recentN개의 값들을 가진 column이 아닐 시 while 문 중단
        if (rowStartIndexInReversedCohort + Number(recentN.value) > reversedCohort.length) {
          flag = false;
          break;
        }

        // 더해야 하는 값들을 더해서(total) 평균값(average)를 구하는 부분
        let total = 0;
        let average = 0;
        for (
          let i = rowStartIndexInReversedCohort;
          i < rowStartIndexInReversedCohort + Number(recentN.value);
          i++
        ) {
          total += reversedCohort[i].values[columnLtvIndex];
        }
        average = Math.round(total / Number(recentN.value));

        tableDataBySegment.x.push(
          makeLtvNameByInterval(columnLtvIndex + 1, selectedParameters.interval.id)
        );
        tableDataBySegment.y.push(average);

        chartData.push({
          segmentId: seg.id,
          segmentValue: seg.value,
          x: makeLtvNameByInterval(columnLtvIndex + 1, selectedParameters.interval.id),
          y: average,
          color: COLORS[colorIndex]
        });

        columnLtvIndex += 1;
      }
      // segment별로 고정된 색상값을 가지기 위해 colorIndex += 1;
      colorIndex += 1;

      tableData.push(tableDataBySegment);
    }

    setResult({
      chart: chartData,
      table: tableData
    });
    setResultCategory({
      ltvChanges: true,
      specificValue: false
    });
    setSelectedInterval(selectedParameters.interval.id);
  };

  const handleSpecificValueGet = async () => {
    const segmentsWithoutAll = cloneDeep(option.segment)
      .sort((a, b) => a.id - b.id)
      .filter((seg) => seg.id !== 0);
    const COLORS = ['#4B43FF', '#00DCFF', '#A12AFF', '#FE59F7', '#FFB800', '#FD6A00', '#18D1A0'];
    let colorIndex = 0;

    const chartData = {
      segments: [],
      data: []
    };
    const tableData = {
      header: segmentsWithoutAll.map((seg) => seg.value),
      rowNames: [],
      rows: {
        avg: [],
        median: [],
        std: [],
        min: [],
        max: [],
        dates: []
      },
      integratedRows: []
    };
    const tempRowNames = new Set([
      t('comparison.average'),
      t('comparison.median'),
      t('comparison.std'),
      t('comparison.min'),
      t('comparison.max')
    ]);
    const tempValuesListRows = [];

    for (const seg of segmentsWithoutAll) {
      const { data } = await axiosPrivate.post(customerAPI.LTV, {
        company_id: userInfo.company.companyId,
        shop_id: userInfo.company.shopId,
        interval: selectedParameters.interval.id,
        from_time:
          selectedParameters.interval.id === MONTH
            ? selectedParameters.monthlyFrom.id
            : selectedParameters.interval.id === QUARTER
            ? selectedParameters.quarterlyFrom.id
            : selectedParameters.yearlyFrom.id,
        to_time:
          selectedParameters.interval.id === MONTH
            ? selectedParameters.monthlyTo.id
            : selectedParameters.interval.id === QUARTER
            ? selectedParameters.quarterlyTo.id
            : selectedParameters.yearlyTo.id,
        segment_id: seg.id
      });

      const cohort = data.result;
      const specificValue =
        selectedParameters[
          selectedParameters.interval.id === MONTH
            ? 'valueMonths'
            : selectedParameters.interval.id === QUARTER
            ? 'valueQuarters'
            : 'valueYears'
        ];

      cohort
        .map((c) => c.x)
        .forEach((d) => {
          tempRowNames.add(d);
        });
      const valuesList = [];

      for (let i = 0; i < cohort.length; i++) {
        chartData.data.push({
          segmentId: seg.id,
          segmentValue: seg.value,
          x: cohort[i].x.slice(0, 7),
          y: cohort[i].values[specificValue.id - 1] ?? 0,
          color: COLORS[colorIndex]
        });
        valuesList.push(cohort[i].values[specificValue.id - 1] ?? 0);
      }

      colorIndex += 1;
      chartData.segments.push(seg);
      const averageValue = Math.round(sum(valuesList) / valuesList.length);
      const medianValue = Math.round(calculateMedian(valuesList));
      const stdValue = Math.round(calculateStd(valuesList));
      const minValue = Math.min(...valuesList);
      const maxValue = Math.max(...valuesList);
      tableData.rows.avg.push(averageValue);
      tableData.rows.median.push(medianValue);
      tableData.rows.std.push(stdValue);
      tableData.rows.max.push(maxValue);
      tableData.rows.min.push(minValue);
      tempValuesListRows.push(valuesList);
    }

    // tempRowNames Set을 다시 array로 변경
    tableData.rowNames = Array.from(tempRowNames);
    // valuesListsRows를 transpose 해서 tableData.rows.dates에 선언
    tableData.rows.dates = tempValuesListRows[0].map((_, colIndex) =>
      tempValuesListRows.map((row) => row[colIndex])
    );
    tableData.integratedRows = tableData.rowNames.map((rowName, i) => {
      const row = [];
      if (i === 0) {
        row.push(rowName);
        row.push(...tableData.rows.avg);
      } else if (i === 1) {
        row.push(rowName);
        row.push(...tableData.rows.median);
      } else if (i === 2) {
        row.push(rowName);
        row.push(...tableData.rows.std);
      } else if (i === 3) {
        row.push(rowName);
        row.push(...tableData.rows.min);
      } else if (i === 4) {
        row.push(rowName);
        row.push(...tableData.rows.max);
      } else {
        row.push(rowName);
        row.push(...tableData.rows.dates[i - 5]);
      }
      return row;
    });

    setResult({ chart: chartData, table: tableData });
    setResultCategory({
      ltvChanges: false,
      specificValue: true
    });
    setSelectedSpecificValue(
      selectedParameters.interval.id === MONTH
        ? selectedParameters.valueMonths.value
        : selectedParameters.interval.id === QUARTER
        ? selectedParameters.valueQuarters.value
        : selectedParameters.valueYears.value
    );
  };

  useEffect(() => {
    if (option) {
      setSelectedParameters((prev) => ({
        ...prev,
        differenceBy: {
          id: 0,
          value: 'segment'
        },
        comparisonTo: {
          id: 0,
          value: 'cohort'
        },
        comparisonBy: {
          id: 0,
          value: 'LTV changes'
        },
        interval: {
          id: option.interval.filter((v) => v.id === MONTH)[0].id,
          value: option.interval.filter((v) => v.id === MONTH)[0].value
        },
        value: {
          id: 0,
          value: 'LTV 1'
        },
        avgMonths: {
          id: 1,
          value: 1
        },
        avgQuarters: {
          id: 1,
          value: 1
        },
        avgYears: {
          id: 1,
          value: 1
        },
        valueMonths: {
          id: 1,
          value: 'LTV 1'
        },
        valueQuarters: {
          id: 1,
          value: 'LTV 1Q'
        },
        valueYears: {
          id: 1,
          value: 'LTV 1Y'
        },
        monthlyFrom: {
          id: option.monthlyFrom[0].id,
          value: option.monthlyFrom[0].value
        },
        monthlyTo: {
          id: option.monthlyTo[option.monthlyTo.length - 1].id,
          value: option.monthlyTo[option.monthlyTo.length - 1].value
        },
        quarterlyFrom: {
          id: option.quarterlyFrom[0].id,
          value: option.quarterlyFrom[0].value
        },
        quarterlyTo: {
          id: option.quarterlyTo[option.quarterlyTo.length - 1].id,
          value: option.quarterlyTo[option.quarterlyTo.length - 1].value
        },
        yearlyFrom: {
          id: option.yearlyFrom[0].id,
          value: option.yearlyFrom[0].value
        },
        yearlyTo: {
          id: option.yearlyTo[option.yearlyTo.length - 1].id,
          value: option.yearlyTo[option.yearlyTo.length - 1].value
        }
      }));
      setIsInitialFetch(true);
    }
  }, [option]);

  useEffect(() => {
    if (isInitialFetch) {
      handlePeriodResultGet();
      setIsInitialFetch(false);
    }
  }, [selectedParameters]);

  useEffect(() => {
    setResult(null);
    setResultCategory({
      ltvChanges: false,
      specificValue: false
    });
  }, [userInfo?.company.shopId]);

  usePageInit();

  return (
    <>
      <MainHeader
        main={t('comparison.comparison')}
        sub={t('comparison.see_differences_between_segments_channels')}
      />
      <>
        {!selectedParameters || !option ? (
          <ParametersSkeleton>
            <ParameterSkeleton />
            <ParameterSkeleton />
            <ParameterSkeleton />
            <ParameterSkeleton />
            <ParameterSkeleton />
            <ParameterSkeleton />
          </ParametersSkeleton>
        ) : (
          <div className="box comparison-parameters">
            <div className="row first">
              <SearchInput
                label="differenceBy"
                width="260"
                options={option.differenceBy}
                parameter={handleParameterGet({ label: 'differenceBy' })}
                setParameter={handleParameterSet}
              />
              <SearchInput
                label="comparisonTo"
                width="260"
                options={option.comparisonTo}
                parameter={handleParameterGet({ label: 'comparisonTo' })}
                setParameter={handleParameterSet}
              />
              <SearchInput
                label="comparisonBy"
                width="260"
                options={option.comparisonBy}
                parameter={handleParameterGet({ label: 'comparisonBy' })}
                setParameter={handleParameterSet}
              />
            </div>
            <div className="row second">
              <SearchInput
                label="interval"
                width="260"
                options={option.interval}
                parameter={handleParameterGet({ label: 'interval' })}
                setParameter={handleParameterSet}
              />
              {selectedParameters.comparisonBy.id === 0 && (
                <SearchInput
                  label={`avg${
                    selectedParameters.interval.id === MONTH
                      ? 'Months'
                      : selectedParameters.interval.id === QUARTER
                      ? 'Quarters'
                      : 'Years'
                  }`}
                  width="260"
                  options={handleOptionsFilter({
                    label: `avg${
                      selectedParameters.interval.id === MONTH
                        ? 'Months'
                        : selectedParameters.interval.id === QUARTER
                        ? 'Quarters'
                        : 'Years'
                    }`
                  })}
                  interval={selectedParameters.interval.id}
                  parameter={handleParameterGet({
                    label: `avg${
                      selectedParameters.interval.id === MONTH
                        ? 'Months'
                        : selectedParameters.interval.id === QUARTER
                        ? 'Quarters'
                        : 'Years'
                    }`,
                    interval: selectedParameters.interval.id
                  })}
                  setParameter={handleParameterSet}
                />
              )}
              {selectedParameters.comparisonBy.id === 1 && (
                <>
                  <SearchInput
                    label={`value${
                      selectedParameters.interval.id === MONTH
                        ? 'Months'
                        : selectedParameters.interval.id === QUARTER
                        ? 'Quarters'
                        : 'Years'
                    }`}
                    width="260"
                    options={handleOptionsFilter({ label: 'value' })}
                    interval={selectedParameters.interval.id}
                    parameter={handleParameterGet({
                      label: 'value',
                      interval: selectedParameters.interval.id
                    })}
                    setParameter={handleParameterSet}
                  />
                  <SearchInput
                    label="from"
                    width="260"
                    options={handleOptionsFilter({ label: 'from' })}
                    interval={selectedParameters.interval.id}
                    parameter={handleParameterGet({
                      label: 'from',
                      interval: selectedParameters.interval.id
                    })}
                    setParameter={handleParameterSet}
                  />
                  <SearchInput
                    label="to"
                    width="260"
                    options={handleOptionsFilter({ label: 'to' })}
                    interval={selectedParameters.interval.id}
                    parameter={handleParameterGet({
                      label: 'to',
                      interval: selectedParameters.interval.id
                    })}
                    setParameter={handleParameterSet}
                  />
                </>
              )}
            </div>
            <div className="row third">
              <PrimaryButton
                value={t('comparison.apply')}
                onClick={() => {
                  setResult(null);
                  setResultCategory({
                    ltvChanges: false,
                    specificValue: false
                  });
                  selectedParameters.comparisonBy.id === 0
                    ? handlePeriodResultGet()
                    : handleSpecificValueGet();
                }}
                style={{ width: '124px' }}
              />
            </div>
          </div>
        )}
        {!result && (
          <div className="box comparison-skeleton-container">
            <TitleSkeleton width={400} />
            <div className="flex-wrapper">
              <DynamicSkeleton width={732} height={314} />
              <DynamicSkeleton width={348} height={314} />
            </div>
            <DynamicSkeleton width={1104} height={314} />
          </div>
        )}

        {resultCategory.ltvChanges && (
          <div className="box ltv-changes-over-time-container">
            <div className="title comparison-title">{t('comparison.ltv_changes_over_time')}</div>
            <div className="comparison-export-button-container">
              <button onClick={handleExport('period')} className="comparison-export-button">
                <img src={EXPORT} alt="export-button" />
                {t('settings.export')}
              </button>
            </div>
            <div className="flex-wrapper">
              <div className="box ltv-changes-over-time">
                <ComparisonMultiline
                  data={result.chart}
                  segments={result.table.map((v) => v.segment)}
                />
              </div>
              <div className="box comparison-guide">
                <div className="title comparison-guide-title">
                  {t('comparison.comparison_by_ltv')}
                </div>
                <img
                  src={
                    selectedInterval === MONTH
                      ? t('comparison.img.comparison_ltv-changes_monthly')
                      : selectedInterval === QUARTER
                      ? t('comparison.img.comparison_ltv-changes_quarterly')
                      : t('comparison.img.comparison_ltv-changes_yearly')
                  }
                  alt="comparison ltv changes"
                />
              </div>
            </div>

            <div className="comparison-table-container">
              <table className="comparison-table">
                <thead>
                  <tr>
                    <th></th>
                    {result.table[0].x.map((v, i) => (
                      <th key={`${v}${i}`}>{v}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {result.table.map((v) => {
                    return (
                      <tr key={v.segment.id}>
                        <td>{v.segment.value}</td>
                        {v.y.map((d, i) => {
                          return <td key={`${d}${i}`}>{numberWithCommas(d)}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {resultCategory.specificValue && (
          <div className="box average-new-customer-container">
            <div className="title comparison-title">
              <Trans
                i18nKey={'comparison.average_ltv_of_new_customers'}
                values={{ value: selectedSpecificValue }}
              />
            </div>
            <div className="comparison-export-button-container">
              <button onClick={handleExport('specificValue')} className="comparison-export-button">
                <img src={EXPORT} alt="export-button" />
                {t('settings.export')}
              </button>
            </div>
            <div className="flex-wrapper">
              <div className="box average-new-customer">
                <ComparisonMultiline data={result.chart.data} segments={result.chart.segments} />
              </div>
              <div className="box comparison-guide">
                <div className="title comparison-guide-title">
                  {t('comparison.comparison_by_specific')}
                </div>
                <img
                  src={t('comparison.img.comparison_specific-value')}
                  alt="comparison specific value"
                />
              </div>
            </div>
            <div className="comparison-table-container">
              <table className="comparison-table">
                <thead>
                  <tr>
                    <th></th>
                    {result.table.header.map((v, i) => (
                      <th key={`${v}${i}`}>{v}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {result.table.integratedRows.map((row, i) => {
                    if (i === 4) {
                      return (
                        <tr key={`${i}`} className="separation">
                          {row.map((cell, j) => (
                            <td key={`${j}`}>{numberWithCommas(cell)}</td>
                          ))}
                        </tr>
                      );
                    }
                    return (
                      <tr key={`${i}`}>
                        {row.map((cell, j) => {
                          if (j === 0) return <td key={`${j}`}>{cell}</td>;
                          return <td key={`${j}`}>{numberWithCommas(cell)}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default Comparison;
