import { useNavigate } from 'react-router-dom';

export function useNavigateRoute() {
  const navigate = useNavigate();

  function handleNavigate(pathname, state = {}) {
    navigate(pathname, {
      state: {
        ...state,
      },
    });
  }
  return handleNavigate;
}
