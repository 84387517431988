import { axiosPrivate, customerAPI } from 'api';
import models from './models';
import * as dataHelpers from './dataHelpers';
import { formatNewDate } from 'utils';
import { theme } from 'styles@emotion/theme';

export const fetcher = {
  async overview(queryKey, userInfo) {
    const { data } = await axiosPrivate.post(customerAPI.OVERVIEW, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId
    });
    return data;
  },
  async segmentation(queryKey, userInfo) {
    const { data } = await axiosPrivate.post(customerAPI.SEGMENTATION, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId
    });
    return data;
  },
  async basicRetention(queryKey, userInfo) {
    const [_, parameters] = queryKey;

    const { data } = await axiosPrivate.post(customerAPI.RETENTION, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      interval: parameters.interval.id,
      from_time:
        parameters.interval.id === 'month'
          ? parameters.monthlyFrom.id
          : parameters.interval.id === 'quarter'
          ? parameters.quarterlyFrom.id
          : parameters.yearlyFrom.id,
      to_time:
        parameters.interval.id === 'month'
          ? parameters.monthlyTo.id
          : parameters.interval.id === 'quarter'
          ? parameters.quarterlyTo.id
          : parameters.yearlyTo.id,
      segment_id: parameters.segment.id,
      // retention value is always same with interval value in basic
      retention: parameters.interval.id,
      channel_id: parameters.channel.id,
      product_id: parameters.item.id,
      region_id: parameters.region.id,
      category_id: parameters.category.id,
      any_moment: parameters.anyMoment.id
    });

    return data;
  },
  async advancedRetention(queryKey, userInfo) {
    const [_, parameters] = queryKey;
    const { data } = await axiosPrivate.post(customerAPI.RETENTION, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      interval: parameters.interval.id,
      from_time:
        parameters.interval.id === 'month'
          ? parameters.monthlyFrom.id
          : parameters.interval.id === 'quarter'
          ? parameters.quarterlyFrom.id
          : parameters.yearlyFrom.id,
      to_time:
        parameters.interval.id === 'month'
          ? parameters.monthlyTo.id
          : parameters.interval.id === 'quarter'
          ? parameters.quarterlyTo.id
          : parameters.yearlyTo.id,
      segment_id: parameters.segment.id,
      retention: parameters.retention.id,
      channel_id: parameters.channel.id,
      product_id: parameters.item.id,
      region_id: parameters.region.id,
      category_id: parameters.category.id,
      any_moment: parameters.anyMoment.id
    });

    return data;
  },
  async basicCohort(queryKey, userInfo) {
    const [_, parameters] = queryKey;
    const { data } = await axiosPrivate.post(customerAPI.LTV, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      interval: parameters.interval.id,
      from_time:
        parameters.interval.id === 'month'
          ? parameters.monthlyFrom.id
          : parameters.interval.id === 'quarter'
          ? parameters.quarterlyFrom.id
          : parameters.yearlyFrom.id,
      to_time:
        parameters.interval.id === 'month'
          ? parameters.monthlyTo.id
          : parameters.interval.id === 'quarter'
          ? parameters.quarterlyTo.id
          : parameters.yearlyTo.id,
      segment_id: parameters.segment.id,
      // period value is always same with interval value in basic
      period: parameters.interval.id,
      channel_id: parameters.channel.id,
      category_id: parameters.category.id,
      product_id: parameters.item.id,
      same: parameters.trackFirstItemOnly.id,
      region_id: parameters.region.id,
      any_moment: parameters.anyMoment.id
    });
    return data;
  },
  async advancedCohort(queryKey, userInfo) {
    const [_, parameters] = queryKey;
    const { data } = await axiosPrivate.post(customerAPI.LTV, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      interval: parameters.interval.id,
      from_time:
        parameters.interval.id === 'month'
          ? parameters.monthlyFrom.id
          : parameters.interval.id === 'quarter'
          ? parameters.quarterlyFrom.id
          : parameters.yearlyFrom.id,
      to_time:
        parameters.interval.id === 'month'
          ? parameters.monthlyTo.id
          : parameters.interval.id === 'quarter'
          ? parameters.quarterlyTo.id
          : parameters.yearlyTo.id,
      segment_id: parameters.segment.id,
      period: parameters.period.id,
      channel_id: parameters.channel.id,
      category_id: parameters.category.id,
      product_id: parameters.item.id,
      same: parameters.trackFirstItemOnly.id,
      region_id: parameters.region.id,
      any_moment: parameters.anyMoment.id
    });
    return data;
  },
  async forecasting(queryKey, userInfo) {
    const [_, parameters] = queryKey;
    const { data } = await axiosPrivate.post(customerAPI.FORECASTING, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      segment_id: parameters.segment.id,
      product_id: parameters.item.id
    });
    return data;
  },
  async crossSelling(queryKey, userInfo) {
    const [_, parameters] = queryKey;
    const { data } = await axiosPrivate.post(customerAPI.CROSS_SELLING, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      segment_id: parameters.segment.id
    });
    return data;
  },
  async userBased(queryKey, userInfo) {
    const [_, parameters] = queryKey;
    const { data } = await axiosPrivate.post(customerAPI.USER_BASED, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      segment_id: parameters.segment.id,
      population: parameters.goal.id,
      // status_id: 2,
      status_id: parameters.status.id,
      retention: parameters.retention.id,
      channel_id: parameters.channel.id,
      region_id: parameters.region.id,
      reduplicate: parameters.reduplicate,
      is_item: parameters.output.id,
      product_id: parameters.item.id,
      category_id: parameters.category.id,
      moment: parameters.moment.id
    });
    return data;
  },
  async featureBased(queryKey, userInfo) {
    const [_, parameters] = queryKey;
    const payloadData = {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      region_id: parameters.region.id,
      category_id: parameters.category.id
    };
    if (Object.keys(parameters).length > 2) {
      payloadData.extras = {};
      for (const key in parameters) {
        if (key !== 'region' && key !== 'category') {
          payloadData['extras'][`${key}_id`] = parameters[key].id;
        }
      }
    }
    const { data } = await axiosPrivate.post(customerAPI.FEATURE_BASED, payloadData);
    return data;
  },
  async report(queryKey, userInfo) {
    const { data } = await axiosPrivate.post(customerAPI.REPORT, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId
    });
    return data;
  },
  async getExport(queryKey, userInfo) {
    const { data } = await axiosPrivate.post(customerAPI.GET_EXPORT, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId
    });
    return data;
  },
  async leadToCustomer(queryKey, userInfo) {
    const [_, parameters] = queryKey;

    function fromWeekDay(v) {
      let time = new Date(v);
      let weekDay = time.getDay();
      let newTime = new Date(time.setDate(time.getDate() - (weekDay - 1)));
      let newFirstWeek = new Intl.DateTimeFormat('fr-CA', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(newTime);

      const lastDateOfMonth = new Date(
        newFirstWeek.slice(0, 4),
        newFirstWeek.slice(5, 7),
        0
      ).getDate();

      if (
        newFirstWeek.slice(0, 5) != v.slice(0, 5) ||
        (newFirstWeek.slice(5, 7) != v.slice(5, 7) && lastDateOfMonth - newFirstWeek.slice(8) >= 3)
      ) {
        time = new Date(v.slice(0, 8) + '05');
        weekDay = time.getDay();
        newTime = new Date(time.setDate(time.getDate() - (weekDay - 1)));
        newFirstWeek = new Intl.DateTimeFormat('fr-CA', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }).format(newTime);
      }

      return newFirstWeek;
    }

    function ToWeekDay(v) {
      // const time = new Date(v);
      // 그달의 마지막 일자
      const lastdateOfMonth = new Date(v.slice(0, 4), v.slice(5, 7), 0).getDate();
      const newLastdateOfMonth = v.slice(0, 8) + lastdateOfMonth.toString();
      const newTime = new Date(newLastdateOfMonth);
      let lastWeekDay = newTime.getDay();
      let newLastTime;
      lastWeekDay === 0
        ? (newLastTime = new Date(newTime.setDate(newTime.getDate() - 6)))
        : (newLastTime = new Date(newTime.setDate(newTime.getDate() - (lastWeekDay - 1))));

      return new Intl.DateTimeFormat('fr-CA', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(newLastTime);
    }
    const { data } = await axiosPrivate.post(customerAPI.LEAD_TO_CUSTOMER, {
      company_id: userInfo.company.companyId,
      shop_id: userInfo.company.shopId,
      segment_id: parameters.segment.id,
      period: parameters.period.id,
      from_time:
        parameters.period.id === 'month'
          ? parameters.monthlyFrom.id
          : fromWeekDay(parameters.monthlyFrom.id),
      to_time:
        parameters.period.id === 'month'
          ? parameters.monthlyTo.id
          : ToWeekDay(parameters.monthlyTo.id)
    });

    return data;
  }
};

export const transformData = {
  overview(data, currency) {
    const summary = dataHelpers.overview.generateSummary(data.cards);
    const sales = dataHelpers.overview.generateSales(data.charts.sales, currency);
    const newLeadToCustomer = dataHelpers.overview.generateNewLeadToCustomer(
      data.charts.lead_to_customer,
      currency
    );
    const monthlyCustomers = dataHelpers.overview.generateMonthlyCustomer(
      data.charts.monthly_customers,
      currency
    );
    const monthlyTransaction = dataHelpers.overview.generateMonthlyTransaction(
      data.charts.monthly_transactions,
      currency
    );
    const categoryRankings = dataHelpers.overview.generateCategoryRankings(
      data.charts.category_ranking
    );
    const locationRankings = dataHelpers.overview.generateLocationRankings(
      data.charts.location_ranking
    );
    const itemRankings = dataHelpers.overview.generateItemRankings(data.tables);
    const customerRankings = dataHelpers.overview.generateCustomerRankings(data.tables, currency);
    const thirdMonthLtv = dataHelpers.overview.generateThirdMonthLtv(
      data.charts.life_time_value,
      currency
    );
    const sixthMonthLtv = dataHelpers.overview.generateSixthMonthLtv(
      data.charts.life_time_value,
      currency
    );
    const abTesting = dataHelpers.overview.generateAbTesting(data.charts.abtest);

    const overview = {
      ...models.overview()
    };
    // overview.dates = `${data.cards.from_time.slice(0, 10)} ~ ${data.cards.to_time.slice(0, 10)}`;
    overview.dates = `${formatNewDate(
      data.cards.from_time.slice(0, 10),
      currency
    )} ~ ${formatNewDate(data.cards.to_time.slice(0, 10), currency)}`;
    overview.currency = data.cards.currency;
    overview.summary.transaction = summary.transaction;
    overview.summary.lead = summary.lead;
    overview.summary.customer.value = summary.customer.value;
    overview.summary.customer.ltc = summary.customer.ltc;
    overview.summary.segments = summary.segments;
    overview.summary.retention = summary.retention;
    overview.summary.ltv6 = summary.ltv6;
    overview.salesChart.monthly = sales.monthly;
    overview.salesChart.yearly = sales.yearly;
    overview.newLeadToCustomerChart.newLead = newLeadToCustomer.newLead;
    overview.newLeadToCustomerChart.newCustomer = newLeadToCustomer.newCustomer;
    overview.newLeadToCustomerChart.ltc = newLeadToCustomer.ltc;
    overview.monthlyCustomersChart.monthlyCustomers = monthlyCustomers;
    overview.monthlyTransactionChart.transactionPerCustomer =
      monthlyTransaction.transactionPerCustomer;
    overview.monthlyTransactionChart.transactionWithoutSignIn =
      monthlyTransaction.transactionWithoutSignIn;
    overview.monthlyTransactionChart.transaction = monthlyTransaction.transaction;
    overview.categoryRankingsChart.categoryRankings = categoryRankings;
    overview.locationRankingsChart.locationRankings = locationRankings;
    overview.itemRankingsTable.export = itemRankings.export;
    overview.itemRankingsTable.itemRankings = itemRankings.table;
    overview.thirdMonthLtvChart.thirdMonthLtv = thirdMonthLtv;
    overview.sixthMonthLtvChart.sixthMonthLtv = sixthMonthLtv;
    overview.abTestingChart.withMomentum = abTesting.withMomentum;
    overview.abTestingChart.withoutMomentum = abTesting.withoutMomentum;
    overview.customersTable.export = customerRankings.export;
    overview.customersTable.customers = customerRankings.table;

    return overview;
  },
  segmentation(data, currency) {
    const segmentsByAscendingId = [...data.segmentation].sort(
      (a, b) => a.segment_id - b.segment_id
    );
    const segmentNames = segmentsByAscendingId.map((v) => v.segment_name);

    const populationSalesAndTransactions =
      dataHelpers.segmentation.generatePopulationSalesAndTransactions(
        segmentsByAscendingId,
        data.charts.last_advanced_segmentation,
        data.charts.advanced_segmentation
      );
    const recencyFrequencyMonetaryValue =
      dataHelpers.segmentation.generateRecencyFrequencyMonetaryValue(
        segmentsByAscendingId,
        data.charts.last_rfm,
        data.charts.rfm,
        data.charts.population
      );
    const numberOfCategoriesAndCustomers =
      dataHelpers.segmentation.generateNumberOfCategoriesAndCustomers(
        segmentsByAscendingId,
        data.charts.last_customer_and_category,
        data.charts.customer_and_category,
        data.charts.population
      );
    const tables = dataHelpers.segmentation.generateTables(
      segmentsByAscendingId,
      data.tables,
      currency
    );
    const exportOverall = data.charts.segmentation[0].options.export;
    const exportRankings = data.tables.map((v) => {
      return {
        tableName: v.name,
        segmendId: v.options.segment_id,
        segmentName: segmentsByAscendingId.filter(
          (segment) => segment.segment_id === v.options.segment_id
        )[0].segment_name,
        url: v.options.export
      };
    });

    const segmentation = {
      ...models.newSegmentation()
    };

    segmentation.segments = segmentsByAscendingId; //temporarily
    segmentation.colors = dataHelpers.COLORS;
    segmentation.segmentNames = segmentNames;
    segmentation.exportOverall = exportOverall;
    segmentation.exportRankings = exportRankings;
    segmentation.chart.populationSalesAndTransactions = populationSalesAndTransactions;
    segmentation.chart.recencyFrequencyMonetaryValue = recencyFrequencyMonetaryValue;
    segmentation.chart.numberOfCategoriesAndCustomers = numberOfCategoriesAndCustomers;
    segmentation.tables = tables;

    return segmentation;
  },
  basicRetention(data, currency) {
    if (data.result.length === 0) return 'no data';

    const retentionRate = dataHelpers.retention.generateRetentionRate(data.result, currency);
    const waterfall = dataHelpers.retention.generateWaterfall(data.result, currency);
    // 엑셀 파일 export 시, 각 데이터에 해당하는 칼럼의 key와 매치시키기 위해 특정 prperty를 가진 객체로 변환.
    const exportData = data.result.map((v) => ({
      date: v.x.slice(0, 7),
      new: v.new,
      retentionRate: v.ratio,
      active: v.active,
      churned: v.churned,
      reactive: v.reactive,
      retained: v.retained
    }));
    const retention = {
      ...models.retention()
    };
    retention.retentionRateChart.retentionRate = retentionRate;
    retention.waterfallChart.waterfall = waterfall;
    retention.exportData = exportData;
    return retention;
  },
  advancedRetention(data, currency) {
    if (data.result.length === 0) return 'no data';

    const retentionRate = dataHelpers.retention.generateRetentionRate(data.result, currency);
    const waterfall = dataHelpers.retention.generateWaterfall(data.result, currency);
    // 엑셀 파일 export 시, 각 데이터에 해당하는 칼럼의 key와 매치시키기 위해 특정 prperty를 가진 객체로 변환.
    const exportData = data.result.map((v) => ({
      date: v.x.slice(0, 7),
      new: v.new,
      retentionRate: v.ratio,
      active: v.active,
      churned: v.churned,
      reactive: v.reactive,
      retained: v.retained
    }));
    const retention = {
      ...models.retention()
    };
    retention.retentionRateChart.retentionRate = retentionRate;
    retention.waterfallChart.waterfall = waterfall;
    retention.exportData = exportData;
    return retention;
  },
  basicCohort(data, currency, parameters) {
    // if (data.result.length === 0) return 'no data';

    const ltv3Chart = data.charts?.life_time_value.filter((v) => v.name === 'ltv3')[0];
    const ltv6Chart = data.charts?.life_time_value.filter((v) => v.name === 'ltv6')[0];
    const customerValueProgress = data.charts?.customer_value_progress
      ? dataHelpers.ltv.generateCustomerValueProgress(data.charts.customer_value_progress)
      : 'no data';
    const ltv3 = ltv3Chart ? dataHelpers.ltv.generateLtv3(ltv3Chart, currency) : 'no data';
    const ltv6 = ltv6Chart ? dataHelpers.ltv.generateLtv6(ltv6Chart, currency) : 'no data';
    const cohortAnalysis =
      data.result.length !== 0
        ? dataHelpers.ltv.generateCohortAnalysis(data.result, currency, parameters)
        : 'no data';

    const ltv = {
      ...models.ltv()
    };
    ltv.customerValueProgressChart.customerValueProgress = customerValueProgress;
    ltv.changesOverTimeChart.ltv6 = ltv6;
    ltv.changesOverTimeChart.ltv3 = ltv3;
    if (data.result.length === 0) {
      ltv.cohortAnalysisTable = 'no data';
    } else {
      ltv.cohortAnalysisTable.headers = cohortAnalysis.headers;
      ltv.cohortAnalysisTable.rows = cohortAnalysis.rows;
    }
    return ltv;
  },
  advancedCohort(data, currency, parameters) {
    // if (data.result.length === 0) return 'no data';

    const ltv3Chart = data.charts?.life_time_value?.filter((v) => v.name === 'ltv3')[0];
    const ltv6Chart = data.charts?.life_time_value?.filter((v) => v.name === 'ltv6')[0];
    const customerValueProgress = data.charts?.customer_value_progress
      ? dataHelpers.ltv.generateCustomerValueProgress(data.charts?.customer_value_progress)
      : 'no data';
    const ltv3 = ltv3Chart ? dataHelpers.ltv.generateLtv3(ltv3Chart, currency) : 'no data';
    const ltv6 = ltv6Chart ? dataHelpers.ltv.generateLtv6(ltv6Chart, currency) : 'no data';
    const cohortAnalysis =
      data.result.length !== 0
        ? dataHelpers.ltv.generateCohortAnalysis(data.result, currency, parameters)
        : 'no data';

    const ltv = {
      ...models.ltv()
    };
    ltv.customerValueProgressChart.customerValueProgress = customerValueProgress;
    ltv.changesOverTimeChart.ltv6 = ltv6;
    ltv.changesOverTimeChart.ltv3 = ltv3;
    if (data.result.length === 0) {
      ltv.cohortAnalysisTable = 'no data';
    } else {
      ltv.cohortAnalysisTable.headers = cohortAnalysis.headers;
      ltv.cohortAnalysisTable.rows = cohortAnalysis.rows;
    }
    return ltv;
  },
  forecasting(data, currency) {
    if (data.result.length === 0) return 'no data';

    const transactionForecasting = dataHelpers.forecasting.generateTransactionForecasting(
      data.result,
      currency
    );
    const forecasting = {
      ...models.forecasting()
    };
    forecasting.transactionForecastingChart.transactionForecasting = transactionForecasting;
    return forecasting;
  },
  crossSelling(data) {
    const crossSelling = {
      ...models.crossSelling()
    };
    crossSelling.export = data?.tables[0]?.options?.export;
    crossSelling.counts = data?.tables[0]?.options?.total_row;
    crossSelling.crossSellingTable.headers = data.tables[0].headers;
    crossSelling.crossSellingTable.cells = data.tables[0].cells;
    return crossSelling;
  },

  //  /////////////////////////////////////////////////////////////////////////////////////
  // 유저베이스 실질적 데이터가 담기는 곳
  userBased(data, currency) {
    const userBased = {
      ...models.userBased()
    };
    if (data.result.length === 0) {
      userBased.export = [];
      userBased.counts = [];
      userBased.topRanking.headers = ['Item', 'Top 1', 'Top 2', 'Top 3', 'Top 4', 'Top 5'];
      userBased.topRanking.cells = [];
      userBased.modelScore.headers = ['Top N', 'Overall', 'Filter'];
      userBased.modelScore.cells = [];

      userBased.userBasedTable.headers = [
        'Name',
        'Segment',
        'Status',
        'Transaction',
        'Latest Transaction',
        'Next Item',
        'Top 2',
        'Top 3',
        'Top 4',
        'Top 5'
      ];
      userBased.userBasedTable.cells = [];

      return userBased;
    }

    if (data.result['Model Score']) {
      userBased.export = data?.options?.export;
      userBased.counts = data?.options?.total_row;
      userBased.topRanking.headers = ['Item', 'Top 1', 'Top 2', 'Top 3', 'Top 4', 'Top 5'];
      userBased.topRanking.cells = data?.result['Top Ranking']?.map((v) => {
        const copied = { ...v };
        return copied;
      });
      userBased.modelScore.headers = ['Top N', 'Overall', 'Filter'];
      userBased.modelScore.cells = data?.result['Model Score']?.map((v) => {
        const copied = { ...v };
        return copied;
      });

      userBased.userBasedTable.headers = [
        'Name',
        'Segment',
        'Status',
        'Transaction',
        'Latest Transaction',
        'Next Item',
        'Top 2',
        'Top 3',
        'Top 4',
        'Top 5'
      ];

      userBased.userBasedTable.cells = data?.result['Customer Found']?.map((v) => {
        const copied = { ...v };
        copied.latest_transaction = {
          at: formatNewDate(copied.latest_transaction.at, currency),
          since: copied.latest_transaction.since
        };
        return copied;
      });
    } else {
      userBased.export = data?.options?.export;
      userBased.counts = data?.options?.total_row;
      userBased.topRanking.headers = data?.result['top_ranking']?.header.map((x) => x.value) ?? [
        'Item',
        'Top 1',
        'Top 2',
        'Top 3',
        'Top 4',
        'Top 5'
      ];
      userBased.topRanking.cells = data?.result['top_ranking']?.body ?? [];

      userBased.crmSimulation.data = {
        total: data?.result['model_score']?.total,
        values: data?.result['model_score']?.values
      };
      userBased.crmSimulation.legends = [
        {
          chartColor: theme.color.neonBlue100,
          id: 0,
          isActive: true,
          name: 'Best Seller',
          translation: 'user_based.best_seller'
        },
        {
          chartColor: theme.color.neonBlue500,
          id: 1,
          isActive: true,
          name: "Retentics'",
          translation: 'user_based.retentics'
        }
      ];

      userBased.userBasedTable.headers = [
        'Name',
        'Segment',
        'Status',
        'Transaction',
        'Latest Transaction',
        'Next Item',
        'Top 2',
        'Top 3',
        'Top 4',
        'Top 5'
      ];

      userBased.userBasedTable.cells =
        data?.result['customer_found']?.map((v) => {
          const copied = { ...v };
          copied.latest_transaction = {
            at: formatNewDate(copied.latest_transaction.at, currency),
            since: copied.latest_transaction.since
          };
          return copied;
        }) ?? [];
    }

    return userBased;
  },
  featureBased(data, currency) {
    const featureBased = {
      ...models.featureBased()
    };
    featureBased.export = data?.options?.export;
    featureBased.counts = data?.options?.total_row;
    featureBased.featureBasedTable.headers = [
      'Name',
      'Segment',
      'Status',
      'Transaction',
      'Latest Transaction',
      'Score'
    ];
    featureBased.featureBasedTable.cells = data.result.map((v) => {
      const copied = { ...v };
      copied.latest_transaction = {
        at: formatNewDate(copied.latest_transaction.at, currency),
        since: copied.latest_transaction.since
      };
      return copied;
    });
    return featureBased;
  },
  report(data) {
    const retention = data.retention;
    const ltv = data.life_time_value;

    const good = [];
    const bad = [];

    retention.forEach((v, i) => {
      if (v.result.good) {
        good.push(v);
      } else {
        bad.push(v);
      }
    });
    ltv.forEach((v, i) => {
      if (v.result.good) {
        good.push(v);
      } else {
        bad.push(v);
      }
    });
    good.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    bad.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

    const report = {
      ...models.report()
    };
    report.good = good;
    report.bad = bad;
    return report;
  },
  getExport(data) {
    const list = data.result.map((v, i) => {
      const date = new Date(v.created_at);
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      const month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(date);
      const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
      const createdAt = `${year.slice(2)}/${month}/${day}`;
      return {
        no: i + 1,
        dataMenu: v.page,
        dataName: v.name,
        userName: v.username,

        date: createdAt
      };
    });
    const getExport = {
      ...models.getExport()
    };
    getExport.list = list;
    return getExport;
  },
  leadToCustomer(data, currency) {
    // return data;
    if (data.result.length === 0) return 'no data';

    const ltcAnalysis = dataHelpers.leadToCustomer.generateLtcAnalysis(data.result, currency);

    const leadToCustomer = {
      ...models.leadToCustomer()
    };
    leadToCustomer.leadToCustomerTable.headers = ltcAnalysis.headers;
    leadToCustomer.leadToCustomerTable.cells = ltcAnalysis.rows;
    return leadToCustomer;
  }
};
