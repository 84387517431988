import styled from '@emotion/styled';
import { theme } from 'styles@emotion/theme';

const Container = styled.div`
  ${theme.component.box}
  display: grid;
  grid-template-columns: 540px 540px;
  column-gap: 24px;
  row-gap: 30px;
  padding-top: 35px;
  padding-bottom: 40px;
  background-color: ${theme.color.white};
`;

export default { Container };
