import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SegmentationTable from './SegmentationTable';
import { TABLETAB } from 'constants/images';
import { exportCSV, numberWithCommas, nameExportFile, labelToTranslationKey } from 'utils';
import { useCustomerServiceMutation } from 'hooks';
import { useUserInfoContext } from 'context';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { mutationKeys } from 'constants/keys';

export function TablesWithTabButton({ data }) {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();
  const [tabSelectIdx, setTabSelectIdx] = useState(0);

  const [logExport] = useCustomerServiceMutation({
    mutationKey: mutationKeys.CUSTOMER.LOG_EXPORT
  });

  function handleLogExport(params) {
    logExport(params);
  }
  const handleSelect = (idx) => {
    setTabSelectIdx(idx);
  };
  const handelGroupToBeExport = async (e, url, name) => {
    e.preventDefault();
    await exportCSV(url, name)
      .then(() => {
        handleLogExport({
          companyId: userInfo?.company.companyId,
          shopId: userInfo?.company.shopId,
          page: 'segmentation',
          fileName: nameExportFile(name)
        });
      })
      .catch((err) => {
        console.log('export failed');
        console.log(err);
      });
  };

  return (
    <>
      <div className="segmentation-table-tab-buttons">
        {data.segmentNames.map((v, i) => {
          return (
            <button
              key={`${v}${i}`}
              className={`segmentation-table-tab-button ${tabSelectIdx === i ? 'selected' : ''}`}
              onClick={() => handleSelect(i)}
              style={{
                borderBottom: tabSelectIdx === i ? `2px solid ${data.colors[i]}` : ''
              }}
            >
              <TABLETAB color={tabSelectIdx === i ? data.colors[i] : '#E0DFF8'} />
              {v}
            </button>
          );
        })}
      </div>
      <div className="box segmentation-tables">
        {data.tables[tabSelectIdx].map((table, i) => {
          if (i === 2) return;
          return (
            <SegmentationTable
              key={`${i}${table.name}`}
              t
              title={i === 0 ? t('segmentation.item_ranking') : t('segmentation.customers')}
              data={table}
            />
          );
        })}
        {data.tables[tabSelectIdx][2] && (
          <div className="segmentation-table-container">
            <div className="segmentation-table-title">
              <Trans
                i18nKey={'segmentation.candidates_for'}
                values={{ group: data.tables[tabSelectIdx][2].segmentName }}
              />
            </div>
            <div className="segmentation-grouptobe-container">
              <div className="segmentation-grouptobe-left">
                <div className="segmentation-grouptobe-left-title">
                  <Trans
                    i18nKey={'segmentation.customers_are_likely_to'}
                    values={{
                      group: data.tables[tabSelectIdx][2].segmentName,
                      count: `${numberWithCommas(data.tables[tabSelectIdx][2].options.total)}`
                    }}
                    components={{ span: <span className="highlight" /> }}
                  />
                </div>
                <div className="segmentation-grouptobe-left-description">
                  <Trans i18nKey={'segmentation.if_you_want_to_send'} />
                </div>
                <div className="segmentation-grouptobe-left-button">
                  <PrimaryButton
                    value={t('segmentation.get_a_list')}
                    onClick={(e) => {
                      if (userInfo?.user.role === 'observer') return;
                      handelGroupToBeExport(
                        e,
                        data.tables[tabSelectIdx][2].options.export,
                        data.tables[tabSelectIdx][2].segmentName
                      );
                    }}
                    style={{ width: '152px', height: '48px' }}
                  />
                </div>
              </div>
              <div className="segmentation-grouptobe-right">
                <table className="segmentation-table grouptobe">
                  <thead>
                    <tr>
                      {data.tables[tabSelectIdx][2].headers.map((d, i) => {
                        if (d.includes('Likely')) {
                          return (
                            <th className="group-table-th" key={`${d}${i}`}>
                              {`Likely to be the ${data.tables[tabSelectIdx][2].segmentName}(% of Current Group)`}
                            </th>
                          );
                        }
                        if (d.includes('% of Group')) {
                          return (
                            <th className="group-table-th" key={`${d}${i}`}>
                              <Trans
                                i18nKey={'segmentation.of_group'}
                                values={{ name: data.tables[tabSelectIdx][2].segmentName }}
                              />
                            </th>
                          );
                        }
                        return (
                          <th className="group-table-th" key={`${d}${i}`}>
                            {t(`segmentation.${labelToTranslationKey(d)}`)}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {data.tables[tabSelectIdx][2].cells.map((r, i, arr) => (
                      <tr key={`row-${i}`}>
                        <>
                          {i === arr.length - 1 ? (
                            // Total
                            <td>{r['Current Group']}</td>
                          ) : (
                            // r['Current Group'] 값의 Group 1, Group 2, Group 3 ... 은 segment id의 오름차순으로 인덱싱된다.
                            // 그 값을 이용하여 data.segmentNames(segment 이름이 오름차순으로 정렬되어있음)으로부터 segment 이름을 참조한다.
                            <td>{data.segmentNames[r['Current Group'].slice(-1)[0] - 1]}</td>
                          )}

                          <td>{r[`Candidates (% of Current Group)`]}</td>
                          <td>{r[`% of Group ${tabSelectIdx + 1}`]}</td>
                        </>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
