import { useQuery } from 'react-query';
import { axiosPrivate, customerAPI } from 'api';
import { useUserInfoContext } from 'context';

export default function useOptionFetch(isOptionFetchAllowed, page) {
  const { userInfo } = useUserInfoContext();
  const { data, status, isSuccess } = useQuery(
    [page, 'options', userInfo?.company.shopId],
    async ({ queryKey }) => {
      const { data } = await axiosPrivate.post(customerAPI.OPTION, {
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        page: queryKey[0]
      });
      return data;
    },
    {
      enabled: isOptionFetchAllowed,
      select: (data) => transformOptions(data)
    }
  );

  return { data, status, isSuccess };
}

function transformOptions(data) {
  // if (data.categories.length === 0) {
  //   return null;
  // }
  return {
    interval: data.intervals.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    monthlyFrom: data.ranges.month.map((v) => ({
      id: v,
      value: v
    })),
    monthlyTo: data.ranges.month.map((v) => ({
      id: v,
      value: v
    })),
    quarterlyFrom: data.ranges.quarter.map((v) => ({
      id: v,
      value: v
    })),
    quarterlyTo: data.ranges.quarter.map((v) => ({
      id: v,
      value: v
    })),
    yearlyFrom: data.ranges.year.map((v) => ({
      id: v,
      value: v
    })),
    yearlyTo: data.ranges.year.map((v) => ({
      id: v,
      value: v
    })),
    retention: data.intervals.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    period: data.periods.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    segment: data.segments.map((v) => ({ id: +v.id, value: v.name ? v.name : '' })),
    channel: data.channels.map((v) => ({ id: +v.id, value: v.name })),
    item: data.items.map((v) => ({ id: +v.id, value: v.name })),
    trackFirstItemOnly: [
      {
        id: false,
        value: 'No'
      },
      {
        id: true,
        value: 'Yes'
      }
    ],
    anyMoment: [
      {
        id: false,
        value: 'No'
      },
      {
        id: true,
        value: 'Yes'
      }
    ],
    status: data.statuses.map((v) => ({ id: +v.id, value: v.name })),
    category: data.categories.map((v) => ({ id: +v.id, value: v.name })),
    region: data.regions.map((v) => ({ id: +v.id, value: v.name }))
  };
}
