const models = {
  profile() {
    return {
      userName: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
    };
  },
};
export default models;
