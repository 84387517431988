import { useState, useEffect } from 'react';
import { useIsFetching } from 'react-query';
import { useUserInfoContext } from 'context';
import { useQuery } from 'react-query';
import { queryFns, transformData } from './queryHelpers';

export function useAccountFetch(isAccountFetchAllowed, accountQueryKey) {
  const isFetching = useIsFetching();
  const { userInfo } = useUserInfoContext();
  const [data, setData] = useState(null);

  const result = useQuery(
    accountQueryKey === 'members'
      ? [accountQueryKey, userInfo?.company?.companyId]
      : [accountQueryKey],
    (context) => queryFns[accountQueryKey](context, userInfo),
    {
      enabled: isAccountFetchAllowed,
      select: (data) => transformData[accountQueryKey](data)
    }
  );

  useEffect(() => {
    if (result?.isSuccess) {
      setData(result.data);
    }
  }, [isFetching]);

  return { data };
}
