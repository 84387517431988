export const accountAPI = {
  PROFILE: `lambdaro/momentum/account-${process.env.REACT_APP_API_ENV}-profile`,
  EDIT_PROFILE: `lambdaro/momentum/account-${process.env.REACT_APP_API_ENV}-update-profile`,
  MEMBERS: `lambdaro/momentum/account-${process.env.REACT_APP_API_ENV}-get-employees`,
  ASSIGN_EMPLOYEE: `lambdaro/momentum/account-${process.env.REACT_APP_API_ENV}-assign-employee`,
  DEACTIVATE_USER: `lambdaro/momentum/account-${process.env.REACT_APP_API_ENV}-deactivate-employee`,
  UPDATE_EMPLOYEE_ROLE: `lambdaro/momentum/account-${process.env.REACT_APP_API_ENV}-update-employee-roles`,
  ORGANIZATION: `lambdaro/momentum/account-${process.env.REACT_APP_API_ENV}-get-company`,
  UPDATE_ORGANIZATION: `lambdaro/momentum/account-${process.env.REACT_APP_API_ENV}-update-company`,
};
