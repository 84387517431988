import { CHEVRONDOUBLEDOWN } from '../../../constants/images';
import './index.scss';

export default function AdvancedButton({ state, changeState }) {
  if (state === 'disabled') {
    return (
      <div className="advanced-button disabled">
        <CHEVRONDOUBLEDOWN color="#DDDDDD" />
      </div>
    );
  }
  if (state === 'pressed') {
    return (
      <div className="advanced-button pressed" onClick={() => changeState('default')}>
        <CHEVRONDOUBLEDOWN color="#222222" />
      </div>
    );
  }
  return (
    <div className="advanced-button" onClick={() => changeState('pressed')}>
      <CHEVRONDOUBLEDOWN color="#222222" />
    </div>
  );
}
