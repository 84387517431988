import { useLocation } from 'react-router-dom';

export function useLocationInfo() {
  const location = useLocation();
  const path = location.pathname.slice(1);
  const isNewMember = path === 'new-member';
  const isOverview = path === 'marketing-overview';
  const isSegmentation = path === 'segment-overview';
  const isRetention = path === 'retention';
  const isCohortAnalysis = path === 'cohort-analysis';
  const isForecasting = path === 'forecasting';
  const isCrossselling = path === 'cross-selling';
  const isUserBased = path === 'user-based';
  const isFeaturebased = path === 'feature-based';
  const isReports = path === 'reports';
  const isDatacenter = path === 'data-center';
  const isLogin = path === 'login';
  const isForgotpassword = path === 'forgot-password';
  const isProfile = path === 'profile';
  const isMember = path === 'member';
  const isOrganization = path === 'organization';
  const isLeadtocustomer = path === 'lead-to-customer';

  return {
    location,
    path,
    isOverview,
    isSegmentation,
    isRetention,
    isCohortAnalysis,
    isForecasting,
    isCrossselling,
    isUserBased,
    isFeaturebased,
    isNewMember,
    isDatacenter,
    isReports,
    isLogin,
    isForgotpassword,
    isProfile,
    isMember,
    isOrganization,
    isLeadtocustomer,
  };
}
