export const authAPI = {
  SIGNIN: 'authenticator/signin',
  GOOGLE_SIGNIN: 'authenticator/google_signin',
  REFRESH: 'authenticator/refresh',
  GOOGLE_REFRESH: 'authenticator/google_refresh',
  SIGNOUT: 'authenticator/signout',
  CHANGE_PASSWORD: 'authenticator/change_password',
  CREATE_USER: 'authenticator/create_user',
  RESPOND_CHALLENGE: 'authenticator/respond_challenge',
};
