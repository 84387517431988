import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavigateRoute } from 'hooks';
import { Menu } from '@headlessui/react';
import { useUserInfoContext } from 'context';
import { useAuthMutation } from 'hooks/auth';
import { mutationKeys } from 'constants/keys';
import { RETENTICS_LOGO, CHEVRON_RIGHT_18px, LINKTOMANUAL, LANGUAGE } from 'constants/images';
import './index.scss';
import { ENG, KOR, changeLanguageTo } from 'utils';

export default function Header({ moveLeft = false, handleShopSelect }) {
  const { t, i18n } = useTranslation();
  const handleNavigate = useNavigateRoute();
  const { userInfo } = useUserInfoContext();
  const [signout] = useAuthMutation({ mutationKey: mutationKeys.AUTH.SIGNOUT });

  return (
    <header className="layout-header">
      <div className={'left-side' + (moveLeft ? ' move-left' : '')}>
        <div
          className={`header-logo`}
          onClick={() => {
            handleNavigate('/marketing-overview');
          }}
        >
          <img src={RETENTICS_LOGO} />
        </div>
        <div className={`company-name`}>
          {userInfo?.company?.companyName}
          <img src={CHEVRON_RIGHT_18px} />
          <div className="shop-button-wrapper">
            <Menu>
              <Menu.Button>
                <div className="shop-name">
                  {userInfo?.company.shops.filter((v) => v.id === userInfo.company.shopId)[0].name}
                </div>
              </Menu.Button>
              <Menu.Items className="shop-list">
                {userInfo?.company.shops.map((v) => (
                  <Menu.Item key={`${v.name}${v.id}`} onClick={handleShopSelect(v.id)}>
                    <div>{v.name}</div>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>
          </div>
        </div>
      </div>

      <div className="right-side">
        <Menu>
          <Menu.Button as={React.Fragment}>
            <img src={LANGUAGE} className="bell" />
          </Menu.Button>
          <Menu.Items className="dropdown-language">
            <Menu.Item>
              <a
                onClick={() => {
                  changeLanguageTo(KOR, i18n);
                }}
              >
                KOR
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                onClick={() => {
                  changeLanguageTo(ENG, i18n);
                }}
              >
                ENG
              </a>
            </Menu.Item>
          </Menu.Items>
        </Menu>
        <img
          src={LINKTOMANUAL}
          alt="bell"
          onClick={() => {
            window.open(
              'https://www.notion.so/makedashy/MakeDashy-Manual-Kor-1bfef1975f3c4a69949274290e213f41?pvs=4',
              '_blank',
              'noopener,noreferrer'
            );
          }}
          className="bell"
        />
        <Menu>
          <Menu.Button>
            <div className="dropdown-button">
              <div className="user-image">{userInfo?.user?.username[0].toUpperCase()}</div>
              <div className="user-name">{userInfo?.user?.username}</div>
            </div>
          </Menu.Button>
          <Menu.Items className="dropdown-list">
            <Menu.Item>
              <Link to="/profile">{t('settings.profile_settings')}</Link>
            </Menu.Item>
            {(userInfo?.user?.role === 'admin' || userInfo?.user?.role === 'master') && (
              <Menu.Item>
                <Link to="/member">{t('settings.member_settings')}</Link>
              </Menu.Item>
            )}
            {(userInfo?.user?.role === 'admin' ||
              userInfo?.user?.role === 'master' ||
              userInfo?.user?.role === 'manager') && (
              <Menu.Item>
                <Link to="/organization">{t('settings.organization_settings')}</Link>
              </Menu.Item>
            )}
            {(userInfo?.user?.role === 'admin' ||
              userInfo?.user?.role === 'master' ||
              userInfo?.user?.role === 'manager' ||
              userInfo?.user?.role === 'analyst') && (
              <Menu.Item style={{ borderTop: '1px solid #DDDDDD' }}>
                <Link to="/data-center">{t('settings.data_center')}</Link>
              </Menu.Item>
            )}
            <Menu.Item style={{ borderTop: '1px solid #DDDDDD' }}>
              <Link
                to="#"
                onClick={() => {
                  signout();
                }}
              >
                {t('settings.logout')}
              </Link>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </header>
  );
}
