import { useNavigate } from 'react-router-dom';
import { TOASTDEFAULT, TOASTLOADING, TOASTSUCCESS, TOASTERROR } from 'constants/images';
import { useDashboardContext } from 'context';
import { useLocationInfo } from 'hooks';
import { useTranslation } from 'react-i18next';
import './index.scss';

export default function Snack({ response, message, refreshEnabled, goEnabled, goTo }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isSidebarMinimized } = useDashboardContext();
  const { isLogin, isDatacenter, isProfile, isMember, isOrganization } = useLocationInfo();

  function reload() {
    window.location.reload();
  }
  function defineBackgroundColor(response) {
    switch (response) {
      case 'success':
        return 'green';
      case 'error':
        return 'red';
      case 'loading':
        return 'blue';
      default:
        return 'blue';
    }
  }
  function defineIcon(response) {
    switch (response) {
      case 'success':
        return TOASTSUCCESS;
      case 'error':
        return TOASTERROR;
      case 'loading':
        return TOASTLOADING;
      default:
        return TOASTDEFAULT;
    }
  }

  const toast = (
    <div
      className={`snack ${defineBackgroundColor(response)} 
      ${isProfile || isMember || isOrganization ? 'no-sidebar' : ''}
      ${
        !isLogin &&
        !isDatacenter &&
        isSidebarMinimized &&
        !isProfile &&
        !isMember &&
        !isOrganization
          ? 'sidebar-minimized'
          : ''
      } ${
        !isLogin &&
        !isDatacenter &&
        !isSidebarMinimized &&
        !isProfile &&
        !isMember &&
        !isOrganization
          ? 'sidebar-expanded'
          : ''
      }`}
    >
      <img src={defineIcon(response)} />

      <div className="message">
        {message || 'We are sorry, but something went wrong. Please try refreshing this page.'}
      </div>
      {refreshEnabled && (
        <button className="refresh-button" onClick={reload}>
          {t('refresh')}
        </button>
      )}
      {goEnabled && (
        <button
          className="refresh-button"
          onClick={() => {
            navigate(goTo.link, {
              state: goTo.navigateState
            });
          }}
        >
          {t('go')}
        </button>
      )}
    </div>
  );
  return toast;
}
