import { useState, useEffect, useRef } from 'react';
import './index.scss';

/**
 * @deprecated
 */
export const TabButton = ({ options, select, selectKey, handleSelect }) => {
  const [activeWidth, setActiveWidth] = useState(0);
  const [leftPosition, setLeftPosition] = useState(0);
  // const leftPosition = `${(select / options.length) * 100}%`;
  const optionsEl = useRef(null);

  useEffect(() => {
    setActiveWidth(optionsEl.current.childNodes[select].offsetWidth);
    setLeftPosition(
      select - 1 < 0
        ? 0
        : optionsEl.current.clientWidth - optionsEl.current.childNodes[select].clientWidth
    );
  }, [select, ...options]);

  return (
    <div className="tab-button">
      <div
        className="tab-button_active"
        style={{ left: `${leftPosition}px`, width: `${activeWidth}px` }}
      />
      <div className="options" ref={optionsEl}>
        {options.map((option, i) => (
          <div
            style={{ width: 'auto' }}
            key={`${option}-${i}`}
            className={'option' + (select === i ? ' selected' : '')}
            onClick={() => {
              if (!selectKey) return handleSelect(i);
              return handleSelect(selectKey, i);
            }}
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  );
};
