import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ChartTabButton } from 'components/buttons';
import {
  SEGMENTATION_CHURN,
  SEGMENTATION_POPULATION,
  LEGEND_INDICATOR_FILLED,
  LEGEND_INDICATOR_DASHED
} from 'constants/images';
import ChartSVG from 'components/charts/ChartSVG';
import { INFO24PX } from 'constants/images';
import { chartKeys } from 'constants/keys';
import { useDashboardContext } from 'context';

const initialIsLegendSelect = {
  population: true,
  churn: true,
  current: true,
  last: true,
  segments: []
};
const initialIsSelect = {
  sales: 'avg',
  transactions: 'avg'
};

export function PopulationSalesTransactions({ data }) {
  const { t } = useTranslation();
  const { currency } = useDashboardContext();
  const [isLegendSelect, setIsLegendSelect] = useState({
    ...initialIsLegendSelect,
    last: data.last.churnExcluded === null || data.last.churnIncluded === null ? false : true,
    segments: data.segments.map(() => true)
  });

  const [isAxisSelect, setIsAxisSelect] = useState(initialIsSelect);
  const [isInactiveLegendTooltipShow, setIsInactiveLegendTooltipShow] = useState(false);
  const { sales, transactions } = isAxisSelect;

  const handleLegendClick =
    (item, segmentIndex = null) =>
    (e) => {
      e.preventDefault();
      if (segmentIndex !== null) {
        const isOneTrue = isLegendSelect.segments.filter((v) => v).length < 2;
        if (isOneTrue && isLegendSelect.segments[segmentIndex]) return;

        setIsLegendSelect((prev) => ({
          ...prev,
          segments: prev.segments.map((v, i) =>
            i === segmentIndex ? !prev.segments[i] : prev.segments[i]
          )
        }));
      } else {
        setIsLegendSelect((prev) => ({
          ...prev,
          [item]: !prev[item]
        }));
      }
    };

  useEffect(() => {
    setIsLegendSelect((prev) => ({
      ...prev,
      last: data.last.churnExcluded === null || data.last.churnIncluded === null ? false : true
    }));
  }, [data]);

  return (
    <>
      <div className="segmentation-pst_legend_filter">
        <div className={`segmentation-pst_legend_filter_item disable`}>
          <img src={SEGMENTATION_POPULATION} alt="segmentation-population" />
          <div>{t('segmentation.population')}</div>
        </div>
        <div
          className={`segmentation-pst_legend_filter_item ${!isLegendSelect.churn ? 'off' : ''}`}
          onClick={handleLegendClick('churn')}
        >
          <img src={SEGMENTATION_CHURN} alt="segmentation-churn" />
          <div>
            <Trans i18nKey={'segmentation.inactive_customers'} />
            <img
              src={INFO24PX}
              alt="info"
              className="info-icon"
              onMouseEnter={() => {
                setIsInactiveLegendTooltipShow(true);
              }}
              onMouseLeave={() => {
                setIsInactiveLegendTooltipShow(false);
              }}
            />
            {isInactiveLegendTooltipShow && (
              <div className="segmentation-pst_inactive-legend-tooltip">
                <div className="segmentation-pst_inactive-legend-tooltip_guide">
                  {`${t('segmentation.inactive_customers')}: \n${t('segmentation.of_purchase_past_year')}`}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={'segmentation-pst_legend_filter_item disable'}>
          <img src={LEGEND_INDICATOR_FILLED} alt="legend-indicator-filled" />
          <div>
            <Trans
              i18nKey={'segmentation.data_updated_on'}
              values={{ date: data.updateDates[0] }}
            />
          </div>
        </div>
        {data.updateDates[1] && (
          <div
            className={`segmentation-pst_legend_filter_item ${!isLegendSelect.last ? 'off' : ''}`}
            onClick={handleLegendClick('last')}
          >
            <img src={LEGEND_INDICATOR_DASHED} alt="legend-indicator-dashed" />
            <div>
              <Trans
                i18nKey={'segmentation.data_updated_on'}
                values={{ date: data.updateDates[1] }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="segmentation-pst_legend_segments">
        {data.segments.map((v, i) => {
          return (
            <div
              key={`${v.segment_id}`}
              className={`segmentation-pst_legend_segments_item ${
                !isLegendSelect.segments[i] ? 'off' : ''
              }`}
              onClick={handleLegendClick('segments', i)}
            >
              <div
                className="segmentation-pst_legend_segments_color"
                style={{ backgroundColor: v.color }}
              />
              <div className="segmentation-pst_legend_segments_name">{v.segment_name}</div>
            </div>
          );
        })}
      </div>
      <div className="segmentation-pst_chart">
        <div className="segmentation-pst_chart_left">
          <ChartTabButton
            label={`${t('segmentation.sales')} (${currency})`}
            options={['avg', 'total']}
            selected={sales}
            handler={(option) => setIsAxisSelect((prev) => ({ ...prev, sales: option }))}
            isVertical={true}
          />
        </div>
        <div className="segmentation-pst_chart_right">
          <ChartSVG
            chartInfo={{
              data: data,
              chartId: chartKeys.SEGMENTATION.POPULATION_SALES_AND_TRANSACTIONS,
              options: {
                isLegendSelect,
                isAxisSelect
              }
            }}
          />
          <ChartTabButton
            label={t('segmentation.transactions')}
            options={['avg', 'total']}
            selected={transactions}
            handler={(option) => setIsAxisSelect((prev) => ({ ...prev, transactions: option }))}
          />
        </div>
      </div>
    </>
  );
}
