import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next'
import { ERROR_FILLED, CHECK_FILLED, EYE_OFF, EYE, X_ICON } from 'constants/images';

import './ForgotPassword.scss';

import { mutationKeys } from 'constants/keys';
import { useAuthMutation } from 'hooks/auth';

const FillCode = ({ isFilled, setIsFilled, values, setValues, onChange, setCurrentStep }) => {
  const { t } = useTranslation()
  const [isShowPassword, setIsShowPassword] = useState(false);

  const [isShowRepassword, setIsShowRepassword] = useState(false);

  const handleBack = () => {
    setCurrentStep('email');
    setValues((prev) => ({ ...prev, email: '', code: '', password: '', repassword: '' }));
  };

  const onKeyDown = (event) => {
    if (event.code === 'Space') event.preventDefault();
  };

  const isActive =
    values.code.length > 0 && values.password.length > 0 && values.repassword.length > 0;

  const isRepassFulled = values.repassword === values.password && values.repassword.length > 0;

  const isPasswordFilled = values.password.length > 0;
  const minChar = values.password.length > 0 && values.password.match(/^.{8,}$/);
  const oneUp = values.password.length > 0 && values.password.match(/^(?=.*[A-Z]).{1,}$/);
  const oneLower = values.password.length > 0 && values.password.match(/^(?=.*?[a-z]).{1,}$/);
  const oneSpecial =
    values.password.length > 0 &&
    values.password.match(/^(?=.*?[`\\/~!@#$%^&*()_|+\-=?;:'",.<>}{\][]).{1,}$/);

  const isPasswordValid = minChar && oneUp && oneLower && oneSpecial;
  const passwordValidStatus = isPasswordValid ? 'valid' : 'invalid';
  const isRepasswordFilled = values.repassword.length > 0;

  const [resetPassword, error] = useAuthMutation({
    mutationKey: mutationKeys.AUTH.CONFIRM_CODE,
    options: {
      onSuccess: () => {
        window.location.replace('/login');
      },
    },
  });
  const handleReset = async () => {
    await resetPassword({ email: values.email, code: values.code, password: values.password });
  };

  const [sendEmail] = useAuthMutation({
    mutationKey: mutationKeys.AUTH.EMAIL,
    options: { onSuccess: () => setCurrentStep('reset') },
  });

  const handleEmail = async () => {
    await sendEmail(values.email);
  };

  return (
    (<div className="FillCode">
      <div className="contents">
        <div className="contents_header">
          <Trans i18nKey={"login.reset_password"} />
        </div>
        <div className="contents_text">
          <Trans i18nKey={"login.please_fill_the_verification_code"} values={{email: values.email}} />
        </div>

        {/* 코드 확인 */}
        <div className="title">
          <Trans i18nKey={"login.code"} />
        </div>
        <div
          className={`inputbox ${
            error?.config.url.includes('authenticator/confirm_forgot_password') && values.code
              ? 'invalid'
              : ''
          }`}
        >
          <input
            type="text"
            className="inputbox_text"
            name="code"
            value={values.code}
            onChange={onChange}
            autoComplete="new-password"
          />
          {error?.config.url.includes('authenticator/confirm_forgot_password') && values.code && (
            <img src={ERROR_FILLED} className="inputbox_error" />
          )}
        </div>
        {error?.config.url.includes('authenticator/confirm_forgot_password') && values.code && (
          <p className="error">{t("login.the_code_is_invalid")}</p>
        )}

        {/* password 입력 */}
        <div className="title">{t("login.password")}</div>
        <div className={`inputbox ${isPasswordFilled ? passwordValidStatus : ''}`}>
          <input
            type={isShowPassword ? 'text' : 'password'}
            className="inputbox_text2 pass"
            name="password"
            value={values.password}
            onKeyDown={onKeyDown}
            onChange={onChange}
            autoComplete="new-password"
          />

          {/* 비밀번호 보이게할땐 eye로 교체 */}
          <div className="eye" onClick={() => setIsShowPassword((prev) => !prev)}>
            <img src={isShowPassword ? EYE : EYE_OFF} />
          </div>

          {isPasswordFilled &&
            (!minChar || !oneUp || !oneLower || !oneSpecial ? (
              <img src={ERROR_FILLED} className="inputbox_error" />
            ) : (
              <img src={CHECK_FILLED} className="inputbox_error" />
            ))}
        </div>
        {isPasswordFilled && (!minChar || !oneUp || !oneLower || !oneSpecial) ? (
          <p className="error">{t('login.password_doesnt_meet_the_guidelines')}</p>
        ) : (
          ''
        )}

        {/* password 타이핑 시작시 보이고 체크되도록 */}
        {isPasswordFilled && (
          <ul className={`check_box `}>
            <li className="check_list">
              {!minChar ? (
                <img src={X_ICON} className="pass_alert" />
              ) : (
                <img src={CHECK_FILLED} className="pass_alert" />
              )}{t("login.must_have_a_minimum_of")}</li>
            <li className="check_list">
              {!oneUp ? (
                <img src={X_ICON} className="pass_alert" />
              ) : (
                <img src={CHECK_FILLED} className="pass_alert" />
              )}{t("login.must_include_at_least_1_upper")}</li>
            <li className="check_list">
              {!oneLower ? (
                <img src={X_ICON} className="pass_alert" />
              ) : (
                <img src={CHECK_FILLED} className="pass_alert" />
              )}{t("login.must_include_at_least_1_lower")}</li>
            <li className="check_list">
              {!oneSpecial ? (
                <img src={X_ICON} className="pass_alert" />
              ) : (
                <img src={CHECK_FILLED} className="pass_alert" />
              )}{t("login.must_include_at_least_1_special")}</li>
          </ul>
        )}

        {/* password 재확인 */}
        <div className="title">{t("login.confirm_password")}</div>
        <div
          className={`inputbox ${isRepasswordFilled && !isRepassFulled ? 'invalid' : ''} ${
            isRepassFulled ? 'valid2' : ''
          }`}
        >
          <input
            type={isShowRepassword ? 'text' : 'password'}
            className="inputbox_text2 pass"
            name="repassword"
            value={values.repassword}
            onChange={onChange}
            autoComplete="new-password"
          />

          {/* 비밀번호 보이게할땐 eye로 교체 */}
          <div className="eye" onClick={() => setIsShowRepassword((prev) => !prev)}>
            <img src={isShowRepassword ? EYE : EYE_OFF} />
          </div>
          {isRepassFulled && <img src={CHECK_FILLED} className="inputbox_error" />}
          {isRepasswordFilled && !isRepassFulled && (
            <img src={ERROR_FILLED} className="inputbox_error" />
          )}
        </div>
        {isRepasswordFilled && !isRepassFulled && <p className="error">Password must be same.</p>}

        {/* ////////////////////////////  */}

        {/* 버튼 부분 */}
        <div className="button_box">
          <button
            className="cancel_button"
            onClick={() => {
              handleBack();
            }}
          >{t("login.back")}</button>

          <button
            className={`send_button ${!isActive ? ' deactive' : ' active'}`}
            disabled={!isActive}
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </button>
        </div>
        <div
          className="retry"
          onClick={() => {
            handleEmail();
          }}
        >
          Send another code
        </div>
      </div>
    </div>)
  );
};

export default FillCode;
