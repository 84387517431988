export const chartKeys = {
  SEGMENTATION: {
    POPULATION_SALES_AND_TRANSACTIONS: 'populationSalesAndTransactions',
    RECENCY_FREQUENCY_AND_MONETARY_VALUE: 'recencyFrequencyAndMonetaryValue',
    NUMBER_OF_CATEGORIES_AND_CUSTOMERS: 'numberOfCategoriesAndCustomers'
  },
  COMPARISON: 'comparison',
  FORECASTING: 'transaction-forecasting',
  MOSAIC: {
    BAR: 'mosaicBar',
    BOX_PLOT: 'mosaicBoxPlot'
  },
  JOURNEY: {
    BEST_JOURNEY: 'bestJourney',
    JOURNEY: 'journey'
  },
  USER_BASED: {
    CRM_SIMULATION: 'userbasedCrmSimulation'
  }
};
