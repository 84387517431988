import { useState, useEffect } from 'react';
import useGetOption from './useGetOption';
import useGetResult from './useGetResult';
import { useLocationInfo } from 'hooks/useLocationInfo';
import {
  initialParameters,
  selectInitialParameters,
  selectInitialParametersLocation,
  checkIsAdvanced,
  adjustPeriodParameter
} from './utils';
import { queryKeys } from 'constants/keys';
import { clearReportsParameters, getReportsParameters } from 'utils/userStorage';
import { useUserInfoContext } from 'context';

/**
 * @description
 * This hook contains 2 query hooks, useGetOption and useGetResult
 * useGetResult is triggered after the useGetOption successfully get the response from the server.
 */
export function useGetCustomerService({ optionQueryKey = '', serviceQueryKey = '' }) {
  const { userInfo } = useUserInfoContext();
  const { isOverview, isSegmentation, isFeaturebased, isOrganization, isReports } =
    useLocationInfo();
  const [advancedButton, setAdvancedButton] = useState('default'); // default, pressed
  const [option, setOption] = useState(null); // response object, null
  const [parameters, setParameters] = useState(initialParameters[serviceQueryKey]);
  const [isGetResult, setIsGetResult] = useState(isSegmentation || false);

  const { data: dummyOption } = useGetOption(
    isOverview || isSegmentation || isOrganization ? null : optionQueryKey
  );

  const { data: result } = useGetResult(
    isGetResult ? checkIsAdvanced[serviceQueryKey](advancedButton) : null,
    parameters
  );

  useEffect(() => {
    setOption(dummyOption);
  }, [dummyOption]);

  function handleResultGet() {
    setIsGetResult(true);
  }

  /*
    A handler which triggers when one of options is selected.
    As the condition of parameters varies by pages, the setting of parameters should be different as well.
  */
  function handleParametersSelect(selectedOption, label) {
    let key = label === 'additional' ? 'region' : label;

    if (serviceQueryKey === queryKeys.SERVICE.COHORT) {
      if (key === 'from') {
        key =
          parameters.interval.value === 'monthly'
            ? 'monthlyFrom'
            : parameters.interval.value === 'quarterly'
            ? 'quarterlyFrom'
            : 'yearlyFrom';
      }
      if (key === 'to') {
        key =
          parameters.interval.value === 'monthly'
            ? 'monthlyTo'
            : parameters.interval.value === 'quarterly'
            ? 'quarterlyTo'
            : 'yearlyTo';
      }
      if (key === 'interval') {
        setParameters((prev) => ({
          ...prev,
          [key]: {
            id: selectedOption.id,
            value: selectedOption.value
          },
          period: adjustPeriodParameter(
            option.period,
            selectedOption.id,
            prev.period.id,
            prev.period.value
          )
        }));
      } else {
        setParameters((prev) => ({
          ...prev,
          [key]: {
            id: selectedOption.id,
            value: selectedOption.value
          }
        }));
      }
    } else if (serviceQueryKey === queryKeys.SERVICE.USER_BASED) {
      if (label === 'status' && selectedOption.id === 0) {
        setParameters((prev) => ({
          ...prev,
          [label]: {
            id: selectedOption.id,
            value: selectedOption.value
          },
          retention: {
            id: option.retention.filter((v) => v.id === 'month')[0].id,
            value: option.retention.filter((v) => v.id === 'month')[0].value
          }
        }));
      } else {
        setParameters((prev) => ({
          ...prev,
          [label]: {
            id: selectedOption.id,
            value: selectedOption.value
          }
        }));
      }
    } else if (serviceQueryKey === queryKeys.SERVICE.LEAD_TO_CUSTOMER) {
      if (key === 'from') {
        key = parameters.period.value === 'monthly' ? 'monthlyFrom' : 'monthlyFrom';
      }
      if (key === 'to') {
        key = parameters.period.value === 'monthly' ? 'monthlyTo' : 'monthlyTo';
      }
      {
        setParameters((prev) => ({
          ...prev,
          [key]: {
            id: selectedOption.id,
            value: selectedOption.value
          }
        }));
      }
    } else {
      setParameters((prev) => ({
        ...prev,
        [key]: {
          id: selectedOption.id,
          value: selectedOption.value
        }
      }));
    }
  }

  /*
    A handler which triggers by clicking parameter extension button
  */
  function handleAdvancedButton(state) {
    if (state === 'pressed') {
      if (serviceQueryKey === queryKeys.SERVICE.COHORT)
        setParameters((prev) => ({
          ...prev,
          period: {
            id: prev.interval.id,
            value: prev.interval.value
          }
        }));
    }
    setAdvancedButton(state);
  }

  /*
    Once the useGetOption fetches the data successfully, 
    1) the initial parameters are set 
    2) and fetch the result.
  */
  useEffect(() => {
    if (option) {
      const reportsParameters = getReportsParameters();
      if (reportsParameters) {
        setAdvancedButton('pressed');
        selectInitialParametersLocation(
          checkIsAdvanced[serviceQueryKey](advancedButton),
          option,
          setParameters,
          reportsParameters
        );
        clearReportsParameters();
      } else {
        selectInitialParameters(
          checkIsAdvanced[serviceQueryKey](advancedButton),
          option,
          setParameters
        );
      }
      // Prevent feature-based recommendation page from fetching the data initially
      if (serviceQueryKey !== queryKeys.SERVICE.FEATURE_BASED) {
        setIsGetResult(true);
      }
    }
  }, [option]);

  useEffect(() => {
    if (!isSegmentation) {
      setIsGetResult(false);
    }
  }, [result]);

  useEffect(() => {
    setParameters(null);
    if (isReports) {
      handleResultGet();
    }
  }, [userInfo?.company.shopId]);

  return {
    option,
    result,
    parameters,
    advancedButton,
    handlers: {
      handleResultGet,
      handleParametersSelect,
      handleAdvancedButton
    }
  };
}
