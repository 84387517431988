import * as Styles from './index.styles';
import { DASHBOARD_IS_COMING } from 'constants/images';
import { useTranslation } from 'react-i18next';

const DashboardIsComing = () => {
  const { t } = useTranslation();
  return (
    <Styles.DashboardIsComing.Container>
      <Styles.DashboardIsComing.Image src={DASHBOARD_IS_COMING} />
      <Styles.DashboardIsComing.H5>
        {t('error.first_dashboard_being_generated')}
      </Styles.DashboardIsComing.H5>
      <Styles.DashboardIsComing.Strong>
        {t('error.plase_wait_for_analyzing_complete')}
      </Styles.DashboardIsComing.Strong>
    </Styles.DashboardIsComing.Container>
  );
};

export default DashboardIsComing;
