import info from '../../assets/icons/info.svg';
import { useTranslation } from 'react-i18next';
import NoData from '../NoData';
import './Table.scss';
import { labelToTranslationKey } from 'utils';

const Table = ({ data, columns, hasIconHeadrs, icons, helpTexts }) => {
  const { t } = useTranslation();
  return (
    <div className="TableOriginal">
      <table>
        <thead>
          <tr className="table_head_row">
            {data.headers.map((item, i) => {
              const hasIconIndex = hasIconHeadrs.indexOf(item);

              return (
                <th
                  key={`${item}${i}`}
                  className={
                    `${item === 'Next Item' ? 'highlight' : ''}` +
                    `${item === 'Filters' ? 'large' : ''}`
                  }
                >
                  {t(`feature_based.${labelToTranslationKey(item)}`)}
                  {/* {item === 'Normal Distribution' && <img src={info} className="info_logo" />} */}
                  {hasIconHeadrs.indexOf(item) > -1 && (
                    <div className="logo_box">{icons[hasIconHeadrs.indexOf(item)]}</div>
                  )}
                  {hasIconIndex > -1 && (
                    <div className="info_box">
                      <div className="triangle" />
                      {helpTexts[hasIconIndex]}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.cells.map((item, i) => {
            return (
              <tr key={`${item.customer_name}${item.transactions}${i}`} className="table_body_row">
                {columns.map((column) => {
                  return (
                    <td key={column.header} className="table_td">
                      {column.header === 'Score' ? item.score.toFixed(2) : ''}
                      {column.header != 'Latest Transaction' && column.header != 'Score'
                        ? item[column.cell]
                        : ''}
                      {column.header === 'Latest Transaction' ? (
                        <div className="latest_transaction">
                          {item.latest_transaction.since}{t("feature_based.days")} <br />
                          <span>{t("feature_based.date")}: {item.latest_transaction.at}</span>
                        </div>
                      ) : (
                        ''
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.cells.length === 0 && (
        <div className="no-data">
          <NoData />
        </div>
      )}
    </div>
  );
};

export default Table;
