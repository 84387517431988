import axios from 'axios';
import i18n from '../i18n';

export const ENG = 'en-US';
export const KOR = 'ko-KR';

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function getTokenHeader(token) {
  return { Authorization: `Bearer ${token}` };
}
export function formatNumber(value) {
  const formatter = new Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(value);
}
export function extractMonth(date) {
  const month = date.slice(5, 7);
  const language = i18n.language;
  if (language === KOR) {
    if (month[0] === '0') return month[1] + '월';
    return month + '월';
  }
  switch (month) {
    case '01':
      return 'Jan';
    case '02':
      return 'Feb';
    case '03':
      return 'Mar';
    case '04':
      return 'Apr';
    case '05':
      return 'May';
    case '06':
      return 'Jun';
    case '07':
      return 'Jul';
    case '08':
      return 'Aug';
    case '09':
      return 'Sep';
    case '10':
      return 'Oct';
    case '11':
      return 'Nov';
    case '12':
      return 'Dec';
    default:
      return;
  }
}
export function convertDateToEnglish(date) {
  let month = date.slice(5, 7);

  // for Forecasting bar chart
  if (date.includes('월')) {
    month = date.slice(5, 7);
    if (month.includes('월')) {
      month = '0' + month[0];
    }
  }
  switch (month) {
    case '01':
      return 'Jan';
    case '02':
      return 'Feb';
    case '03':
      return 'Mar';
    case '04':
      return 'Apr';
    case '05':
      return 'May';
    case '06':
      return 'Jun';
    case '07':
      return 'Jul';
    case '08':
      return 'Aug';
    case '09':
      return 'Sep';
    case '10':
      return 'Oct';
    case '11':
      return 'Nov';
    case '12':
      return 'Dec';
    default:
      return;
  }
}
export function nameExportFile(title) {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
  const day = ('0' + currentDate.getDate()).slice(-2);
  const dateString = year + month + day;

  const fileName = `${dateString}_${title}.csv`;

  return fileName;
}
export function exportCSV(url, title, withoutDate = false) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = withoutDate ? title : nameExportFile(title);
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function exportXLSX(url, title) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${nameExportFile(title).slice(0, -4)}.xlsx`;
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function formatNewDate(originalDate, currency) {
  const separation = originalDate.split('-');
  const year = separation[0];
  let month = separation[1];
  if (month.length === 1) month = '0' + month;
  const date = separation[2] || null;

  return `${year}/${month}${date ? `/${date}` : ''}`;
}

export function roundedHorizontalRect(x, y, width, height, allRadius) {
  function arc(r, sign) {
    return r ? `a${r * sign[0]},${r * sign[1]} 0 0 1 ${r * sign[2]},${r * sign[3]}` : '';
  }
  const calculateAllRadius = [
    Math.min(allRadius[0], height, width),
    Math.min(allRadius[1], height, width),
    Math.min(allRadius[2], height, width),
    Math.min(allRadius[3], height, width)
  ];

  return `M${x + calculateAllRadius[0]},${y}h${
    width - calculateAllRadius[0] - calculateAllRadius[1]
  }${arc(calculateAllRadius[1], [1, 1, 1, 1])}v${
    height - calculateAllRadius[1] - calculateAllRadius[2]
  }${arc(calculateAllRadius[2], [1, 1, -1, 1])}h${
    -width + calculateAllRadius[2] + calculateAllRadius[3]
  }${arc(calculateAllRadius[3], [1, 1, -1, -1])}v${
    -height + calculateAllRadius[3] + calculateAllRadius[0]
  }${arc(calculateAllRadius[0], [1, 1, 1, -1])}z`;
}

export function roundedVerticalRect(x, y, width, height, allRadius) {
  function arc(r, sign) {
    return r ? `a${r * sign[0]},${r * sign[1]} 0 0 1 ${r * sign[2]},${r * sign[3]}` : '';
  }
  const calculateAllRadius = [
    Math.min(allRadius[0], height, width),
    Math.min(allRadius[1], height, width),
    Math.min(allRadius[2], height, width),
    Math.min(allRadius[3], height, width)
  ];

  return `M${x + calculateAllRadius[0]},${y}h${
    width - calculateAllRadius[0] - calculateAllRadius[1]
  }${arc(calculateAllRadius[1], [1, 1, 1, 1])}v${
    height - calculateAllRadius[1] - calculateAllRadius[2]
  }${arc(calculateAllRadius[2], [1, 1, -1, 1])}h${
    -width + calculateAllRadius[2] + calculateAllRadius[3]
  }${arc(calculateAllRadius[3], [1, 1, -1, -1])}v${
    -height + calculateAllRadius[3] + calculateAllRadius[0]
  }${arc(calculateAllRadius[0], [1, 1, 1, -1])}z`;
}

export function calculateMedian(numbers) {
  const sorted = Array.from(numbers).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}

export function calculateStd(arr) {
  // Creating the mean with Array.reduce
  let mean =
    arr.reduce((acc, curr) => {
      return acc + curr;
    }, 0) / arr.length;

  // Assigning (value - mean) ^ 2 to every array item
  arr = arr.map((k) => {
    return (k - mean) ** 2;
  });

  // Calculating the sum of updated array
  let sum = arr.reduce((acc, curr) => acc + curr, 0);

  // Calculating the variance
  let variance = sum / arr.length;

  // Returning the standard deviation
  return Math.sqrt(sum / arr.length);
}
export function sumOfArray(values) {
  return values.reduce((acc, cur) => acc + cur, 0);
}

export function debounce(func, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function labelToTranslationKey(label) {
  const keys = label
    .replace(/[.,#!$%^&*;:{}=\-_`~()%]/g, '')
    .split(' ')
    .filter((s, i) => s.length > 0 && i < 5)
    .map((s) => s.toLowerCase());
  return keys.join('_');
}

export function changeLanguageTo(language, i18n) {
  if (language !== ENG && language !== KOR) {
    console.log('Invalid language');
    return;
  }
  i18n.changeLanguage(language);
  // setValueInLocalStorage('language', language) // no need to set to local storage
}

function cloneDeeply(arr) {
  let clone = [];

  for (let i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i])) {
      clone.push(cloneDeeply(arr[i]));
    } else if (typeof arr[i] === 'object' && arr[i] !== null) {
      let clonedObject = {};

      for (let key in arr[i]) {
        if (key in arr[i]) {
          if (Array.isArray(arr[i][key])) {
            clonedObject[key] = cloneDeeply(arr[i][key]);
          } else if (typeof arr[i][key] === 'object' && arr[i][key] !== null) {
            clonedObject[key] = cloneDeeply(arr[i][key]);
          } else {
            clonedObject[key] = arr[i][key];
          }
        }
      }

      clone.push(clonedObject);
    } else {
      clone.push(arr[i]);
    }
  }

  return clone;
}
export function orderByProperty(arr, prop, order) {
  const ORDER = order === 'desc' ? 'desc' : 'asc'; // default를 asc로 지정

  const clonedArr = cloneDeeply(arr);

  switch (ORDER) {
    case 'asc':
      clonedArr.sort((a, b) => a[prop] - b[prop]);
      break;
    case 'desc':
      clonedArr.sort((a, b) => b[prop] - a[prop]);
      break;
    default:
      throw new Error();
  }
  return clonedArr;
}