import styled from '@emotion/styled';
import { theme } from 'styles@emotion/theme';

export const NotAnalyzed = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 246px;
  `,
  Image: styled.img`
    width: 208px;
    height: 128px;
    margin-bottom: 25px;
  `,
  H5: styled.h5`
    margin: 0;
    margin-bottom: 8px;
    ${theme.font.h5}
  `,
  Strong: styled.strong`
    ${theme.font.strong}
  `
};
