import { useEffect, useMemo } from 'react';
import { useLocationInfo, useCollapse } from 'hooks';
import { useNavigateRoute } from 'hooks';
import { Link } from 'react-router-dom';
import { animated } from 'react-spring';
import {
  RETENTICS_LOGO,
  CHEVRON_RIGHT,
  SIDEBAR_CLOSE,
  SIDEBAR_BUSINESS_OVERVIEW,
  SIDEBAR_SEGMENT,
  SIDEBAR_INDEPTH_ANALYSIS,
  SIDEBAR_MAKEDASHY_REPORTS,
  SIDEBAR_AI_RECOMMENDATION
} from 'constants/images';
import './index.scss';

function NavItem({ isMinimized, isSidebarHovered, name, sub }) {
  const { path } = useLocationInfo();
  const [ref, animatedCollapse, isCollapsed, handleCollapse] = useCollapse();

  const isCurrentPage = sub.some((e) => path === e.url);

  useEffect(() => {
    handleCollapse(true, isCurrentPage);
  }, []);

  return (
    <>
      <li
        className={(isMinimized ? 'minimized' : '') + (isSidebarHovered ? ' hovered' : '')}
        onClick={handleCollapse}
      >
        <div
          className={
            'active-area' +
            (!isCollapsed ? ' open' : '') +
            (isCurrentPage ? ' current-page' : '') +
            (isMinimized ? ' minimized' : '') +
            (isSidebarHovered ? ' hovered' : '')
          }
        >
          <Link
            className={isMinimized ? 'minimized' : ''}
            onClick={(e) => path === 'overview' && e.preventDefault()}
          >
            {name === 'Business Overview' ? (
              <SIDEBAR_BUSINESS_OVERVIEW color={isCurrentPage ? '#4B43FF' : '#616161'} />
            ) : name === 'Segment' ? (
              <SIDEBAR_SEGMENT color={isCurrentPage ? '#4B43FF' : '#616161'} />
            ) : name === 'In-Depth Analysis' ? (
              <SIDEBAR_INDEPTH_ANALYSIS color={isCurrentPage ? '#4B43FF' : '#616161'} />
            ) : name === 'MakeDashy Reports' ? (
              <SIDEBAR_MAKEDASHY_REPORTS color={isCurrentPage ? '#4B43FF' : '#616161'} />
            ) : (
              <SIDEBAR_AI_RECOMMENDATION color={isCurrentPage ? '#4B43FF' : '#616161'} />
            )}
            {(isMinimized && !isSidebarHovered) || name}
          </Link>
          {!(isMinimized && !isSidebarHovered) && (
            <div className={'open-arrow' + (!isCollapsed ? ' open' : '')}>
              <CHEVRON_RIGHT color={isCurrentPage ? '#4B43FF' : '#616161'} />
            </div>
          )}
        </div>
      </li>
      {!(isMinimized && !isSidebarHovered) ? (
        <animated.div className="sub-container" style={animatedCollapse}>
          <ul className="sub" ref={ref}>
            {sub.map((item, i) => (
              <li key={item + i}>
                <Link
                  className={'sub-active-area' + (path === item.url ? ' current' : '')}
                  to={item.url}
                  onClick={(e) => path === item.url && e.preventDefault()}
                >
                  <span>{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </animated.div>
      ) : (
        ''
      )}
    </>
  );
}
export default function Sidebar({ isMinimized, isSidebarHovered, handleMinimize, handleHover }) {
  const handleNavigate = useNavigateRoute();
  return (
    <aside
      className={
        'layout-sidebar' + (isMinimized ? ' minimized' : '') + (isSidebarHovered ? ' hovered' : '')
      }
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <div className={`upperside`}>
        <div
          className="logo"
          onClick={() => {
            handleNavigate('/marketing-overview');
          }}
        >
          {(!isMinimized || isSidebarHovered) && <img src={RETENTICS_LOGO} />}
        </div>
        <img
          className={'open-button' + (isMinimized ? ' rotate' : '')}
          src={SIDEBAR_CLOSE}
          alt="sidebar-open"
          onClick={handleMinimize}
        />
      </div>
      <div className="navigation-container">
        <ul className="navigation">
          <NavItem
            isMinimized={isMinimized}
            isSidebarHovered={isSidebarHovered}
            name="Business Overview"
            sub={[
              { name: 'Marketing Overview', url: 'marketing-overview' },
              { name: 'Retention', url: 'retention' }
            ]}
          />
          <NavItem
            isMinimized={isMinimized}
            isSidebarHovered={isSidebarHovered}
            name="Segment"
            sub={[
              { name: 'Segment Overview', url: 'segment-overview' },
              { name: 'Mosaic Analysis', url: 'mosaic-analysis' }
            ]}
          />
          <NavItem
            isMinimized={isMinimized}
            isSidebarHovered={isSidebarHovered}
            name="In-Depth Analysis"
            sub={[
              { name: 'Cohort Analysis', url: 'cohort-analysis' },
              { name: 'Comparison', url: 'comparison' },
              { name: 'Cross-Selling', url: 'cross-selling' },
              { name: 'Lead to Customer', url: 'lead-to-customer' },
              { name: 'Item Journey', url: 'item-journey' }
            ]}
          />
          <NavItem
            isMinimized={isMinimized}
            isSidebarHovered={isSidebarHovered}
            name="AI Recommendation"
            sub={[
              { name: 'User-Based', url: 'user-based' },
              { name: 'Feature-Based', url: 'feature-based' }
            ]}
          />
        </ul>
      </div>
    </aside>
  );
}
