import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FILE, ERROR_FILLED, X_ICON, LOADING, EXPORT } from 'constants/images';
import { useSubmitMosaicFile, useGetExampleFile } from 'hooks';
import { MOSAIC } from 'constants';
import * as Styles from './index.styles';

function pickErrorMessage(errorMessageFromServer, t) {
  switch (errorMessageFromServer) {
    case 'The datatype on number file should be numeric':
      return t('mosaic.the_data_type_on_number');
    case 'The datatype on boolean file should be boolean':
      return t('mosaic.the_data_type_on_boolean');
    case 'The datatype on text file should be string':
      return t('mosaic.the_data_type_on_text');
    case 'Missing customer_id column':
      return t('mosaic.the_first_column_name_should');
    case 'The file should include 2 columns':
      return t('mosaic.the_file_should_include_only');
    case 'Number of valid customers is too small':
      return t('mosaic.each_group_should_include_at');
    case 'All data points are missing':
      return t('mosaic.each_group_should_include_at');
    case 'The customer_id must be numeric':
      return t('mosaic.the_customer_id_must_be');
    case 'The feature is existing':
      return t('mosaic.the_feature_is_existing');
    case 'customer_id must be unique':
      return t('mosaic.customer_id_must_be_unique');
    default:
      return errorMessageFromServer;
  }
}

// Uploader 관련 컴포넌트 (우측 상단 box)
// Uploader, FileInput, UploaderInputx, UploaderExample
const Uploader = () => {
  const { t } = useTranslation();

  const [inputFile, setInputFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');

  const { uploadFileToAWS } = useSubmitMosaicFile();
  const { getExampleFile } = useGetExampleFile();

  const handleFileChange = (file) => {
    setInputFile(file);
    setIsSubmitError(false);
    setSubmitErrorMessage('');
  };

  const handleFileSubmit = async () => {
    setIsSubmitError(false);
    setIsProcessing(true);
    try {
      await uploadFileToAWS({
        location: MOSAIC,
        type: 'mosaic',
        file: inputFile
      });
    } catch (error) {
      const errorMessageFromServer = error.message;
      setIsSubmitError(true);
      setSubmitErrorMessage(pickErrorMessage(errorMessageFromServer, t));
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Styles.Container>
      <Styles.Title>{t('mosaic.download_id_file_and_make')}</Styles.Title>
      <Styles.ExampleLink
        onClick={() => {
          getExampleFile();
        }}
      >
        custmer_id.csv
        <Styles.ExportIcon src={EXPORT} alt="example.csv" />
      </Styles.ExampleLink>
      <UploaderInputs
        handleFileChange={handleFileChange}
        isError={isSubmitError}
        errorMessage={submitErrorMessage}
      />
      {/* <UploaderExample selectedFileFormat={selectedFileFormat} /> */}
      <Styles.SubmitButton onClick={handleFileSubmit} disabled={!inputFile || isProcessing}>
        {!isProcessing ? t('mosaic.submit') : <Styles.SubmitProcessingIcon src={LOADING} />}
      </Styles.SubmitButton>
    </Styles.Container>
  );
};
const FileInput = ({ onChange, isError, errorMessage }) => {
  const { t } = useTranslation();

  const [filename, setFilename] = useState(null); // null, string

  const handleFileInputChange = (e) => {
    const inputFile = e.target.files[0];
    const inputFilename = inputFile.name;

    onChange(inputFile);
    setFilename(inputFilename);
  };

  return (
    <Styles.FileInput.Container>
      <Styles.FileInput.InputContainer>
        <Styles.FileInput.FileInformation>
          <Styles.FileInput.FileIcon src={FILE} />
          <Styles.FileInput.FileName>{filename ?? t('mosaic.empty')}</Styles.FileInput.FileName>
          {isError && <Styles.FileInput.FileErrorIcon src={ERROR_FILLED} />}
        </Styles.FileInput.FileInformation>
        <Styles.FileInput.ChooseFile.Label htmlFor="choose-file">
          {t('mosaic.choose_a_file')}
        </Styles.FileInput.ChooseFile.Label>
        <Styles.FileInput.ChooseFile.Input
          id="choose-file"
          type="file"
          accept=".csv"
          onChange={handleFileInputChange}
        />
      </Styles.FileInput.InputContainer>
      {isError && (
        <Styles.FileInput.ErrorContainer>
          <Styles.FileInput.HelperText>
            {t('mosaic.your_data_doesn’t_meet_the')}
          </Styles.FileInput.HelperText>
          <Styles.FileInput.ErrorMessage>
            <Styles.FileInput.ErrorMessageIcon src={X_ICON} />
            {errorMessage}
          </Styles.FileInput.ErrorMessage>
        </Styles.FileInput.ErrorContainer>
      )}
    </Styles.FileInput.Container>
  );
};
const UploaderInputs = ({ handleFileChange, isError, errorMessage }) => {
  return (
    <Styles.Inputs>
      <FileInput onChange={handleFileChange} isError={isError} errorMessage={errorMessage} />
    </Styles.Inputs>
  );
};

export default Uploader;
