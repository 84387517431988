import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useQuery } from 'react-query';
import MainHeader from 'components/MainHeader';
import RetentionRateChart from 'components/charts/RetentionRateChart';
import SearchInput from 'components/inputs/SearchInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import AdvancedButton from 'components/buttons/AdvancedButton';
import { INFO24PX } from 'constants/images';
import { useCustomerServiceMutation, usePageInit } from 'hooks';
import PurchasedItemSearchInput from 'components/inputs/PurchasedItemSearchInput';
import { mutationKeys, queryKeys } from 'constants/keys';
import { useUserInfoContext } from 'context';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { nameExportFile } from 'utils';
import './index.scss';
import { axiosPrivate, customerAPI } from 'api';
import useGetResult from 'hooks/service/useGetCustomerService/useGetResult';
import { checkIsAdvanced } from 'hooks/service/useGetCustomerService/utils';
import { getReportsParameters } from 'utils/userStorage';
import {
  ParametersSkeleton,
  ParameterSkeleton,
  TitleSkeleton,
  DynamicSkeleton
} from 'components/skeletons';

function adjustRetentionParameter(
  retentionOption,
  selectedIntervalId,
  previousRetentionId,
  previousRetentionValue
) {
  let id;
  let value;
  if (selectedIntervalId === 'month') {
    id = previousRetentionId;
    value = previousRetentionValue;
  }
  if (selectedIntervalId === 'quarter') {
    if (previousRetentionId === 'quarter' || previousRetentionId === 'year') {
      id = previousRetentionId;
      value = previousRetentionValue;
    } else if (previousRetentionId === 'month') {
      id = retentionOption.filter((v) => v.id === 'quarter')[0].id;
      value = retentionOption.filter((v) => v.id === 'quarter')[0].value;
    }
  }
  if (selectedIntervalId === 'year') {
    if (previousRetentionId === 'year') {
      id = previousRetentionId;
      value = previousRetentionValue;
    } else if (previousRetentionId === 'month' || previousRetentionId === 'quarter') {
      id = retentionOption.filter((v) => v.id === 'year')[0].id;
      value = retentionOption.filter((v) => v.id === 'year')[0].value;
    }
  }
  return { id, value };
}

const transformOptions = (data) => {
  const toBeReturn = {
    interval: data.intervals.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    monthlyFrom: data.ranges.month.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    monthlyTo: data.ranges.month.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    quarterlyFrom: data.ranges.quarter.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    quarterlyTo: data.ranges.quarter.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    yearlyFrom: data.ranges.year.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    yearlyTo: data.ranges.year.map((v) => ({
      id: v,
      value: v.slice(0, -3)
    })),
    retention: data.intervals.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    period: data.periods.map((v) => ({
      id: v,
      value: v + 'ly'
    })),
    segment: data.segments.map((v) => ({ id: +v.id, value: v.name ? v.name : '' })),
    channel: data.channels.map((v) => ({ id: +v.id, value: v.name })),
    trackFirstItemOnly: [
      {
        id: false,
        value: 'No'
      },
      {
        id: true,
        value: 'Yes'
      }
    ],
    anyMoment: [
      {
        id: false,
        value: 'No'
      },
      {
        id: true,
        value: 'Yes'
      }
    ],
    status: data.statuses.map((v) => ({ id: +v.id, value: v.name })),
    region: data.regions.map((v) => ({ id: +v.id, value: v.name }))
  };
  return toBeReturn;
};

const Retention = () => {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();
  const [selectedParameters, setSelectedParameters] = useState(null);
  const [advancedButton, setAdvancedButton] = useState('default'); // default, pressed
  const [isGetResult, setIsGetResult] = useState(false);
  const reportsParameters = getReportsParameters();

  const { data: optionWithoutPurchasedItem } = useQuery(
    [queryKeys.OPTION.RETENTION, 'options', userInfo?.company.shopId],
    async ({ queryKey }) => {
      const { data } = await axiosPrivate.post(customerAPI.OPTION, {
        company_id: userInfo?.company.companyId,
        shop_id: userInfo?.company.shopId,
        page: queryKey[0]
      });
      return data;
    },
    {
      select: (data) => transformOptions(data)
    }
  );
  const { data: result } = useGetResult(
    isGetResult && selectedParameters
      ? checkIsAdvanced[queryKeys.SERVICE.RETENTION](advancedButton)
      : null,
    selectedParameters
  );

  const [logExport] = useCustomerServiceMutation({
    mutationKey: mutationKeys.CUSTOMER.LOG_EXPORT
  });

  const handleOptionsFilter = ({ label }) => {
    if (label === 'from') {
      if (selectedParameters.interval.id === 'month') {
        return optionWithoutPurchasedItem.monthlyFrom.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.monthlyTo.id) < 0
        );
      } else if (selectedParameters.interval.id === 'quarter') {
        return optionWithoutPurchasedItem.quarterlyFrom.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.quarterlyTo.id) < 0
        );
      } else {
        return optionWithoutPurchasedItem.yearlyFrom.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.yearlyTo.id) < 0
        );
      }
    }
    if (label === 'to') {
      if (selectedParameters.interval.id === 'month') {
        return optionWithoutPurchasedItem.monthlyTo.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.monthlyFrom.id) > 0
        );
      } else if (selectedParameters.interval.id === 'quarter') {
        return optionWithoutPurchasedItem.quarterlyTo.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.quarterlyFrom.id) > 0
        );
      } else {
        return optionWithoutPurchasedItem.yearlyTo.filter(
          (date) => new Date(date.id) - new Date(selectedParameters.yearlyFrom.id) > 0
        );
      }
    }
    if (label === 'retention') {
      if (selectedParameters.interval.id === 'month') {
        return optionWithoutPurchasedItem.retention;
      } else if (selectedParameters.interval.id === 'quarter') {
        return optionWithoutPurchasedItem.retention.filter(
          (v) => v.id === 'quarter' || v.id === 'year'
        );
      } else {
        return optionWithoutPurchasedItem.retention.filter((v) => v.id === 'year');
      }
    }
    return;
  };

  const handleParameterGet = ({ label, interval }) => {
    if (label === 'from') {
      switch (interval) {
        case 'month':
          return selectedParameters.monthlyFrom;
        case 'quarter':
          return selectedParameters.quarterlyFrom;
        default:
          return selectedParameters.yearlyFrom;
      }
    }

    if (label === 'to') {
      switch (interval) {
        case 'month':
          return selectedParameters.monthlyTo;
        case 'quarter':
          return selectedParameters.quarterlyTo;
        default:
          return selectedParameters.yearlyTo;
      }
    }

    if (label === 'purchasedItem') {
      return {
        category: selectedParameters.category,
        item: selectedParameters.item,
        anyMoment: selectedParameters.anyMoment
      };
    }

    return selectedParameters[label];
  };

  const handleParameterSet = (selectedOption, label) => {
    let key = label === 'additional' ? 'region' : label;
    if (key === 'from') {
      key =
        selectedParameters.interval.id === 'month'
          ? 'monthlyFrom'
          : selectedParameters.interval.id === 'quarter'
          ? 'quarterlyFrom'
          : 'yearlyFrom';
    }
    if (key === 'to') {
      key =
        selectedParameters.interval.id === 'month'
          ? 'monthlyTo'
          : selectedParameters.interval.id === 'quarter'
          ? 'quarterlyTo'
          : 'yearlyTo';
    }
    if (key === 'interval') {
      setSelectedParameters((prev) => ({
        ...prev,
        [key]: {
          id: selectedOption.id,
          value: selectedOption.value
        },
        retention: adjustRetentionParameter(
          optionWithoutPurchasedItem.retention,
          selectedOption.id,
          prev.retention.id,
          prev.retention.value
        )
      }));
    } else if (key === 'additional') {
      setSelectedParameters((prev) => ({
        ...prev,
        region: {
          id: selectedOption.id,
          value: selectedOption.value
        }
      }));
    } else {
      setSelectedParameters((prev) => ({
        ...prev,
        [key]: {
          id: selectedOption.id,
          value: selectedOption.value
        }
      }));
    }
  };

  const handleResultGet = () => {
    setIsGetResult(true);
  };

  const handleAdvancedButton = (state) => {
    setAdvancedButton(state);
  };

  useEffect(() => {
    setIsGetResult(false);
  }, [result]);

  // set the initial parameters right after fetching the list
  useEffect(() => {
    if (optionWithoutPurchasedItem) {
      if (reportsParameters) {
        setAdvancedButton('pressed');
        setSelectedParameters((prev) => ({
          ...prev,
          interval: {
            id: reportsParameters.interval,
            value: optionWithoutPurchasedItem.interval.filter(
              (v) => v.id === reportsParameters.interval
            )[0].value
          },
          monthlyFrom: {
            id: reportsParameters.monthlyFrom,
            value: reportsParameters.monthlyFrom
          },
          monthlyTo: {
            id: reportsParameters.monthlyTo,
            value: reportsParameters.monthlyTo
          },
          quarterlyFrom: {
            id: optionWithoutPurchasedItem.quarterlyFrom[0].id,
            value: optionWithoutPurchasedItem.quarterlyFrom[0].value
          },
          quarterlyTo: {
            id: optionWithoutPurchasedItem.quarterlyTo[
              optionWithoutPurchasedItem.quarterlyTo.length - 1
            ].id,
            value:
              optionWithoutPurchasedItem.quarterlyTo[
                optionWithoutPurchasedItem.quarterlyTo.length - 1
              ].value
          },
          yearlyFrom: {
            id: optionWithoutPurchasedItem.yearlyFrom[0].id,
            value: optionWithoutPurchasedItem.yearlyFrom[0].value
          },
          yearlyTo: {
            id: optionWithoutPurchasedItem.yearlyTo[optionWithoutPurchasedItem.yearlyTo.length - 1]
              .id,
            value:
              optionWithoutPurchasedItem.yearlyTo[optionWithoutPurchasedItem.yearlyTo.length - 1]
                .value
          },
          segment: {
            id: reportsParameters.segment,
            value: optionWithoutPurchasedItem.segment.filter(
              (v) => v.id === reportsParameters.segment
            )[0].value
          },
          retention: {
            id: reportsParameters.retention,
            value: optionWithoutPurchasedItem.retention.filter(
              (v) => v.id === reportsParameters.retention
            )[0].value
          },
          region: {
            id: reportsParameters.region,
            value: optionWithoutPurchasedItem.region.filter(
              (v) => v.id === reportsParameters.region
            )[0].value
          },
          channel: {
            id: reportsParameters.channel,
            value: optionWithoutPurchasedItem.channel.filter(
              (v) => v.id === reportsParameters.channel
            )[0].value
          },
          anyMoment: {
            id: reportsParameters.anyMoment,
            value: optionWithoutPurchasedItem.anyMoment.filter((v) => v.id === false)[0].value
          },
          category: {},
          item: {}
        }));
      } else {
        setSelectedParameters((prev) => ({
          ...prev,
          interval: {
            id: optionWithoutPurchasedItem.interval.filter((v) => v.id === 'month')[0].id,
            value: optionWithoutPurchasedItem.interval.filter((v) => v.id === 'month')[0].value
          },
          monthlyFrom: {
            id: optionWithoutPurchasedItem.monthlyFrom[0].id,
            value: optionWithoutPurchasedItem.monthlyFrom[0].value
          },
          monthlyTo: {
            id: optionWithoutPurchasedItem.monthlyTo[
              optionWithoutPurchasedItem.monthlyTo.length - 1
            ].id,
            value:
              optionWithoutPurchasedItem.monthlyTo[optionWithoutPurchasedItem.monthlyTo.length - 1]
                .value
          },
          quarterlyFrom: {
            id: optionWithoutPurchasedItem.quarterlyFrom[0].id,
            value: optionWithoutPurchasedItem.quarterlyFrom[0].value
          },
          quarterlyTo: {
            id: optionWithoutPurchasedItem.quarterlyTo[
              optionWithoutPurchasedItem.quarterlyTo.length - 1
            ].id,
            value:
              optionWithoutPurchasedItem.quarterlyTo[
                optionWithoutPurchasedItem.quarterlyTo.length - 1
              ].value
          },
          yearlyFrom: {
            id: optionWithoutPurchasedItem.yearlyFrom[0].id,
            value: optionWithoutPurchasedItem.yearlyFrom[0].value
          },
          yearlyTo: {
            id: optionWithoutPurchasedItem.yearlyTo[optionWithoutPurchasedItem.yearlyTo.length - 1]
              .id,
            value:
              optionWithoutPurchasedItem.yearlyTo[optionWithoutPurchasedItem.yearlyTo.length - 1]
                .value
          },
          segment: {
            id: optionWithoutPurchasedItem.segment.filter((v) => v.id === 0)[0].id,
            value: optionWithoutPurchasedItem.segment.filter((v) => v.id === 0)[0].value
          },
          retention: {
            id: optionWithoutPurchasedItem.retention.filter((v) => v.id === 'month')[0].id,
            value: optionWithoutPurchasedItem.retention.filter((v) => v.id === 'month')[0].value
          },
          region: {
            id: optionWithoutPurchasedItem.region.filter((v) => v.id === 0)[0].id,
            value: optionWithoutPurchasedItem.region.filter((v) => v.id === 0)[0].value
          },
          channel: {
            id: optionWithoutPurchasedItem.channel.filter((v) => v.id === 0)[0].id,
            value: optionWithoutPurchasedItem.channel.filter((v) => v.id === 0)[0].value
          },
          anyMoment: {
            id: optionWithoutPurchasedItem.anyMoment.filter((v) => v.id === false)[0].id,
            value: optionWithoutPurchasedItem.anyMoment.filter((v) => v.id === false)[0].value
          },
          category: {},
          item: {}
        }));
      }

      setIsGetResult(true);
    }
  }, [optionWithoutPurchasedItem]);

  useEffect(() => {
    if (selectedParameters) {
      setSelectedParameters(null);
    }
  }, [userInfo?.company.shopId]);

  useEffect(() => {
    usePageInit();
  }, [])

  function handleExport() {
    if (userInfo?.user.role === 'observer' || result === 'no data') return;

    const firstWorksheetColumns = [
      { header: 'Date', key: 'date' },
      { header: 'New', key: 'new' },
      { header: 'Retention Rate', key: 'retentionRate' },
      { header: 'Active', key: 'active' },
      { header: 'Churned', key: 'churned' },
      { header: 'Reactive', key: 'reactive' },
      { header: 'Retained', key: 'retained' }
    ];
    const secondWorksheetColumns = [
      { header: 'Interval', key: 'interval' },
      { header: 'From', key: 'from' },
      { header: 'To', key: 'to' },
      { header: 'Segment', key: 'segment' },
      { header: 'Retention', key: 'retention' },
      { header: 'Channel', key: 'channel' },
      { header: 'Category', key: 'category' },
      { header: 'Item', key: 'item' },
      { header: 'Region', key: 'region' },
      { header: 'Any Moment', key: 'anyMoment' }
    ];
    const firstWorksheetRows = result?.exportData;
    const secondWorksheetRows = [
      {
        interval: selectedParameters.interval.value,
        from:
          selectedParameters.interval.value === 'monthly'
            ? selectedParameters.monthlyFrom.value
            : selectedParameters.interval.value === 'quarterly'
            ? selectedParameters.quarterlyFrom.value
            : selectedParameters.yearlyFrom.value,
        to:
          selectedParameters.interval.value === 'monthly'
            ? selectedParameters.monthlyTo.value
            : selectedParameters.interval.value === 'quarterly'
            ? selectedParameters.quarterlyTo.value
            : selectedParameters.yearlyTo.value,
        segment: selectedParameters.segment.value,
        retention: selectedParameters.retention.value,
        channel: selectedParameters.channel.value,
        category: selectedParameters.category.value,
        item: selectedParameters.item.value,
        region: selectedParameters.region.value,
        anyMoment: selectedParameters.anyMoment.value
      }
    ];

    const workbook = new Excel.Workbook();
    const firstWorksheetName = 'data';
    const secondWorksheetName = 'parameters';
    const workBookName = `${nameExportFile('retention').slice(0, -4)}.xlsx`;
    const saveExcel = async () => {
      try {
        const firstWorksheet = workbook.addWorksheet(firstWorksheetName);
        const secondWorksheet = workbook.addWorksheet(secondWorksheetName);

        /**
         * First worksheet : data
         */
        firstWorksheet.columns = firstWorksheetColumns;
        firstWorksheet.getRow(1).font = { bold: true };
        firstWorksheet.columns.forEach((column) => {
          column.width = column.header.length + 5;
          column.alignment = { horizontal: 'center' };
        });
        firstWorksheetRows.forEach((singleData) => {
          firstWorksheet.addRow(singleData);
        });
        firstWorksheet.eachRow({ includeEmpty: false }, (row) => {
          const currentCell = row._cells;
          currentCell.forEach((singleCell) => {
            const cellAddress = singleCell._address;
            firstWorksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        });

        /**
         * Second worksheet : parameters
         */
        secondWorksheet.columns = secondWorksheetColumns;
        secondWorksheet.getRow(1).font = { bold: true };
        secondWorksheet.columns.forEach((column) => {
          column.width = column.header.length + 5;
          column.alignment = { horizontal: 'center' };
        });
        secondWorksheetRows.forEach((singleData) => {
          secondWorksheet.addRow(singleData);
        });
        secondWorksheet.eachRow({ includeEmpty: false }, (row) => {
          const currentCell = row._cells;
          currentCell.forEach((singleCell) => {
            const cellAddress = singleCell._address;
            secondWorksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        });

        /**
         * Export it!
         */
        const buf = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buf]), workBookName);
        logExport({
          companyId: userInfo?.company.companyId,
          shopId: userInfo?.company.shopId,
          page: 'retention',
          fileName: `${nameExportFile('retention').slice(0, -4)}.xlsx`
        });
      } catch (error) {
        console.error(error);
      } finally {
        workbook.removeWorksheet(firstWorksheetName);
        workbook.removeWorksheet(secondWorksheetName);
      }
    };
    saveExcel();
  }

  return (
    <>
      <MainHeader main={t("retention.retention")} sub={t("retention.see_new_reactive_and_churned")} />
      {!selectedParameters || !optionWithoutPurchasedItem ? (
        <ParametersSkeleton>
          <ParameterSkeleton />
          <ParameterSkeleton />
          <ParameterSkeleton />
          <ParameterSkeleton />
        </ParametersSkeleton>
      ) : (
        <div className="box retention-parameters">
          <div className="row first">
            <SearchInput
              label="interval"
              width="260"
              options={optionWithoutPurchasedItem.interval}
              parameter={handleParameterGet({ label: 'interval' })}
              setParameter={handleParameterSet}
            />
            <SearchInput
              label="from"
              width="260"
              options={handleOptionsFilter({ label: 'from' })}
              interval={selectedParameters.interval.id}
              parameter={handleParameterGet({
                label: 'from',
                interval: selectedParameters.interval.id
              })}
              setParameter={handleParameterSet}
            />
            <SearchInput
              label="to"
              width="260"
              options={handleOptionsFilter({ label: 'to' })}
              interval={selectedParameters.interval.id}
              parameter={handleParameterGet({
                label: 'to',
                interval: selectedParameters.interval.id
              })}
              setParameter={handleParameterSet}
            />
            <SearchInput
              label="segment"
              width="260"
              options={optionWithoutPurchasedItem.segment}
              parameter={handleParameterGet({ label: 'segment' })}
              setParameter={handleParameterSet}
            />
          </div>
          {advancedButton === 'pressed' && (
            <>
              <div className="advanced-division">
                <svg>
                  <line x1="0" y1="50%" x2="100%" y2="50%" stroke="#DDDDDD" strokeDasharray={3} />
                </svg>
                <span className="info">
                  {t("retention.advanced")}
                </span>
                <span className="tooltip-box">
                    <TooltipInfo belong="parameter" />
                  </span>
              </div>
              <div className="row second">
                <SearchInput
                  label="retention"
                  width="260"
                  style={{ zIndex: 5 }}
                  options={handleOptionsFilter({ label: 'retention' })}
                  interval={selectedParameters.interval.id}
                  parameter={handleParameterGet({ label: 'retention' })}
                  setParameter={handleParameterSet}
                />
                <PurchasedItemSearchInput
                  label={t("options.purchased_item")}
                  width="260"
                  queryKey={queryKeys.OPTION.RETENTION}
                  anyMomentOptions={optionWithoutPurchasedItem.anyMoment}
                  parameters={handleParameterGet({ label: 'purchasedItem' })}
                  setParameter={handleParameterSet}
                  setParametersState={setSelectedParameters} // temporarily
                  reportsParameters={reportsParameters} // temporarily
                />
                <SearchInput
                  style={{ zIndex: 4 }}
                  label="channel"
                  width="260"
                  options={optionWithoutPurchasedItem.channel}
                  parameter={handleParameterGet({ label: 'channel' })}
                  setParameter={handleParameterSet}
                />
                <SearchInput
                  style={{ zIndex: 4 }}
                  label="additional"
                  width="260"
                  options={optionWithoutPurchasedItem.region}
                  parameter={handleParameterGet({ label: 'region' })}
                  setParameter={handleParameterSet}
                />
              </div>
            </>
          )}
          <div className="row fourth">
          <PrimaryButton value={t("retention.apply")} onClick={handleResultGet} style={{ width: '124px' }} />
            <AdvancedButton state={advancedButton} changeState={handleAdvancedButton} />
          </div>
        </div>
      )}

      <div className="box retention-rate-chart-container">
        {!result ? (
          <div className="overview-skeleton-container">
            <TitleSkeleton width={400} />
            <DynamicSkeleton width={1100} height={403} />
          </div>
        ) : (
          <RetentionRateChart
            title={t("retention.retention_rate")}
            chartId="rr"
            data={result}
            svgWidth={1110}
            svgHeight={370}
            onExport={handleExport}
          />
        )}
      </div>
    </>
  );
};

function TooltipInfo() {
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  return (
    <div className="tooltip-info-container">
      {isTooltipShown && (
        <div className="tooltip">
          <Trans i18nKey={"cohort.if_you_adjust_advanced"} />
        </div>
      )}

      <img
        src={INFO24PX}
        alt="info"
        onMouseOver={() => {
          setIsTooltipShown(true);
        }}
        onMouseLeave={() => {
          setIsTooltipShown(false);
        }}
      />
    </div>
  );
}

export default Retention;