import { axiosPrivate, customerAPI } from 'api';
import { getStore } from 'utils/userStorage';

export const mutationFns = {
  async evaluateReport(tmp) {
    const { data } = await axiosPrivate.post(customerAPI.REPORT_EVALUATE, {
      company_id: getStore().company.companyId,
      shop_id: getStore().company.shopId,
      report_id: tmp.reportId,
      evaluation: tmp.evaluation,
    });
    return data;
  },
  async openReport(reportId) {
    const { data } = await axiosPrivate.post(customerAPI.OPEN_REPORT, {
      company_id: getStore().company.companyId,
      shop_id: getStore().company.shopId,
      report_id: reportId,
    });
    return data;
  },
  async logExport({ companyId, shopId, page, fileName, options = null }) {
    const { data } = await axiosPrivate.post(customerAPI.LOG_EXPORT, {
      company_id: companyId,
      shop_id: shopId,
      page,
      name: fileName,
      options,
    });
    return data;
  },
  async editSegmentName({ companyId, shopId, segmentId, segmentName }) {
    const { data } = await axiosPrivate.post(customerAPI.EDIT_SEGMENT_NAME, {
      company_id: companyId,
      shop_id: shopId,
      segment_id: segmentId,
      segment_name: segmentName,
    });
    return data;
  },
};
