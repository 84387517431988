import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountFetch, useAccountMutation } from 'hooks/account';
import { mutationKeys, queryKeys } from 'constants/keys';
import MainHeader from 'components/MainHeader';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePasword';
import { useUserInfoContext } from 'context';
import './index.scss';

export default function Profile() {
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();
  const [inputProfile, setInputProfile] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });
  const [inputPasswords, setInputPasswords] = useState({
    current: '',
    new: '',
    confirm: '',
  });

  const { data } = useAccountFetch(true, queryKeys.ACCOUNT.PROFILE);
  const [editProfile] = useAccountMutation({ mutationKey: mutationKeys.ACCOUNT.EDIT_PROFILE });
  const [changePassword, status, , changePasswordError] = useAccountMutation({
    mutationKey: mutationKeys.ACCOUNT.CHANGE_PASSWORD,
  });

  const handleProfileInput = (key) => (e) => {
    setInputProfile((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };
  const handleInputPasswords = (key) => (e) => {
    setInputPasswords((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  const handleEditProfile = () => {
    editProfile({
      id: data.employeeId,
      userName: data.userName,
      firstName: inputProfile.firstName || data.firstName,
      lastName: inputProfile.lastName || data.lastName,
      phoneNumber: inputProfile.phoneNumber || data.phoneNumber,
    });
  };
  const handleChangePassword = () => {
    changePassword({
      accessToken: userInfo?.token?.accessToken,
      userName: data.userName,
      currentPassword: inputPasswords.current,
      newPassword: inputPasswords.new,
    });
  };

  useEffect(() => {
    setInputProfile({
      firstName: '',
      lastName: '',
      phoneNumber: '',
    });
  }, [data]);
  useEffect(() => {
    if (status === 'success') {
      setInputPasswords({
        current: '',
        new: '',
        cofirm: '',
      });
    }
  }, [status]);

  return (
    <>
      <MainHeader main={t("settings.profile_settings")} />
      <div className="box profile edit-profile">
        <EditProfile
          data={data}
          handleProfileInput={handleProfileInput}
          inputProfile={inputProfile}
          handleEditProfile={handleEditProfile}
        />
      </div>

      <div className="box profile change-password">
        <ChangePassword
          error={changePasswordError}
          handleInputPasswords={handleInputPasswords}
          inputPasswords={inputPasswords}
          handleChangePassword={handleChangePassword}
        />
      </div>
    </>
  );
}
