import { css } from '@emotion/react';

export const theme = {
  color: {
    neonBlue900: '#060073',
    neonBlue800: '#0b03a6',
    neonBlue700: '#2118d9',
    neonBlue600: '#362ef2',
    neonBlue500: '#4b43ff',
    neonBlue400: '#6a62fe',
    neonBlue300: '#8681fc',
    neonBlue200: '#a8a3fb',
    neonBlue100: '#c1bef7',
    neonBlue50: '#e0dff8',

    aquaBlue500: '#00dcff',
    aquaBlue300: '#A1F0FC',

    neonGreen500: '#18D1A0',

    neonPurple500: '#A12AFF',
    neonPurple300: '#D299FF',

    neonPink500: '#FE59F7',
    neonPink300: '#FE9AFA',

    neonOrange500: '#FD6A00',
    neonOrange300: '#FEAF78',

    neonYellow500: '#FFB800',
    neonYellow300: '#FFE499',

    black: '#000000',
    white: '#ffffff',

    gray900: '#222222',
    gray700: '#616161',
    gray600: '#757575',
    gray500: '#9e9e9e',
    gray400: '#bdbdbd',
    gray300: '#dddddd',
    gray200: '#eeeeee',
    gray100: '#f5f5f5',
    gray50: '#fafafa',

    red900: '#ad0000',
    red800: '#ce1212',
    red700: '#da1b1b',
    red600: '#e93535',
    red500: '#f94c4c',
    red400: '#ff7070',
    red300: '#ff9999',
    red200: '#ffb3b3',
    red100: '#fad1d1',
    red50: '#fde7e7',

    green500: '#4caf50',
  },
  font: {
    h1: css`
      font-family: 'Manrope';
      font-size: 96px;
      line-height: 104px;
      font-weight: 700;
    `,
    h2: css`
      font-family: 'Manrope';
      font-size: 64px;
      line-height: 72px;
      font-weight: 700;
    `,
    h3: css`
      font-family: 'Manrope';
      font-size: 48px;
      line-height: 56px;
      font-weight: 700;
    `,
    h4: css`
      font-family: 'Manrope';
      font-size: 40px;
      line-height: 48px;
      font-weight: 700;
    `,
    h5: css`
      font-family: 'Manrope';
      font-size: 32px;
      line-height: 40px;
      font-weight: 700;
    `,
    h6: css`
      font-family: 'Manrope';
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
    `,
    strong: css`
      font-family: 'Manrope';
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    `,
    bigNumber: css`
      font-family: 'Pretendard';
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
    `,
    subtitleLarge: css`
      font-family: 'Pretendard';
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
    `,
    subtitleRegular: css`
      font-family: 'Pretendard';
      font-size: 18px;
      line-height: 26px;
      font-weight: 400;
    `,
    bodyLarge: css`
      font-family: 'Pretendard';
      font-size: 18px;
      line-height: 26px;
      font-weight: 400;
    `,
    bodyRegular: css`
      font-family: 'Pretendard';
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    `,
    bodySmall: css`
      font-family: 'Pretendard';
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
    `,
    smallText: css`
      font-family: 'Pretendard';
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
    `,
    footnote: css`
      font-family: 'Pretendard';
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
    `,
    buttonRegular: css`
      font-family: 'Manrope';
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    `,
    buttonSmall: css`
      font-family: 'Manrope';
      font-size: 14px;
      line-height: 22px;
      font-weight: 700;
    `,
    label: css`
      font-family: 'Pretendard';
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    `,
    chartTitle: css`
      font-family: 'Manrope';
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;
    `,
    chartCategory: css`
      font-family: 'Pretendard';
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
    `,
    chartBody: css`
      font-family: 'Pretendard';
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
    `,
    chartAxisLabel: css`
      font-family: 'Pretendard';
      font-size: 13px;
      line-height: 13px;
      font-weight: 500;
    `,
    chartLegend: css`
      font-family: 'Pretendard';
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
    `,
    chartLegendSemibold: css`
      font-family: 'Pretendard';
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
    `,
    chartTooltipTitle: css`
      font-family: 'Pretendard';
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
    `,
    chartTooltipSmall: css`
      font-family: 'Pretendard';
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
    `,
    chartTooltipBody: css`
      font-family: 'Pretendard';
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    `,
  },
  component: {
    box: css`
      padding: 24px;
      background-color: $white;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    `,
  },
};
