import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/buttons/PrimaryButton';

export default function EditProfile({ data, handleProfileInput, inputProfile, handleEditProfile }) {
  const { t } = useTranslation()
  
  return (<>
    <div className="title">{t("settings.edit_profile")}</div>
    <div className="boxes">
      <form autoComplete="off">
        <div className="input-box">
          <div className="label">{t("inquiries.user_name")}</div>
          <div className="input-wrapper disabled">
            <input className="input" placeholder={data?.userName} disabled />
          </div>
        </div>
        <div className="input-box">
          <div className="label">{t("settings.first_name")}</div>
          <div className="input-wrapper">
            <input
              className="input"
              placeholder={data?.firstName}
              onChange={handleProfileInput('firstName')}
              value={inputProfile.firstName}
              autoComplete="new-password"
            />
          </div>
        </div>
        <div className="input-box">
          <div className="label">{t("settings.last_name")}</div>
          <div className="input-wrapper">
            <input
              className="input"
              placeholder={data?.lastName}
              onChange={handleProfileInput('lastName')}
              value={inputProfile.lastName}
              autoComplete="new-password"
            />
          </div>
        </div>
        <div className="input-box">
          <div className="label">{t("settings.email_address")}</div>
          <div className="input-wrapper disabled">
            <input className="input" placeholder={data?.emailAddress} disabled />
          </div>
        </div>
        <div className="input-box">
          <div className="label">{t("settings.phone_number")}</div>
          <div className="input-wrapper">
            <input
              name="phone"
              type="text"
              className="input"
              placeholder={data?.phoneNumber}
              onChange={handleProfileInput('phoneNumber')}
              value={inputProfile.phoneNumber}
              autoComplete="new-password"
            />
          </div>
        </div>
        <div className="button-box">
          <PrimaryButton
            value={t("settings.save")}
            disabled={
              !inputProfile?.firstName && !inputProfile?.lastName && !inputProfile?.phoneNumber
            }
            onClick={handleEditProfile}
            style={{ width: '138px' }}
          />
        </div>
      </form>
    </div>
  </>);
}
