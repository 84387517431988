import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LEGEND_INDICATOR_FILLED_RECT, LEGEND_INDICATOR_DASHED_RECT } from 'constants/images';
import ChartSVG from 'components/charts/ChartSVG';
import { chartKeys } from 'constants/keys';
import * as d3 from 'd3';
import { numberWithCommas } from 'utils';
import { useDashboardContext } from 'context';

const initialIsLegendSelect = {
  last: true,
  current: true
};

export const RecencyFrequencyMonetaryValue = ({ data }) => {
  const { t } = useTranslation();
  const { currency } = useDashboardContext();

  const [isLegendSelect, setIsLegendSelect] = useState({
    ...initialIsLegendSelect,
    last: data.isLastExist
  });
  const [isHover, setIsHover] = useState(new Array(data.segments.length).fill(false));

  const handleLegendClick = (item) => {
    if ((!isLegendSelect.last || !isLegendSelect.current) && isLegendSelect[item] === true) return;
    setIsLegendSelect((prev) => ({
      ...prev,
      [item]: !prev[item]
    }));
  };

  const handleCopy = (index) => (e) => {
    const copyTextarea = document.createElement('textarea');
    copyTextarea.style.position = 'fixed';
    copyTextarea.style.opacity = '0';

    let textContent = '';
    textContent += `${data.segments[index].segment_name}\n`;
    textContent += `Population: ${data.populations[index].y}\n`;
    textContent += `${t('segmentation.days_since_last_purchase')}: ${
      data.recency[index].xCurrent
    }${t('segmentation.days')} `;
    if (isLegendSelect.last) {
      textContent +=
        Math.round(
          ((data.recency[index].xCurrent - data.recency[index].xLast) / data.recency[index].xLast) *
            100
        ) + '%';
    }
    textContent += '\n';
    textContent += `${t('segmentation.purchase_cycledays')}: ${data.frequency[index].xCurrent}${t(
      'segmentation.days'
    )} `;
    if (isLegendSelect.last) {
      textContent +=
        Math.round(
          ((data.frequency[index].xCurrent - data.frequency[index].xLast) /
            data.frequency[index].xLast) *
            100
        ) + '%';
    }
    textContent += '\n';
    textContent += `${t('segmentation.average_order_value')}: ${
      data.monetaryValue[index].xCurrent
    } `;
    if (isLegendSelect.last) {
      textContent +=
        Math.round(
          ((data.monetaryValue[index].xCurrent - data.monetaryValue[index].xLast) /
            data.monetaryValue[index].xLast) *
            100
        ) + '%';
    }

    copyTextarea.textContent = textContent;

    document.body.appendChild(copyTextarea);
    copyTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(copyTextarea);
  };

  const handleHover = (index) => (e) => {
    if (index === undefined) {
      // mouse out
      setIsHover((prev) => prev.map((v) => false));
      d3.select('.segmentation-rfm_chart_tooltip').style('visibility', 'hidden');
    } else {
      // mouse in
      setIsHover((prev) => {
        prev[index] = true;
        return [...prev];
      });
    }
  };

  const handleTooltip = (index) => (e) => {
    d3.select('.segmentation-rfm_chart_tooltip').style('visibility', 'visible');
    const tooltip = d3.select('.segmentation-rfm_chart_tooltip');
    const [x, y] = d3.pointer(e);

    const tooltipName = tooltip.select('.tooltip-name');
    tooltipName.select('.segment-color').style('background-color', data.segments[index].color);
    tooltipName.select('.segment-name').node().textContent = data.segments[index].segment_name;

    const tooltipPopulation = tooltip.select('.tooltip-population');
    tooltipPopulation.node().textContent = `${t('segmentation.rfm_population')}: ${numberWithCommas(
      data.populations[index].y
    )}`;

    tooltip
      .style('left', `${x - tooltip.node().getBoundingClientRect().width / 2}px`)
      .style('top', `${57 * index + y - tooltip.node().getBoundingClientRect().height}px`);

    Array.from(tooltip.selectAll('.tooltip-value').nodes()).forEach((elem, i) => {
      if (i === 0) {
        elem.childNodes[1].textContent = data.recency[index].xCurrent + t('segmentation.days');
        if (isLegendSelect.last) {
          let variation =
            Math.round(
              ((data.recency[index].xCurrent - data.recency[index].xLast) /
                data.recency[index].xLast) *
                100
            ) + '%';
          elem.childNodes[2].classList.remove('negative');
          elem.childNodes[2].classList.remove('positive');
          if (variation[0] === '-') {
            elem.childNodes[2].classList.add('negative');
            variation = variation.slice(1);
          } else if (variation[0] !== '0') {
            elem.childNodes[2].classList.add('positive');
          }

          elem.childNodes[2].textContent = variation;
        }
      }
      if (i === 1) {
        elem.childNodes[1].textContent = data.frequency[index].xCurrent + t('segmentation.days');
        if (isLegendSelect.last) {
          let variation =
            Math.round(
              ((data.frequency[index].xCurrent - data.frequency[index].xLast) /
                data.frequency[index].xLast) *
                100
            ) + '%';
          elem.childNodes[2].classList.remove('negative');
          elem.childNodes[2].classList.remove('positive');
          if (variation[0] === '-') {
            elem.childNodes[2].classList.add('negative');
            variation = variation.slice(1);
          } else if (variation[0] !== '0') {
            elem.childNodes[2].classList.add('positive');
          }
          elem.childNodes[2].textContent = variation;
        }
      }
      if (i === 2) {
        elem.childNodes[1].textContent = data.monetaryValue[index].xCurrent;
        if (isLegendSelect.last) {
          let variation =
            Math.round(
              ((data.monetaryValue[index].xCurrent - data.monetaryValue[index].xLast) /
                data.monetaryValue[index].xLast) *
                100
            ) + '%';

          elem.childNodes[2].classList.remove('negative');
          elem.childNodes[2].classList.remove('positive');
          if (variation[0] === '-') {
            elem.childNodes[2].classList.add('negative');
            variation = variation.slice(1);
          } else if (variation[0] !== '0') {
            elem.childNodes[2].classList.add('positive');
          }

          elem.childNodes[2].textContent = variation;
        }
      }
    });
  };

  return (
    <>
      <div className="segmentation-rfm_legend">
        <div className={`segmentation-rfm_legend_item disable`}>
          <img src={LEGEND_INDICATOR_FILLED_RECT} alt="legend-indicator-filled" />
          <div>
            <Trans
              i18nKey={'segmentation.data_updated_on'}
              values={{ date: data.updateDates[0] }}
            />
          </div>
        </div>
        {data.updateDates[1] && (
          <div
            className={`segmentation-rfm_legend_item ${!isLegendSelect.last ? 'off' : ''}`}
            onClick={() => handleLegendClick('last')}
          >
            <img src={LEGEND_INDICATOR_DASHED_RECT} alt="legend-indicator-dashed" />
            <div>
              <Trans
                i18nKey={'segmentation.data_updated_on'}
                values={{ date: data.updateDates[1] }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="segmentation-rfm_chart">
        <div className="segmentation-rfm_chart_highlight">
          {data.segments.map((v, i) => {
            return (
              <div
                key={`${v.segment_id}`}
                className={`segmentation-rfm_chart_highlight_row ${isHover[i] ? 'hover' : ''}`}
              />
            );
          })}
        </div>
        <div className="segmentation-rfm_chart_segments">
          <EachColumn
            data={data.segments}
            header={t('segmentation.group')}
            rows={(data) =>
              data.map((v, i) => {
                return (
                  <div
                    key={`${v.segment_name}${v.segment_id}${v.color}`}
                    className="segmentation-rfm_chart_row"
                  >
                    <div
                      className="segmentation-rfm_chart_segment_color"
                      style={{ backgroundColor: v.color }}
                    />
                    <div className="segmentation-rfm_chart_segment_name">{v.segment_name}</div>
                  </div>
                );
              })
            }
          />
        </div>
        <div className="segmentation-rfm_chart_recency">
          <EachColumn
            data={data.recency}
            header={t('segmentation.days_since_last_purchase')}
            rows={(data) =>
              data.map((v) => {
                return (
                  <div key={`${v.current}${v.segment_id}`} className="segmentation-rfm_chart_row">
                    {v.current}
                  </div>
                );
              })
            }
          />
        </div>
        <div className="segmentation-rfm_chart_frequency">
          <EachColumn
            data={data.frequency}
            header={t('segmentation.purchase_cycledays')}
            rows={(data) => (
              <ChartSVG
                chartInfo={{
                  data: data,
                  chartId: chartKeys.SEGMENTATION.RECENCY_FREQUENCY_AND_MONETARY_VALUE,
                  options: { isLegendSelect }
                }}
              />
            )}
          />
        </div>
        <div className="segmentation-rfm_chart_monetary_value">
          <EachColumn
            data={data.monetaryValue}
            header={`${t('segmentation.average_order_value')} (${currency})`}
            rows={(data) => (
              <ChartSVG
                chartInfo={{
                  data: data,
                  chartId: chartKeys.SEGMENTATION.RECENCY_FREQUENCY_AND_MONETARY_VALUE,
                  options: { isLegendSelect }
                }}
              />
            )}
          />
        </div>
        <div className="segmentation-rfm_chart_overlay">
          {data.segments.map((v, i) => (
            <div
              onMouseEnter={handleHover(i)}
              onMouseMove={handleTooltip(i)}
              onMouseLeave={handleHover()}
              onMouseDown={handleCopy(i)}
              key={`${v.segment_id}`}
              className="segmentation-rfm_chart_overlay_row"
            />
          ))}
          <div className="segmentation-rfm_chart_tooltip">
            <div className="tooltip-name">
              <span
                className="segment-color"
                style={{
                  display: 'inline-block',
                  marginRight: '5px',
                  width: '9px',
                  height: '9px',
                  borderRadius: '50%'
                }}
              />
              <span className="segment-name" />
            </div>
            <div className="tooltip-population" />
            <div className="tooltip-value">
              <div className="tooltip-value_title">
                {t('segmentation.days_since_last_purchase')}:{' '}
              </div>
              <div className="tooltip-value_value" />
              <div className="tooltip-value_variation" />
            </div>
            <div className="tooltip-value">
              <div className="tooltip-value_title">{t('segmentation.purchase_cycledays')}: </div>
              <div className="tooltip-value_value" />
              <div className="tooltip-value_variation" />
            </div>
            <div className="tooltip-value">
              <div className="tooltip-value_title">{t('segmentation.average_order_value')}: </div>
              <div className="tooltip-value_value" />
              <div className="tooltip-value_variation" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const EachColumn = ({ data, header, rows }) => (
  <>
    <div className="segmentation-rfm_chart_header">{header}</div>
    {rows && <div className="segmentation-rfm_chart_rows">{rows(data)}</div>}
  </>
);
