import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { theme } from 'styles@emotion/theme';

const Container = styled.div`
  position: fixed;
  z-index: 13;
  top: 0;
  left: ${({ isGlobalStyle }) => (isGlobalStyle ? '0px' : '240px')};
  width: ${({ isGlobalStyle }) => (isGlobalStyle ? '100%' : 'calc(100% - 240px)')};
  height: 100vh;
`;

const Dimmed = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${theme.color.gray300};
  opacity: 0.5;
`;

const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  padding: 24px;
  border-radius: 10px;
  background-color: ${theme.color.white};
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
  width: 480px;
`;

const ModalTitle = styled.div`
  margin-bottom: 12px;
  ${theme.font.h6}
`;

const ModalDescription = styled.div`
  ${theme.font.bodyRegular}
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  & button {
    padding: 10.5px 24px;
    ${theme.font.buttonRegular}
    border-radius: 100px;
    & + button {
      margin-left: 8px;
    }
  }
`;

const ModalCancelButton = styled.button`
  color: ${theme.color.neonBlue500};
`;

const ModalConfirmButton = styled.button`
  background-color: ${theme.color.neonBlue500};
  color: ${theme.color.white};
`;

const MosaicLabel = styled.label`
  ${theme.font.bodyRegular}
`;
const MosaicInput = styled.input`
  display: block;
  margin-top: 5px;
  margin-bottom: 12px;
  padding: 16px;
  width: 100%;
  height: 56px;
  border: 1px solid ${theme.color.gray300};
  border-radius: 4px;
  ${theme.font.bodyRegular}
  color: ${theme.color.gray900};

  &::placeholder {
    color: ${theme.color.gray500};
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(90deg);
  }
  37.5%{
    transform: rotate(135deg);
  }
  50% {
    transform: rotate(180deg);
  }
  62.5% {
    transform: rotate(225deg);
  }
  75% {
    transform: rotate(270deg);
  }
  87.5%{
    transform: rotate(315deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Delay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 100%;
  background-color: ${theme.color.gray300};
  opacity: 0.7;
  border-radius: 10px;
`;
const DelayImage = styled.img`
  animation: ${spin} 1s linear infinite;
`;
const DelayText = styled.div`
  ${theme.font.h6}
  color: ${theme.color.neonBlue500};
`;

export default {
  Container,
  Dimmed,
  Modal,
  ModalTitle,
  ModalDescription,
  MosaicLabel,
  MosaicInput,
  ModalButtons,
  ModalCancelButton,
  ModalConfirmButton,
  Delay,
  DelayImage,
  DelayText
};
