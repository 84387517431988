import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDeleteMosaicByGroup, useEditMosaicByGroup, useUpdateCandidate } from 'hooks';
import {
  WARNING_FILLED,
  X_ICON,
  NODATA,
  LOADING,
  LEGEND_INDICATOR_STAR,
  MOSAIC_EDIT,
  NEED_MORE_DATA
} from 'constants/images';
import { chartKeys } from 'constants/keys';
import { useGlobalModal, useUserInfoContext } from 'context';
import Chart from 'components/emotion/Chart';
import Spacer from 'components/emotion/Spacer';
import * as Styles from './index.styles';
import { theme } from 'styles@emotion/theme';
import { labelToTranslationKey } from 'utils';
import PrimaryButton from 'components/emotion/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { CANDIDATE_STATUS } from 'constants';

// Result 관련 컴포넌트 하단 box
// Result, ResultChart
const Result = ({ data, tab, candidateStatus }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userInfo } = useUserInfoContext();
  const [beingDeletedChartGroup, setBeingDeletedChartGroup] = useState('');
  const [isEditContainerOpen, setIsEditContainerOpen] = useState([]);
  const { modal } = useGlobalModal();

  const { deleteChart } = useDeleteMosaicByGroup();
  const { editChart } = useEditMosaicByGroup();
  const { updateCandidate, isUpdateLoading } = useUpdateCandidate();

  const handleEditContainerToggle = (index) => {
    if (!isNaN(index)) {
      setIsEditContainerOpen((prev) => {
        const newArr = [...prev].map((isOpen, i) => (i !== index ? false : isOpen));
        newArr[index] = !newArr[index];
        return newArr;
      });
    } else {
      setIsEditContainerOpen((prev) => [...prev].map(() => false));
    }
  };

  const handleEdit =
    ({ chartGroup, dashboardId, title, description }) =>
    (e) => {
      e.preventDefault();

      // EditContainer close
      handleEditContainerToggle();

      modal({
        content: {
          category: 'mosaicInput',
          title: t('mosaic.modal.edit_title_and_description'),
          mosaicEdit: {
            title,
            description
          },
          cancelButton: t('mosaic.cancel'),
          confirmButton: t('mosaic.save')
        },
        onConfirm: {
          fn: editChart,
          parameters: {
            chartGroup,
            dashboardId
          }
        }
      });
    };

  const handleDelete =
    ({ chartGroup, dashboardId }) =>
    async (e) => {
      e.preventDefault();

      // EditContainer close
      handleEditContainerToggle();

      modal({
        content: {
          category: 'description',
          title: t('mosaic.confirming_delete'),
          description: t('mosaic.do_you_really_want_to'),
          cancelButton: t('mosaic.cancel'),
          confirmButton: t('mosaic.delete')
        },
        onConfirm: {
          fn: deleteChart,
          parameters: {
            chartGroup,
            dashboardId,
            startDeleteProcess: () => setBeingDeletedChartGroup(chartGroup)
          }
        }
      });
    };

  useEffect(() => {
    if (!data) return;

    setBeingDeletedChartGroup('');
    setIsEditContainerOpen(new Array(data.length).fill(false));
  }, [data]);

  if (!data) {
    return <></>;
  }

  return (
    <Styles.Container>
      {data.length === 0 ? (
        <Styles.MissedData.Container>
          <Styles.MissedData.NoDataImage src={NODATA} />
          <Styles.MissedData.NoDataBigText>
            {t('mosaic.theres_no_data')}
          </Styles.MissedData.NoDataBigText>
          <Styles.MissedData.NoDataSmallText>
            {t('mosaic.please_submit_your_data_for')}
          </Styles.MissedData.NoDataSmallText>
        </Styles.MissedData.Container>
      ) : (
        <>
          {tab === 'current' && (
            <>
              <Styles.TitleWrapper>
                <Styles.Title>{t('mosaic.lets_check_out_the_results')}</Styles.Title>
                <Styles.TitleLastOrder>
                  {t('mosaic.last_order')}: {data[0].lastOrder}
                </Styles.TitleLastOrder>
              </Styles.TitleWrapper>
              <Styles.Description>{t('mosaic.did_you_get_any_useful')}</Styles.Description>

              <Spacer horizontal size={8} />
            </>
          )}
          <Styles.Charts>
            {data.map((chart, i) => (
              <ChartContainer
                key={JSON.stringify(chart.file)}
                chart={chart}
                beingDeletedChartGroup={beingDeletedChartGroup}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                isEditContainerOpen={isEditContainerOpen[i]}
                setIsEditContainerOpen={setIsEditContainerOpen}
                index={i}
                handleEditContainerToggle={handleEditContainerToggle}
                tab={tab}
              />
            ))}
          </Styles.Charts>
        </>
      )}
      {/*
        BE 수정이 된 이후 다시 배포
      tab === 'current' && candidateStatus && (
        <Styles.UpdateCandidatesWrapper>
          {candidateStatus === CANDIDATE_STATUS.INITIAL && <></>}

          {candidateStatus === CANDIDATE_STATUS.NOT_UPDATED && (
            <PrimaryButton
              style={{ width: '230px' }}
              value={t('mosaic.update_candidates')}
              onClick={() => {
                if (userInfo?.user.role === 'observer') return;
                updateCandidate();
              }}
              isLoading={isUpdateLoading}
            />
          )}

          {candidateStatus === CANDIDATE_STATUS.UPDATING && (
            <PrimaryButton
              style={{ width: '230px' }}
              value={t('mosaic.update_candidates')}
              onClick={() => {
                if (userInfo?.user.role === 'observer') return;
                updateCandidate();
              }}
              isLoading={true}
            />
          )}

          {candidateStatus === CANDIDATE_STATUS.UPDATED && (
            <PrimaryButton
              style={{ width: '295px' }}
              value={t('mosaic.go_and_get_the_updated')}
              onClick={() => {
                navigate('/segment-overview', {
                  state: { from: 'mosaic-candidate' }
                });
              }}
            />
          )}
        </Styles.UpdateCandidatesWrapper>
            )*/}
    </Styles.Container>
  );
};

const PartialSignificanceDescription = () => (
  <Styles.Chart.SignificantMessage>
    <Styles.Chart.SignificantMessageMain>
      <Styles.Chart.SignificantIcon src={WARNING_FILLED} />
      <Trans
        i18nKey={'mosaic.only_data_with_asterisks_are'}
        components={{
          a: (
            <LEGEND_INDICATOR_STAR
              color={theme.font.gray900}
              style={{ marginRight: '2px', marginLeft: '5px' }}
            />
          )
        }}
      />
    </Styles.Chart.SignificantMessageMain>
  </Styles.Chart.SignificantMessage>
);
const WholeSignificanceDescription = () => {
  const { t } = useTranslation();
  return (
    <Styles.Chart.SignificantMessage>
      <Styles.Chart.SignificantMessageMain>
        <Styles.Chart.SignificantIcon src={WARNING_FILLED} />
        {t('mosaic.this_is_statistically_not_significant')}
      </Styles.Chart.SignificantMessageMain>
      {/* <Styles.Chart.SignificantMessageSub>
        <Trans i18nKey={'mosaic.try_uploading_your_file_with'} />
      </Styles.Chart.SignificantMessageSub> */}
    </Styles.Chart.SignificantMessage>
  );
};
const ResultChart = ({ type, data, segments, legends, partialSignificantLegends }) => {
  return (
    <Chart
      chartInfo={{
        data,
        segments,
        legends,
        chartId: type === 'number' ? chartKeys.MOSAIC.BOX_PLOT : chartKeys.MOSAIC.BAR,
        partialSignificantLegends
      }}
    />
  );
};

const ChartContainer = ({
  chart,
  beingDeletedChartGroup,
  handleEdit,
  handleDelete,
  isEditContainerOpen,
  index,
  handleEditContainerToggle,
  tab
}) => {
  const { t } = useTranslation();

  let significanceDescription;
  let partialSignificantLegends = null; // null, {}
  let description;
  let content;

  // significant description을 지정하는 조건문
  if (chart.file.type !== 'text') {
    // number, boolean
    if (!chart.significant.feature_significance[chart.chart_group]) {
      significanceDescription = <WholeSignificanceDescription />;
    }
  } else {
    // text
    if (
      Object.values(chart.significant.feature_significance).filter((value) => value).length ===
      Object.values(chart.significant.feature_significance).length
    ) {
      significanceDescription = <></>;
    } else if (
      Object.values(chart.significant.feature_significance).filter((value) => !value).length ===
      Object.values(chart.significant.feature_significance).length
    ) {
      significanceDescription = <WholeSignificanceDescription />;
    } else {
      partialSignificantLegends = chart.significant.feature_significance;
      significanceDescription = <PartialSignificanceDescription />;
    }
  }

  /*
    - content 변수에 JSX 할당
      case 1 차트가 삭제되고 있는 경우 loading spinner 
      case 2 "More than one group is too small" message일 경우
      case 3 "Add data proportional to the group size." message일 경우
      case 4 default : chart display
  */
  if (beingDeletedChartGroup === chart.chart_group) {
    content = (
      <Styles.Chart.DeleteProcessingContainer>
        <Styles.Chart.DeleteProcessingIcon src={LOADING} />
      </Styles.Chart.DeleteProcessingContainer>
    );
  } else if (
    !chart.significant.requirement_pass &&
    chart.significant.requirement_pass_message === 'More than one group is too small.'
  ) {
    content = (
      <Styles.Chart.ContentContainer includeLastOrder={tab === 'history'}>
        <Styles.Chart.ChartContainer>
          <Styles.Chart.NoDataImage src={NEED_MORE_DATA} />
          <Styles.Chart.InvalidData>{t('mosaic.need_more_data')}</Styles.Chart.InvalidData>
        </Styles.Chart.ChartContainer>
        <Spacer horizontal size={60} />
        <Styles.Chart.BottomContainer>
          <Styles.Chart.ErrorMessage>
            <Styles.Chart.ErrorMessageIcon src={X_ICON} />
            {t(`mosaic.${labelToTranslationKey(chart.significant.requirement_pass_message)}`)}
          </Styles.Chart.ErrorMessage>

          <Spacer horizontal size={10} />
        </Styles.Chart.BottomContainer>
      </Styles.Chart.ContentContainer>
    );
  } else if (
    !chart.significant.requirement_pass &&
    chart.significant.requirement_pass_message === 'Add data proportional to the group size.'
  ) {
    content = (
      <Styles.Chart.ContentContainer includeLastOrder={tab === 'history'}>
        <Styles.Chart.ChartContainer>
          <ResultChart
            type={chart.file.type}
            data={chart.charts}
            segments={chart.segments}
            legends={chart.legends}
            partialSignificantLegends={partialSignificantLegends}
          />
        </Styles.Chart.ChartContainer>
        <Styles.Chart.BottomContainer>
          {significanceDescription}

          <Spacer horizontal size={10} />

          <Styles.Chart.ErrorMessage>
            <Styles.Chart.ErrorMessageIcon src={X_ICON} />
            {t(`mosaic.${labelToTranslationKey(chart.significant.requirement_pass_message)}`)}
          </Styles.Chart.ErrorMessage>

          <Spacer horizontal size={10} />
        </Styles.Chart.BottomContainer>
      </Styles.Chart.ContentContainer>
    );
  } else {
    content = (
      <Styles.Chart.ContentContainer includeLastOrder={tab === 'history'}>
        <Styles.Chart.ChartContainer>
          <ResultChart
            type={chart.file.type}
            data={chart.charts}
            segments={chart.segments}
            legends={chart.legends}
            partialSignificantLegends={partialSignificantLegends}
          />
        </Styles.Chart.ChartContainer>
        <Styles.Chart.BottomContainer>
          {significanceDescription}

          <Spacer horizontal size={10} />
        </Styles.Chart.BottomContainer>
      </Styles.Chart.ContentContainer>
    );
  }

  // metadata description
  if (chart.metadata?.description) {
    description = chart.metadata?.description;
  } else if (
    chart.significant.warnings?.includes('Dataset contains duplicated values for some customers')
  ) {
    description = t('mosaic.there_are_duplicate_customer_id');
  } else {
    description = t('mosaic.write_your_own_comments_here');
  }
  return (
    <Styles.Chart.Container>
      {tab === 'history' && (
        <Styles.Chart.LastOrder>
          {t('mosaic.last_order')}: {chart.lastOrder}
        </Styles.Chart.LastOrder>
      )}
      <Styles.Chart.MetaDataContainer>
        <Styles.Chart.Title>{chart.metadata?.title || chart.chart_group}</Styles.Chart.Title>
        <Styles.Chart.Description>{description}</Styles.Chart.Description>
        <Styles.Chart.EditImage
          src={MOSAIC_EDIT}
          onClick={() => handleEditContainerToggle(index)}
        />
        {isEditContainerOpen && (
          <Styles.Chart.EditContainer>
            <Styles.Chart.EditContainerEditButton
              onClick={handleEdit({
                chartGroup: chart.chart_group,
                title: chart.metadata?.title || chart.chart_group,
                description: chart.metadata?.description,
                dashboardId: chart.dashboardId
              })}
            >
              {t('mosaic.edit')}
            </Styles.Chart.EditContainerEditButton>
            <Styles.Chart.EditContainerDeleteButton
              onClick={handleDelete({
                chartGroup: chart.chart_group,
                dashboardId: chart.dashboardId
              })}
            >
              {t('mosaic.delete')}
            </Styles.Chart.EditContainerDeleteButton>
          </Styles.Chart.EditContainer>
        )}
      </Styles.Chart.MetaDataContainer>
      <Spacer horizontal size={20} />
      {content}
    </Styles.Chart.Container>
  );
};

export default Result;
