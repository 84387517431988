/**
 * Follow alphabethical order
 */
import { ReactComponent as Ai } from 'assets/icons/ai.svg';
import { ReactComponent as Analysis } from 'assets/icons/analysis.svg';
import ArrowDown from 'assets/icons/arrow_down.svg';
import Bell from 'assets/icons/bell.svg';
import { ReactComponent as CaretUpFilled24px } from 'assets/icons/caret_up_filled_24px.svg';
import CheckFilled from 'assets/icons/check_filled.svg';
import { ReactComponent as ChevronDoubleDown } from 'assets/icons/chevron_double_down.svg';
import ChevronDown18px from 'assets/icons/chevron_down_18px.svg';
import ChevronRight18px from 'assets/icons/chevron_right_18px.svg';
import ChevronDown from 'assets/icons/chevron_down.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron_right.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron_up.svg';
import CohortTooltipLtv from 'assets/icons/cohort_tooltip_ltv.svg';
import CohortTooltipRetention from 'assets/icons/cohort_tooltip_retention.svg';
import CreateNewButton from 'assets/icons/create_new_button.svg';
import DashboardIsComing from 'assets/icons/dashboard_is_coming.svg';
import Delete from 'assets/icons/delete.svg';
import Edit from 'assets/icons/edit.svg';
import Error404 from 'assets/icons/error_404.png';
import ErrorFilled from 'assets/icons/error_filled.svg';
import ExportButton from 'assets/icons/export_button.svg';
import Export from 'assets/icons/export.svg';
import EyeOff from 'assets/icons/eye_off.svg';
import Eye from 'assets/icons/eye.svg';
import File from 'assets/icons/file.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import Info24pxRed from 'assets/icons/info_24px_red.svg';
import Info24px from 'assets/icons/info_24px.svg';
import Info from 'assets/icons/info.svg';
import InputValidMark from 'assets/icons/input_valid_mark.svg';
import ItemJourneyPlus from 'assets/icons/item_journey_plus.svg';
import ItemJourneyPlusCircle from 'assets/icons/item_journey_plus_circle.svg';
import LegendCheckboxCheck from 'assets/icons/legend_checkbox-check.svg';
import { ReactComponent as LegendIndicatorCircle } from 'assets/icons/legend_indicator_circle.svg';
import LegendIndicatorFilled from 'assets/icons/legend_indicator_filled.svg';
import LegendIndicatorFilledRect from 'assets/icons/legend_indicator_filled_rect.svg';
import LegendIndicatorDashed from 'assets/icons/legend_indicator_dashed.svg';
import LegendIndicatorDashedRect from 'assets/icons/legend_indicator_dashed_rect.svg';
import { ReactComponent as LegendIndicatorStar } from 'assets/icons/legend_indicator_star.svg';
import LinkToManual from 'assets/icons/link_to_manual.svg';
import Loading from 'assets/icons/loading.svg';
import MembersRemove from 'assets/icons/members_remove.svg';
import MembersSave from 'assets/icons/members_save.svg';
import ModalSpinner from 'assets/icons/modal_spinner.svg';
import ModalXButton from 'assets/icons/modal_x_button.svg';
import MosaicEdit from 'assets/icons/mosaic_edit.svg';
import NeedMoreData from 'assets/icons/need_more_data.svg';
import NoData from 'assets/icons/no_data.svg';
import RemoveText from 'assets/icons/remove_text.svg';
import { ReactComponent as Report } from 'assets/icons/report.svg';
import Search from 'assets/icons/search.svg';
import SegmentationChurn from 'assets/icons/segmentation_churn.svg';
import SegmentationPopulation from 'assets/icons/segmentation_population.svg';
import SegmentationGuideTooltip from 'assets/icons/segmentation-guide-tooltip.gif';
import { ReactComponent as SidebarAiRecommendation } from 'assets/icons/sidebar_ai_recommendation.svg';
import { ReactComponent as SidebarBusinessOverview } from 'assets/icons/sidebar_business_overview.svg';
import SidebarClose from 'assets/icons/sidebar_close.svg';
import { ReactComponent as SidebarIndepthAnalysis } from 'assets/icons/sidebar_indepth_analysis.svg';
import { ReactComponent as SidebarMakedashyReports } from 'assets/icons/sidebar_makedashy_reports.svg';
import { ReactComponent as SidebarSegment } from 'assets/icons/sidebar_segment.svg';
import { ReactComponent as TableTab } from 'assets/icons/table_tab.svg';
import { ReactComponent as ThumbsUp } from 'assets/icons/thumbs_up.svg';
import { ReactComponent as ThumbsDown } from 'assets/icons/thumbs_down.svg';
import ToastClose from 'assets/icons/toast_close.svg';
import ToastDefault from 'assets/icons/toast_default.svg';
import ToastError from 'assets/icons/toast_error.svg';
import ToastLoading from 'assets/icons/toast_loading.svg';
import ToastSuccess from 'assets/icons/toast_success.svg';
import VariationNegative from 'assets/icons/variation_negative.svg';
import VariationPositive from 'assets/icons/variation_positive.svg';
import WarningFilled from 'assets/icons/warning_filled.svg';
import X_icon from 'assets/icons/x_icon.svg';
import Language from 'assets/icons/language.svg';

export const AI = Ai;
export const ANALYSIS = Analysis;
export const ARROW_DOWN = ArrowDown;
export const BELL = Bell;
export const CARETUPFILLED24PX = CaretUpFilled24px;
export const CHECK_FILLED = CheckFilled;
export const CHEVRONDOUBLEDOWN = ChevronDoubleDown;
export const CHEVRON_DOWN_18px = ChevronDown18px;
export const CHEVRON_RIGHT_18px = ChevronRight18px;
export const CHEVRON_DOWN = ChevronDown;
export const CHEVRON_RIGHT = ChevronRight;
export const CHEVRON_UP = ChevronUp;
export const COHORT_TOOLTIP_LTV = CohortTooltipLtv;
export const COHORT_TOOLTIP_RETENTION = CohortTooltipRetention;
export const CREATE_NEW_BUTTON = CreateNewButton;
export const DASHBOARD_IS_COMING = DashboardIsComing;
export const DELETE = Delete;
export const EDIT = Edit;
export const ERROR_404 = Error404;
export const ERROR_FILLED = ErrorFilled;
export const EXPORT_BUTTON = ExportButton;
export const EXPORT = Export;
export const EYE_OFF = EyeOff;
export const EYE = Eye;
export const FILE = File;
export const HOME = Home;
export const INFO24PXRED = Info24pxRed;
export const INFO24PX = Info24px;
export const INFO = Info;
export const INPUT_VALID_MARK = InputValidMark;
export const ITEM_JOURNEY_PLUS = ItemJourneyPlus;
export const ITEM_JOURNEY_PLUS_CIRCLE = ItemJourneyPlusCircle;
export const LEGEND_CHECKBOX_CHECK = LegendCheckboxCheck;
export const LEGEND_INDICATOR_CIRCLE = LegendIndicatorCircle;
export const LEGEND_INDICATOR_FILLED = LegendIndicatorFilled;
export const LEGEND_INDICATOR_FILLED_RECT = LegendIndicatorFilledRect;
export const LEGEND_INDICATOR_DASHED = LegendIndicatorDashed;
export const LEGEND_INDICATOR_DASHED_RECT = LegendIndicatorDashedRect;
export const LEGEND_INDICATOR_STAR = LegendIndicatorStar;
export const LINKTOMANUAL = LinkToManual;
export const LOADING = Loading;
export const MEMBERS_REMOVE = MembersRemove;
export const MEMBERS_SAVE = MembersSave;
export const MODAL_SPINNER = ModalSpinner;
export const MODAL_X_BUTTON = ModalXButton;
export const MOSAIC_EDIT = MosaicEdit;
export const NEED_MORE_DATA = NeedMoreData;
export const NODATA = NoData;
export const REMOVETEXT = RemoveText;
export const REPORT = Report;
export const SEARCH = Search;
export const SEGMENTATION_CHURN = SegmentationChurn;
export const SEGMENTATION_POPULATION = SegmentationPopulation;
export const SEGMENTATION_GUIDE_TOOLTIP = SegmentationGuideTooltip;
export const SIDEBAR_AI_RECOMMENDATION = SidebarAiRecommendation;
export const SIDEBAR_BUSINESS_OVERVIEW = SidebarBusinessOverview;
export const SIDEBAR_CLOSE = SidebarClose;
export const SIDEBAR_INDEPTH_ANALYSIS = SidebarIndepthAnalysis;
export const SIDEBAR_MAKEDASHY_REPORTS = SidebarMakedashyReports;
export const SIDEBAR_SEGMENT = SidebarSegment;
export const TABLETAB = TableTab;
export const THUMBSDOWN = ThumbsDown;
export const THUMBSUP = ThumbsUp;
export const TOASTCLOSE = ToastClose;
export const TOASTDEFAULT = ToastDefault;
export const TOASTERROR = ToastError;
export const TOASTSUCCESS = ToastSuccess;
export const TOASTLOADING = ToastLoading;
export const VARIATION_NEGATIVE = VariationNegative;
export const VARIATION_POSITIVE = VariationPositive;
export const WARNING_FILLED = WarningFilled;
export const X_ICON = X_icon;
export const LANGUAGE = Language;
